import React, { useCallback } from 'react';
import { Table, Tbody, Th, Thead, Tr } from '@patternfly/react-table';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@patternfly/react-icons';
import { Button, Level, LevelItem, Toolbar, ToolbarContent, ToolbarItem } from '@patternfly/react-core';
import { useDispatch, useSelector } from 'react-redux';

import { StoreState } from '../../../store/store';
import { imageSelectors, newDicomTagAdded } from '../../../store/image/imageSlice';
import DicomTagTableItem from './DicomTagTableItem';

/** Ids for different types of dose target table columns. */
export const columnIds = {
    dicomAttribute: 'DICOM attribute',
    dicomValue: 'DICOM value',
};

/** Styling names for dose target table columns. */
export const columnCssNames = {
    modified: 'column-modified',
    dicomAttribute: 'column-dicom-attribute',
    dicomValue: 'column-dicom-value',
}


interface DicomTagTableProps {
    dicomRestrictionId: string;
    dicomTagIds: string[];
}

const DicomTagTable = (props: DicomTagTableProps) => {

    const { dicomRestrictionId, dicomTagIds } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const dicomRestriction = useSelector((state: StoreState) => imageSelectors.selectDicomRestrictionById(state, dicomRestrictionId));

    const handleAddDicomTag = useCallback(() => {
        dispatch(newDicomTagAdded(dicomRestrictionId));
    }, [dispatch, dicomRestrictionId]);

    if (dicomRestriction === undefined) {
        return null;
    }

    return (
        <div>
            <div>
                <Table variant='compact' isStickyHeader className="mv-sticky-fix">
                    <Thead>
                        <Tr className='customization-form-table unselectable'>
                            <Th aria-label='Is modified?' className={columnCssNames.modified}></Th>
                            <Th aria-label='DICOM attribute' className={columnCssNames.dicomAttribute}>{t('customizationPage.image.dicomRestrictionSection.dicomTags.attribute.title')}</Th>
                            <Th aria-label='DICOM value' className={columnCssNames.dicomValue}>{t('customizationPage.image.dicomRestrictionSection.dicomTags.value.title')}</Th>
                            <Th aria-label='Action menu'></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {dicomTagIds.map(tId => <DicomTagTableItem dicomTagId={tId} key={tId} dicomTagIdList={dicomTagIds} />)}
                    </Tbody>
                </Table>
            </div>

            <div>
                <Level>
                    <LevelItem>
                        <Toolbar>
                            <ToolbarContent>
                                <ToolbarItem>
                                    <Button
                                        size="sm"
                                        variant="secondary"
                                        icon={<PlusIcon />}
                                        // isDisabled={isToolbarDisabled}
                                        onClick={handleAddDicomTag}>{t('customizationPage.image.dicomRestrictionSection.dicomTags.addNewDicomTag')}</Button>
                                </ToolbarItem>

                            </ToolbarContent>
                        </Toolbar>
                    </LevelItem>
                </Level>
            </div>

        </div>
    );
}

export default DicomTagTable;
