import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { AeTitleRule, CustomizationBase, CustomizationObjectType, DicomAttributeRule, DicomRule, Model, ModelVisibility, OutputMetadataItem } from "../global-types/customization-types";
import { BackendValidationErrorViewModel, FormValidationError } from "../global-types/store-errors";
import { has, isObject } from "lodash-es";
import { naturalSort } from "../../util/sort";
import { AdaptCustomizationEntities, AdaptCustomizationOutput, AdaptRoiRule, SelectionInclusion } from "./adapt-types";
import { AdaptSliceWrapper } from "../global-types/modelTypeReducerHelper/adaptSliceWrapper";
import { setObjectAndAncestorsAsModified } from "../global-types/reducer-helpers";
import { defaultCreateNewAdaptRoiRule } from "./adapt-helpers";

// entity adapters (see https://redux-toolkit.js.org/api/createEntityAdapter) for adapt customization types
export const modelAdapter = createEntityAdapter<Model>();
export const customizationBaseAdapter = createEntityAdapter<CustomizationBase>();
export const adaptOutputAdapter = createEntityAdapter<AdaptCustomizationOutput>();
export const outputMetadataAdapter = createEntityAdapter<OutputMetadataItem>();

export const adaptRoiRuleAdapter = createEntityAdapter<AdaptRoiRule>();

export const aeTitleRuleAdapter = createEntityAdapter<AeTitleRule>();
export const dicomRuleAdapter = createEntityAdapter<DicomRule>();
export const dicomAttributeRuleAdapter = createEntityAdapter<DicomAttributeRule>();

// entity adapter selectors -- these should not be exported/used directly but rather through the slice selectors
export const modelAdapterSelectors = modelAdapter.getSelectors((state: AdaptSliceState) => state.models);
export const customizationBaseAdapterSelectors = customizationBaseAdapter.getSelectors((state: AdaptSliceState) => state.customizationBases);
export const adaptOutputAdapterSelectors = adaptOutputAdapter.getSelectors((state: AdaptSliceState) => state.adaptOutputs);
export const outputMetadataAdapterSelectors = outputMetadataAdapter.getSelectors((state: AdaptSliceState) => state.outputMetadata);

export const adaptRoiRuleAdapterSelectors = adaptRoiRuleAdapter.getSelectors((state: AdaptSliceState) => state.adaptRoiRules);

export const aeTitleRuleAdapterSelectors = aeTitleRuleAdapter.getSelectors((state: AdaptSliceState) => state.aeTitleRules);
export const dicomRuleAdapterSelectors = dicomRuleAdapter.getSelectors((state: AdaptSliceState) => state.dicomRules);
export const dicomAttributeRuleAdapterSelectors = dicomAttributeRuleAdapter.getSelectors((state: AdaptSliceState) => state.dicomAttributeRules);


/** An entity adapter for CRUD operations for backend-based customization validation errors */
export const backendValidationErrorAdapter = createEntityAdapter<BackendValidationErrorViewModel>();
/** An entity adapter for CRUD operations for UI-supplied customization validation errors */
export const formValidationErrorAdapter = createEntityAdapter<FormValidationError>();

// selectors for 'original' data
export const originalOutputMetadataAdapterSelectors = outputMetadataAdapter.getSelectors((state: AdaptSliceState) => state.originalOutputMetadata);
export const originalAdaptRoiRuleAdapterSelectors = adaptRoiRuleAdapter.getSelectors((state: AdaptSliceState) => state.originalAdaptRoiRules);

// validation error selectors
export const customizationValidationErrorAdapterSelectors = backendValidationErrorAdapter.getSelectors((state: AdaptSliceState) => state.backendValidationErrors);
export const formValidationErrorAdapterSelectors = formValidationErrorAdapter.getSelectors((state: AdaptSliceState) => state.formValidationErrors);


/** Type guard for AdaptSliceState type. */
export const isAdaptSliceState = (obj: any): obj is AdaptSliceState => {
    return has(obj, 'models') &&
        has(obj, 'adaptOutputs') &&
        has(obj, 'adaptRoiRules') &&
        has(obj, 'adaptRoiRules.entities') &&
        isObject(obj.adaptRoiRules.entities);
}


export type AdaptSliceState = {
    /** In-memory CRUD-based normalized data structure for customization objects modelling adapt models. */
    models: EntityState<Model, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization base objects. */
    customizationBases: EntityState<CustomizationBase, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization outputs. */
    adaptOutputs: EntityState<AdaptCustomizationOutput, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization metadata. */
    outputMetadata: EntityState<OutputMetadataItem, string>,

    /** In-memory CRUD-based normalized data structure for customization objects modelling adapt+ ROI rule objects. */
    adaptRoiRules: EntityState<AdaptRoiRule, string>,

    /** In-memory CRUD-based normalized data structure for customization objects modelling AE Title-based model selection rules. */
    aeTitleRules: EntityState<AeTitleRule, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling DICOM-based model selection rules. */
    dicomRules: EntityState<DicomRule, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling DICOM attribute rules within DICOM-based model selection rules. */
    dicomAttributeRules: EntityState<DicomAttributeRule, string>,



    // these are for storing data directly from the backend, so any local modifications can be easily reverted back to them

    /** Original, unmodified directly-from-backend versions of adapt+ model customization data objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalModels: EntityState<Model, string>;
    /** Original, unmodified directly-from-backend versions of adapt+ customization base data objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalCustomizationBases: EntityState<CustomizationBase, string>;
    /** Original, unmodified directly-from-backend versions of adapt+ customization output data objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalAdaptOutputs: EntityState<AdaptCustomizationOutput, string>;
    /** Original, unmodified directly-from-backend versions of adapt+ customization metadata objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalOutputMetadata: EntityState<OutputMetadataItem, string>;

    /** Original, unmodified directly-from-backend versions of adapt+ ROI rule objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalAdaptRoiRules: EntityState<AdaptRoiRule, string>,

    /** Original, unmodified directly-from-backend versions of adapt+ AE Title rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalAeTitleRules: EntityState<AeTitleRule, string>;
    /** Original, unmodified directly-from-backend versions of adapt+ DICOM rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalDicomRules: EntityState<DicomRule, string>;
    /** Original, unmodified directly-from-backend versions of adapt+ DICOM attribute rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalDicomAttributeRules: EntityState<DicomAttributeRule, string>;


    /** Adapt++ customization validation errors coming from backend. */
    backendValidationErrors: EntityState<BackendValidationErrorViewModel, string>,
    /** Adapt+ customization validation errors generated in UI (in "forms"). */
    formValidationErrors: EntityState<FormValidationError, string>,

    /** An error that occurred when fetching adapt+ customizations, or null. */
    customizationFetchError: string | null,

    /** Saving of model customizations to backend is in progress if true, false otherwise. */
    isModelCustomizationSavingInProgress: boolean,

    /** True if resetting all adapt+ customizations to factory defaults is currently in progress, false otherwise. */
    isAllModelCustomizationsResetInProgress: boolean,

    /** True if resetting a single adapt+ customization output to factory defaults is currently in progress, false otherwise. */
    isOutputResetInProgress: boolean,

    /** Model customization save error, if any. */
    modelCustomizationSaveError: string | null,

    /** An error regarding model customization data that's not directly related to saving */
    modelCustomizationDataError: string | null,

    /** An error message regarding exporting or importing entire customization config to/from JSON */
    customizationImportExportError: string | null,
};



export const initialState: AdaptSliceState = {
    models: modelAdapter.getInitialState(),
    customizationBases: customizationBaseAdapter.getInitialState(),
    adaptOutputs: adaptOutputAdapter.getInitialState(),
    outputMetadata: outputMetadataAdapter.getInitialState(),

    adaptRoiRules: adaptRoiRuleAdapter.getInitialState(),

    aeTitleRules: aeTitleRuleAdapter.getInitialState(),
    dicomRules: dicomRuleAdapter.getInitialState(),
    dicomAttributeRules: dicomAttributeRuleAdapter.getInitialState(),

    originalModels: modelAdapter.getInitialState(),
    originalCustomizationBases: customizationBaseAdapter.getInitialState(),
    originalAdaptOutputs: adaptOutputAdapter.getInitialState(),
    originalOutputMetadata: outputMetadataAdapter.getInitialState(),
    originalAdaptRoiRules: adaptRoiRuleAdapter.getInitialState(),
    originalAeTitleRules: aeTitleRuleAdapter.getInitialState(),
    originalDicomRules: dicomRuleAdapter.getInitialState(),
    originalDicomAttributeRules: dicomAttributeRuleAdapter.getInitialState(),

    backendValidationErrors: backendValidationErrorAdapter.getInitialState(),
    formValidationErrors: formValidationErrorAdapter.getInitialState(),

    customizationFetchError: null,
    isModelCustomizationSavingInProgress: false,
    isAllModelCustomizationsResetInProgress: false,
    isOutputResetInProgress: false,
    modelCustomizationSaveError: null,
    modelCustomizationDataError: null,
    customizationImportExportError: null,
};



const sliceWrapper = new AdaptSliceWrapper();



/** Redux store slice for interacting with adapt+ configuration and customization. */
const adaptSlice = createSlice({
    name: 'adapt',
    initialState,
    reducers: {
        /**
         * Sets current adapt model customization entities to provided values. This is used when initializing, swapping, or
         * resetting entire model customizations loaded into memory.
         * @param action.customizations All adapt-related model customization entities to load into memory. If set to undefined then
         * in-model entities are reset to default empty values.
         * @param action.errorMessage Optional error message.
         */
        modelCustomizationsSet(state, action: PayloadAction<{ customizations: AdaptCustomizationEntities | null, errorMessage?: string }>) {
            const { customizations, errorMessage } = action.payload;

            if (customizations !== null) {
                // set everything according to input
                modelAdapter.setAll(state.models, customizations.models);
                customizationBaseAdapter.setAll(state.customizationBases, customizations.customizationBases);
                adaptOutputAdapter.setAll(state.adaptOutputs, customizations.adaptOutputs);
                outputMetadataAdapter.setAll(state.outputMetadata, customizations.outputMetadata);

                adaptRoiRuleAdapter.setAll(state.adaptRoiRules, customizations.adaptRoiRules);

                aeTitleRuleAdapter.setAll(state.aeTitleRules, customizations.aeTitleRules);
                dicomRuleAdapter.setAll(state.dicomRules, customizations.dicomRules);
                dicomAttributeRuleAdapter.setAll(state.dicomAttributeRules, customizations.dicomAttributeRules);

                // set original collections, also, so we can revert changes to the actual collection with ease
                modelAdapter.setAll(state.originalModels, customizations.models);
                customizationBaseAdapter.setAll(state.originalCustomizationBases, customizations.customizationBases);
                adaptOutputAdapter.setAll(state.originalAdaptOutputs, customizations.adaptOutputs);
                outputMetadataAdapter.setAll(state.originalOutputMetadata, customizations.outputMetadata);

                adaptRoiRuleAdapter.setAll(state.originalAdaptRoiRules, customizations.adaptRoiRules);

                aeTitleRuleAdapter.setAll(state.originalAeTitleRules, customizations.aeTitleRules);
                dicomRuleAdapter.setAll(state.originalDicomRules, customizations.dicomRules);
                dicomAttributeRuleAdapter.setAll(state.originalDicomAttributeRules, customizations.dicomAttributeRules);
            } else {
                // set everything to default values
                modelAdapter.setAll(state.models, modelAdapter.getInitialState().entities as Record<string, Model>);
                customizationBaseAdapter.setAll(state.customizationBases, customizationBaseAdapter.getInitialState().entities as Record<string, CustomizationBase>);
                adaptOutputAdapter.setAll(state.adaptOutputs, adaptOutputAdapter.getInitialState().entities as Record<string, AdaptCustomizationOutput>);
                outputMetadataAdapter.setAll(state.outputMetadata, outputMetadataAdapter.getInitialState().entities as Record<string, OutputMetadataItem>);

                adaptRoiRuleAdapter.setAll(state.adaptRoiRules, adaptRoiRuleAdapter.getInitialState().entities as Record<string, AdaptRoiRule>);

                aeTitleRuleAdapter.setAll(state.aeTitleRules, aeTitleRuleAdapter.getInitialState().entities as Record<string, AeTitleRule>);
                dicomRuleAdapter.setAll(state.dicomRules, dicomRuleAdapter.getInitialState().entities as Record<string, DicomRule>);
                dicomAttributeRuleAdapter.setAll(state.dicomAttributeRules, dicomAttributeRuleAdapter.getInitialState().entities as Record<string, DicomAttributeRule>);

                // set original collections, also, so we can revert changes to the actual collection with ease
                modelAdapter.setAll(state.originalModels, modelAdapter.getInitialState().entities as Record<string, Model>);
                customizationBaseAdapter.setAll(state.originalCustomizationBases, customizationBaseAdapter.getInitialState().entities as Record<string, CustomizationBase>);
                adaptOutputAdapter.setAll(state.originalAdaptOutputs, adaptOutputAdapter.getInitialState().entities as Record<string, AdaptCustomizationOutput>);
                outputMetadataAdapter.setAll(state.originalOutputMetadata, outputMetadataAdapter.getInitialState().entities as Record<string, OutputMetadataItem>);

                adaptRoiRuleAdapter.setAll(state.originalAdaptRoiRules, adaptRoiRuleAdapter.getInitialState().entities as Record<string, AdaptRoiRule>);

                aeTitleRuleAdapter.setAll(state.originalAeTitleRules, aeTitleRuleAdapter.getInitialState().entities as Record<string, AeTitleRule>);
                dicomRuleAdapter.setAll(state.originalDicomRules, dicomRuleAdapter.getInitialState().entities as Record<string, DicomRule>);
                dicomAttributeRuleAdapter.setAll(state.originalDicomAttributeRules, dicomAttributeRuleAdapter.getInitialState().entities as Record<string, DicomAttributeRule>);
            }

            state.customizationFetchError = errorMessage !== undefined ? errorMessage : null;
            if (errorMessage === undefined) {
                // clear any errors
                backendValidationErrorAdapter.removeAll(state.backendValidationErrors);
                formValidationErrorAdapter.removeAll(state.formValidationErrors);

                console.log('TODO 1: implement initial validation')
                // // calculate initial form validation errors
                // for (const outputId of state.adaptOutputs.ids) {
                //     sliceWrapper.performFormValidationForOutput(state, outputId);
                // }
            }
        },

        /**
         * Updates a adapt model's visibility.
         * @param action.modelId Internal (entity adapter) ID of the adapt model to update.
         * @param action.visibility The new visibility setting for the adapt model.
         */
        modelVisibilityUpdated(state, action: PayloadAction<{ modelId: string, visibility: ModelVisibility }>) {
            const { modelId, visibility } = action.payload;
            sliceWrapper.updateModelVisibility(state, modelId, visibility);
        },

        /**
        * Updates the description field on a customization base object.
        * @param action.customizationBaseId Internal (entity adapter) ID of the customization base to update.
        * @param action.description The new description to set.
        */
        modelCustomizationDescriptionUpdated(state, action: PayloadAction<{ customizationBaseId: string, description: string }>) {
            const { customizationBaseId, description } = action.payload;
            sliceWrapper.updateModelCustomizationDescription(state, customizationBaseId, description);
        },

        /**
         * Updates an output metadata item tuple. If either attribute or value is left as undefined then that particular prop is not updated
         * and the existing prop is left unchanged.
         * @param action.metadataId Internal (entity adapter) ID of the output metadata item that will be updated.
         * @param action.attribute The new attribute name of the metadata item that will be used, or undefined if attribute should not be changed.
         * @param action.value The new value of the metadata item, or undefined if value should not be changed.
         * @param action.isUndoOperation Marks this update as part of an undo operation. Note that the correct reverted attribute and value props
         * must be supplied to this function manually, this function does not perform an undo by itself. Optional, defaults to false.
         */
        metadataItemUpdated(state, action: PayloadAction<{ metadataId: string, attribute: string | undefined, value: string | undefined, isUndoOperation?: boolean }>) {
            const { metadataId, attribute, value, isUndoOperation } = action.payload;
            sliceWrapper.updateMetadataItem(state, metadataId, attribute, value, isUndoOperation);
        },

        /**
         * Removes an output metadata item.
         * @param action.metadataId Internal (entity adapter) ID of the output metadata item that will be removed.
         */
        metadataItemRemoved(state, action: PayloadAction<string>) {
            const metadataId = action.payload;
            sliceWrapper.removeMetadataItem(state, metadataId);
        },

        /**
         * Removes a tag from a (newly created) customization metadata item that would mark it as being the target of being
         * automatically scrolled to in UI.
         * 
         * This tag is generally given to metadata items after their creation in the UI and should be removed once
         * the UI has scrolled to the correct position in the UI once.
         * 
         * @param action Internal (entity adapter) ID of the customization metadata item where this tag is to be removed.
         */
        scrollToViewFromModelCustomizationMetadataRemoved(state, action: PayloadAction<string>) {
            const metadataId = action.payload;
            sliceWrapper.removeScrollToViewFromMetadata(state, metadataId);
        },

        /**
        * Adds supplied AE Title rule into adapt customization configuration.
        * @param action The AE Title rule to add.
        */
        aeTitleRuleAdded(state, action: PayloadAction<AeTitleRule>) {
            const aeTitleRule = action.payload;
            sliceWrapper.addAeTitleRule(state, aeTitleRule);
        },

        /**
         * Updates the 'action' field in an AE Title rule.
         * @param action.id Internal (entity adapter) ID of the AE Title rule to update.
         * @param action.action The new action value for the rule.
         */
        aeTitleRuleActionUpdated(state, action: PayloadAction<{ id: string, action: string }>) {
            const { id, action: newAction } = action.payload;
            sliceWrapper.updateAeTitleRuleAction(state, id, newAction);
        },

        /**
         * Removes an AE Title rule from adapt customization configuration.
         * Throws if the AE Title rule to be removed is not found from configuration.
         * @param action Internal (entity adapter) ID of the AE Title rule to remove.
         */
        aeTitleRuleRemoved(state, action: PayloadAction<string>) {
            const id = action.payload;
            sliceWrapper.removeAeTitleRule(state, id);
        },

        /**
         * Adds supplied DICOM rule into adapt customization configuration.
         * Note that DICOM attribute rules under a DICOM rule must be added separately.
         * @param action The DICOM rule to add.
         */
        dicomRuleAdded(state, action: PayloadAction<DicomRule>) {
            const dicomRule = action.payload;
            sliceWrapper.addDicomRule(state, dicomRule);
        },

        /**
         * Removes a DICOM rule from adapt customization configuration. Also removes any DICOM attribute
         * rules under the DICOM rule.
         * Throws if the DICOM rule to be removed is not found from configuration.
         * @param action Internal (entity adapter) ID of the DICOM rule to remove.
         */
        dicomRuleRemoved(state, action: PayloadAction<string>) {
            const id = action.payload;
            sliceWrapper.removeDicomRule(state, id);
        },

        /**
        * Adds supplied DICOM attribute rule into adapt customization configuration.
        * Note that the parent DICOM rule must already exist in configuration or this function will throw.
        * @param action The DICOM attribute rule to add.
        */
        dicomAttributeRuleAdded(state, action: PayloadAction<DicomAttributeRule>) {
            const dicomAttributeRule = action.payload;
            sliceWrapper.addDicomAttributeRule(state, dicomAttributeRule);
        },

        /**
         * Removes a DICOM attribute rule from adapt customization configuration.
         * Throws if the DICOM attribute rule to be removed or its parent DICOM rule are not found from configuration.
         * @param action Internal (entity adapter) ID of the DICOM attribute rule to remove.
         */
        dicomAttributeRuleRemoved(state, action: PayloadAction<string>) {
            const id = action.payload;
            sliceWrapper.removeDicomAttributeRule(state, id);
        },

        /**
         * Updates a DICOM attribute rule.
         * @param action.id Internal (entity adapter) ID of the DICOM attribute rule to update.
         * @param action.dicomAttribute The attribute field of the rule is updated to this value.
         * @param action.dicomValue The DICOM value field of the rule is updated to this value.
         */
        dicomAttributeRuleUpdated(state, action: PayloadAction<{ id: string, dicomAttribute: string, dicomValue: string }>) {
            const { id, dicomAttribute, dicomValue } = action.payload;
            sliceWrapper.updateDicomAttributeRule(state, id, dicomAttribute, dicomValue);
        },

        /**
         * Updates an adapt output's smoothing sigma setting.
         * @param action.id ID of the adapt+ customization output.
         * @param action.smoothingSigma The smoothing sigma to set -- either a number or null.
         */
        smoothingSigmaSet(state, action: PayloadAction<{ id: string, smoothingSigma: number | null }>) {
            const { id, smoothingSigma } = action.payload;

            const output = state.adaptOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find adapt output with id ${id}`); }

            adaptOutputAdapter.updateOne(state.adaptOutputs, { id: id, changes: { smoothingSigma } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Updates an adapt output's isValidMaskIncluded setting.
         * @param action.id ID of the adapt+ customization output.
         * @param action.isIncluded True to include, false to exclude.
         */
        isValidMaskIncludedSet(state, action: PayloadAction<{ id: string, isIncluded: boolean }>) {
            const { id, isIncluded } = action.payload;

            const output = state.adaptOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find adapt output with id ${id}`); }

            adaptOutputAdapter.updateOne(state.adaptOutputs, { id: id, changes: { isValidMaskIncluded: isIncluded } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Updates an adapt output's expected result setting.
         * @param action.id ID of the adapt+ customization output.
         * @param action.areInputsReturned True to include inputs, false to exclude.
         */
        expectedResultAreInputsReturnedSet(state, action: PayloadAction<{ id: string, areInputsReturned: boolean }>) {
            const { id, areInputsReturned } = action.payload;

            const output = state.adaptOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find adapt output with id ${id}`); }

            adaptOutputAdapter.updateOne(state.adaptOutputs, { id: id, changes: { expectedResult: { ...output.expectedResult, areInputsReturned } } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Updates an adapt output's expected result setting.
         * @param action.id ID of the adapt+ customization output.
         * @param action.areResultsCompressedAsZip True to compress as zip, false otherwise.
         */
        expectedResultAreResultsCompressedAsZipSet(state, action: PayloadAction<{ id: string, areResultsCompressedAsZip: boolean }>) {
            const { id, areResultsCompressedAsZip } = action.payload;

            const output = state.adaptOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find adapt output with id ${id}`); }

            adaptOutputAdapter.updateOne(state.adaptOutputs, { id: id, changes: { expectedResult: { ...output.expectedResult, areResultsCompressedAsZip } } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Updates an adapt output's expected result setting.
         * @param action.id ID of the adapt+ customization output.
         * @param action.isRegFileReturned True to return REG file, false otherwise.
         */
        expectedResultIsRegFileReturnedSet(state, action: PayloadAction<{ id: string, isRegFileReturned: boolean }>) {
            const { id, isRegFileReturned } = action.payload;

            const output = state.adaptOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find adapt output with id ${id}`); }

            adaptOutputAdapter.updateOne(state.adaptOutputs, { id: id, changes: { expectedResult: { ...output.expectedResult, isRegFileReturned } } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Updates an adapt output's expected result setting.
         * @param action.id ID of the adapt+ customization output.
         * @param action.isRegisteredScanReturned True to return registered CT/MR/PT scan file, false otherwise.
         */
        expectedResultIsRegisteredScanReturnedSet(state, action: PayloadAction<{ id: string, isRegisteredScanReturned: boolean }>) {
            const { id, isRegisteredScanReturned } = action.payload;

            const output = state.adaptOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find adapt output with id ${id}`); }

            adaptOutputAdapter.updateOne(state.adaptOutputs, { id: id, changes: { expectedResult: { ...output.expectedResult, isRegisteredScanReturned } } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Updates an adapt output's expected result setting.
         * @param action.id ID of the adapt+ customization output.
         * @param action.isRTDoseReturned True to return RTDOSE file, false otherwise.
         */
        expectedResultIsRTDoseReturnedSet(state, action: PayloadAction<{ id: string, isRTDoseReturned: boolean }>) {
            const { id, isRTDoseReturned } = action.payload;

            const output = state.adaptOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find adapt output with id ${id}`); }

            adaptOutputAdapter.updateOne(state.adaptOutputs, { id: id, changes: { expectedResult: { ...output.expectedResult, isRTDoseReturned } } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Updates an adapt output's expected result setting.
         * @param action.id ID of the adapt+ customization output.
         * @param action.isRTPlanReturned True to return RTPLAN file, false otherwise.
         */
        expectedResultIsRTPlanReturnedSet(state, action: PayloadAction<{ id: string, isRTPlanReturned: boolean }>) {
            const { id, isRTPlanReturned } = action.payload;

            const output = state.adaptOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find adapt output with id ${id}`); }

            adaptOutputAdapter.updateOne(state.adaptOutputs, { id: id, changes: { expectedResult: { ...output.expectedResult, isRTPlanReturned } } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Updates an adapt output's expected result setting.
         * @param action.id ID of the adapt+ customization output.
         * @param action.isRTStructReturned True to return RTSTRUCT file, false otherwise.
         */
        expectedResultIsRTStructReturnedSet(state, action: PayloadAction<{ id: string, isRTStructReturned: boolean }>) {
            const { id, isRTStructReturned } = action.payload;

            const output = state.adaptOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find adapt output with id ${id}`); }

            adaptOutputAdapter.updateOne(state.adaptOutputs, { id: id, changes: { expectedResult: { ...output.expectedResult, isRTStructReturned } } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Sets whether adapt output's roi selection rules are enabled or disabled.
         * @param action.id ID of the adapt+ customization output.
         * @param action.isEnabled True to enable, false to disable.
         */
        areRoiSelectionRulesEnabledSet(state, action: PayloadAction<{ id: string, isEnabled: boolean }>) {
            const { id, isEnabled } = action.payload;

            const output = state.adaptOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find adapt output with id ${id}`); }

            adaptOutputAdapter.updateOne(state.adaptOutputs, { id: id, changes: { roiSelection: { ...output.roiSelection, isEnabled } } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Sets whether adapt output's roi selection rules should include or exclude matching rois.
         * @param action.id ID of the adapt+ customization output.
         * @param action.inclusion The inclusion/exclusion setting to use.
         */
        roiSelectionRulesIncludedSet(state, action: PayloadAction<{ id: string, inclusion: SelectionInclusion }>) {
            const { id, inclusion } = action.payload;

            const output = state.adaptOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find adapt output with id ${id}`); }

            adaptOutputAdapter.updateOne(state.adaptOutputs, { id: id, changes: { roiSelection: { ...output.roiSelection, inclusion } } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
        * Adds a new ROI selection rule with default settings to the given adapt output.
        * @param action ID of the adapt output.
        */
        newRoiSelectionRuleAdded(state, action: PayloadAction<string>) {
            const outputId = action.payload;

            const output = state.adaptOutputs.entities[outputId];
            if (output === undefined) { throw new Error(`Could not find adapt output with id ${outputId}`); }

            const roiRule = defaultCreateNewAdaptRoiRule(outputId);
            adaptRoiRuleAdapter.addOne(state.adaptRoiRules, roiRule);
            adaptOutputAdapter.updateOne(state.adaptOutputs, {
                id: outputId,
                changes:
                {
                    roiSelection: {
                        ...output.roiSelection,
                        roiRules: output.roiSelection.roiRules.concat(roiRule.id)
                    }
                }
            });

            setObjectAndAncestorsAsModified(state, [roiRule.id], CustomizationObjectType.AdaptRoiRule);
            sliceWrapper.performFormValidationForOutput(state, outputId);
        },

        /**
         * Sets a ROI rule's name regexp attribute.
         * @param action.id ID of the roi rule.
         * @param action.regExp The value to set the name regexp field to.
         */
        roiSelectionRuleNameRegExpSet(state, action: PayloadAction<{ id: string, regExp: string }>) {
            const { id, regExp } = action.payload;

            const roiRule = state.adaptRoiRules.entities[id];
            if (roiRule === undefined) { throw new Error(`Could not find roi rule with id ${id}`); }

            adaptRoiRuleAdapter.updateOne(state.adaptRoiRules, { id: id, changes: { nameRegExp: regExp } });
            setObjectAndAncestorsAsModified(state, [roiRule.id], CustomizationObjectType.AdaptRoiRule);
            console.log('TODO 3: perform validation here?')
            // if (target.outputId) {
            //     sliceWrapper.performFormValidationForOutput(state, target.outputId);
            // }
        },

        /**
         * Sets a ROI rule's interpreted type attribute.
         * @param action.id ID of the roi rule.
         * @param action.regExp The value to set the interpreted type field to.
         */
        roiSelectionRuleInterpretedTypeSet(state, action: PayloadAction<{ id: string, interpretedType: string }>) {
            const { id, interpretedType } = action.payload;

            const roiRule = state.adaptRoiRules.entities[id];
            if (roiRule === undefined) { throw new Error(`Could not find roi rule with id ${id}`); }

            adaptRoiRuleAdapter.updateOne(state.adaptRoiRules, { id: id, changes: { interpretedType } });
            setObjectAndAncestorsAsModified(state, [roiRule.id], CustomizationObjectType.AdaptRoiRule);
            console.log('TODO 3: perform validation here?')
            // if (target.outputId) {
            //     sliceWrapper.performFormValidationForOutput(state, target.outputId);
            // }
            // }
        },

        /**
         * Sets a ROI rule's isEnabled attribute.
         * @param action.id ID of the roi rule.
         * @param action.isEnabled True to enable this roi selection rule, false otherwise.
         */
        roiSelectionRuleIsEnabledSet(state, action: PayloadAction<{ id: string, isEnabled: boolean }>) {
            const { id, isEnabled } = action.payload;

            const roiRule = state.adaptRoiRules.entities[id];
            if (roiRule === undefined) { throw new Error(`Could not find roi rule with id ${id}`); }

            adaptRoiRuleAdapter.updateOne(state.adaptRoiRules, { id: id, changes: { isEnabled } });
            setObjectAndAncestorsAsModified(state, [roiRule.id], CustomizationObjectType.AdaptRoiRule);
            console.log('TODO 3: perform validation here?')
            // if (target.outputId) {
            //     sliceWrapper.performFormValidationForOutput(state, target.outputId);
            // }
            // }
        },

        /**
         * Removes selection roi rule with given ID
         * @param action ID of the roi rule.
         */
        roiSelectionRuleRemoved(state, action: PayloadAction<string>) {
            const roiRuleId = action.payload;

            const roiRule = state.adaptRoiRules.entities[roiRuleId];
            if (roiRule === undefined) { throw new Error(`Could not find roi rule with id ${roiRuleId}`); }

            const outputId = roiRule.outputId;
            if (!outputId) { throw new Error(`ROi rule ${roiRuleId} is not linked to a valid adapt output object`); }
            const output = state.adaptOutputs.entities[outputId];
            if (output === undefined) { throw new Error(`Could not find adapt output with id ${outputId}`); }

            adaptOutputAdapter.updateOne(state.adaptOutputs, {
                id: outputId,
                changes: {
                    roiSelection: {
                        ...output.roiSelection,
                        roiRules: output.roiSelection.roiRules.filter(rId => rId !== roiRuleId)
                    }
                }
            });
            adaptRoiRuleAdapter.removeOne(state.adaptRoiRules, roiRuleId);

            setObjectAndAncestorsAsModified(state, [outputId], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, outputId);
        },


        /**
         * Removes the specified customization base entity and all entities under it in the hierarchy.
         * @param action The ID of the customization base to remove.
         */
        customizationBaseRemoved(state, action: PayloadAction<string>) {
            const customizationBaseId = action.payload;

            sliceWrapper.removeCustomizationBase(state, customizationBaseId);
        },


        /** Unit test helper reducer for setting an object and all its ancestors as modified.
         *
         * Do not use this reducer outside of unit tests!
         */
        _unitTest_customizationObjectPathSetAsModified(state, action: PayloadAction<{ objectIds: string[], objectType: CustomizationObjectType }>) {
            const { objectIds, objectType } = action.payload;
            setObjectAndAncestorsAsModified(state, objectIds, objectType);
        },
    },
    selectors: {

        /** Returns all adapt+ models that have their visibility set as Always Hidden. */
        selectUserHiddenModels: createSelector(
            (state: AdaptSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => m.visibility === ModelVisibility.AlwaysHidden)),

        /** Returns all adapt+ models that are not in current user's license. */
        selectNonLicensedModels: createSelector(
            (state: AdaptSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => !m.isAvailable)),

        /** Returns all adapt+ models that are deprecated. */
        selectDeprecatedModels: createSelector(
            (state: AdaptSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => m.isDeprecated)),

        /** Returns the top-level model name for a adapt+ output */
        selectModelNameForOutput: (state, outputId: string) => {
            const output = localSelectors.selectOutputById(state, outputId);
            if (output) {
                const customization = localSelectors.selectCustomizationBaseById(state, output.modelCustomizationBaseId);
                if (customization) {
                    const model = localSelectors.selectModelById(state, customization.modelId);
                    if (model) {
                        return model.modelName;
                    }
                }
            }
        },

        /** Returns true if any model customization object has been modified. */
        selectIsAnyCustomizationModelModified: createSelector(
            (state: AdaptSliceState): Model[] => localSelectors.selectModels(state),
            (models) => models.some(m => m.isModified)),

        /** Returns true if all model selection rules are valid, false if any of them is invalid. */
        selectIsEverySelectionRuleValid: createSelector(
            [
                (state: AdaptSliceState): AeTitleRule[] => localSelectors.selectAeTitleRules(state),
                (state: AdaptSliceState): DicomRule[] => localSelectors.selectDicomRules(state),
                (state: AdaptSliceState): DicomAttributeRule[] => localSelectors.selectDicomAttributeRules(state),

            ],
            (aeTitleRules, dicomRules, dicomAttributeRules) => aeTitleRules.every(r => r.isValid)
                && dicomRules.every(r => r.isValid)
                && dicomAttributeRules.every(r => r.isValid)),

        /** Returns an array of all customization names and their internal parent model IDs. */
        selectAllCustomizationNamesAndParentModelIds: createSelector(
            (state: AdaptSliceState): CustomizationBase[] => localSelectors.selectCustomizationBases(state),
            (customizations) => customizations.map(c => ({ customizationName: c.customizationName, modelId: c.modelId }))),

        /** Returns true if there are ANY unsaved ADAPT+ changes in the app. */
        selectAnyUnsavedAdaptChangesInApp: (state) => localSelectors.selectIsAnyCustomizationModelModified(state),

        // ENTITY ADAPTER SELECTORS:
        // for all supported createEntityAdapter selectors see https://redux-toolkit.js.org/api/createEntityAdapter#selector-functions

        selectModels: (state): Model[] => modelAdapterSelectors.selectAll(state),
        selectModelEntities: (state) => modelAdapterSelectors.selectEntities(state),
        selectModelById: (state, id: string): Model | undefined => modelAdapterSelectors.selectById(state, id),
        selectModelsInAlphabeticalOrder: createSelector(
            [(state: AdaptSliceState): Model[] => localSelectors.selectModels(state)],
            models => naturalSort(models, 'modelName')),

        selectCustomizationBases: (state) => customizationBaseAdapterSelectors.selectAll(state),
        selectCustomizationBaseIds: (state) => customizationBaseAdapterSelectors.selectIds(state),
        selectCustomizationBaseById: (state, id: string): CustomizationBase | undefined => customizationBaseAdapterSelectors.selectById(state, id),
        selectCustomizationBaseEntities: (state) => customizationBaseAdapterSelectors.selectEntities(state),
        selectCustomizationBasesInAlphabeticalOrder: createSelector(
            [(state: AdaptSliceState): CustomizationBase[] => localSelectors.selectCustomizationBases(state)],
            bases => naturalSort(bases, 'customizationName')),

        selectOutputs: (state) => adaptOutputAdapterSelectors.selectAll(state),
        selectOutputIds: (state) => adaptOutputAdapterSelectors.selectIds(state),
        selectOutputEntities: (state) => adaptOutputAdapterSelectors.selectEntities(state),
        selectOutputById: (state, id: string): AdaptCustomizationOutput | undefined => adaptOutputAdapterSelectors.selectById(state, id),


        selectOutputMetadata: (state) => outputMetadataAdapterSelectors.selectAll(state),
        selectOutputMetadataEntities: (state) => outputMetadataAdapterSelectors.selectEntities(state),
        selectOriginalOutputMetadataEntities: (state) => originalOutputMetadataAdapterSelectors.selectEntities(state),
        selectOutputMetadataById: (state, id: string): OutputMetadataItem | undefined => outputMetadataAdapterSelectors.selectById(state, id),

        selectAeTitleRules: (state) => aeTitleRuleAdapterSelectors.selectAll(state),
        selectAeTitleRuleEntities: (state) => aeTitleRuleAdapterSelectors.selectEntities(state),
        selectAeTitleRuleById: (state, id: string): AeTitleRule | undefined => aeTitleRuleAdapterSelectors.selectById(state, id),

        selectDicomRules: (state) => dicomRuleAdapterSelectors.selectAll(state),
        selectDicomRuleEntities: (state) => dicomRuleAdapterSelectors.selectEntities(state),
        selectDicomRuleById: (state, id: string): DicomRule | undefined => dicomRuleAdapterSelectors.selectById(state, id),

        selectDicomAttributeRules: (state) => dicomAttributeRuleAdapterSelectors.selectAll(state),
        selectDicomAttributeRuleById: (state, id: string): DicomAttributeRule | undefined => dicomAttributeRuleAdapterSelectors.selectById(state, id),

        selectRoiRuleById: (state, id: string): AdaptRoiRule | undefined => adaptRoiRuleAdapterSelectors.selectById(state, id),
        selectOriginalRoiRuleById: (state, id: string): AdaptRoiRule | undefined => originalAdaptRoiRuleAdapterSelectors.selectById(state, id),

        selectCustomizationValidationError: (state, id: string): BackendValidationErrorViewModel | undefined => customizationValidationErrorAdapterSelectors.selectById(state, id),
        selectCustomizationValidationErrorEntities: (state) => customizationValidationErrorAdapterSelectors.selectEntities(state),

        selectFormValidationErrors: (state) => formValidationErrorAdapterSelectors.selectAll(state),
    }
});

export const {
    modelCustomizationsSet,
    modelVisibilityUpdated,
    modelCustomizationDescriptionUpdated,
    metadataItemUpdated,
    metadataItemRemoved,
    scrollToViewFromModelCustomizationMetadataRemoved,
    aeTitleRuleAdded,
    aeTitleRuleActionUpdated,
    aeTitleRuleRemoved,
    dicomRuleAdded,
    dicomRuleRemoved,
    dicomAttributeRuleAdded,
    dicomAttributeRuleRemoved,
    dicomAttributeRuleUpdated,

    smoothingSigmaSet,
    isValidMaskIncludedSet,
    expectedResultAreInputsReturnedSet,
    expectedResultAreResultsCompressedAsZipSet,
    expectedResultIsRegFileReturnedSet,
    expectedResultIsRegisteredScanReturnedSet,
    expectedResultIsRTDoseReturnedSet,
    expectedResultIsRTPlanReturnedSet,
    expectedResultIsRTStructReturnedSet,
    areRoiSelectionRulesEnabledSet,
    roiSelectionRulesIncludedSet,
    newRoiSelectionRuleAdded,
    roiSelectionRuleRemoved,
    roiSelectionRuleIsEnabledSet,
    roiSelectionRuleNameRegExpSet,
    roiSelectionRuleInterpretedTypeSet,

    // doseScalingMethodSet,
    // doseScalingVolumeSet,
    // doseScalingDoseSet,
    // pixelSpacingSet,
    // doseCroppingIsEnabledSet,
    // doseCroppingValueSet,
    // outputIsRTPlanIncludedSet,
    // outputIsBeamIncludedSet,
    // outputMachineTypeSet,
    // outputMachineNameSet,
    // doseRoiNameSet,
    // doseRoiRegExpSet,
    // doseRoiChangesReverted,
    // doseTargetMethodSet,
    // doseTargetUnitSet,
    // doseTargetRoiNameSet,
    // doseTargetRegExpSet,
    // doseTargetPrescriptionSet,
    // newDoseTargetAdded,
    // doseTargetRemoved,
    // doseTargetChangesReverted,
    // allCustomizationChangesReverted,
    // modelCustomizationObjectsSetAsModified,
    // modelCustomizationSaved,
    // saveModelCustomizationStarted,
    // saveModelCustomizationFinished,
    // customizationBaseDuplicated,
    // customizationDuplicationItemsAdded,
    customizationBaseRemoved,
    // allModelCustomizationsReset,
    // resetAllModelCustomizationsStarted,
    // resetAllModelCustomizationsFinished,
    // singleCustomizationReset,
    // resetSingleOutputStarted,
    // resetSingleOutputFinished,
    // customizationOutputReplaced,
    // customizationRenamed,
    // modelCustomizationExported,
    // modelCustomizationExportFailed,
    // modelCustomizationImported,
    // modelCustomizationImportFailed,

    _unitTest_customizationObjectPathSetAsModified,
    // _unitTest_formValidationErrorAdded,
    // _unitTest_formValidationErrorRemoved,
    // _unitTest_backendValidationErrorAdded,

} = adaptSlice.actions;

const localSelectors = adaptSlice.getSelectors();

export const { getInitialState, selectors: adaptSelectors } = adaptSlice;

export default adaptSlice.reducer;
