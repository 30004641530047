import { all, call, put, fork, select, take, takeEvery } from "typed-redux-saga";
import { get, isString } from "lodash-es";
import { daemonPrefix } from "../../environments";
import { configurationTargetListFetched, configurationTargetListSet, configurationTargetSelectors, currentConfigurationTargetSet, fetchConfigurationTargetDataFinished, fetchConfigurationTargetDataStarted } from "./configurationTargetSlice";
import { fetchContouringCustomizationsSaga } from "../contouring/contouringSagas";
import { fetchDaemonConfigsSaga } from "../daemon/daemonSagas";
import { fetchDoseCustomizationsSaga } from "../dose/doseSagas";
import { callApi } from "../sagas";
import { appConfigSelectors } from "../appConfig/appConfigSlice";
import { SupportedModelType } from "../appConfig/appDeploymentInfoTypes";
import { fetchAdaptCustomizationsSaga } from "../adapt/adaptSagas";
import { fetchImageCustomizationsSaga } from "../image/imageSagas";

export function* fetchConfigurationTargetListSaga() {

    yield* callApi({
        doApiCall: function* (client) {
            const configurationTargetList = yield* call(async () => client.fetchClientListAsync());
            return configurationTargetList;
        },
        onSuccess: function* (result) {
            yield* put(configurationTargetListSet({ configurationTargetList: result }));

            // immediately set current app client in certain conditions
            // (re-select client list here in case we do any post-processing
            // for the list in the reducer in future)
            const configurationTargetList = yield* select(configurationTargetSelectors.selectConfigurationTargetList);
            if (configurationTargetList !== undefined) {
                if (configurationTargetList.length === 1) {
                    // if we only have one item available, auto-select it
                    yield* put(currentConfigurationTargetSet(configurationTargetList[0].userId));
                } else {
                    // if there's exactly one daemon client available, auto-select it
                    const daemons = configurationTargetList.filter(c => c.userName.startsWith(daemonPrefix));
                    if (daemons.length === 1) {
                        yield* put(currentConfigurationTargetSet(daemons[0].userId));
                    }
                }
            }
        },
        onFailure: function* (error) {
            console.error('Was not able to fetch client list');
            // TODO: this format might be cockpit-specific? In that case it must be fixed everywhere in this file.
            const errorMessage = isString(error) ? error : `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
            yield* put(configurationTargetListSet({ configurationTargetList: null, errorMessage: `An error occurred when trying to retrieve client list: ${errorMessage}` }));
        }
    });
}

function* watchFetchConfigurationTargetListSaga() {
    yield* takeEvery(configurationTargetListFetched, fetchConfigurationTargetListSaga);
}

/** Watch when current configuration target is changed and re-fetch any data related to previous target as well. */
function* watchCurrentConfigurationTargetListSet() {
    while (true) {
        yield* take(currentConfigurationTargetSet);
        const currentTarget = yield* select(configurationTargetSelectors.selectCurrent);

        // block UI while fetching new values
        yield* put(fetchConfigurationTargetDataStarted());

        // queue re-fetches (currently just model and daemon configs, licenses are gotten through the page component instead of here)
        // (only fetch model types that are supported in current deployment)
        const appDeploymentInfo = yield* select(appConfigSelectors.selectAppDeploymentInfo);
        if (!appDeploymentInfo) { throw new Error('Application is not initialized properly -- cannot fetch data from backend'); }

        let modelTypeFetchCalls = [];
        if (appDeploymentInfo.supportedModelTypes.includes(SupportedModelType.Contour)) {
            modelTypeFetchCalls.push(call(fetchContouringCustomizationsSaga, currentTarget));
        }
        if (appDeploymentInfo.supportedModelTypes.includes(SupportedModelType.Dose)) {
            modelTypeFetchCalls.push(call(fetchDoseCustomizationsSaga, currentTarget));
        }
        if (appDeploymentInfo.supportedModelTypes.includes(SupportedModelType.Image)) {
            modelTypeFetchCalls.push(call(fetchImageCustomizationsSaga, currentTarget));
        }
        if (appDeploymentInfo.supportedModelTypes.includes(SupportedModelType.Adapt)) {
            modelTypeFetchCalls.push(call(fetchAdaptCustomizationsSaga, currentTarget));
        }

        yield* all([
            ...modelTypeFetchCalls,
            call(fetchDaemonConfigsSaga, currentTarget)
        ]);

        // re-fetching is done
        yield* put(fetchConfigurationTargetDataFinished());
    }
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForConfigurationTargetSagas() {
    return [
        watchFetchConfigurationTargetListSaga(),
        watchCurrentConfigurationTargetListSet(),
    ];
}