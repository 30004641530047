import { EntityAdapter, Update } from "@reduxjs/toolkit";
import GenericSliceWrapper, { FormValidationErrorCalculationResults, RemovedOutputEntities } from "./genericSliceWrapper";
import { CustomizationObjectType } from "../customization-types";
import { FormValidationErrorType } from "../form-errors";
import { FormValidationError, generateNewFormValidationError } from "../store-errors";
import { aeTitleRuleAdapter, backendValidationErrorAdapter, bodyMaskAdapter, customizationBaseAdapter, dicomAttributeRuleAdapter, dicomRuleAdapter, fillHolesAdapter, formValidationErrorAdapter, holeMaskAdapter, imageContourGenerationAdapter, imageDicomRestrictionAdapter, imageDicomTagAdapter, imageOutputAdapter, imageOutputGeometryAdapter, imagePostProcessingAdapter, ImageSliceState, keepLargestComponentAdapter, modelAdapter, outputMetadataAdapter } from "../../image/imageSlice";
import { BodyMask, FillHoles, HoleMask, ImageContourGeneration, ImageCustomizationEntities, ImageCustomizationOutput, ImageDicomRestriction, ImageDicomTag, ImageOutputGeometry, ImagePostProcessing, KeepLargestComponent } from "../../image/image-types";

export class ImageSliceWrapper extends GenericSliceWrapper<ImageSliceState> {

    outputAdapter: EntityAdapter<ImageCustomizationOutput, string>;
    imageDicomRestrictionAdapter: EntityAdapter<ImageDicomRestriction, string>;
    imageOutputGeometryAdapter: EntityAdapter<ImageOutputGeometry, string>;
    imageContourGenerationAdapter: EntityAdapter<ImageContourGeneration, string>;
    imagePostProcessingAdapter: EntityAdapter<ImagePostProcessing, string>;
    imageDicomTagAdapter: EntityAdapter<ImageDicomTag, string>;
    keepLargestComponentAdapter: EntityAdapter<KeepLargestComponent, string>;
    fillHolesAdapter: EntityAdapter<FillHoles, string>;
    bodyMaskAdapter: EntityAdapter<BodyMask, string>;
    holeMaskAdapter: EntityAdapter<HoleMask, string>;

    constructor() {
        super(
            modelAdapter,
            customizationBaseAdapter,
            outputMetadataAdapter,
            aeTitleRuleAdapter,
            dicomRuleAdapter,
            dicomAttributeRuleAdapter,
            backendValidationErrorAdapter,
            formValidationErrorAdapter
        )
        this.outputAdapter = imageOutputAdapter;
        this.imageDicomRestrictionAdapter = imageDicomRestrictionAdapter;
        this.imageOutputGeometryAdapter = imageOutputGeometryAdapter;
        this.imageContourGenerationAdapter = imageContourGenerationAdapter;
        this.imagePostProcessingAdapter = imagePostProcessingAdapter;
        this.imageDicomTagAdapter = imageDicomTagAdapter;
        this.keepLargestComponentAdapter = keepLargestComponentAdapter;
        this.fillHolesAdapter = fillHolesAdapter;
        this.bodyMaskAdapter = bodyMaskAdapter;
        this.holeMaskAdapter = holeMaskAdapter;
    }

    protected findOutput(state: ImageSliceState, outputId: string): ImageCustomizationOutput | undefined {
        return state.imageOutputs.entities[outputId];
    }

    protected updateOutput(state: ImageSliceState, update: Update<ImageCustomizationOutput, string>): void {
        this.outputAdapter.updateOne(state.imageOutputs, update);
    }

    /** Collects which store entities need to be removed and updated when a customization output is removed. */
    public collectCustomizationOutputEntitiesFromStoreForRemoval(state: ImageSliceState, output: ImageCustomizationOutput, customizationBaseId: string): RemovedOutputEntities {

        const metadataIdsToBeRemoved: string[] = [];
        const dicomRestrictionIdsToBeRemoved: string[] = [];
        const outputGeometryIdsToBeRemoved: string[] = [];
        const contourGenerationIdsToBeRemoved: string[] = [];
        const postProcessingIdsToBeRemoved: string[] = [];
        const dicomTagIdsToBeRemoved: string[] = [];
        const keepLargestComponentIdsToBeRemoved: string[] = [];
        const fillHolesIdsToBeRemoved: string[] = [];
        const bodyMaskIdsToBeRemoved: string[] = [];
        const holeMaskIdsToBeRemoved: string[] = [];


        metadataIdsToBeRemoved.push(...output.metadata);
        if (output.dicomRestriction) {
            dicomRestrictionIdsToBeRemoved.push(output.dicomRestriction);
            const dicomRestriction = state.imageDicomRestrictions.entities[output.dicomRestriction];
            if (dicomRestriction && dicomRestriction.tags) { dicomTagIdsToBeRemoved.push(...dicomRestriction.tags); }
        }
        if (output.outputGeometry) { outputGeometryIdsToBeRemoved.push(output.outputGeometry); }
        if (output.contourGeneration) { contourGenerationIdsToBeRemoved.push(output.contourGeneration); }
        if (output.postProcessing) {
            postProcessingIdsToBeRemoved.push(output.postProcessing);
            const postProcessing = state.imagePostProcessing.entities[output.postProcessing];
            if (postProcessing) {
                if (postProcessing.keepLargestComponent) { keepLargestComponentIdsToBeRemoved.push(postProcessing.keepLargestComponent); }
                if (postProcessing.fillHoles) {
                    fillHolesIdsToBeRemoved.push(postProcessing.fillHoles);
                    const fillHoles = state.fillHoles.entities[postProcessing.fillHoles];
                    if (fillHoles && fillHoles.bodyMask) { bodyMaskIdsToBeRemoved.push(fillHoles.bodyMask); }
                    if (fillHoles && fillHoles.holeMask) { holeMaskIdsToBeRemoved.push(fillHoles.holeMask); }
                }
            }
        }

        return {
            metadataIdsToBeRemoved,
            dicomRestrictionIdsToBeRemoved,
            outputGeometryIdsToBeRemoved,
            contourGenerationIdsToBeRemoved,
            postProcessingIdsToBeRemoved,
            dicomTagIdsToBeRemoved,
            keepLargestComponentIdsToBeRemoved,
            fillHolesIdsToBeRemoved,
            bodyMaskIdsToBeRemoved,
            holeMaskIdsToBeRemoved,
            allRemovedIds: [
                ...metadataIdsToBeRemoved,
                ...dicomRestrictionIdsToBeRemoved,
                ...outputGeometryIdsToBeRemoved,
                ...contourGenerationIdsToBeRemoved,
                ...postProcessingIdsToBeRemoved,
                ...dicomTagIdsToBeRemoved,
                ...keepLargestComponentIdsToBeRemoved,
                ...fillHolesIdsToBeRemoved,
                ...bodyMaskIdsToBeRemoved,
                ...holeMaskIdsToBeRemoved,
            ],
        };
    };



    protected removeOutputRelatedItems(state: ImageSliceState, outputIds: string[], items: RemovedOutputEntities) {
        const {
            metadataIdsToBeRemoved,
            dicomRestrictionIdsToBeRemoved,
            outputGeometryIdsToBeRemoved,
            contourGenerationIdsToBeRemoved,
            postProcessingIdsToBeRemoved,
            dicomTagIdsToBeRemoved,
            keepLargestComponentIdsToBeRemoved,
            fillHolesIdsToBeRemoved,
            bodyMaskIdsToBeRemoved,
            holeMaskIdsToBeRemoved,
        } = items;

        if (metadataIdsToBeRemoved) { this.outputMetadataAdapter.removeMany(state.outputMetadata, metadataIdsToBeRemoved); }
        if (dicomRestrictionIdsToBeRemoved) { this.imageDicomRestrictionAdapter.removeMany(state.imageDicomRestrictions, dicomRestrictionIdsToBeRemoved); }
        if (outputGeometryIdsToBeRemoved) { this.imageOutputGeometryAdapter.removeMany(state.imageOutputGeometry, outputGeometryIdsToBeRemoved); }
        if (contourGenerationIdsToBeRemoved) { this.imageContourGenerationAdapter.removeMany(state.imageContourGeneration, contourGenerationIdsToBeRemoved); }
        if (postProcessingIdsToBeRemoved) { this.imagePostProcessingAdapter.removeMany(state.imagePostProcessing, postProcessingIdsToBeRemoved); }
        if (dicomTagIdsToBeRemoved) { this.imageDicomTagAdapter.removeMany(state.imageDicomTags, dicomTagIdsToBeRemoved); }
        if (keepLargestComponentIdsToBeRemoved) { this.keepLargestComponentAdapter.removeMany(state.keepLargestComponent, keepLargestComponentIdsToBeRemoved); }
        if (fillHolesIdsToBeRemoved) { this.fillHolesAdapter.removeMany(state.fillHoles, fillHolesIdsToBeRemoved); }
        if (bodyMaskIdsToBeRemoved) { this.bodyMaskAdapter.removeMany(state.bodyMasks, bodyMaskIdsToBeRemoved); }
        if (holeMaskIdsToBeRemoved) { this.holeMaskAdapter.removeMany(state.holeMasks, holeMaskIdsToBeRemoved); }

        this.outputAdapter.removeMany(state.imageOutputs, outputIds);
    }

    protected setOutputRelatedItemsAsModified(state: ImageSliceState, entitiesToUpdate: Partial<ImageCustomizationEntities>) {

        const dicomRestrictionIds = entitiesToUpdate.imageDicomRestrictions?.map(id => id.id);
        const outputGeometryIds = entitiesToUpdate.imageOutputGeometry?.map(id => id.id);
        const contourGenerationIds = entitiesToUpdate.imageContourGeneration?.map(id => id.id);
        const postProcessingIds = entitiesToUpdate.imagePostProcessing?.map(id => id.id);

        // the rest of the types do not as of yet have an isModified prop (and maybe never will?)
        // const dicomTagIds = entitiesToUpdate.imageDicomTags?.map(id => id.id);
        // const keepLargestComponentIds = entitiesToUpdate.keepLargestComponent?.map(id => id.id);
        // const fillHolesIds = entitiesToUpdate.fillHoles?.map(id => id.id);
        // const bodyMaskIds = entitiesToUpdate.bodyMasks?.map(id => id.id);
        // const holeMaskIds = entitiesToUpdate.holeMasks?.map(id => id.id);

        const customizationOutputsIds = entitiesToUpdate.imageOutputs?.map(id => id.id);

        if (dicomRestrictionIds && dicomRestrictionIds.length > 0) {
            this.imageDicomRestrictionAdapter.updateMany(
                state.imageDicomRestrictions,
                dicomRestrictionIds.map(dicomRestrictionId => ({ id: dicomRestrictionId, changes: { isModified: true } }))
            );
        }

        if (outputGeometryIds && outputGeometryIds.length > 0) {
            this.imageOutputGeometryAdapter.updateMany(
                state.imageOutputGeometry,
                outputGeometryIds.map(outputGeometryId => ({ id: outputGeometryId, changes: { isModified: true } }))
            );
        }

        if (contourGenerationIds && contourGenerationIds.length > 0) {
            this.imageContourGenerationAdapter.updateMany(
                state.imageContourGeneration,
                contourGenerationIds.map(contourGenerationId => ({ id: contourGenerationId, changes: { isModified: true } }))
            );
        }

        if (postProcessingIds && postProcessingIds.length > 0) {
            this.imagePostProcessingAdapter.updateMany(
                state.imagePostProcessing,
                postProcessingIds.map(postProcessingId => ({ id: postProcessingId, changes: { isModified: true } }))
            );
        }

        if (customizationOutputsIds && customizationOutputsIds.length > 0) {
            this.outputAdapter.updateMany(
                state.imageOutputs,
                customizationOutputsIds.map(customizationOutputId => ({ id: customizationOutputId, changes: { isModified: true } }))
            );
        }
    }

    /** Calculates form validation errors for given customization output.
     * TODO: once we have more types of validation errors consider splitting
     * this into smaller functions, and consider whether all of them need to be
     * checked here.
     */
    protected calculateFormValidationErrorsForCustomizationOutput(state: ImageSliceState, outputId: string): FormValidationErrorCalculationResults {
        const errorIdsToRemove: string[] = [];
        const errorsToAdd: FormValidationError[] = [];

        const output = state.imageOutputs.entities[outputId];
        if (!output) { throw new Error(`Customization output (${outputId}) is undefined`); }

        // const existingErrors = Object.values(state.formValidationErrors.entities).filter(e => output.rois.includes(e?.itemId));
        // const existingEmptyNameErrors = existingErrors.filter(e => e?.errorType === FormValidationErrorType.RoiNameIsEmpty);
        // const existingDuplicateNameErrors = existingErrors.filter(e => e?.errorType === FormValidationErrorType.DuplicateRoiNamesInOutput);

        // collect existing duplicate name errors nearby so we can use them when doing a delta within this output
        // map potential INCLUDED duplicate roiIds (string array value) under the potential duplicate name (string key)
        // this is needed for the duplicate name check resolution later
        // const duplicateNames: { [name: string]: string[] } = {};
        // for (const roi of rois) {
        //     duplicateNames[roi.name] = duplicateNames[roi.name] || [];
        //     duplicateNames[roi.name].push(roi.id);
        // }

        // // check all the validation errors for ROIs in one loop
        // for (const roi of rois) {

        //     let dontAddNewValidationErrors = false;


        //     // check for empty names rois (RoiNameIsEmpty)
        //     const matchingExistingEmptyNameErrors = existingEmptyNameErrors.filter(e => e.itemId === roi.id);
        //     const hasMatchingEmptyNameErrors = matchingExistingEmptyNameErrors.length > 0;
        //     const isNameEmpty = !roi.name.trim();

        //     // NOTE: new errors are NOT created if existing ones with the same FormValidationErrorType have already been found!
        //     if (isNameEmpty && !hasMatchingEmptyNameErrors && !dontAddNewValidationErrors) {
        //         // create a new error
        //         errorsToAdd.push(generateNewFormValidationError(FormValidationErrorType.RoiNameIsEmpty, roi.id, CustomizationObjectType.Roi));
        //     } else if (dontAddNewValidationErrors || (!isNameEmpty && hasMatchingEmptyNameErrors)) {
        //         // remove existing errors
        //         errorIdsToRemove.push(...matchingExistingEmptyNameErrors.map(m => m.id));
        //     }

        //     // if this name was empty then don't add any new validation errors (but we still need to check if any can be removed)
        //     if (isNameEmpty) {
        //         dontAddNewValidationErrors = true;
        //     }


        //     // check for duplicate names within (included) rois in the same output (DuplicateRoiNamesInOutput)
        //     // now loop through the ROIs again and do a delta against existing errors, creating new errors and marking existing ones to be removed as needed
        //     const matchingExistingDuplicateNameErrors = existingDuplicateNameErrors.filter(e => e.itemId === roi.id);
        //     const hasMatchingDuplicateNameErrors = matchingExistingDuplicateNameErrors.length > 0;
        //     const isNameDuplicated = duplicateNames[roi.name] && duplicateNames[roi.name].length > 1;

        //     // NOTE: new errors are NOT created if existing ones with the same FormValidationErrorType have already been found!
        //     if (isNameDuplicated && !hasMatchingDuplicateNameErrors && !dontAddNewValidationErrors) {
        //         // create a new error
        //         errorsToAdd.push(generateNewFormValidationError(FormValidationErrorType.DuplicateRoiNamesInOutput, roi.id, CustomizationObjectType.Roi));
        //     } else if (dontAddNewValidationErrors || (!isNameDuplicated && hasMatchingDuplicateNameErrors)) {
        //         // remove existing errors
        //         errorIdsToRemove.push(...matchingExistingDuplicateNameErrors.map(m => m.id));
        //     }
        // }

        return {
            validationErrorIdsToRemove: errorIdsToRemove,
            validationErrorsToAdd: errorsToAdd,
        }
    }
}
