import { has, isArray, isString, isNumber, isBoolean, isPlainObject, isNull } from "lodash-es";


export default class ExpectedProp {
    public jsonProp: string;
    public modelProp: string;
    public type: string;

    constructor(jsonProp: string, modelProp: string, type: string) {
        this.jsonProp = jsonProp;
        this.modelProp = modelProp;
        this.type = type;
    }
}

export enum PropType { String = 'string', Number = 'number', Array = 'array', Object = 'object', Boolean = 'boolean', RgbArray = 'rgbArray' };

export type SupportedProp = {
    /** Name of the property in queried object. */
    propName: string,
    /** Type of the property. */
    propType: PropType,
    /** True if property is allowed to be null OR the set propType, false otherwise. */
    allowNull?: boolean
};

/** Helper function for asserting that supplied obj is of expected type. */
export function assertObjType(obj: any, propType: PropType.Number, printMessagesToConsole?: boolean): asserts obj is number;
export function assertObjType(obj: any, propType: PropType.String, printMessagesToConsole?: boolean): asserts obj is string;
export function assertObjType(obj: any, propType: PropType.Boolean, printMessagesToConsole?: boolean): asserts obj is boolean;
export function assertObjType(obj: any, propType: PropType.Object, printMessagesToConsole?: boolean): asserts obj is {};
export function assertObjType(obj: any, propType: PropType.Array, printMessagesToConsole?: boolean): asserts obj is [];
export function assertObjType(obj: any, propType: PropType.RgbArray, printMessagesToConsole?: boolean): asserts obj is [];
export function assertObjType(obj: any, propType: PropType, printMessagesToConsole: boolean = true): asserts obj is PropType {

    let passedAssert = false;
    switch (propType) {
        case PropType.String:
            passedAssert = isString(obj);
            break;
        case PropType.Number:
            passedAssert = isNumber(obj);
            break;
        case PropType.Array:
            passedAssert = isArray(obj);
            break;
        case PropType.Object:
            passedAssert = isPlainObject(obj);
            break;
        case PropType.Boolean:
            passedAssert = isBoolean(obj);
            break;
        case PropType.RgbArray:
            passedAssert = isArray(obj) && obj.length === 3 && isNumber(obj[0]) && isNumber(obj[1]) && isNumber(obj[2]);
            break;
        default:
            throw new Error(`Unsupported propType: ${propType}`);
    }

    if (!passedAssert) {
        const message = `Expected prop type ${propType}, got ${typeof obj}.`;
        if (printMessagesToConsole) {
            console.error(message);
            console.log('obj:', obj);
        }
        throw new Error(message);
    }
}

export function isExpectedProp(obj: any, propName: string, propType: PropType, allowNull: boolean = false): boolean {
    if (has(obj, propName)) {
        const prop = obj[propName];

        if (allowNull && isNull(prop)) {
            return true;
        }

        switch (propType) {
            case PropType.String:
                return isString(prop);
            case PropType.Number:
                return isNumber(prop);
            case PropType.Array:
                return isArray(prop);
            case PropType.Object:
                return isPlainObject(prop);
            case PropType.Boolean:
                return isBoolean(prop);
            case PropType.RgbArray:
                return isArray(prop) && prop.length === 3 && isNumber(prop[0]) && isNumber(prop[1]) && isNumber(prop[2]);
            default:
                break;
        }
    }

    return false;
}

export type AssertResult = { passed: boolean; validationError?: string; };

export function hasExpectedProps(obj: any, expectedProps: SupportedProp[]): { allPassed: boolean, results: AssertResult[] } {
    const results: AssertResult[] = expectedProps.map(expectedProp => isExpectedProp(obj, expectedProp.propName, expectedProp.propType, expectedProp.allowNull) ?
        { passed: true }
        :
        // note: have to do a weird isArray check in the line below since 'typeof [array]' returns 'object' in javascript
        { passed: false, validationError: `For '${expectedProp.propName}': expected '${expectedProp.propType}', got '${isArray(obj[expectedProp.propName]) ? 'array' : typeof obj[expectedProp.propName]}'` });

    return { allPassed: results.every(r => r.passed), results };
}

/**
 * Helper function for asserting that an object (e.g. JSON DTO) has expected properties.
 * @param obj The object to assert. Usually a JSON data-transfer object directly from a backend API.
 * @param expectedProps An array of properties and their types that must be found on the object.
 * @param assertMessage The error message that will be thrown if any of these assertions fail. Optional,
 * a default will be used if omitted.
 */
export function assertExpectedProps(obj: any, expectedProps: SupportedProp[], assertMessage?: string, printMessagesToConsole: boolean = true) {
    const assertResults = hasExpectedProps(obj, expectedProps);
    if (!assertResults.allPassed) {
        const message = assertMessage || 'Unexpected prop type(s) encountered.';
        if (printMessagesToConsole) {
            console.error(message);
            console.log(assertResults.results.filter(r => !r.passed).map(r => r.validationError).join('\n'));
            console.log(obj);
        }
        throw new Error(message);
    }
}

/** Helper function for asserting that all items of an array are of expected type. */
export function assertArray(array: any[], expectedProp: PropType.Number, printMessagesToConsole?: boolean): asserts array is number[];
export function assertArray(array: any[], expectedProp: PropType.String, printMessagesToConsole?: boolean): asserts array is string[];
export function assertArray(array: any[], expectedProp: PropType.Boolean, printMessagesToConsole?: boolean): asserts array is boolean[];
export function assertArray(array: any[], expectedProp: PropType.Object, printMessagesToConsole?: boolean): asserts array is {}[];
export function assertArray(array: any[], expectedProp: PropType.Array, printMessagesToConsole?: boolean): asserts array is [][];
export function assertArray(array: any[], expectedProp: PropType.RgbArray, printMessagesToConsole?: boolean): asserts array is [][];
export function assertArray(array: any[], expectedProp: PropType, printMessagesToConsole: boolean = true): asserts array is PropType[] {
    if (!isArray(array)) {
        throw new Error(`Expected a valid array, got ${typeof array}`);
    }

    // convert array into an object for more streamlined prop checking, use
    // array indices as fake prop names
    const arrayAsObj: any = {};
    array.forEach((item, index) => arrayAsObj[index.toString()] = item);

    assertExpectedProps(arrayAsObj, Object.keys(arrayAsObj).map(key => ({
        propName: key, propType: expectedProp
    })
    ), undefined, printMessagesToConsole);

}
