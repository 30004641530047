import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DataList, Flex, FlexItem, } from "@patternfly/react-core";
import { useCallback, useMemo } from "react";

import { StoreState } from "../../../store/store";
import { doseSelectors, doseTargetMethodSet, doseTargetUnitSet } from "../../../store/dose/doseSlice";
import Section from "../Section";
import { makeSelectDoseTargetsForOutput } from "../../../store/dose/doseSelectors";
import { CustomizationObjectType, SelectedDoseUnit } from "../../../store/global-types/customization-types";
import SectionItem from "../SectionItem";
import FormSelect from "../../../components/form-select";
import { TargetMethod } from "../../../store/dose/dose-types";
import { isSomeEnum } from "../../../util/enum";
import DoseTargetTable from "./DoseTargetTable";

interface DoseTargetSectionProps {
    outputId: string;
}

const DoseTargetSection = (props: DoseTargetSectionProps) => {
    const { outputId } = props;

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const output = useSelector((state: StoreState) => doseSelectors.selectOutputById(state, outputId));
    const validationEntities = useSelector((state: StoreState) => doseSelectors.selectCustomizationValidationErrorEntities(state));

    const selectDoseTargetsForOutput = useMemo(makeSelectDoseTargetsForOutput, []);
    const doseTargets = useSelector((state: StoreState) => selectDoseTargetsForOutput(state, outputId));

    const handleTargetMethodChanged = useCallback((method: string) => {
        if (isSomeEnum(TargetMethod)(method)) {
            dispatch(doseTargetMethodSet({ outputId, targetMethod: method }));
        }
    }, [dispatch, outputId]);

    const handleTargetUnitChanged = useCallback((unit: string) => {
        if (isSomeEnum(SelectedDoseUnit)(unit)) {
            dispatch(doseTargetUnitSet({ outputId, doseUnit: unit }));
        }
    }, [dispatch, outputId]);

    if (!output) {
        return null;
    }

    const targetsHaveValidationErrors = output.targets.some(tId => validationEntities[tId] !== undefined && validationEntities[tId]!.type === CustomizationObjectType.Target);

    return (
        <div>
            <DataList aria-label={`Dose target customization`} isCompact className="dose-target-section">

                <Section
                    isModified={false}
                    hasValidationError={targetsHaveValidationErrors}
                    validationError={undefined}
                >
                    <Flex className={`target-customization-row`}>
                        <FlexItem>
                            <SectionItem title={t('customizationPage.dose.targets.method.title')}>

                                <FormSelect
                                    items={[
                                        { label: t('customizationPage.dose.targets.targetMethod.variable.title'), value: TargetMethod.Variable },
                                        { label: t('customizationPage.dose.targets.targetMethod.fixed.title'), value: TargetMethod.Fixed },
                                    ]}
                                    selectedValue={output.targetMethod}
                                    onSelect={handleTargetMethodChanged}
                                    className="target-method-select"
                                />


                            </SectionItem>
                        </FlexItem>
                        <FlexItem className="mv-flex-grow">
                            <SectionItem title={t('customizationPage.dose.targets.unit.title')} className="target-dose-unit">

                                <FormSelect
                                    items={[
                                        { label: t('common.dose.unit.gy'), value: SelectedDoseUnit.Gy.toString() },
                                        { label: t('common.dose.unit.cgy'), value: SelectedDoseUnit.cGy.toString() }
                                    ]}
                                    selectedValue={output.targetUnit}
                                    onSelect={handleTargetUnitChanged}
                                />

                            </SectionItem>
                        </FlexItem>
                    </Flex>

                </Section>

            </DataList>

            <DoseTargetTable
                outputId={outputId}
                targetIds={output.targets}
            />
        </div>
    );
}

export default DoseTargetSection;
