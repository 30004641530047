import { DataList, FormGroup, InputGroup, Switch } from '@patternfly/react-core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../store/store';
import Section from '../Section';
import SectionItem from '../SectionItem';
import NumberInput from '../../../components/number-input';
import { createLength, getLengthInCm, LengthUnit } from '../../../store/global-types/units';
import { imageOutputGeometryFovSizeSet, imageOutputGeometryMatchInputSet, imageOutputGeometryMatchInputSliceThicknessSet, imageOutputGeometryMatrixSizeSet, imageOutputGeometryOutputSliceThicknessSet, imageSelectors } from '../../../store/image/imageSlice';

import '../customization-form.css';

interface OutputGeometryFormProps {
    outputId: string,
}

const OutputGeometryForm = (props: OutputGeometryFormProps) => {

    const { outputId } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const output = useSelector((state: StoreState) => imageSelectors.selectOutputById(state, outputId));
    const outputGeometry = useSelector((state: StoreState) => imageSelectors.selectOutputGeometryForOutput(state, outputId));

    const handleMatchInputGeometryToggled = useCallback(() => {
        if (outputGeometry) { dispatch(imageOutputGeometryMatchInputSet({ id: outputGeometry.id, matchInput: !outputGeometry.matchInputGeometry })); }
    }, [dispatch, outputGeometry]);

    const handleFovChanged = useCallback((value: number) => {
        if (outputGeometry) { dispatch(imageOutputGeometryFovSizeSet({ id: outputGeometry.id, fovSize: createLength(value, LengthUnit.LengthInCm) })); }
    }, [dispatch, outputGeometry]);

    const handleMatrixSizeChanged = useCallback((value: number) => {
        if (outputGeometry) { dispatch(imageOutputGeometryMatrixSizeSet({ id: outputGeometry.id, matrixSize: value })); }
    }, [dispatch, outputGeometry]);

    const handleMatchInputSliceThicknessToggled = useCallback(() => {
        if (outputGeometry) { dispatch(imageOutputGeometryMatchInputSliceThicknessSet({ id: outputGeometry.id, matchInputSliceThickness: !outputGeometry.sliceThickness.matchInput })); }
    }, [dispatch, outputGeometry]);

    const handleOutputSliceThicknessChanged = useCallback((value: number) => {
        if (outputGeometry) { dispatch(imageOutputGeometryOutputSliceThicknessSet({ id: outputGeometry.id, sliceThickness: createLength(value, LengthUnit.LengthInCm) })); }
    }, [dispatch, outputGeometry]);

    // TODO: add section-specific output validity checking here later on if we have
    // any relevant form validation for those parts -- currently we don't
    const outputIsValid = true;

    if (!output || !outputGeometry) {
        return null;
    }

    return (
        <div>
            <DataList aria-label={`Image+ output geometry customization`} isCompact className="customization-form">

                <Section
                    title={undefined}
                    isModified={false}
                    hasValidationError={!outputIsValid}
                    validationError={undefined}
                >

                    {/* <SectionHelpText
                    helpText={t('customizationPage.dose.outputSection.doseScaling.helpText')}
                /> */}

                    <SectionItem label={t('customizationPage.image.outputGeometrySection.matchInput.label')}>
                        <FormGroup>
                            <InputGroup>
                                <Switch
                                    label={t('customizationPage.image.outputGeometrySection.matchInput.on')}
                                    labelOff={t('customizationPage.image.outputGeometrySection.matchInput.off')}
                                    isChecked={outputGeometry.matchInputGeometry}
                                    onChange={handleMatchInputGeometryToggled}
                                />
                            </InputGroup>
                        </FormGroup>
                    </SectionItem>


                    {!outputGeometry.matchInputGeometry && (
                        <>
                            <SectionItem label={t('customizationPage.image.outputGeometrySection.fov.title')}>
                                <FormGroup>
                                    <NumberInput
                                        fieldId={`output-geometry-fov-field-for-${outputId}`}
                                        onChange={handleFovChanged}
                                        defaultValue={getLengthInCm(outputGeometry.fovSize)}
                                        lowerBound={0}
                                        unit="cm"
                                    />
                                </FormGroup>
                            </SectionItem>

                            <SectionItem label={t('customizationPage.image.outputGeometrySection.matrix.title')}>
                                <FormGroup>
                                    <NumberInput
                                        fieldId={`output-geometry-matrix-field-for-${outputId}`}
                                        onChange={handleMatrixSizeChanged}
                                        defaultValue={outputGeometry.matrixSize}
                                        lowerBound={0}
                                    />
                                </FormGroup>
                            </SectionItem>

                            <SectionItem label={t('customizationPage.image.outputGeometrySection.sliceThickness.title')}>

                                <SectionItem label={t('customizationPage.image.outputGeometrySection.sliceThickness.matchInput.label')}>
                                    <FormGroup>
                                        <InputGroup>
                                            <Switch
                                                label={t('customizationPage.image.outputGeometrySection.sliceThickness.matchInput.label.on')}
                                                labelOff={t('customizationPage.image.outputGeometrySection.sliceThickness.matchInput.label.off')}
                                                isChecked={outputGeometry.sliceThickness.matchInput}
                                                onChange={handleMatchInputSliceThicknessToggled}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </SectionItem>

                                {!outputGeometry.sliceThickness.matchInput && (
                                    <SectionItem label={t('customizationPage.image.outputGeometrySection.sliceThickness.thickness')}>
                                        <FormGroup>
                                            <NumberInput
                                                fieldId={`output-geometry-output-slice-thickness-for-${outputId}`}
                                                onChange={handleOutputSliceThicknessChanged}
                                                defaultValue={getLengthInCm(outputGeometry.sliceThickness.sliceThickness)}
                                                lowerBound={0}
                                                unit="cm"
                                            />
                                        </FormGroup>
                                    </SectionItem>
                                )}

                            </SectionItem>
                        </>
                    )}
                </Section>

            </DataList >
        </div>
    );
}

export default OutputGeometryForm;
