import { Button, DataList, FormGroup, InputGroup, InputGroupItem, Popover, Radio, Switch } from '@patternfly/react-core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OutlinedQuestionCircleIcon } from '@patternfly/react-icons';

import { StoreState } from '../../../store/store';
import Section from '../Section';
import SectionItem from '../SectionItem';
import { imageContourGenerationActionSet, imageContourGenerationAttachToSet, imageContourGenerationEnabledSet, imageSelectors } from '../../../store/image/imageSlice';

import '../customization-form.css';
import DebouncedTextInput from '../../../components/debounced-text-input';
import { ContourAttachSeries } from '../../../store/image/image-types';

interface ContourGenerationFormProps {
    outputId: string,
}

const ContourGenerationForm = (props: ContourGenerationFormProps) => {

    const { outputId } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const output = useSelector((state: StoreState) => imageSelectors.selectOutputById(state, outputId));
    const contourGeneration = useSelector((state: StoreState) => imageSelectors.selectContourGenerationForOutput(state, outputId));

    const handleEnableContourGenerationToggled = useCallback(() => {
        if (contourGeneration) { dispatch(imageContourGenerationEnabledSet({ id: contourGeneration.id, isEnabled: !contourGeneration.isEnabled })); }
    }, [dispatch, contourGeneration]);

    const handleActionChanged = useCallback((action: string) => {
        if (contourGeneration) { dispatch(imageContourGenerationActionSet({ id: contourGeneration.id, actionName: action })); }
    }, [dispatch, contourGeneration]);

    const handleAttachContoursToOutput = useCallback(() => {
        if (contourGeneration) { dispatch(imageContourGenerationAttachToSet({ id: contourGeneration.id, attachTo: ContourAttachSeries.Output })); }
    }, [dispatch, contourGeneration]);

    const handleAttachContoursToInput = useCallback(() => {
        if (contourGeneration) { dispatch(imageContourGenerationAttachToSet({ id: contourGeneration.id, attachTo: ContourAttachSeries.Input })); }
    }, [dispatch, contourGeneration]);

    // TODO: add section-specific output validity checking here later on if we have
    // any relevant form validation for those parts -- currently we don't
    const outputIsValid = true;
    const actionFieldRef = React.createRef<HTMLDivElement>();

    if (!output || !contourGeneration) {
        return null;
    }


    return (
        <div>
            <DataList aria-label={`Image+ contour generation customization`} isCompact className="customization-form">

                <Section
                    title={undefined}
                    isModified={false}
                    hasValidationError={!outputIsValid}
                    validationError={undefined}
                >

                    <SectionItem label={t('customizationPage.image.contourGenerationSection.enabled.label')}>
                        <FormGroup>
                            <InputGroup>
                                <Switch
                                    label={t('customizationPage.image.contourGenerationSection.enabled.on')}
                                    labelOff={t('customizationPage.image.contourGenerationSection.enabled.off')}
                                    isChecked={contourGeneration.isEnabled}
                                    onChange={handleEnableContourGenerationToggled}
                                />
                            </InputGroup>
                        </FormGroup>
                    </SectionItem>

                    {contourGeneration.isEnabled && (
                        <>

                            <SectionItem label={t('customizationPage.image.contourGenerationSection.attachTo.label')}>
                                <FormGroup
                                    role="radiogroup"
                                    isStack
                                >
                                    <Radio
                                        name="image-contour-generation-attach-to"
                                        id="image-contour-generation-attach-to-output"
                                        label={t('customizationPage.image.contourGenerationSection.attachTo.output')}
                                        onChange={handleAttachContoursToOutput}
                                        isChecked={contourGeneration.attachTo === ContourAttachSeries.Output}
                                    />
                                    <Radio
                                        name="image-contour-generation-attach-to"
                                        id="image-contour-generation-attach-to-input"
                                        label={t('customizationPage.image.contourGenerationSection.attachTo.input')}
                                        onChange={handleAttachContoursToInput}
                                        isChecked={contourGeneration.attachTo === ContourAttachSeries.Input}
                                    />

                                </FormGroup>
                            </SectionItem>

                            <SectionItem label={t('customizationPage.image.contourGenerationSection.action.label')}>
                                <FormGroup>
                                    <InputGroup ref={actionFieldRef}>
                                        <InputGroupItem isFill>
                                            <DebouncedTextInput
                                                fieldId={`contour-generation-${outputId}-action`}
                                                onDebouncedChange={handleActionChanged}
                                                defaultValue={contourGeneration.action || ''}
                                                placeholder={t('customizationPage.image.contourGenerationSection.action.placeholder')}
                                            />
                                        </InputGroupItem>
                                        <InputGroupItem>
                                            <Popover
                                                bodyContent={(<div>{t('customizationPage.image.contourGenerationSection.action.message')}</div>)}
                                                appendTo={actionFieldRef.current || undefined}
                                                withFocusTrap={true}
                                            >
                                                <Button variant="control" className="appended-field-button"><OutlinedQuestionCircleIcon /></Button>
                                            </Popover>
                                        </InputGroupItem>
                                    </InputGroup>
                                </FormGroup>
                            </SectionItem>

                        </>
                    )}

                </Section>

            </DataList >
        </div>
    );
}

export default ContourGenerationForm;
