import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@patternfly/react-core';
import { Td, Tr } from '@patternfly/react-table';

import { StoreState } from '../../../store/store';
import { dicomTagAttributeSet, dicomTagRemoved, dicomTagValueSet, imageSelectors } from '../../../store/image/imageSlice';
import { columnCssNames, columnIds } from './DicomTagTable';
import { ItemActionsColumn } from '../ItemActionsColumn';
import DebouncedTextInput from '../../../components/debounced-text-input';

interface DicomTagTableItemProps {
    dicomTagId: string;
    dicomTagIdList: string[];
}

const DicomTagTableItem = (props: DicomTagTableItemProps) => {

    const { dicomTagId, dicomTagIdList } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const dicomTag = useSelector((state: StoreState) => imageSelectors.selectDicomTagById(state, dicomTagId));
    const hasOriginalTarget = useSelector((state: StoreState) => imageSelectors.selectOriginalDicomTagById(state, dicomTagId) !== undefined);
    // const validationError = useSelector((state: StoreState) => doseSelectors.selectCustomizationValidationError(state, targetId));

    const handleDicomAttributeChanged = useCallback((attribute: string) => {
        if (!dicomTagId) { throw new Error('DICOM tag is not defined'); }

        dispatch(dicomTagAttributeSet({ id: dicomTagId, attribute: attribute }));
    }, [dispatch, dicomTagId]);

    const handleDicomValueChanged = useCallback((value: string) => {
        if (!dicomTagId) { throw new Error('DICOM tag is not defined'); }

        dispatch(dicomTagValueSet({ id: dicomTagId, value: value }));
    }, [dispatch, dicomTagId]);

    const handleRemoveDicomTag = useCallback(() => {
        dispatch(dicomTagRemoved(dicomTagId));
    }, [dispatch, dicomTagId]);

    if (dicomTag === undefined) {
        return null;
    }

    // const hasValidationError = validationError !== undefined && validationError.type === CustomizationObjectType.Target;
    const hasValidationError = false;

    return (
        <>
            <Tr className={`roi-item ${hasValidationError ? 'validation-error' : undefined}`}>
                <Td className={columnCssNames.modified}><div className="roi-item-is-modified" title="This customization has unsaved changes.">{/*target.isModified ? '*' : <>&nbsp;</>*/}</div></Td>

                <Td dataLabel={columnIds.dicomAttribute} className={columnCssNames.dicomAttribute}>
                    <div>
                        <DebouncedTextInput
                            fieldId={`dicom-tag-${dicomTagId}-attribute`}
                            onDebouncedChange={handleDicomAttributeChanged}
                            defaultValue={dicomTag.attribute}
                            placeholder={t('customizationPage.image.dicomRestrictionSection.dicomTags.attribute.placeholder')}
                        />
                    </div>
                </Td>

                <Td dataLabel={columnIds.dicomValue} className={columnCssNames.dicomValue}>
                    <div>
                        <DebouncedTextInput
                            fieldId={`dicom-tag-${dicomTagId}-value`}
                            onDebouncedChange={handleDicomValueChanged}
                            defaultValue={dicomTag.value}
                            placeholder={t('customizationPage.image.dicomRestrictionSection.dicomTags.value.placeholder')}
                        />
                    </div>
                </Td>

                <Td isActionCell>
                    <ItemActionsColumn items={[
                        {
                        //     title: t('customizationPage.dose.targets.undoChanges'),
                        //     onClick: handleRevertTargetChanges,
                        //     disabled: !hasOriginalTarget || !dicomTag.isModified,
                        // }, {
                            title: t('customizationPage.image.dicomRestrictionSection.dicomTags.removeDicomTag'),
                            onClick: handleRemoveDicomTag,
                        },
                    ]} />
                </Td>
            </Tr>

            {/* {hasValidationError && (
                <Tr className="roi-item validation-error validation-error-box selectable">
                    <Td dataLabel="Validation error" noPadding={true} colSpan={100}>
                        <Alert variant="danger" isInline isPlain isExpandable title={`Validation error: ${validationError.message}`}>
                            {validationError.field && (<div>Field: {validationError.field}</div>)}
                            <div>Error type: {validationError.detail}</div>
                            <div>Error ctx: {validationError.ctx}</div>
                        </Alert>
                    </Td>
                </Tr>
            )} */}

        </>
    );
}

export default DicomTagTableItem;
