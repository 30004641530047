import React, { PropsWithChildren } from 'react';
import { Split, SplitItem, StackItem, TextContent, Text } from '@patternfly/react-core';

import './section.css';

interface SectionItemProps {
    /** A small explaining text element */
    label?: string,
    /** A large explaining text element */
    title?: string,
    isModified?: boolean,
    className?: string,
}

const SectionItem = (props: PropsWithChildren<SectionItemProps>) => {
    const { label, title, isModified, className, children } = props;

    return (
        <StackItem className={`section-item ${className || ''}`}>
            <Split>
                {title !== undefined && (
                    <SplitItem className="section-title">
                        <TextContent>
                            <Text><b>{title}{`${isModified ? '*' : ''}`}</b></Text>
                        </TextContent>
                    </SplitItem>
                )}
                {label !== undefined && (
                    <SplitItem className="section-item-label">
                        <TextContent>
                            <Text>
                                {label}
                            </Text>
                        </TextContent>
                    </SplitItem>
                )}
                <SplitItem>
                    {children}
                </SplitItem>
            </Split>
        </StackItem>
    );

}

export default SectionItem;
