import { DataList, FormGroup, InputGroup, Switch } from '@patternfly/react-core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../store/store';
import Section from '../Section';
import SectionItem from '../SectionItem';
import { imageDicomRestrictionEnabledSet, imageSelectors } from '../../../store/image/imageSlice';

import '../customization-form.css';
import DicomTagTable from './DicomTagTable';

interface DicomRestrictionFormProps {
    outputId: string,
}

const DicomRestrictionForm = (props: DicomRestrictionFormProps) => {

    const { outputId } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const output = useSelector((state: StoreState) => imageSelectors.selectOutputById(state, outputId));
    const dicomRestriction = useSelector((state: StoreState) => imageSelectors.selectDicomRestrictionForOutput(state, outputId));

    const handleEnableDicomRestrictionToggled = useCallback(() => {
        if (dicomRestriction) { dispatch(imageDicomRestrictionEnabledSet({ id: dicomRestriction.id, isEnabled: !dicomRestriction.isEnabled })); }
    }, [dispatch, dicomRestriction]);


    // TODO: add section-specific output validity checking here later on if we have
    // any relevant form validation for those parts -- currently we don't
    const outputIsValid = true;

    if (!output || !dicomRestriction) {
        return null;
    }

    return (
        <div>
            <DataList aria-label={`Image+ dicom restriction customization`} isCompact className="customization-form">

                <Section
                    title={undefined}
                    isModified={false}
                    hasValidationError={!outputIsValid}
                    validationError={undefined}
                >

                    <SectionItem label={t('customizationPage.image.dicomRestrictionSection.enabled.label')}>
                        <FormGroup>
                            <InputGroup>
                                <Switch
                                    label={t('customizationPage.image.dicomRestrictionSection.enabled.on')}
                                    labelOff={t('customizationPage.image.dicomRestrictionSection.enabled.off')}
                                    isChecked={dicomRestriction.isEnabled}
                                    onChange={handleEnableDicomRestrictionToggled}
                                />
                            </InputGroup>
                        </FormGroup>
                    </SectionItem>

                </Section>

            </DataList >

            {dicomRestriction.isEnabled && (
                <DicomTagTable
                    dicomRestrictionId={dicomRestriction.id}
                    dicomTagIds={dicomRestriction.tags || []}
                />
            )}
        </div>
    );
}

export default DicomRestrictionForm;
