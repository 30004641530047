import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import '../customization-page.css';
import SectionLabel from '../SectionLabel';
import { StoreState } from '../../../store/store';
import { CustomizationObjectType, ModelVisibility } from '../../../store/global-types/customization-types';
import { RTSTRUCT_METADATA_ATTRIBUTE_PRESETS } from '../../../store/contouring/contouring-types';
import CustomizationSection from '../CustomizationSection';
import CustomizationForm from '../CustomizationForm';
import { adaptSelectors, metadataItemRemoved, metadataItemUpdated, modelCustomizationDescriptionUpdated, modelVisibilityUpdated, scrollToViewFromModelCustomizationMetadataRemoved } from '../../../store/adapt/adaptSlice';
import { makeSelectIsAnyMetadataForOutputModified, makeSelectIsAnyTriggerForBaseModified } from '../../../store/adapt/adaptSelectors';
import AdaptSelectionRules from '../../selection/adapt/AdaptSelectionRules';
import AdaptOutputForm from './AdaptOutputForm';
import RoiSelectionForm from './RoiSelectionForm';

interface AdaptCustomizationFormProps {
    outputId: string,
}

const AdaptCustomizationForm = (props: AdaptCustomizationFormProps) => {
    const { outputId } = props;
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const customizationOutput = useSelector((state: StoreState) => adaptSelectors.selectOutputById(state, outputId));
    const customizationBase = useSelector((state: StoreState) => customizationOutput ? adaptSelectors.selectCustomizationBaseById(state, customizationOutput.modelCustomizationBaseId) : undefined);
    const model = useSelector((state: StoreState) => customizationBase ? adaptSelectors.selectModelById(state, customizationBase.modelId) : undefined);
    const validationEntities = useSelector(adaptSelectors.selectCustomizationValidationErrorEntities);
    const outputMetadata = useSelector(adaptSelectors.selectOutputMetadataEntities);
    const originalMetadata = useSelector(adaptSelectors.selectOriginalOutputMetadataEntities);
    const [isOutputSectionExpanded, setOutputSectionExpanded] = useState(true);
    const [isRoiRulesSectionExpanded, setRoiRulesSectionExpanded] = useState(true);

    const selectIsAnyMetadataForOutputModified = useMemo(makeSelectIsAnyMetadataForOutputModified, []);
    const isAnyMetadataModified = useSelector((state: StoreState) => selectIsAnyMetadataForOutputModified(state, outputId));

    const selectIsAnyTriggerRuleForBaseModified = useMemo(makeSelectIsAnyTriggerForBaseModified, []);
    const isAnyTriggerRuleModified = useSelector((state: StoreState) => customizationOutput ? selectIsAnyTriggerRuleForBaseModified(state, customizationOutput.modelCustomizationBaseId) : false);


    const handleToggleOutputSectionExpanded = useCallback(() => {
        setOutputSectionExpanded(!isOutputSectionExpanded);
    }, [isOutputSectionExpanded]);

    const handleToggleRoiRulesSectionExpanded = useCallback(() => {
        setRoiRulesSectionExpanded(!isRoiRulesSectionExpanded);
    }, [isRoiRulesSectionExpanded]);

    const onSetModelVisibility = useCallback((modelId: string, visibility: ModelVisibility) => {
        dispatch(modelVisibilityUpdated({ modelId, visibility }));
    }, [dispatch]);

    const onDescriptionChanged = useCallback((text: string) => {
        if (customizationBase) {
            dispatch(modelCustomizationDescriptionUpdated({ customizationBaseId: customizationBase.id, description: text }));
        }
    }, [dispatch, customizationBase]);

    const onMetadataItemUpdated = useCallback((metadataId: string, attribute: string | undefined, value: string | undefined, isUndoOperation?: boolean) => {
        dispatch(metadataItemUpdated({ metadataId, attribute, value, isUndoOperation }));
    }, [dispatch]);

    const onMetadataItemRemoved = useCallback((metadataId: string) => {
        dispatch(metadataItemRemoved(metadataId));
    }, [dispatch]);

    const removeScrollToViewFromMetadataItem = useCallback((metadataId: string) => {
        dispatch(scrollToViewFromModelCustomizationMetadataRemoved(metadataId));
    }, [dispatch]);


    // sanity check that we have retrieved valid objects from redux store
    // (this check has to be done after all hooks calls)
    if (customizationOutput === undefined || customizationBase === undefined || model === undefined) {
        return null;
    }

    const outputHasValidErrors = validationEntities[outputId] !== undefined && validationEntities[outputId].type === CustomizationObjectType.CustomizationOutput;


    return (<CustomizationForm
        model={model}
        customizationBase={customizationBase}
        customizationOutput={customizationOutput}
        validationEntities={validationEntities}
        outputMetadata={outputMetadata}
        originalMetadata={originalMetadata}
        metadataPresets={RTSTRUCT_METADATA_ATTRIBUTE_PRESETS}
        isAnyMetadataModified={isAnyMetadataModified}
        isAnyTriggerRuleModified={isAnyTriggerRuleModified}
        onSetModelVisibility={onSetModelVisibility}
        onDescriptionChanged={onDescriptionChanged}
        onMetadataItemUpdated={onMetadataItemUpdated}
        onMetadaItemRemoved={onMetadataItemRemoved}
        removeScrollToViewFromMetadataItem={removeScrollToViewFromMetadataItem}
        isMetadataSectionDisabled={false}
        isDicomSelectionDisabled={false}
        hideTargetFile={true}
        selectionRules={(
            <AdaptSelectionRules
                customizationBaseId={customizationBase.id}
                isUndoDisabled={true}
                setUndoState={() => { }}
                heading={`model-selection-for-${customizationBase.id}`}
                hideCustomizationName
                showMultipleOutputsWarning={customizationBase.outputs.length > 1}
            />
        )}
    >

        <CustomizationSection
            label={t('customizationPage.adapt.outputSection.title')}
            isModified={customizationOutput.isModified}
            isValid={!outputHasValidErrors}
            collapsedMessage={t('customizationPage.adapt.outputSection.message')}
            isExpanded={isOutputSectionExpanded}
            onClick={handleToggleOutputSectionExpanded}
        >
            <AdaptOutputForm outputId={outputId} />
        </CustomizationSection>

        <CustomizationSection
            label={t('customizationPage.adapt.roiSelectionSection.title')}
            isModified={customizationOutput.isModified}
            isValid={!outputHasValidErrors}
            collapsedMessage={t('customizationPage.adapt.roiSelectionSection.message')}
            isExpanded={isRoiRulesSectionExpanded}
            onClick={handleToggleRoiRulesSectionExpanded}
        >
            <RoiSelectionForm outputId={outputId} />
        </CustomizationSection>

    </CustomizationForm>
    );


    // return (
    //     <CustomizationForm
    //         model={model}
    //         customizationBase={customizationBase}
    //         customizationOutput={customizationOutput}
    //         validationEntities={validationEntities}
    //         outputMetadata={outputMetadata}
    //         originalMetadata={originalMetadata}
    //         metadataPresets={RTSTRUCT_METADATA_ATTRIBUTE_PRESETS}
    //         isAnyMetadataModified={isAnyMetadataModified}
    //         isAnyTriggerRuleModified={isAnyTriggerRuleModified}
    //         onSetModelVisibility={onSetModelVisibility}
    //         onDescriptionChanged={onDescriptionChanged}
    //         onMetadataItemUpdated={onMetadataItemUpdated}
    //         onMetadaItemRemoved={onMetadaItemRemoved}
    //         removeScrollToViewFromMetadataItem={removeScrollToViewFromMetadataItem}
    //         isMetadataSectionDisabled={true}
    //         isDicomSelectionDisabled={true}
    //         hideTargetFile={true}
    //         selectionRules={(
    //             <ImageSelectionRules
    //                 customizationBaseId={customizationBase.id}
    //                 isUndoDisabled={true}
    //                 setUndoState={() => { }}
    //                 heading={`model-selection-for-${customizationBase.id}`}
    //                 hideCustomizationName
    //                 showMultipleOutputsWarning={customizationBase.outputs.length > 1}
    //             />
    //         )}
    //     >

    //         <CustomizationSection
    //             label={outputSectionLabel}
    //             collapsedMessage={t('customizationPage.dose.outputSection.message')}
    //             isExpanded={isOutputSectionExpanded}
    //             onClick={handleToggleOutputSectionExpanded}
    //         >
    //             <ImageOutputForm outputId={outputId} />
    //         </CustomizationSection>

    //         <CustomizationSection
    //             label={targetsSectionLabel}
    //             collapsedMessage={t('customizationPage.dose.targetsSection.message')}
    //             isExpanded={isTargetsSectionExpanded}
    //             onClick={handleToggleTargetsSectionExpanded}
    //         >
    //             <DoseTargetSection
    //                 outputId={outputId}
    //             />
    //         </CustomizationSection>

    //         <CustomizationSection
    //             label={roisSectionLabel}
    //             collapsedMessage={t('customizationPage.dose.roisSection.message')}
    //             isExpanded={isRoisSectionExpanded}
    //             onClick={handleToggleRoisSectionExpanded}
    //         >
    //             <DoseRoiTable
    //                 outputId={outputId}
    //                 roiIds={customizationOutput.rois}
    //             />
    //         </CustomizationSection>

    //     </CustomizationForm >
    // );
}

export default AdaptCustomizationForm;
