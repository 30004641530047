import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import '../customization-page.css';
import { StoreState } from '../../../store/store';
import { CustomizationObjectType, ModelVisibility } from '../../../store/global-types/customization-types';
import { doseSelectors, metadataItemRemoved, metadataItemUpdated, modelCustomizationDescriptionUpdated, modelVisibilityUpdated, scrollToViewFromModelCustomizationMetadataRemoved } from '../../../store/dose/doseSlice';
import { RTSTRUCT_METADATA_ATTRIBUTE_PRESETS } from '../../../store/contouring/contouring-types';
import DoseRoiTable from './DoseRoiTable';
import DoseSelectionRules from '../../selection/dose/DoseSelectionRules';
import DoseOutputForm from './DoseOutputForm';
import CustomizationSection from '../CustomizationSection';
import CustomizationForm from '../CustomizationForm';
import { makeSelectDoseRoisForOutput, makeSelectIsAnyMetadataForOutputModified, makeSelectIsAnyRoiForOutputModified, makeSelectIsAnyTargetForOutputModified, makeSelectIsAnyTriggerForBaseModified } from '../../../store/dose/doseSelectors';
import DoseTargetSection from './DoseTargetSection';

interface DoseCustomizationFormProps {
    outputId: string,
}

const DoseCustomizationForm = (props: DoseCustomizationFormProps) => {
    const { outputId } = props;
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const customizationOutput = useSelector((state: StoreState) => doseSelectors.selectOutputById(state, outputId));
    const customizationBase = useSelector((state: StoreState) => customizationOutput ? doseSelectors.selectCustomizationBaseById(state, customizationOutput.modelCustomizationBaseId) : undefined);
    const model = useSelector((state: StoreState) => customizationBase ? doseSelectors.selectModelById(state, customizationBase.modelId) : undefined);
    const validationEntities = useSelector(doseSelectors.selectCustomizationValidationErrorEntities);
    const outputMetadata = useSelector(doseSelectors.selectOutputMetadataEntities);
    const originalMetadata = useSelector(doseSelectors.selectOriginalOutputMetadataEntities);
    const [isOutputSectionExpanded, setOutputSectionExpanded] = useState(true);
    const [isRoisSectionExpanded, setRoisSectionExpanded] = useState(true);
    const [isTargetsSectionExpanded, setTargetsSectionExpanded] = useState(true);

    const selectDoseRoisForOutput = useMemo(makeSelectDoseRoisForOutput, []);
    const doseRois = useSelector((state: StoreState) => selectDoseRoisForOutput(state, outputId));

    const selectIsAnyRoiForOutputModified = useMemo(makeSelectIsAnyRoiForOutputModified, []);
    const isAnyRoiModified = useSelector((state: StoreState) => selectIsAnyRoiForOutputModified(state, outputId));

    const selectIsAnyTargetForOutputModified = useMemo(makeSelectIsAnyTargetForOutputModified, []);
    const isAnyTargetModified = useSelector((state: StoreState) => selectIsAnyTargetForOutputModified(state, outputId));

    const selectIsAnyMetadataForOutputModified = useMemo(makeSelectIsAnyMetadataForOutputModified, []);
    const isAnyMetadataModified = useSelector((state: StoreState) => selectIsAnyMetadataForOutputModified(state, outputId));

    const selectIsAnyTriggerRuleForBaseModified = useMemo(makeSelectIsAnyTriggerForBaseModified, []);
    const isAnyTriggerRuleModified = useSelector((state: StoreState) => customizationOutput ? selectIsAnyTriggerRuleForBaseModified(state, customizationOutput.modelCustomizationBaseId) : false);


    const handleToggleOutputSectionExpanded = useCallback(() => {
        setOutputSectionExpanded(!isOutputSectionExpanded);
    }, [isOutputSectionExpanded]);

    const handleToggleRoisSectionExpanded = useCallback(() => {
        setRoisSectionExpanded(!isRoisSectionExpanded);
    }, [isRoisSectionExpanded]);

    const handleToggleTargetsSectionExpanded = useCallback(() => {
        setTargetsSectionExpanded(!isTargetsSectionExpanded);
    }, [isTargetsSectionExpanded]);

    const onSetModelVisibility = useCallback((modelId: string, visibility: ModelVisibility) => {
        dispatch(modelVisibilityUpdated({ modelId, visibility }));
    }, [dispatch]);

    const onDescriptionChanged = useCallback((text: string) => {
        if (customizationBase) {
            dispatch(modelCustomizationDescriptionUpdated({ customizationBaseId: customizationBase.id, description: text }));
        }
    }, [dispatch, customizationBase]);

    const onMetadataItemUpdated = useCallback((metadataId: string, attribute: string | undefined, value: string | undefined, isUndoOperation?: boolean) => {
        dispatch(metadataItemUpdated({ metadataId, attribute, value, isUndoOperation }));
    }, [dispatch]);

    const onMetadaItemRemoved = useCallback((metadataId: string) => {
        dispatch(metadataItemRemoved(metadataId));
    }, [dispatch]);

    const removeScrollToViewFromMetadataItem = useCallback((metadataId: string) => {
        dispatch(scrollToViewFromModelCustomizationMetadataRemoved(metadataId));
    }, [dispatch]);


    // sanity check that we have retrieved valid objects from redux store
    // (this check has to be done after all hooks calls)
    if (customizationOutput === undefined || customizationBase === undefined || model === undefined) {
        return null;
    }

    const outputHasValidErrors = validationEntities[outputId] !== undefined && validationEntities[outputId].type === CustomizationObjectType.CustomizationOutput;
    const roisHaveValidationErrors = customizationOutput.rois.some(rId => validationEntities[rId] !== undefined && validationEntities[rId]!.type === CustomizationObjectType.Roi);
    const targetsHaveValidationErrors = customizationOutput.targets.some(tId => validationEntities[tId] !== undefined && validationEntities[tId]!.type === CustomizationObjectType.Target);



    return (
        <CustomizationForm
            model={model}
            customizationBase={customizationBase}
            customizationOutput={customizationOutput}
            validationEntities={validationEntities}
            outputMetadata={outputMetadata}
            originalMetadata={originalMetadata}
            metadataPresets={RTSTRUCT_METADATA_ATTRIBUTE_PRESETS}
            isAnyMetadataModified={isAnyMetadataModified}
            isAnyTriggerRuleModified={isAnyTriggerRuleModified}
            onSetModelVisibility={onSetModelVisibility}
            onDescriptionChanged={onDescriptionChanged}
            onMetadataItemUpdated={onMetadataItemUpdated}
            onMetadaItemRemoved={onMetadaItemRemoved}
            removeScrollToViewFromMetadataItem={removeScrollToViewFromMetadataItem}
            isMetadataSectionDisabled={true}
            isDicomSelectionDisabled={true}
            hideTargetFile={true}
            selectionRules={(
                <DoseSelectionRules
                    customizationBaseId={customizationBase.id}
                    isUndoDisabled={true}
                    setUndoState={() => { }}
                    heading={`model-selection-for-${customizationBase.id}`}
                    hideCustomizationName
                    showMultipleOutputsWarning={customizationBase.outputs.length > 1}
                />
            )}
        >

            <CustomizationSection
                label={t('customizationPage.dose.outputSection.title')}
                isModified={customizationOutput.isModified}
                isValid={!outputHasValidErrors}
                collapsedMessage={t('customizationPage.dose.outputSection.message')}
                isExpanded={isOutputSectionExpanded}
                onClick={handleToggleOutputSectionExpanded}
            >
                <DoseOutputForm outputId={outputId} />
            </CustomizationSection>

            <CustomizationSection
                label={t('customizationPage.dose.targetsSection.title')}
                isModified={isAnyTargetModified}
                isValid={!targetsHaveValidationErrors}
                itemCount={customizationOutput.targets.length}
                collapsedMessage={t('customizationPage.dose.targetsSection.message')}
                isExpanded={isTargetsSectionExpanded}
                onClick={handleToggleTargetsSectionExpanded}
            >
                <DoseTargetSection
                    outputId={outputId}
                />
            </CustomizationSection>

            <CustomizationSection
                label={t('customizationPage.dose.roisSection.title')}
                isModified={isAnyRoiModified}
                isValid={!roisHaveValidationErrors}
                itemCount={customizationOutput.rois.length}
                collapsedMessage={t('customizationPage.dose.roisSection.message')}
                isExpanded={isRoisSectionExpanded}
                onClick={handleToggleRoisSectionExpanded}
            >
                <DoseRoiTable
                    outputId={outputId}
                    roiIds={customizationOutput.rois}
                />
            </CustomizationSection>

        </CustomizationForm >
    );
}

export default DoseCustomizationForm;
