/***
 * This file contains helper functions for converting between JSON and view model formats for
 * common parts of algorithm customization types (e.g. 'contouring' and 'dose' customization formats
 * have many identical props)
 */

import { forEach, groupBy, has, isArray, isBoolean, isObject, isString, maxBy } from "lodash-es";
import { assertExpectedProps, PropType } from "../../util/expected-prop";
import { createNewAeTitleRule, createNewDicomAttributeRule, createNewDicomRule, createNewMetadataItem, createNewModel, generateNewId, getModelVisibilityForJson, parseModelVisibility, UI_ID_ATTRIBUTE } from "./customization-helpers";
import { AeTitleRule, CustomizationBase, DICOM_RULE_TRIGGER_ACTION, DicomAttributeRule, DicomRule, DOSE_MODEL_TYPE, isAeTitleRule, isDicomRule, Model, ModelTrigger, ModelType, OutputMetadataItem, SEGMENTATION_MODEL_TYPE } from "./customization-types";
import { ContouringCustomizationEntities, ContouringCustomizationEntitiesForExport, ContouringCustomizationOutput, ContouringRoi, GlobalContouringRoi } from "../contouring/contouring-types";
import { DoseCustomizationEntities, DoseCustomizationEntitiesForExport, DoseCustomizationOutput, DoseRoi, DoseTarget } from "../dose/dose-types";
import { _convertContouringCustomizationOutputJsonToViewModel, _convertContouringCustomizationOutputToJson, createGlobalRoiCustomization, generateRoiCustomizationHash } from "../contouring/contouring-helpers";
import { _convertDoseCustomizationJsonToViewModel, _convertDoseCustomizationOutputToJson } from "../dose/dose-helpers";
import { getCollectionOrUndefinedIfEmpty } from "../../util/array";
import { assertIsDefined } from "../../util/assert";
import { BodyMask, FillHoles, HoleMask, IMAGE_MODEL_TYPE, ImageContourGeneration, ImageCustomizationEntities, ImageCustomizationEntitiesForExport, ImageCustomizationOutput, ImageDicomRestriction, ImageDicomTag, ImageOutputGeometry, ImagePostProcessing, KeepLargestComponent } from "../image/image-types";
import { _convertImageCustomizationJsonToViewModel, _convertImageCustomizationOutputToJson } from "../image/image-helpers";
import { ADAPT_MODEL_TYPE, AdaptCustomizationEntities, AdaptCustomizationEntitiesForExport, AdaptCustomizationOutput, AdaptRoiRule } from "../adapt/adapt-types";
import { _convertAdaptCustomizationJsonToViewModel, _convertAdaptCustomizationOutputToJson } from "../adapt/adapt-helpers";


const supportedAlgorithmTypes = [SEGMENTATION_MODEL_TYPE, DOSE_MODEL_TYPE, IMAGE_MODEL_TYPE, ADAPT_MODEL_TYPE];

type AllCustomizationEntities =
    ContouringCustomizationEntities &
    DoseCustomizationEntities &
    ImageCustomizationEntities &
    AdaptCustomizationEntities;

type AllCustomizationEntitiesForExport =
    ContouringCustomizationEntitiesForExport &
    DoseCustomizationEntitiesForExport &
    ImageCustomizationEntitiesForExport &
    AdaptCustomizationEntitiesForExport;


// entity collection types

type ContouringEntityCollections = {
    contouringOutputCollection: ContouringCustomizationOutput[],
    contouringRoiCollection: ContouringRoi[],
}

type DoseEntityCollections = {
    doseOutputCollection: DoseCustomizationOutput[],
    doseRoiCollection: DoseRoi[],
    doseTargetCollection: DoseTarget[],
}

type ImageEntityCollections = {
    imageOutputCollection: ImageCustomizationOutput[],
    imageDicomRestrictionCollection: ImageDicomRestriction[],
    imageDicomTagCollection: ImageDicomTag[],
    imageOutputGeometryCollection: ImageOutputGeometry[],
    imageContourGenerationCollection: ImageContourGeneration[],
    imagePostProcessingCollection: ImagePostProcessing[],
    keepLargestComponentCollection: KeepLargestComponent[],
    fillHolesCollection: FillHoles[],
    bodyMaskCollection: BodyMask[],
    holeMaskCollection: HoleMask[],
}

type AdaptEntityCollections = {
    adaptOutputCollection: AdaptCustomizationOutput[],
    adaptRoiRuleCollection: AdaptRoiRule[],
}

// entity collection type guards

const isContouringEntityCollections = (obj: any): obj is ContouringEntityCollections => {
    const castObj = obj as ContouringEntityCollections;
    return has(castObj, 'contouringOutputCollection') && has(castObj, 'contouringRoiCollection');
}

const isDoseEntityCollections = (obj: any): obj is DoseEntityCollections => {
    const castObj = obj as DoseEntityCollections;
    return has(castObj, 'doseOutputCollection') && has(castObj, 'doseRoiCollection') && has(castObj, 'doseTargetCollection');
}

const isImageEntityCollections = (obj: any): obj is ImageEntityCollections => {
    const castObj = obj as ImageEntityCollections;
    return has(castObj, 'imageOutputCollection') && has(castObj, 'imageDicomRestrictionCollection') && has(castObj, 'imageOutputGeometryCollection');
}

const isAdaptEntityCollections = (obj: any): obj is AdaptEntityCollections => {
    const castObj = obj as AdaptEntityCollections;
    return has(castObj, 'adaptOutputCollection') && has(castObj, 'adaptRoiRuleCollection');
}


/**
 * A generic main function for converting incoming customization JSON to internal view models.
 * Works with any supported algorithm.
 * 
 * Regarding sorting: if any sorting is done, it's generally done to try to ensure order by original
 * names using naturalSort. Dynamic sorting during user operation is generally not wanted as it could
 * erratically shift the position of the element on the page. Set the 'noSorting' prop to 'true' if sorting
 * is not wanted (e.g. for roundtrip unit tests).
 * 
 * @param jsonObject The incoming JSON DTO object to convert to view models.
 * @param modelType If defined will throw if models other than the defined model type are in the
 * JSON. Converts anything supported supplied into it if left undefined. Defaults to undefined.
 * @param noSorting If true then the order of items in the supplied JSON is left intact in the view models. Defaults to false.
 * @returns A collection of converted view models. Any props in the returned collection object that did not have any matching
 * view models are left undefined.
 */
export const convertCustomizationJsonObjectToViewModels = (jsonObject: any, modelType: ModelType, noSorting: boolean = false): Partial<AllCustomizationEntities> => {
    if (!jsonObject || !isObject(jsonObject)) {
        throw new Error('Invalid JSON object provided for customizations conversion.');
    }

    const modelCollection: Model[] = [];
    const customizationBaseCollection: CustomizationBase[] = [];
    const metadataCollection: OutputMetadataItem[] = [];
    const aeTitleRuleCollection: AeTitleRule[] = [];
    const dicomRuleCollection: DicomRule[] = [];
    const dicomAttributeRuleCollection: DicomAttributeRule[] = [];

    // contour+ items
    const contouringOutputCollection: ContouringCustomizationOutput[] = [];
    const contouringRoiCollection: ContouringRoi[] = [];
    const contouringGlobalRoiCollection: GlobalContouringRoi[] = [];

    // dose+ items
    const doseOutputCollection: DoseCustomizationOutput[] = [];
    const doseRoiCollection: DoseRoi[] = [];
    const doseTargetCollection: DoseTarget[] = [];

    // image+ items
    const imageOutputCollection: ImageCustomizationOutput[] = [];
    const imageDicomRestrictionCollection: ImageDicomRestriction[] = [];
    const imageDicomTagCollection: ImageDicomTag[] = [];
    const imageOutputGeometryCollection: ImageOutputGeometry[] = [];
    const imageContourGenerationCollection: ImageContourGeneration[] = [];
    const imagePostProcessingCollection: ImagePostProcessing[] = [];
    const keepLargestComponentCollection: KeepLargestComponent[] = [];
    const fillHolesCollection: FillHoles[] = [];
    const bodyMaskCollection: BodyMask[] = [];
    const holeMaskCollection: HoleMask[] = [];

    // adapt+ items
    const adaptOutputCollection: AdaptCustomizationOutput[] = [];
    const adaptRoiRuleCollection: AdaptRoiRule[] = [];

    const assertMessage = 'Unexpected contouring model/structure customization format.';

    // convert json into view model objects
    forEach(jsonObject, (modelEntry: any) => {
        convertSingleCustomizationJsonEntryToViewModelObjects(
            modelEntry,

            modelCollection,
            customizationBaseCollection,
            metadataCollection,
            aeTitleRuleCollection,
            dicomRuleCollection,
            dicomAttributeRuleCollection,

            {
                contouringOutputCollection,
                contouringRoiCollection,

                doseOutputCollection,
                doseRoiCollection,
                doseTargetCollection,

                imageOutputCollection,
                imageDicomRestrictionCollection,
                imageDicomTagCollection,
                imageOutputGeometryCollection,
                imageContourGenerationCollection,
                imagePostProcessingCollection,
                keepLargestComponentCollection,
                fillHolesCollection,
                bodyMaskCollection,
                holeMaskCollection,

                adaptOutputCollection,
                adaptRoiRuleCollection,
            },

            assertMessage,
            modelType,
            noSorting);
    });

    // collect global ROIs if we have supported objects
    if ((modelType === undefined || modelType === ModelType.Contouring) && contouringRoiCollection.length > 0) {
        // collect similar rois into GlobalRoiCustomization groups
        const groupedRois = groupBy(contouringRoiCollection, roi => roi.operation);

        for (const roiCustomizations of Object.values(groupedRois)) {
            const hashedCustomizations: { [hash: string]: ContouringRoi[] } = {};
            for (const roiCustomization of roiCustomizations) {
                // find the largest group of similar roi customizations for each specific roi -- use hashes to find identical customizations
                const hashedRoi = generateRoiCustomizationHash(roiCustomization);
                hashedCustomizations[hashedRoi] = hashedCustomizations[hashedRoi] || [];
                hashedCustomizations[hashedRoi].push(roiCustomization);
            }
            const largestGroupHash = maxBy(Object.keys(hashedCustomizations), hash => hashedCustomizations[hash].length);
            if (largestGroupHash === undefined) { throw new Error('Could not find hash for largest group for roi'); }
            const largestGroupRois = hashedCustomizations[largestGroupHash];
            const largestGroupRoiIds = largestGroupRois.map(r => r.id);
            const excludedRois = roiCustomizations.filter(r => !largestGroupRoiIds.includes(r.id));
            const globalRoiCustomization = createGlobalRoiCustomization(largestGroupRois, excludedRois);
            contouringGlobalRoiCollection.push(globalRoiCustomization);
        }
    }

    return {
        models: getCollectionOrUndefinedIfEmpty(modelCollection),
        customizationBases: getCollectionOrUndefinedIfEmpty(customizationBaseCollection),
        outputMetadata: getCollectionOrUndefinedIfEmpty(metadataCollection),
        aeTitleRules: getCollectionOrUndefinedIfEmpty(aeTitleRuleCollection),
        dicomRules: getCollectionOrUndefinedIfEmpty(dicomRuleCollection),
        dicomAttributeRules: getCollectionOrUndefinedIfEmpty(dicomAttributeRuleCollection),

        contouringOutputs: getCollectionOrUndefinedIfEmpty(contouringOutputCollection),
        contouringRois: getCollectionOrUndefinedIfEmpty(contouringRoiCollection),
        contouringGlobalRois: getCollectionOrUndefinedIfEmpty(contouringGlobalRoiCollection),

        doseOutputs: getCollectionOrUndefinedIfEmpty(doseOutputCollection),
        doseRois: getCollectionOrUndefinedIfEmpty(doseRoiCollection),
        doseTargets: getCollectionOrUndefinedIfEmpty(doseTargetCollection),

        imageOutputs: getCollectionOrUndefinedIfEmpty(imageOutputCollection),
        imageDicomRestrictions: getCollectionOrUndefinedIfEmpty(imageDicomRestrictionCollection),
        imageDicomTags: getCollectionOrUndefinedIfEmpty(imageDicomTagCollection),
        imageOutputGeometry: getCollectionOrUndefinedIfEmpty(imageOutputGeometryCollection),
        imageContourGeneration: getCollectionOrUndefinedIfEmpty(imageContourGenerationCollection),
        imagePostProcessing: getCollectionOrUndefinedIfEmpty(imagePostProcessingCollection),
        keepLargestComponent: getCollectionOrUndefinedIfEmpty(keepLargestComponentCollection),
        fillHoles: getCollectionOrUndefinedIfEmpty(fillHolesCollection),
        bodyMasks: getCollectionOrUndefinedIfEmpty(bodyMaskCollection),
        holeMasks: getCollectionOrUndefinedIfEmpty(holeMaskCollection),

        adaptOutputs: getCollectionOrUndefinedIfEmpty(adaptOutputCollection),
        adaptRoiRules: getCollectionOrUndefinedIfEmpty(adaptRoiRuleCollection),
    }
}

/** Converts a single JSON model object into matching view model objects. Usually
 * you'll probably want to call convertCustomizationJsonObjectToViewModels above instead
 * but this function may be useful in certain cases for converting just a single model.
 */
export const convertSingleCustomizationJsonEntryToViewModelObjects = (
    modelEntry: any,

    modelCollection: Model[],
    customizationBaseCollection: CustomizationBase[],
    metadataCollection: OutputMetadataItem[],
    aeTitleRuleCollection: AeTitleRule[],
    dicomRuleCollection: DicomRule[],
    dicomAttributeRuleCollection: DicomAttributeRule[],

    modelTypeCollections: ContouringEntityCollections | DoseEntityCollections | ImageEntityCollections | AdaptEntityCollections,

    assertMessage: string,
    modelType: ModelType,
    noSorting: boolean
) => {

    // prepare a model id
    const modelId = generateNewId();

    // assert that the base model stuff (element root items) looks correct
    assertExpectedProps(modelEntry, [
        { propName: 'model_name', propType: PropType.String },
        { propName: 'model_label', propType: PropType.String },
        { propName: 'model_type', propType: PropType.String },
        { propName: 'body_part', propType: PropType.String },
        { propName: 'description', propType: PropType.String },
        { propName: 'customizations', propType: PropType.Array },
        { propName: 'triggers', propType: PropType.Array },
    ], assertMessage);

    const modelName = modelEntry['model_name'];
    const modelTypeAsString = modelEntry['model_type'];

    // check that model type is supported
    assertSupportedModelType(modelTypeAsString, modelType, modelTypeCollections);

    // extract selection triggers
    const selectionTriggerResults = extractSelectionTriggers(modelEntry, assertMessage);
    const { triggers, dicomAttributeRules, modelTriggersByCustomizationNames } = selectionTriggerResults;

    // extract model customization bases
    const customizationBaseResults = extractCustomizationBases(modelEntry, assertMessage);


    // extract customizations per supported algorithm
    let customizations: CustomizationBase[];
    if (modelType === ModelType.Contouring && isContouringEntityCollections(modelTypeCollections)) {
        customizations = customizationBaseResults.map(modelCustomizationBaseResult => _convertContouringCustomizationOutputJsonToViewModel(
            modelCustomizationBaseResult,
            modelId,
            modelTypeCollections.contouringOutputCollection,
            metadataCollection,
            modelTypeCollections.contouringRoiCollection,
            modelTriggersByCustomizationNames,
            assertMessage,
            noSorting
        ));
    } else if (modelType === ModelType.Dose && isDoseEntityCollections(modelTypeCollections)) {
        customizations = customizationBaseResults.map(modelCustomizationBaseResult => _convertDoseCustomizationJsonToViewModel(
            modelCustomizationBaseResult,
            modelId,
            modelTypeCollections.doseOutputCollection,
            metadataCollection,
            modelTypeCollections.doseRoiCollection,
            modelTypeCollections.doseTargetCollection,
            modelTriggersByCustomizationNames,
            assertMessage,
            noSorting
        ));
    } else if (modelType === ModelType.Image && isImageEntityCollections(modelTypeCollections)) {
        customizations = customizationBaseResults.map(modelCustomizationBaseResult => _convertImageCustomizationJsonToViewModel(
            modelCustomizationBaseResult,
            modelId,
            modelTypeCollections.imageOutputCollection,
            metadataCollection,
            modelTypeCollections.imageDicomRestrictionCollection,
            modelTypeCollections.imageDicomTagCollection,
            modelTypeCollections.imageOutputGeometryCollection,
            modelTypeCollections.imageContourGenerationCollection,
            modelTypeCollections.imagePostProcessingCollection,
            modelTypeCollections.keepLargestComponentCollection,
            modelTypeCollections.fillHolesCollection,
            modelTypeCollections.bodyMaskCollection,
            modelTypeCollections.holeMaskCollection,
            modelTriggersByCustomizationNames,
            assertMessage,
            noSorting
        ));
    } else if (modelType === ModelType.Adapt && isAdaptEntityCollections(modelTypeCollections)) {
        customizations = customizationBaseResults.map(modelCustomizationBaseResult => _convertAdaptCustomizationJsonToViewModel(
            modelCustomizationBaseResult,
            modelId,
            modelTypeCollections.adaptOutputCollection,
            metadataCollection,
            modelTypeCollections.adaptRoiRuleCollection,
            modelTriggersByCustomizationNames,
            assertMessage,
            noSorting
        ));
    }
    else {
        customizations = [];
    }

    const customizationIds = customizations.map(c => c.id);

    // add newly created objects into collections that will eventually be put into redux store
    customizationBaseCollection.push(...customizations);
    aeTitleRuleCollection.push(...triggers.filter(isAeTitleRule));
    dicomRuleCollection.push(...triggers.filter(isDicomRule));
    dicomAttributeRuleCollection.push(...dicomAttributeRules);

    const model = extractModel(modelId, modelType, modelName, customizationIds, modelEntry, assertMessage);

    modelCollection.push(model);

}

/** Converts model type into a JSON string (or undefined if model type is invalid or unsupported). */
const getModelTypeAsString = (modelType: ModelType): string | undefined => {
    switch (modelType) {
        case ModelType.Contouring:
            return SEGMENTATION_MODEL_TYPE;
        case ModelType.Dose:
            return DOSE_MODEL_TYPE;
        case ModelType.Image:
            return IMAGE_MODEL_TYPE;
        case ModelType.Adapt:
            return ADAPT_MODEL_TYPE;
        default:
            return undefined;
    }
}

/** Checks if given modelType is valid and supported, and throws if not. */
const assertSupportedModelType = (
    modelTypeAsString: any,
    supportedModelType: ModelType,
    modelTypeCollections: ContouringEntityCollections | DoseEntityCollections | ImageEntityCollections | AdaptEntityCollections
) => {
    if (!isString(modelTypeAsString)) {
        console.error(modelTypeAsString);
        throw new Error('Could not detect model type');
    }

    if (!supportedAlgorithmTypes.includes(modelTypeAsString)) {
        throw new Error(`Model type (${modelTypeAsString}) is not supported`);
    }

    let isModelTypeValid = false;
    switch (supportedModelType) {
        case ModelType.Contouring:
            if (modelTypeAsString === SEGMENTATION_MODEL_TYPE && isContouringEntityCollections(modelTypeCollections)) {
                isModelTypeValid = true;
            }
            break;
        case ModelType.Dose:
            if (modelTypeAsString === DOSE_MODEL_TYPE && isDoseEntityCollections(modelTypeCollections)) {
                isModelTypeValid = true;
            }
            break;
        case ModelType.Image:
            if (modelTypeAsString === IMAGE_MODEL_TYPE && isImageEntityCollections(modelTypeCollections)) {
                isModelTypeValid = true;
            }
            break;
        case ModelType.Adapt:
            if (modelTypeAsString === ADAPT_MODEL_TYPE && isAdaptEntityCollections(modelTypeCollections)) {
                isModelTypeValid = true;
            }
            break;
        default:
            break;
    }

    if (!isModelTypeValid) {
        throw new Error(`Unexpected model type encountered (${modelTypeAsString}) -- only ${supportedModelType} model type is supported for this customization`);
    }
}

/** A temporary collection where model triggers (ae title rules and dicom rules) are stored during JSON-to-ViewModel conversion
    and are linked by the customization names that they belong to. This is to make linking model triggers a bit later to their
    matching CustomizationBase objects easier, as the parent customizationName field itself is NOT stored in any of the model
    trigger-related view model objects. */
export type ModelTriggersByCustomizationNames = { [customizationName: string]: ModelTrigger[]; };

export type SelectionTriggerExtractResults = {
    triggers: ModelTrigger[],
    dicomAttributeRules: DicomAttributeRule[],
    modelTriggersByCustomizationNames: ModelTriggersByCustomizationNames,
}

/** Extracts selection triggers (AeTitleRules, DicomRules, DicomAttributeRules) from a matching portion of a 
 * customization JSON. */
export const extractSelectionTriggers = (modelEntry: any, assertMessage: string): SelectionTriggerExtractResults => {

    // assert that incoming JSON DTO looks valid
    assertExpectedProps(modelEntry, [
        { propName: 'triggers', propType: PropType.Array },
    ], assertMessage);

    const modelTriggersByCustomizationNames: ModelTriggersByCustomizationNames = {};
    const dicomAttributeRulesCollection: DicomAttributeRule[] = [];

    // extract selection triggers
    const triggers: ModelTrigger[] = modelEntry['triggers'].map((triggerEntry: any) => {
        // assert that trigger rule entries look correct
        assertExpectedProps(triggerEntry, [
            { propName: 'customization_name', propType: PropType.String },
            { propName: 'subscription', propType: PropType.String },
            { propName: 'action', propType: PropType.String },
        ], assertMessage);

        const customizationName = triggerEntry['customization_name'];
        const subscription = triggerEntry['subscription'];
        const action = triggerEntry['action'];
        const aggregationEntry = triggerEntry['aggregation'];
        const aggregation = isString(aggregationEntry) ? aggregationEntry : null;

        // prepare temporary model trigger collection for this customization name
        if (!modelTriggersByCustomizationNames[customizationName]) {
            modelTriggersByCustomizationNames[customizationName] = [];
        }

        if (triggerEntry['dicom_attributes'] !== undefined) {
            // this is a DICOM attribute trigger
            // pre-generate dicom rule ID as we need it for dicom attribute rules
            const dicomRuleId = generateNewId();

            // assert that DICOM-specific trigger rule entries look correct
            assertExpectedProps(triggerEntry, [
                { propName: 'dicom_attributes', propType: PropType.Object },
            ], assertMessage);

            // collect dicom attribute rules
            const dicomAttributeRules = Object.entries(triggerEntry['dicom_attributes']).map(kvp => {
                const key = kvp[0];
                const value = kvp[1];
                if (!isString(value)) {
                    const message = `Unexpected type in received JSON: expected DICOM Attribute Rule key-value pairs to be all strings.`;
                    console.error(message);
                    console.log(`${key}: ${value}`);
                    console.log(triggerEntry);
                    throw new Error(message);
                }
                const dicomAttributeRule = createNewDicomAttributeRule(key, value, dicomRuleId);
                return dicomAttributeRule;
            });

            const dicomAttributeRuleIds = dicomAttributeRules.map(d => d.id);
            const dicomRule = createNewDicomRule(dicomAttributeRuleIds, dicomRuleId, undefined, aggregation, subscription);

            // add newly created objects into collections that will eventually be put into redux store
            dicomAttributeRulesCollection.push(...dicomAttributeRules);
            modelTriggersByCustomizationNames[customizationName].push(dicomRule);

            return dicomRule;

        } else {
            // this is an AE title trigger
            const action = triggerEntry['action'];
            const isEditable = action !== customizationName; // triggers matching exact model names are hardcoded and cannot be edited
            const aeTitleRule = createNewAeTitleRule(action, aggregation, subscription, isEditable);
            modelTriggersByCustomizationNames[customizationName].push(aeTitleRule);
            return aeTitleRule;
        }

    });

    return { triggers, dicomAttributeRules: dicomAttributeRulesCollection, modelTriggersByCustomizationNames };
}

export type CustomizationBaseExtractResult = {
    customizationId: string,
    customizationName: string,
    customizationDescription: string,
    dtoOutputs: any,
}

/**
 * Extracts customization bases from a matching portion of a customization JSON. Outputs ('files' entry in the JSON)
 * are also extracted as pure JSON -- these must be handled separately in a context-specific manner (e.g. contouring
 * and dose customization outputs need to be handled in a different way).
 */
export const extractCustomizationBases = (modelEntry: any, assertMessage: string): CustomizationBaseExtractResult[] => {

    // assert that given json entry actually has a customizations prop
    assertExpectedProps(modelEntry, [{ propName: 'customizations', propType: PropType.Array }]);

    const results: CustomizationBaseExtractResult[] = [];

    const customizations: CustomizationBase[] = modelEntry['customizations'].map((modelCustomizationBaseEntry: any) => {

        // prepare a customization base id
        const customizationId = generateNewId();

        // assert that customization entries look correct
        assertExpectedProps(modelCustomizationBaseEntry, [
            { propName: 'customization_name', propType: PropType.String },
            { propName: 'description', propType: PropType.String },
            { propName: 'files', propType: PropType.Array },
        ], assertMessage);

        const customizationName = modelCustomizationBaseEntry['customization_name'];
        const customizationDescription = modelCustomizationBaseEntry['description'];
        const dtoOutputs = modelCustomizationBaseEntry['files'];

        results.push({ customizationId, customizationName, customizationDescription, dtoOutputs });
    });

    return results;
}

/**
 * Extracts customization output metadata from a matching portion of a customization JSON.
 */
export const extractOutputMetadata = (customizationOutputEntry: any, assertMessage: string): OutputMetadataItem[] => {

    // assert that given json entry actually has a metadata prop
    assertExpectedProps(customizationOutputEntry, [{ propName: 'metadata', propType: PropType.Array }]);

    const metadata: OutputMetadataItem[] = customizationOutputEntry['metadata'].map((metadataEntry: any) => {

        // assert that metadata entries look correct
        assertExpectedProps(metadataEntry, [
            { propName: 'attribute', propType: PropType.String },
            { propName: 'value', propType: PropType.String },
        ], assertMessage);

        // remove python string begin and end blocks from the string if found ("f'" and "'", e.g. "f'{model_name}'" becomes "{model_name}")
        const metadataAttribute = metadataEntry['attribute'];
        const metadataValue: string = metadataEntry['value'];
        let parsedMetadataValue = metadataValue.length >= 3 && metadataValue.startsWith("f'") && metadataValue.endsWith("'")
            ? metadataValue.substring(2, metadataValue.length - 1)
            : metadataValue;

        return createNewMetadataItem(metadataAttribute, parsedMetadataValue);
    });

    return metadata;
}

/**
 * Extracts customization model from a matching portion of a customization JSON and creates a matching object according
 * to other given input values.
 */
export const extractModel = (modelId: string, modelType: ModelType, modelName: string, customizationIds: string[], modelEntry: any, assertMessage: string): Model => {

    // assert that model entry looks correct
    assertExpectedProps(modelEntry, [
        { propName: 'model_label', propType: PropType.String },
        { propName: 'body_part', propType: PropType.String },
        { propName: 'description', propType: PropType.String },
    ], assertMessage);

    // for backwards compatibility do not assert 'model_tags' or 'deprecated'
    const modelTags = isArray(modelEntry['model_tags']) ? modelEntry['model_tags'] : [];
    if (!modelTags.every(t => isString(t))) {
        throw new Error('Invalid model tags (expected strings only)');
    }
    const isDeprecated = isBoolean(modelEntry['deprecated']) ? modelEntry['deprecated'] : false;

    const model = createNewModel(modelName,
        modelType,
        modelEntry['model_label'],
        modelEntry['body_part'],
        modelEntry['description'],
        parseModelVisibility(modelEntry['visibility']),
        modelTags,
        customizationIds,
        isDeprecated,
        modelId);

    return model;
}





/** A generic main function for converting internal view model representation of customization objects 
 * into a customization JSON hierarchy. Works with any supported algorithm.
 * @param entities The customization view model entities to convert to JSON.
 * @param onlyConvertModelType If defined will throw if models other than the defined model type are among 
 * the entities. Converts anything supported supplied into it if left undefined. Defaults to undefined.
 * @param noUiId Internal IDs for the view models needed for UI functionality will not be included in the export if true, and will be included
 * if set to false. Defaults to false. Including internal IDs is needed when sending data to backend to be able to cross-reference received
 * errors to their matching UI view models, but these IDs should be omitted when this JSON will be user-facing (e.g. when exported directly
 * as a downloaded file) or when running certain unit tests (e.g. round-trip tests will break if extra UI ID fields are present).
 * @returns An array of model JSON objects, ready to be sent to backend.
 */
export const convertCustomizationViewModelsToJson = (entities: Partial<AllCustomizationEntitiesForExport>, onlyConvertModelType?: ModelType, noUiId: boolean = false) => {
    const jsonObject: any[] = [];

    const errorMessage = 'Could not find object matching given ID -- store is in inconsistent state';

    assertIsDefined(entities.models, errorMessage);

    for (const model of entities.models) {
        const modelTypeAsString = getModelTypeAsString(model.modelType);
        if (!modelTypeAsString) { throw new Error(`Invalid model type ${model.modelType} for model ${model.modelName}`); }

        if (model.modelType === ModelType.None) {
            throw new Error('Unsupported model type');
        }
        if (onlyConvertModelType && onlyConvertModelType !== model.modelType) {
            throw new Error(`Unexpected model type encountered (${modelTypeAsString}) -- only ${onlyConvertModelType} model type is supported for this customization`);
        }

        const jsonModel: any = {
            'model_name': model.modelName,
            'model_label': model.label,
            'model_type': modelTypeAsString,
            'body_part': model.bodyPart,
            'visibility': getModelVisibilityForJson(model.visibility),
            'description': model.description,
            'model_tags': model.tags,
            'deprecated': model.isDeprecated,
            'triggers': [],
            'customizations': [],
        };
        if (!noUiId) { jsonModel[UI_ID_ATTRIBUTE] = model.id }

        // collect customizations
        for (const customizationBaseId of model.customizations) {
            assertIsDefined(entities.customizationBases, errorMessage);
            const customizationBase = entities.customizationBases.find(c => c.id === customizationBaseId);
            if (customizationBase === undefined) { throw new Error(errorMessage); }
            const jsonCustomization: any = {
                'customization_name': customizationBase.customizationName.trim(),
                'description': customizationBase.description.trim(),
                'files': [],
            };
            if (!noUiId) { jsonCustomization[UI_ID_ATTRIBUTE] = customizationBase.id }

            // collect AE Title triggers
            for (const aeTitleRuleId of customizationBase.aeTitleRules) {
                assertIsDefined(entities.aeTitleRules, errorMessage);
                const aeTitleRule = entities.aeTitleRules.find(ae => ae.id === aeTitleRuleId);
                if (aeTitleRule === undefined) { throw new Error(errorMessage); }

                const trigger: any = {
                    'customization_name': customizationBase.customizationName.trim(),
                    'aggregation': aeTitleRule.aggregation,
                    'subscription': aeTitleRule.subscription.trim(),
                    'action': aeTitleRule.action.trim(),
                };
                if (!noUiId) { trigger[UI_ID_ATTRIBUTE] = aeTitleRule.id }
                jsonModel['triggers'].push(trigger);
            }

            // collect DICOM triggers
            for (const dicomRuleId of customizationBase.dicomRules) {
                assertIsDefined(entities.dicomRules, errorMessage);
                const dicomRule = entities.dicomRules.find(d => d.id === dicomRuleId);
                if (dicomRule === undefined) { throw new Error(errorMessage); }

                const jsonDicomAttributes: any = {};
                for (const dicomAttributeId of dicomRule.dicomAttributes) {
                    assertIsDefined(entities.dicomAttributeRules, errorMessage);
                    const dicomAttribute = entities.dicomAttributeRules.find(d => d.id === dicomAttributeId);
                    if (dicomAttribute === undefined) { throw new Error(errorMessage); }

                    // trim inside dicom regex value
                    const dicomValue = dicomAttribute.value.trim();
                    const trimmedDicomValue = dicomValue.startsWith('^') && dicomValue.endsWith('$') ? `^${dicomValue.slice(1, -1).trim()}$` : dicomValue;

                    jsonDicomAttributes[dicomAttribute.attribute.trim()] = trimmedDicomValue;
                }

                const trigger: any = {
                    'customization_name': customizationBase.customizationName.trim(),
                    'aggregation': dicomRule.aggregation,
                    'subscription': dicomRule.subscription.trim(),
                    'action': DICOM_RULE_TRIGGER_ACTION,
                    'dicom_attributes': jsonDicomAttributes,
                };
                if (!noUiId) { trigger[UI_ID_ATTRIBUTE] = dicomRule.id }
                jsonModel['triggers'].push(trigger);
            }


            // collect customization outputs depending on model type
            for (const outputId of customizationBase.outputs) {

                if (model.modelType === ModelType.Contouring) {
                    assertIsDefined(entities.outputMetadata, errorMessage);
                    assertIsDefined(entities.contouringOutputs, errorMessage);
                    assertIsDefined(entities.contouringRois, errorMessage);
                    const output = entities.contouringOutputs.find(o => o.id === outputId);
                    if (!output) { throw new Error(errorMessage); }
                    _convertContouringCustomizationOutputToJson(
                        jsonCustomization,
                        output,
                        entities.outputMetadata,
                        entities.contouringRois,
                        errorMessage,
                        noUiId
                    );
                }

                if (model.modelType === ModelType.Dose) {
                    assertIsDefined(entities.outputMetadata, errorMessage);
                    assertIsDefined(entities.doseOutputs, errorMessage);
                    assertIsDefined(entities.doseRois, errorMessage);
                    assertIsDefined(entities.doseTargets, errorMessage);
                    const output = entities.doseOutputs.find(o => o.id === outputId);
                    if (!output) { throw new Error(errorMessage); }
                    _convertDoseCustomizationOutputToJson(
                        jsonCustomization,
                        output,
                        entities.outputMetadata,
                        entities.doseRois,
                        entities.doseTargets,
                        errorMessage,
                        noUiId
                    );
                }

                if (model.modelType === ModelType.Image) {
                    assertIsDefined(entities.outputMetadata, errorMessage);
                    assertIsDefined(entities.imageOutputs, errorMessage);
                    assertIsDefined(entities.imageDicomRestrictions, errorMessage);
                    assertIsDefined(entities.imageDicomTags, errorMessage);
                    assertIsDefined(entities.imageOutputGeometry, errorMessage);
                    assertIsDefined(entities.imageContourGeneration, errorMessage);
                    assertIsDefined(entities.imagePostProcessing, errorMessage);
                    assertIsDefined(entities.keepLargestComponent, errorMessage);
                    assertIsDefined(entities.fillHoles, errorMessage);
                    assertIsDefined(entities.bodyMasks, errorMessage);
                    assertIsDefined(entities.holeMasks, errorMessage);
                    const output = entities.imageOutputs.find(o => o.id === outputId);
                    if (!output) { throw new Error(errorMessage); }
                    _convertImageCustomizationOutputToJson(
                        jsonCustomization,
                        output,
                        entities.outputMetadata,
                        entities.imageDicomRestrictions,
                        entities.imageDicomTags,
                        entities.imageOutputGeometry,
                        entities.imageContourGeneration,
                        entities.imagePostProcessing,
                        entities.keepLargestComponent,
                        entities.fillHoles,
                        entities.bodyMasks,
                        entities.holeMasks,
                        errorMessage,
                        noUiId
                    );
                }

                if (model.modelType === ModelType.Adapt) {
                    assertIsDefined(entities.outputMetadata, errorMessage);
                    assertIsDefined(entities.adaptOutputs, errorMessage);
                    assertIsDefined(entities.adaptRoiRules, errorMessage);
                    const output = entities.adaptOutputs.find(o => o.id === outputId);
                    if (!output) { throw new Error(errorMessage); }
                    _convertAdaptCustomizationOutputToJson(
                        jsonCustomization,
                        output,
                        entities.outputMetadata,
                        entities.adaptRoiRules,
                        errorMessage,
                        noUiId
                    );
                }
            }

            jsonModel['customizations'].push(jsonCustomization);
        }

        jsonObject.push(jsonModel);
    }

    return jsonObject;
}


export const _convertOutputMetadataViewModelToJson = (jsonOutput: any, metadata: OutputMetadataItem, noUiId: boolean) => {
    let metadataValue = metadata.value.trim();

    // add python string blocks (f' and ') unless they're already in the string
    const parsedMetadataValue = metadataValue.startsWith("f'") && metadataValue.endsWith("'")
        ? metadataValue
        : `f'${metadataValue}'`;

    const jsonMetadata: any = {
        'attribute': metadata.attribute.trim(),
        'value': parsedMetadataValue.trim(),
    };
    if (!noUiId) { jsonMetadata[UI_ID_ATTRIBUTE] = metadata.id }
    jsonOutput['metadata'].push(jsonMetadata);
}
