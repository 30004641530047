import { DataList, FormGroup, InputGroup, Radio, Switch } from '@patternfly/react-core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../store/store';
import Section from '../Section';
import SectionItem from '../SectionItem';
import { adaptSelectors, areRoiSelectionRulesEnabledSet, roiSelectionRulesIncludedSet } from '../../../store/adapt/adaptSlice';
import { SelectionInclusion } from '../../../store/adapt/adapt-types';
import RoiSelectionRuleTable from './RoiSelectionRuleTable';


interface RoiSelectionFormProps {
    outputId: string,
}

const RoiSelectionForm = (props: RoiSelectionFormProps) => {

    const { outputId } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const output = useSelector((state: StoreState) => adaptSelectors.selectOutputById(state, outputId));

    const handleEnableRoiSelectionToggled = useCallback(() => {
        if (output) { dispatch(areRoiSelectionRulesEnabledSet({ id: outputId, isEnabled: !output.roiSelection.isEnabled })); }
    }, [dispatch, output]);

    const handleSetInclusionToInclude = useCallback(() => {
        if (output) { dispatch(roiSelectionRulesIncludedSet({ id: output.id, inclusion: SelectionInclusion.Include })); }
    }, [dispatch, output]);

    const handleSetInclusionToExclude = useCallback(() => {
        if (output) { dispatch(roiSelectionRulesIncludedSet({ id: output.id, inclusion: SelectionInclusion.Exclude })); }
    }, [dispatch, output]);


    // TODO: add section-specific output validity checking here later on if we have
    // any relevant form validation for those parts -- currently we don't
    const outputIsValid = true;

    if (!output) {
        return null;
    }

    return (
        <div>
            <DataList aria-label={`Adapt+ ROI selection customization`} isCompact className="customization-form">

                <Section
                    title={undefined}
                    isModified={false}
                    hasValidationError={!outputIsValid}
                    validationError={undefined}
                >

                    <SectionItem label={t('customizationPage.adapt.roiSelectionSection.enabled.label')}>
                        <FormGroup>
                            <InputGroup>
                                <Switch
                                    label={t('customizationPage.adapt.roiSelectionSection.enabled.on')}
                                    labelOff={t('customizationPage.adapt.roiSelectionSection.enabled.off')}
                                    isChecked={output.roiSelection.isEnabled}
                                    onChange={handleEnableRoiSelectionToggled}
                                />
                            </InputGroup>
                        </FormGroup>
                    </SectionItem>

                    {output.roiSelection.isEnabled && (
                        <SectionItem label={t('customizationPage.adapt.roiSelectionSection.inclusion')}>
                            <FormGroup
                                role="radiogroup"
                                isStack
                            >
                                <Radio
                                    name="adapt-roi-selection-inclusion"
                                    id="adapt-roi-selection-inclusion-include"
                                    label={t('customizationPage.adapt.roiSelectionSection.inclusion.include')}
                                    onChange={handleSetInclusionToInclude}
                                    isChecked={output.roiSelection.inclusion === SelectionInclusion.Include}
                                />
                                <Radio
                                    name="adapt-roi-selection-inclusion"
                                    id="adapt-roi-selection-inclusion-exclude"
                                    label={t('customizationPage.adapt.roiSelectionSection.inclusion.exclude')}
                                    onChange={handleSetInclusionToExclude}
                                    isChecked={output.roiSelection.inclusion === SelectionInclusion.Exclude}
                                />

                            </FormGroup>
                        </SectionItem>
                    )}

                </Section>

            </DataList >

            {output.roiSelection.isEnabled && (
                <RoiSelectionRuleTable
                    outputId={outputId}
                    roiRuleIds={output.roiSelection.roiRules}
                />
            )}
        </div>
    );
}

export default RoiSelectionForm;
