import React, { useCallback } from 'react';
import { Table, Tbody, Th, Thead, Tr } from '@patternfly/react-table';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@patternfly/react-icons';
import { Button, Level, LevelItem, Toolbar, ToolbarContent, ToolbarItem } from '@patternfly/react-core';
import { useDispatch, useSelector } from 'react-redux';

import { StoreState } from '../../../store/store';
import { adaptSelectors, newRoiSelectionRuleAdded } from '../../../store/adapt/adaptSlice';
import RoiRuleSelectionRuleItem from './RoiSelectionRuleItem';

/** Ids for different types of adapt roi selection table columns. */
export const columnIds = {
    isEnabled: 'Enabled',
    nameRegExp: 'Name regular expression',
    interpretedType: 'Interpreted type',
};

/** Styling names for adapt roi selection table columns. */
export const columnCssNames = {
    modified: 'column-modified',
    isEnabled: 'column-enabled',
    nameRegExp: 'column-name-regexp',
    interpretedType: 'column-interpreted-type',
}


interface RoiSelectionRuleTableProps {
    outputId: string;
    roiRuleIds: string[];
}

const RoiSelectionRuleTable = (props: RoiSelectionRuleTableProps) => {

    const { outputId, roiRuleIds } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const output = useSelector((state: StoreState) => adaptSelectors.selectOutputById(state, outputId));

    const handleAddRoiSelectionRule = useCallback(() => {
        dispatch(newRoiSelectionRuleAdded(outputId));
    }, [dispatch, outputId]);

    if (output === undefined) {
        return null;
    }

    return (
        <div>
            <div>
                <Table variant='compact' isStickyHeader className="mv-sticky-fix">
                    <Thead>
                        <Tr className='customization-form-table unselectable'>
                            <Th aria-label='Is modified?' className={columnCssNames.modified}></Th>
                            <Th aria-label='Name regular expression' className={columnCssNames.nameRegExp}>{t('customizationPage.adapt.roiSelectionSection.roiRules.regex.title')}</Th>
                            <Th aria-label='Interpreted type' className={columnCssNames.interpretedType} info={{ tooltip: t('customizationPage.adapt.roiSelectionSection.roiRules.interpretedType.helpText') }}>
                                {t('customizationPage.adapt.roiSelectionSection.roiRules.interpretedType.title')}
                            </Th>
                            <Th aria-label='Is enabled?' className={columnCssNames.isEnabled}>{t('customizationPage.adapt.roiSelectionSection.roiRules.enabled.title')}</Th>
                            <Th aria-label='Action menu'></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {roiRuleIds.map(rId => <RoiRuleSelectionRuleItem roiRuleId={rId} key={rId} roiRuleIdList={roiRuleIds} />)}
                    </Tbody>
                </Table>
            </div>

            <div>
                <Level>
                    <LevelItem>
                        <Toolbar>
                            <ToolbarContent>
                                <ToolbarItem>
                                    <Button
                                        size="sm"
                                        variant="secondary"
                                        icon={<PlusIcon />}
                                        // isDisabled={isToolbarDisabled}
                                        onClick={handleAddRoiSelectionRule}>{t('customizationPage.adapt.roiSelectionSection.roiRules.addNewRule')}</Button>
                                </ToolbarItem>

                            </ToolbarContent>
                        </Toolbar>
                    </LevelItem>
                </Level>
            </div>

        </div>
    );
}

export default RoiSelectionRuleTable;
