import { enumFromStringOrDefault } from "../../util/enum";
import { assertArray, assertExpectedProps, PropType } from "../../util/expected-prop";
import { createNewModelCustomizationBase, generateNewId, UI_ID_ATTRIBUTE } from "../global-types/customization-helpers";
import { CustomizationBase, isAeTitleRule, isDicomRule, METADATA_FILENAME_ATTRIBUTE, ModelType, OutputMetadataItem } from "../global-types/customization-types";
import { createLength, createVolume, getLengthInCm, getVolumeInMm3, LengthUnit, VolumeUnit } from "../global-types/units";
import { _convertOutputMetadataViewModelToJson, convertCustomizationJsonObjectToViewModels, convertCustomizationViewModelsToJson, CustomizationBaseExtractResult, extractOutputMetadata, ModelTriggersByCustomizationNames } from "../global-types/view-model-conversion-helpers";
import { BodyMask, ContourAttachSeries, FillHoles, HoleMask, ImageContourGeneration, ImageCustomizationEntities, ImageCustomizationOutput, ImageDicomRestriction, ImageDicomTag, ImageOutputGeometry, ImagePostProcessing, ImageSliceThickness, KeepLargestComponent, ImageSmoothingMethod, ImageSmoothing, ImageCustomizationEntitiesForExport } from "./image-types";


export const createNewImageCustomizationOutput = (
    metadataIds: string[],
    dicomRestrictionId: string,
    outputGeometryId: string,
    contourGenerationId: string,
    postProcessingId: string,
    customizationBaseId: string,
    filename?: string,
    id?: string,
    isModified?: boolean): ImageCustomizationOutput => {
    return {
        id: id || generateNewId(),
        metadata: metadataIds,

        dicomRestriction: dicomRestrictionId,
        outputGeometry: outputGeometryId,
        contourGeneration: contourGenerationId,
        postProcessing: postProcessingId,

        modelCustomizationBaseId: customizationBaseId,
        filename: filename || '',
        isModified: isModified || false,
    };
}


export const createNewImageDicomRestriction = (isEnabled: boolean, tags: string[],
    outputId?: string, id?: string, isModified?: boolean): ImageDicomRestriction => {
    return {
        id: id || generateNewId(),

        isEnabled: isEnabled,
        tags: tags,

        isModified: isModified || false,
        outputId: outputId,
    };
}

export const createNewImageDicomTag = (attribute: string, value: string, dicomRestrictionId?: string, id?: string, isModified?: boolean): ImageDicomTag => {
    return {
        id: id || generateNewId(),

        attribute,
        value,

        isModified: isModified || false,
        dicomRestrictionId,
    };
}

export const createNewImageSliceThickness = (matchInput: boolean, sliceThicknessCm: number): ImageSliceThickness => {
    return {
        matchInput: matchInput,
        sliceThickness: createLength(sliceThicknessCm, LengthUnit.LengthInCm),
    };
}

export const createNewImageOutputGeometry = (matchInput: boolean, fovCm: number, sliceThickness: ImageSliceThickness, matrixSize: number,
    outputId?: string, id?: string, isModified?: boolean
): ImageOutputGeometry => {
    return {
        id: id || generateNewId(),

        matchInputGeometry: matchInput,
        fovSize: createLength(fovCm, LengthUnit.LengthInCm),
        sliceThickness: sliceThickness,
        matrixSize: matrixSize,

        isModified: isModified || false,
        outputId: outputId,
    };
}

export const createNewImageContourGeneration = (isEnabled: boolean, action: string | null, attachTo: ContourAttachSeries,
    outputId?: string, id?: string, isModified?: boolean): ImageContourGeneration => {
    return {
        id: id || generateNewId(),

        isEnabled,
        action,
        attachTo,

        isModified: isModified || false,
        outputId: outputId,
    }
}

export const createNewImageSmoothing = (smoothingMethod: ImageSmoothingMethod, sigma: number): ImageSmoothing => {
    return {
        method: smoothingMethod,
        sigma,
    }
}

export const createNewKeepLargestComponent = (isEnabled: boolean, huThreshold: number, deleteSmallComponentsVolMm3: number, smoothing: ImageSmoothing | null,
    id?: string, isModified?: boolean): KeepLargestComponent => {
    return {
        id: id || generateNewId(),

        isEnabled,
        huThreshold,
        deleteSmallComponentsVol: createVolume(deleteSmallComponentsVolMm3, VolumeUnit.VolumeInMm3),
        smoothing,

        isModified: isModified || false,
    };
}

export const createNewBodyMask = (isEnabled: boolean, huThreshold: number, erode: number[], smoothing: ImageSmoothing | null, deleteTop: number, deleteBottom: number,
    id?: string, isModified?: boolean): BodyMask => {
    return {
        id: id || generateNewId(),
        isEnabled,

        huThreshold,
        erode,
        smoothing,
        deleteTopSlices: deleteTop,
        deleteBottomSlices: deleteBottom,

        isModified: isModified || false,
    };
}

export const createNewHoleMask = (isEnabled: boolean, huThresholdLower: number, huThresholdUpper: number, deleteSmallCcsVolMm3: number,
    dilate: number[], smoothing: ImageSmoothing | null,
    id?: string, isModified?: boolean): HoleMask => {
    return {
        id: id || generateNewId(),
        isEnabled,

        huThresholdLower,
        huThresholdUpper,
        deleteSmallComponentsVol: createVolume(deleteSmallCcsVolMm3, VolumeUnit.VolumeInMm3),
        dilate,
        smoothing,

        isModified: isModified || false,
    };
}

export const createNewFillHoles = (isEnabled: boolean, bodyMask: BodyMask, holeMask: HoleMask, id?: string, isModified?: boolean): FillHoles => {
    return {
        id: id || generateNewId(),

        isEnabled,
        bodyMask: bodyMask.id,
        holeMask: holeMask.id,

        isModified: isModified || false,
    };
}

export const createNewPostProcessing = (restoreNonHumanAnatomy: boolean,
    keepLargestComponent: KeepLargestComponent, fillHoles: FillHoles,
    outputId?: string, id?: string, isModified?: boolean): ImagePostProcessing => {
    return {
        id: id || generateNewId(),

        restoreNonHumanAnatomy,
        keepLargestComponent: keepLargestComponent.id,
        fillHoles: fillHoles.id,

        isModified: isModified || false,
        outputId: outputId,
    }
}



export const defaultCreateNewImageSmoothing = (smoothingMethod?: ImageSmoothingMethod, sigma?: number): ImageSmoothing => {
    return createNewImageSmoothing(smoothingMethod || ImageSmoothingMethod.Gaussian, sigma !== undefined ? sigma : 1);
}

export const defaultCreateNewBodyMask = (isEnabled?: boolean, huThreshold?: number, erode?: number[], smoothing?: ImageSmoothing | null, deleteTop?: number, deleteBottom?: number,
    id?: string, isModified?: boolean): BodyMask => {
    return createNewBodyMask(
        isEnabled !== undefined ? isEnabled : true,
        huThreshold !== undefined ? huThreshold : -400,
        erode !== undefined ? erode : [5, 5, 5],
        smoothing ? smoothing : defaultCreateNewImageSmoothing(),
        deleteTop !== undefined ? deleteTop : 40,
        deleteBottom !== undefined ? deleteBottom : 40,
        id,
        isModified,
    );
}

export const defaultCreateNewHoleMask = (isEnabled?: boolean,huThresholdLower?: number, huThresholdUpper?: number, deleteSmallCcsVolMm3?: number,
    dilate?: number[], smoothing?: ImageSmoothing | null,
    id?: string, isModified?: boolean): HoleMask => {
    return createNewHoleMask(
        isEnabled !== undefined ? isEnabled : true,
        huThresholdLower !== undefined ? huThresholdLower : -400,
        huThresholdUpper !== undefined ? huThresholdUpper : -150,
        deleteSmallCcsVolMm3 !== undefined ? deleteSmallCcsVolMm3 : 70,
        dilate !== undefined ? dilate : [3, 3, 3],
        smoothing ? smoothing : defaultCreateNewImageSmoothing(),
        id,
        isModified,
    );
}


export const defaultCreateNewImageSliceThickness = (matchInput?: boolean, sliceThicknessCm?: number | null): ImageSliceThickness => {
    return createNewImageSliceThickness(matchInput !== undefined ? matchInput : true, sliceThicknessCm ? sliceThicknessCm : 0);
}

export const defaultCreateNewImageOutputGeometry = (matchInput?: boolean, fovCm?: number | null, sliceThickness?: ImageSliceThickness | null, matrixSize?: number | null,
    outputId?: string, id?: string, isModified?: boolean
): ImageOutputGeometry => {
    return createNewImageOutputGeometry(
        matchInput !== undefined ? matchInput : true,
        fovCm ? fovCm : 0,
        sliceThickness ? sliceThickness : defaultCreateNewImageSliceThickness(),
        matrixSize ? matrixSize : 512,
        outputId,
        id,
        isModified,

    );
}



/** Convert image customization JSON from config API into flattened internal view model representation.
 * 
 * Regarding sorting: if any sorting is done, it's generally done to try to ensure order by original
 * name using naturalSort. Dynamic sorting during user operation is generally not wanted as it could
 * erratically shift the position of the element on the page. Set the 'noSorting' prop to 'true' if sorting
 * is not wanted (e.g. for roundtrip unit tests).
 */
export const convertImageJsonObjectToViewModels = (jsonObject: any, noSorting: boolean = false): ImageCustomizationEntities => {

    const results = convertCustomizationJsonObjectToViewModels(jsonObject, ModelType.Image, noSorting);

    return {
        models: results.models || [],
        customizationBases: results.customizationBases || [],

        imageOutputs: results.imageOutputs || [],
        outputMetadata: results.outputMetadata || [],

        imageDicomRestrictions: results.imageDicomRestrictions || [],
        imageDicomTags: results.imageDicomTags || [],
        imageOutputGeometry: results.imageOutputGeometry || [],
        imageContourGeneration: results.imageContourGeneration || [],
        imagePostProcessing: results.imagePostProcessing || [],
        keepLargestComponent: results.keepLargestComponent || [],
        fillHoles: results.fillHoles || [],
        bodyMasks: results.bodyMasks || [],
        holeMasks: results.holeMasks || [],

        aeTitleRules: results.aeTitleRules || [],
        dicomRules: results.dicomRules || [],
        dicomAttributeRules: results.dicomAttributeRules || [],
    }
}


/**
 * Helper function for converting a customization base and all its outputs and other child objects into image+ customization entities.
 * You shouldn't need to call this function manually.
 */
export const _convertImageCustomizationJsonToViewModel = (
    modelCustomizationBaseResult: CustomizationBaseExtractResult,
    modelId: string,
    imageOutputCollection: ImageCustomizationOutput[],
    outputMetadataCollection: OutputMetadataItem[],
    imageDicomRestrictionCollection: ImageDicomRestriction[],
    imageDicomTagCollection: ImageDicomTag[],
    imageOutputGeometryCollection: ImageOutputGeometry[],
    imageContourGenerationCollection: ImageContourGeneration[],
    imagePostProcessingCollection: ImagePostProcessing[],
    keepLargestComponentCollection: KeepLargestComponent[],
    fillHolesCollection: FillHoles[],
    bodyMaskCollection: BodyMask[],
    holeMaskCollection: HoleMask[],
    modelTriggersByCustomizationNames: ModelTriggersByCustomizationNames,
    assertMessage: string,
    noSorting: boolean,
): CustomizationBase => {
    const { customizationId, customizationName, customizationDescription, dtoOutputs } = modelCustomizationBaseResult;

    // each 'file' will become its own customization output object
    const outputs: ImageCustomizationOutput[] = dtoOutputs.map((customizationOutputEntry: any) => {

        // assert that output entries look correct
        assertExpectedProps(customizationOutputEntry, [
            { propName: 'metadata', propType: PropType.Array },
            { propName: 'dicom_restriction', propType: PropType.Object },
            { propName: 'output_geometry', propType: PropType.Object },
            { propName: 'contour_generation', propType: PropType.Object },
            { propName: 'postprocessing', propType: PropType.Object },
        ], assertMessage);

        // extract metadata
        const metadata = extractOutputMetadata(customizationOutputEntry, assertMessage);

        // extract dicom restriction
        const dicomRestrictionEntry = customizationOutputEntry['dicom_restriction'];
        assertExpectedProps(dicomRestrictionEntry, [
            { propName: 'enabled', propType: PropType.Boolean },
            { propName: 'tags', propType: PropType.Array },
        ]);

        // extract tags
        const tagsEntry = dicomRestrictionEntry['tags'];
        let tags: ImageDicomTag[] = [];
        assertArray(tagsEntry, PropType.Object);
        tagsEntry.forEach((tagEntry: any) => {
            assertExpectedProps(tagEntry, [
                { propName: 'attribute', propType: PropType.String },
                { propName: 'value', propType: PropType.String },
            ]);
            const tag = createNewImageDicomTag(tagEntry['attribute'], tagEntry['value']);
            tags.push(tag);
        });

        const dicomRestriction = createNewImageDicomRestriction(dicomRestrictionEntry['enabled'], tags.map(t => t.id));

        // push tags to collection and update parent ids
        if (tags) {
            imageDicomTagCollection.push(...tags);
            tags.forEach(t => t.dicomRestrictionId = dicomRestriction.id);
        }

        // extract output geometry
        const outputGeometryEntry = customizationOutputEntry['output_geometry'];
        assertExpectedProps(outputGeometryEntry, [
            { propName: 'match_input', propType: PropType.Boolean },
            { propName: 'fov_cm', propType: PropType.Number, allowNull: true },
            { propName: 'slice_thickness', propType: PropType.Object, allowNull: true },
            { propName: 'matrix_size', propType: PropType.Number, allowNull: true },
        ]);

        let sliceThickness: ImageSliceThickness | null = null;
        const sliceThicknessEntry = outputGeometryEntry['slice_thickness'];
        if (sliceThicknessEntry !== null) {
            assertExpectedProps(sliceThicknessEntry, [
                { propName: 'match_input', propType: PropType.Boolean },
                { propName: 'slice_thickness_cm', propType: PropType.Number, allowNull: true },
            ]);
            sliceThickness = defaultCreateNewImageSliceThickness(
                sliceThicknessEntry['match_input'],
                sliceThicknessEntry['slice_thickness_cm'],
            );
        }

        const outputGeometry = defaultCreateNewImageOutputGeometry(
            outputGeometryEntry['match_input'],
            outputGeometryEntry['fov_cm'],
            sliceThickness,
            outputGeometryEntry['matrix_size'],
        );

        // extract contour generation
        const contourGenerationEntry = customizationOutputEntry['contour_generation'];
        assertExpectedProps(contourGenerationEntry, [
            { propName: 'enabled', propType: PropType.Boolean },
            { propName: 'action', propType: PropType.String },
            { propName: 'attach_to', propType: PropType.String },
        ]);

        const attachTo = enumFromStringOrDefault(ContourAttachSeries, contourGenerationEntry['attach_to'], ContourAttachSeries.NotSet);

        // unparsed 'default' values are not allowed!
        if (attachTo === ContourAttachSeries.NotSet) { throw new Error(`Could not parse contour attach to series value from '${contourGenerationEntry['attach_to']}`); }

        const contourGeneration = createNewImageContourGeneration(
            contourGenerationEntry['enabled'],
            contourGenerationEntry['action'],
            attachTo,
        );

        // extract postprocessing
        const postProcessingEntry = customizationOutputEntry['postprocessing'];
        assertExpectedProps(postProcessingEntry, [
            { propName: 'keep_largest_component', propType: PropType.Object },
            { propName: 'fill_holes', propType: PropType.Object },
            { propName: 'restore_nonhuman_anatomy', propType: PropType.Object },
        ]);

        // extract keepLargestComponent
        const keepLargestComponentEntry = postProcessingEntry['keep_largest_component'];
        assertExpectedProps(keepLargestComponentEntry, [
            { propName: 'enabled', propType: PropType.Boolean },
            { propName: 'hu_threshold', propType: PropType.Number },
            { propName: 'delete_small_ccs_vol_mm3', propType: PropType.Number },
            { propName: 'smoothing', propType: PropType.Object },
        ]);

        const smoothing = parseImageSmoothingFromJsonEntry(keepLargestComponentEntry['smoothing']);

        const keepLargestComponent = createNewKeepLargestComponent(
            keepLargestComponentEntry['enabled'],
            keepLargestComponentEntry['hu_threshold'],
            keepLargestComponentEntry['delete_small_ccs_vol_mm3'],
            smoothing,
        );

        // extract fillHoles
        const fillHolesEntry = postProcessingEntry['fill_holes'];
        assertExpectedProps(fillHolesEntry, [
            { propName: 'enabled', propType: PropType.Boolean },
            { propName: 'body_mask', propType: PropType.Object, allowNull: true },
            { propName: 'hole_mask', propType: PropType.Object, allowNull: true },
        ]);

        // extract body mask
        const bodyMaskEntry = fillHolesEntry['body_mask'];
        let bodyMask: BodyMask;
        if (bodyMaskEntry !== null) {
            assertExpectedProps(bodyMaskEntry, [
                { propName: 'hu_threshold', propType: PropType.Number },
                { propName: 'erode', propType: PropType.Array },
                { propName: 'smoothing', propType: PropType.Object, allowNull: true },
                { propName: 'delete_top', propType: PropType.Number },
                { propName: 'delete_bottom', propType: PropType.Number },
            ]);

            const smoothing = parseImageSmoothingFromJsonEntry(bodyMaskEntry['smoothing']);
            const erodeEntry = bodyMaskEntry['erode'];
            assertArray(erodeEntry, PropType.Number);

            bodyMask = createNewBodyMask(
                true,
                bodyMaskEntry['hu_threshold'],
                erodeEntry,
                smoothing,
                bodyMaskEntry['delete_top'],
                bodyMaskEntry['delete_bottom'],
            );
        } else {
            bodyMask = defaultCreateNewBodyMask();
        }

        // extract hole mask
        const holeMaskEntry = fillHolesEntry['hole_mask'];
        let holeMask: HoleMask;
        if (holeMaskEntry !== null) {
            assertExpectedProps(holeMaskEntry, [
                { propName: 'hu_threshold_lower', propType: PropType.Number },
                { propName: 'hu_threshold_upper', propType: PropType.Number },
                { propName: 'delete_small_ccs_vol_mm3', propType: PropType.Number },
                { propName: 'dilate', propType: PropType.Array },
                { propName: 'smoothing', propType: PropType.Object, allowNull: true },
            ]);

            const smoothing = parseImageSmoothingFromJsonEntry(holeMaskEntry['smoothing']);
            const dilateEntry = holeMaskEntry['dilate'];
            assertArray(dilateEntry, PropType.Number);

            holeMask = createNewHoleMask(
                true,
                holeMaskEntry['hu_threshold_lower'],
                holeMaskEntry['hu_threshold_upper'],
                holeMaskEntry['delete_small_ccs_vol_mm3'],
                dilateEntry,
                smoothing,
            );
        } else {
            holeMask = defaultCreateNewHoleMask();
        }

        const fillHoles = createNewFillHoles(
            fillHolesEntry['enabled'],
            bodyMask,
            holeMask,
        );

        // push masks to collections and update parent ids
        if (bodyMask) {
            bodyMaskCollection.push(bodyMask);
            bodyMask.fillHolesId = fillHoles?.id;
        }
        if (holeMask) {
            holeMaskCollection.push(holeMask);
            holeMask.fillHolesId = fillHoles?.id;
        }

        // extract restore nonhuman anatomy
        const restoreNonHumanAnatomyEntry = postProcessingEntry['restore_nonhuman_anatomy'];
        assertExpectedProps(restoreNonHumanAnatomyEntry, [
            { propName: 'enabled', propType: PropType.Boolean },
        ]);

        const postProcessing = createNewPostProcessing(
            restoreNonHumanAnatomyEntry['enabled'],
            keepLargestComponent,
            fillHoles,
        );

        // push postprocessing items to collections and update parent ids
        keepLargestComponentCollection.push(keepLargestComponent);
        fillHolesCollection.push(fillHoles);

        keepLargestComponent.postProcessingId = postProcessing.id;
        fillHoles.postProcessingId = postProcessing.id;

        // collect ids
        const metadataIds = metadata.map(m => m.id);
        const filename = metadata.find(m => m.attribute === METADATA_FILENAME_ATTRIBUTE)?.value;


        const output = createNewImageCustomizationOutput(
            metadataIds,
            dicomRestriction.id,
            outputGeometry.id,
            contourGeneration.id,
            postProcessing.id,
            customizationId,
            filename,
        );

        // update parent ids to child objects & 
        // add newly created objects into collections that will eventually be put into redux store
        metadata.forEach(m => m.modelCustomizationOutputId = output.id);
        outputMetadataCollection.push(...metadata);

        imageDicomRestrictionCollection.push(dicomRestriction);
        imageOutputGeometryCollection.push(outputGeometry);
        imageContourGenerationCollection.push(contourGeneration);
        imagePostProcessingCollection.push(postProcessing);

        dicomRestriction.outputId = output.id;
        outputGeometry.outputId = output.id;
        contourGeneration.outputId = output.id;
        postProcessing.outputId = output.id;

        return output;
    });

    const outputIds = outputs.map(o => o.id);
    const matchingTriggers = modelTriggersByCustomizationNames[customizationName] || [];
    const aeTitleRules = matchingTriggers.filter(isAeTitleRule);
    const dicomRules = matchingTriggers.filter(isDicomRule);
    const aeTitleRuleIds = aeTitleRules.map(t => t.id);
    const dicomRuleIds = dicomRules.map(t => t.id);
    const customization = createNewModelCustomizationBase(customizationName, customizationDescription, outputIds, aeTitleRuleIds, dicomRuleIds, modelId, customizationId);

    // update matching customization IDs now that we have a customization object
    matchingTriggers.forEach(t => t.modelCustomizationBaseId = customization.id);

    // add newly created objects into collections that will eventually be put into redux store
    imageOutputCollection.push(...outputs);

    return customization;
}


/** Convert image customization view models to JSON presentation.
 * @param imageEntities The image customization view model entities to convert to JSON.
 * @param noUiId Internal IDs for the view models needed for UI functionality will not be included in the export if true, and will be included
 * if set to false. Defaults to false. Including internal IDs is needed when sending data to backend to be able to cross-reference received
 * errors to their matching UI view models, but these IDs should be omitted when this JSON will be user-facing (e.g. when exported directly
 * as a downloaded file) or when running certain unit tests (e.g. round-trip tests will break if extra UI ID fields are present).
 * @returns An array of model JSON objects, ready to be sent to backend.
 */
export const convertImageViewModelsToJson = (imageEntities: Partial<ImageCustomizationEntitiesForExport>, noUiId: boolean = false): any => {

    const jsonModels = convertCustomizationViewModelsToJson(imageEntities, ModelType.Image, noUiId);

    return jsonModels;
}

/**
 * Convert internal view model representation of image output objects and related children
 * into a model customization JSON hierarchy. You shouldn't need to call this manually.
 * @param jsonCustomization Result JSON will be embedded into this JSON.
 * @param output Image output view model to convert to JSON.
 * @param outputMetadata Collection of output metadata related to this customization set.
 * @param errorMessage Error message to throw on failure.
 * @param noUiId Marks if internal UI IDs should be included in converted JSON.
 */
export const _convertImageCustomizationOutputToJson = (
    jsonCustomization: any,
    output: ImageCustomizationOutput,
    outputMetadata: OutputMetadataItem[],
    imageDicomRestrictions: ImageDicomRestriction[],
    imageDicomTags: ImageDicomTag[],
    imageOutputGeometry: ImageOutputGeometry[],
    imageContourGeneration: ImageContourGeneration[],
    imagePostProcessing: ImagePostProcessing[],
    keepLargestComponent: KeepLargestComponent[],
    fillHoles: FillHoles[],
    bodyMasks: BodyMask[],
    holeMasks: HoleMask[],
    errorMessage: string,
    noUiId: boolean = false) => {

    if (output === undefined) { throw new Error(errorMessage); }

    const jsonOutput: any = {
        'metadata': [],
    };
    if (!noUiId) { jsonOutput[UI_ID_ATTRIBUTE] = output.id; }

    for (const metadataId of output.metadata) {
        const metadata = outputMetadata.find(m => m.id === metadataId);
        if (metadata === undefined) { throw new Error(errorMessage); }
        _convertOutputMetadataViewModelToJson(jsonOutput, metadata, noUiId);
    }

    // convert dicom restriction
    let jsonDicomRestriction: any;

    const dicomRestriction = imageDicomRestrictions.find(d => d.id === output.dicomRestriction);
    if (dicomRestriction === undefined) { throw new Error(errorMessage); }

    jsonDicomRestriction = {
        'enabled': dicomRestriction.isEnabled,
    };
    if (!noUiId) { jsonDicomRestriction[UI_ID_ATTRIBUTE] = dicomRestriction.id; }

    // convert dicom tags
    const jsonTags: any = [];
    for (const tagId of dicomRestriction.tags) {
        const tag = imageDicomTags.find(t => t.id === tagId);
        if (tag === undefined) { throw new Error(errorMessage); }
        const jsonTag: any = {
            'attribute': tag.attribute,
            'value': tag.value
        };
        if (!noUiId) { jsonTag[UI_ID_ATTRIBUTE] = tag.id; }
        jsonTags.push(jsonTag);
    }
    jsonDicomRestriction['tags'] = jsonTags;
    jsonOutput['dicom_restriction'] = jsonDicomRestriction;

    // convert output geometry
    const outputGeometry = imageOutputGeometry.find(o => o.id === output.outputGeometry);
    if (outputGeometry === undefined) { throw new Error(errorMessage); }
    const jsonOutputGeometry: any = {
        'match_input': outputGeometry.matchInputGeometry,
        'fov_cm': outputGeometry.fovSize ? getLengthInCm(outputGeometry.fovSize) : null,
        'matrix_size': outputGeometry.matrixSize,
        'slice_thickness': outputGeometry.sliceThickness ? {
            'match_input': outputGeometry.sliceThickness.matchInput,
            'slice_thickness_cm': outputGeometry.sliceThickness.sliceThickness ? getLengthInCm(outputGeometry.sliceThickness.sliceThickness) : null,
        } : null,
    };
    if (!noUiId) { jsonOutputGeometry[UI_ID_ATTRIBUTE] = outputGeometry.id; }
    jsonOutput['output_geometry'] = jsonOutputGeometry;

    // convert contour generation
    const contourGeneration = imageContourGeneration.find(c => c.id === output.contourGeneration);
    if (contourGeneration === undefined) { throw new Error(errorMessage); }

    if (contourGeneration.attachTo === ContourAttachSeries.NotSet) { throw new Error(`Contour generation attachTo is not set.`); }

    const jsonContourGeneration: any = {
        'enabled': contourGeneration.isEnabled,
        'action': contourGeneration.action,
        'attach_to': contourGeneration.attachTo.toString(),
    };
    if (!noUiId) { jsonContourGeneration[UI_ID_ATTRIBUTE] = contourGeneration.id; }
    jsonOutput['contour_generation'] = jsonContourGeneration;

    // convert post processing
    const postProcessing = imagePostProcessing.find(p => p.id === output.postProcessing);
    if (postProcessing === undefined) { throw new Error(errorMessage); }

    const jsonPostProcessing: any = {};
    if (!noUiId) { jsonPostProcessing[UI_ID_ATTRIBUTE] = postProcessing.id; }

    // convert keep largest component
    const keepLargestComponentVm = keepLargestComponent.find(k => k.id === postProcessing.keepLargestComponent);
    if (keepLargestComponentVm === undefined) { throw new Error(errorMessage); }
    const jsonKeepLargestComponent: any = {
        'enabled': keepLargestComponentVm.isEnabled,
        'hu_threshold': keepLargestComponentVm.huThreshold,
        'delete_small_ccs_vol_mm3': getVolumeInMm3(keepLargestComponentVm.deleteSmallComponentsVol),
        'smoothing': getImageSmoothingJsonFromViewModel(keepLargestComponentVm.smoothing),
    };
    if (!noUiId) { jsonKeepLargestComponent[UI_ID_ATTRIBUTE] = keepLargestComponentVm.id; }
    jsonPostProcessing['keep_largest_component'] = jsonKeepLargestComponent;

    // convert fill holes
    const fillHolesVm = fillHoles.find(k => k.id === postProcessing.fillHoles);
    if (fillHolesVm === undefined) { throw new Error(errorMessage); }
    const jsonFillHoles: any = {
        'enabled': fillHolesVm.isEnabled,
    };
    if (!noUiId) { jsonFillHoles[UI_ID_ATTRIBUTE] = fillHolesVm.id; }

    // convert body mask
    let jsonBodyMask: any;
    if (!fillHolesVm.bodyMask) {
        jsonBodyMask = null;
    } else {
        const bodyMaskVm = bodyMasks.find(k => k.id === fillHolesVm.bodyMask);
        if (bodyMaskVm === undefined) { throw new Error(errorMessage); }
        jsonBodyMask = {
            'hu_threshold': bodyMaskVm.huThreshold,
            'erode': bodyMaskVm.erode,
            'smoothing': getImageSmoothingJsonFromViewModel(bodyMaskVm.smoothing),
            'delete_top': bodyMaskVm.deleteTopSlices,
            'delete_bottom': bodyMaskVm.deleteBottomSlices,
        };
        if (!noUiId) { jsonBodyMask[UI_ID_ATTRIBUTE] = bodyMaskVm.id; }
    }
    jsonFillHoles['body_mask'] = jsonBodyMask;

    // convert hole mask
    let jsonHoleMask: any;
    if (!fillHolesVm.bodyMask) {
        jsonHoleMask = null;
    } else {
        const holeMaskVm = holeMasks.find(k => k.id === fillHolesVm.holeMask);
        if (holeMaskVm === undefined) { throw new Error(errorMessage); }
        jsonHoleMask = {
            'hu_threshold_lower': holeMaskVm.huThresholdLower,
            'hu_threshold_upper': holeMaskVm.huThresholdUpper,
            'delete_small_ccs_vol_mm3': getVolumeInMm3(holeMaskVm.deleteSmallComponentsVol),
            'dilate': holeMaskVm.dilate,
            'smoothing': getImageSmoothingJsonFromViewModel(holeMaskVm.smoothing),
        };
        if (!noUiId) { jsonHoleMask[UI_ID_ATTRIBUTE] = holeMaskVm.id; }
    }
    jsonFillHoles['hole_mask'] = jsonHoleMask;

    jsonPostProcessing['fill_holes'] = jsonFillHoles;

    // convert restore nonhuman anatomy
    jsonPostProcessing['restore_nonhuman_anatomy'] = {
        'enabled': postProcessing.restoreNonHumanAnatomy,
    };

    jsonOutput['postprocessing'] = jsonPostProcessing;

    jsonCustomization['files'].push(jsonOutput);
}

const parseImageSmoothingFromJsonEntry = (entry: any): ImageSmoothing => {
    if (entry === null) {
        return defaultCreateNewImageSmoothing();
    }

    assertExpectedProps(entry, [
        { propName: 'method', propType: PropType.String },
        { propName: 'sigma', propType: PropType.Number },
    ]);

    const smoothingMethod = enumFromStringOrDefault(ImageSmoothingMethod, entry['method'], ImageSmoothingMethod.NotSet);

    // unparsed 'default' values are not allowed!
    if (smoothingMethod === ImageSmoothingMethod.NotSet) { throw new Error(`Could not parse post processing smoothing method from '${entry['method']}`); }

    return createNewImageSmoothing(
        smoothingMethod,
        entry['sigma'],
    );
}

const getImageSmoothingJsonFromViewModel = (smoothing: ImageSmoothing | null): any | null => {
    if (!smoothing) {
        return null;
    } else {
        if (smoothing.method === ImageSmoothingMethod.NotSet) { throw new Error(`Smoothing method is not set.`); }
        return {
            'method': smoothing.method.toString(),
            'sigma': smoothing.sigma,
        };
    }
}
