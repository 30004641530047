import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Switch } from '@patternfly/react-core';
import { Td, Tr } from '@patternfly/react-table';

import { StoreState } from '../../../store/store';
import { ItemActionsColumn } from '../ItemActionsColumn';
import { adaptSelectors, roiSelectionRuleInterpretedTypeSet, roiSelectionRuleIsEnabledSet, roiSelectionRuleNameRegExpSet, roiSelectionRuleRemoved } from '../../../store/adapt/adaptSlice';
import { columnCssNames, columnIds } from './RoiSelectionRuleTable';
import RegexField from '../../../components/regex-field';
import InterpretedTypeDropdown from '../../../components/InterpretedTypeDropdown';

interface RoiRuleSelectionRuleItemProps {
    roiRuleId: string;
    roiRuleIdList: string[];
}

const RoiRuleSelectionRuleItem = (props: RoiRuleSelectionRuleItemProps) => {

    const { roiRuleId, roiRuleIdList } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const roiRule = useSelector((state: StoreState) => adaptSelectors.selectRoiRuleById(state, roiRuleId));
    const hasOriginalTarget = useSelector((state: StoreState) => adaptSelectors.selectOriginalRoiRuleById(state, roiRuleId) !== undefined);
    // const validationError = useSelector((state: StoreState) => adaptSelectors.selectCustomizationValidationError(state, targetId));

    const handleNameRegExpChanged = useCallback((regExp: string) => {
        dispatch(roiSelectionRuleNameRegExpSet({ id: roiRuleId, regExp, }));
    }, [dispatch, roiRuleId]);

    const handleInterpretedTypeChange = useCallback((interpretedType: string) => {
        dispatch(roiSelectionRuleInterpretedTypeSet({ id: roiRuleId, interpretedType }));
    }, [dispatch, roiRuleId]);

    const handleIsEnabledChange = useCallback(() => {
        if (roiRule) { dispatch(roiSelectionRuleIsEnabledSet({ id: roiRuleId, isEnabled: !roiRule.isEnabled })); }
    }, [dispatch, roiRule]);

    const handleRemoveRoiRule = useCallback(() => {
        dispatch(roiSelectionRuleRemoved(roiRuleId));
    }, [dispatch, roiRuleId]);

    if (roiRule === undefined) {
        return null;
    }

    // const hasValidationError = validationError !== undefined && validationError.type === CustomizationObjectType.Target;
    const hasValidationError = false;

    return (
        <>
            <Tr className={`roi-item ${hasValidationError ? 'validation-error' : undefined}`}>
                <Td className={columnCssNames.modified}><div className="roi-item-is-modified" title="This customization has unsaved changes.">{/*target.isModified ? '*' : <>&nbsp;</>*/}</div></Td>

                <Td dataLabel={columnIds.nameRegExp} className={columnCssNames.nameRegExp}>
                    <div>
                        <RegexField
                            id={`regex-for-${roiRuleId}`}
                            label={undefined}
                            onChange={handleNameRegExpChanged}
                            regexValue={roiRule.nameRegExp}
                        />
                    </div>
                </Td>

                <Td dataLabel={columnIds.interpretedType} className={columnCssNames.interpretedType}>
                    <div>
                        <InterpretedTypeDropdown value={roiRule.interpretedType} onSelect={handleInterpretedTypeChange} allowEmptyValue />
                    </div>
                </Td>

                <Td dataLabel={columnIds.isEnabled} className={columnCssNames.isEnabled}>
                    <div>
                        <Switch
                            id={`is-enabled-for-${roiRuleId}`}
                            isChecked={roiRule.isEnabled}
                            onChange={handleIsEnabledChange}
                            label={t('customizationPage.adapt.roiSelectionSection.roiRules.enabled.on')}
                            labelOff={t('customizationPage.adapt.roiSelectionSection.roiRules.enabled.off')}
                        />
                    </div>
                </Td>

                <Td isActionCell>
                    <ItemActionsColumn items={[
                        {
                            //     title: t('customizationPage.dose.targets.undoChanges'),
                            //     onClick: handleRevertTargetChanges,
                            //     disabled: !hasOriginalTarget || !dicomTag.isModified,
                            // }, {
                            title: t('customizationPage.adapt.roiSelectionSection.roiRules.removeRule'),
                            onClick: handleRemoveRoiRule,
                        },
                    ]} />
                </Td>
            </Tr>

            {/* {hasValidationError && (
                <Tr className="roi-item validation-error validation-error-box selectable">
                    <Td dataLabel="Validation error" noPadding={true} colSpan={100}>
                        <Alert variant="danger" isInline isPlain isExpandable title={`Validation error: ${validationError.message}`}>
                            {validationError.field && (<div>Field: {validationError.field}</div>)}
                            <div>Error type: {validationError.detail}</div>
                            <div>Error ctx: {validationError.ctx}</div>
                        </Alert>
                    </Td>
                </Tr>
            )} */}

        </>
    );
}

export default RoiRuleSelectionRuleItem;
