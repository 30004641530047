import { EntityAdapter, Update } from "@reduxjs/toolkit";
import GenericSliceWrapper, { FormValidationErrorCalculationResults, RemovedOutputEntities } from "./genericSliceWrapper";
import { CustomizationObjectType } from "../customization-types";
import { FormValidationErrorType } from "../form-errors";
import { FormValidationError, generateNewFormValidationError } from "../store-errors";
import { adaptOutputAdapter, adaptRoiRuleAdapter, AdaptSliceState, aeTitleRuleAdapter, backendValidationErrorAdapter, customizationBaseAdapter, dicomAttributeRuleAdapter, dicomRuleAdapter, formValidationErrorAdapter, modelAdapter, outputMetadataAdapter } from "../../adapt/adaptSlice";
import { AdaptCustomizationEntities, AdaptCustomizationOutput, AdaptRoiRule } from "../../adapt/adapt-types";

export class AdaptSliceWrapper extends GenericSliceWrapper<AdaptSliceState> {

    outputAdapter: EntityAdapter<AdaptCustomizationOutput, string>;
    adaptRoiRuleAdapter: EntityAdapter<AdaptRoiRule, string>;


    constructor() {
        super(
            modelAdapter,
            customizationBaseAdapter,
            outputMetadataAdapter,
            aeTitleRuleAdapter,
            dicomRuleAdapter,
            dicomAttributeRuleAdapter,
            backendValidationErrorAdapter,
            formValidationErrorAdapter
        )
        this.outputAdapter = adaptOutputAdapter;
        this.adaptRoiRuleAdapter = adaptRoiRuleAdapter;
    }

    protected findOutput(state: AdaptSliceState, outputId: string): AdaptCustomizationOutput | undefined {
        return state.adaptOutputs.entities[outputId];
    }

    protected updateOutput(state: AdaptSliceState, update: Update<AdaptCustomizationOutput, string>): void {
        this.outputAdapter.updateOne(state.adaptOutputs, update);
    }

    /** Collects which store entities need to be removed and updated when a customization output is removed. */
    public collectCustomizationOutputEntitiesFromStoreForRemoval(state: AdaptSliceState, output: AdaptCustomizationOutput, customizationBaseId: string): RemovedOutputEntities {

        const metadataIdsToBeRemoved: string[] = [];
        const adaptRoiRuleIdsToBeRemoved: string[] = [];



        metadataIdsToBeRemoved.push(...output.metadata);
        if (output.roiSelection) { adaptRoiRuleIdsToBeRemoved.push(...output.roiSelection.roiRules); }

        return {
            metadataIdsToBeRemoved,
            adaptRoiRuleIdsToBeRemoved,
            allRemovedIds: [
                ...metadataIdsToBeRemoved,
                ...adaptRoiRuleIdsToBeRemoved,
            ],
        };
    };



    protected removeOutputRelatedItems(state: AdaptSliceState, outputIds: string[], items: RemovedOutputEntities) {
        const { metadataIdsToBeRemoved, adaptRoiRuleIdsToBeRemoved, } = items;

        if (metadataIdsToBeRemoved) { this.outputMetadataAdapter.removeMany(state.outputMetadata, metadataIdsToBeRemoved); }
        if (adaptRoiRuleIdsToBeRemoved) { this.adaptRoiRuleAdapter.removeMany(state.adaptRoiRules, adaptRoiRuleIdsToBeRemoved); }


        this.outputAdapter.removeMany(state.adaptOutputs, outputIds);
    }

    protected setOutputRelatedItemsAsModified(state: AdaptSliceState, entitiesToUpdate: Partial<AdaptCustomizationEntities>) {

        const adaptRoiRuleIds = entitiesToUpdate.adaptRoiRules?.map(id => id.id);
        const customizationOutputsIds = entitiesToUpdate.adaptOutputs?.map(id => id.id);

        // TODO: decide if adaptRoiRule should have an isModified flag
        // if (adaptRoiRuleIds && adaptRoiRuleIds.length > 0) {
        //     this.adaptRoiRuleAdapter.updateMany(
        //         state.adaptRoiRules,
        //         adaptRoiRuleIds.map(adaptRoiRuleId => ({ id: adaptRoiRuleId, changes: { isModified: true } }))
        //     );
        // }

        if (customizationOutputsIds && customizationOutputsIds.length > 0) {
            this.outputAdapter.updateMany(
                state.adaptOutputs,
                customizationOutputsIds.map(customizationOutputId => ({ id: customizationOutputId, changes: { isModified: true } }))
            );
        }
    }

    /** Calculates form validation errors for given customization output.
     * TODO: once we have more types of validation errors consider splitting
     * this into smaller functions, and consider whether all of them need to be
     * checked here.
     */
    protected calculateFormValidationErrorsForCustomizationOutput(state: AdaptSliceState, outputId: string): FormValidationErrorCalculationResults {
        const errorIdsToRemove: string[] = [];
        const errorsToAdd: FormValidationError[] = [];

        const output = state.adaptOutputs.entities[outputId];
        if (!output) { throw new Error(`Customization output (${outputId}) is undefined`); }

        // const existingErrors = Object.values(state.formValidationErrors.entities).filter(e => output.rois.includes(e?.itemId));
        // const existingEmptyNameErrors = existingErrors.filter(e => e?.errorType === FormValidationErrorType.RoiNameIsEmpty);
        // const existingDuplicateNameErrors = existingErrors.filter(e => e?.errorType === FormValidationErrorType.DuplicateRoiNamesInOutput);

        // // collect existing duplicate name errors nearby so we can use them when doing a delta within this output
        // // map potential INCLUDED duplicate roiIds (string array value) under the potential duplicate name (string key)
        // // this is needed for the duplicate name check resolution later
        // const duplicateNames: { [name: string]: string[] } = {};
        // for (const roi of rois) {
        //     duplicateNames[roi.name] = duplicateNames[roi.name] || [];
        //     duplicateNames[roi.name].push(roi.id);
        // }

        // // check all the validation errors for ROIs in one loop
        // for (const roi of rois) {

        //     let dontAddNewValidationErrors = false;


        //     // check for empty names rois (RoiNameIsEmpty)
        //     const matchingExistingEmptyNameErrors = existingEmptyNameErrors.filter(e => e.itemId === roi.id);
        //     const hasMatchingEmptyNameErrors = matchingExistingEmptyNameErrors.length > 0;
        //     const isNameEmpty = !roi.name.trim();

        //     // NOTE: new errors are NOT created if existing ones with the same FormValidationErrorType have already been found!
        //     if (isNameEmpty && !hasMatchingEmptyNameErrors && !dontAddNewValidationErrors) {
        //         // create a new error
        //         errorsToAdd.push(generateNewFormValidationError(FormValidationErrorType.RoiNameIsEmpty, roi.id, CustomizationObjectType.Roi));
        //     } else if (dontAddNewValidationErrors || (!isNameEmpty && hasMatchingEmptyNameErrors)) {
        //         // remove existing errors
        //         errorIdsToRemove.push(...matchingExistingEmptyNameErrors.map(m => m.id));
        //     }

        //     // if this name was empty then don't add any new validation errors (but we still need to check if any can be removed)
        //     if (isNameEmpty) {
        //         dontAddNewValidationErrors = true;
        //     }


        //     // check for duplicate names within (included) rois in the same output (DuplicateRoiNamesInOutput)
        //     // now loop through the ROIs again and do a delta against existing errors, creating new errors and marking existing ones to be removed as needed
        //     const matchingExistingDuplicateNameErrors = existingDuplicateNameErrors.filter(e => e.itemId === roi.id);
        //     const hasMatchingDuplicateNameErrors = matchingExistingDuplicateNameErrors.length > 0;
        //     const isNameDuplicated = duplicateNames[roi.name] && duplicateNames[roi.name].length > 1;

        //     // NOTE: new errors are NOT created if existing ones with the same FormValidationErrorType have already been found!
        //     if (isNameDuplicated && !hasMatchingDuplicateNameErrors && !dontAddNewValidationErrors) {
        //         // create a new error
        //         errorsToAdd.push(generateNewFormValidationError(FormValidationErrorType.DuplicateRoiNamesInOutput, roi.id, CustomizationObjectType.Roi));
        //     } else if (dontAddNewValidationErrors || (!isNameDuplicated && hasMatchingDuplicateNameErrors)) {
        //         // remove existing errors
        //         errorIdsToRemove.push(...matchingExistingDuplicateNameErrors.map(m => m.id));
        //     }
        // }

        return {
            validationErrorIdsToRemove: errorIdsToRemove,
            validationErrorsToAdd: errorsToAdd,
        }
    }
}
