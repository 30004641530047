import { has, isArray, isBoolean, isNumber, isObject, isString } from "lodash-es";
import { AeTitleRule, CustomizationBase, CustomizationOutput, DicomAttributeRule, DicomRule, isCustomizationOutput, Model, OutputMetadataItem } from "../global-types/customization-types";
import { isLengthValue, LengthValue } from "../global-types/units";

export const EXPORT_ADAPT_JSON_FILE_PREFIX = 'mvision-adapt-';

/** Prefix to use for adapt+ model AE Titles */
export const AETITLE_PREFIX_ADAPT = 'MV_TODO_ADAPT';

/** Model type for adapt+ models. */
export const ADAPT_MODEL_TYPE = 'registration';

/** Default value to use when smoothing sigma is enabled. */
export const DEFAULT_SMOOTHING_SIGMA = 0.5;

/** Whether to include or exclude ROIs defined in ROI selection rules */
export enum SelectionInclusion { NotSet = 'not_set', Include = 'include', Exclude = 'exclude' };

/** A single customization output (ie. a result file) for an adapt+ model. A customization base contains one or more of these. */
export type AdaptCustomizationOutput = CustomizationOutput & {

    /** Rules for selecting ROIs that will be propagated or focused on during registration */
    roiSelection: AdaptRoiSelection;

    /** Set of booleans to tell the worker what expect should be returned */
    expectedResult: AdaptExpectedResult;

    /** Pixel spacing */
    pixelSpacing: LengthValue[];

    /** Smoothing sigma for smoothing contours in the RTSTRUCTs */
    smoothingSigma: number | null;

    /** Include the valid region mask in the RTSTRUCT. Primarily for internal testing purposes. */
    isValidMaskIncluded: boolean;
};

/** Customization type for Adapt+ ROI selection */
export type AdaptRoiSelection = {
    /** Whether ROI selection options are used or not. */
    isEnabled: boolean;

    /** whether to include/exclude the ROI in the result */
    inclusion: SelectionInclusion;

    /** List of rules used to select ROIs that need to be propagated or focused on during registration */
    roiRules: string[]; // AdaptRoiRule ids
};

export type AdaptRoiRule = {
    /** Redux ID of this adapt ROI rule. */
    id: string;

    /** whether to enable the rule or not. True -> the rule is applied, False -> The rule is ignored */
    isEnabled: boolean;
    /** regexp used to match the name of the ROI in the RTSTRUCT */
    nameRegExp: string;
    /** If it matches the specified type, e.g. ORGAN or PTV, it is included/excluded from the results based on "included" attribute */
    interpretedType: string;

    /** True if this item has unsaved changes, false otherwise. */
    isModified: boolean;
    /** Parent model customization output this item belongs to. */
    outputId?: string;  // this is optional because it's usually set post-constructor
}

/** Set of booleans to tell the worker what expect should be returned */
export type AdaptExpectedResult = {
    /** Should REG file be returned */
    isRegFileReturned: boolean;
    /** Should registered CT/MR/PT scan be returned */
    isRegisteredScanReturned: boolean;
    /** Should registered RTDOSE (if RTDose input is provided) be returned */
    isRTDoseReturned: boolean;
    /** Should registered RTPLAN (if RTPLAN input is provided) be returned */
    isRTPlanReturned: boolean;
    /** Should registered RTSTRUCT file (Registered contours if RTSTRUCT is provided) be returned */
    isRTStructReturned: boolean;
    /** Should the result files be compressed in a zip file */
    areResultsCompressedAsZip: boolean;
    /** Should the input files be returned in the result */
    areInputsReturned: boolean;

};

/** A full collection of adapt customization-related entities and any
 * related internal helper objects. */
export type AdaptCustomizationEntities = {
    models: Model[];
    customizationBases: CustomizationBase[];
    adaptOutputs: AdaptCustomizationOutput[];
    outputMetadata: OutputMetadataItem[];

    adaptRoiRules: AdaptRoiRule[];

    aeTitleRules: AeTitleRule[];
    dicomRules: DicomRule[];
    dicomAttributeRules: DicomAttributeRule[];
};

/** A full collection of adapt customization-related entities without
 * any internal helper entities that are not needed for export.
 * NOTE: as of now this is identical with AdaptCustomizationEntities but
 * it's possible these will diverge in future. */
export type AdaptCustomizationEntitiesForExport = {
    models: Model[];
    customizationBases: CustomizationBase[];
    adaptOutputs: AdaptCustomizationOutput[];
    outputMetadata: OutputMetadataItem[];

    adaptRoiRules: AdaptRoiRule[];

    aeTitleRules: AeTitleRule[];
    dicomRules: DicomRule[];
    dicomAttributeRules: DicomAttributeRule[];
};


// *** type guards ***

/** Checks if the object is of type `AdaptCustomizationOutput`. */
export const isAdaptCustomizationOutput = (obj: any): obj is AdaptCustomizationOutput => {
    const output = obj as AdaptCustomizationOutput;
    return !!output
        && isCustomizationOutput(obj)
        && isAdaptRoiSelection(output.roiSelection)
        && (isAdaptExpectedResult(output.expectedResult))
        && isArray(output.pixelSpacing)
        && output.pixelSpacing.every(p => isLengthValue(p))
        && (isNumber(output.smoothingSigma) || output.smoothingSigma === null)
};

export const isAdaptRoiSelection = (obj: any): obj is AdaptRoiSelection => {
    const roiSelection = obj as AdaptRoiSelection;
    return !!roiSelection
        && isBoolean(roiSelection.isEnabled)
        && (roiSelection.inclusion === SelectionInclusion.Include || roiSelection.inclusion === SelectionInclusion.Exclude)
        && isArray(roiSelection.roiRules);
}

/** Checks if the object is of type `AdaptRoiRule`. */
export const isAdaptRoiRule = (obj: any): obj is AdaptRoiRule => {
    const roiRule = obj as AdaptRoiRule;
    return !!roiRule
        && isObject(roiRule)
        && has(roiRule, 'isEnabled') && isBoolean(roiRule.isEnabled)
        && has(roiRule, 'nameRegExp') && isString(roiRule.nameRegExp)
        && has(roiRule, 'interpretedType') && isString(roiRule.interpretedType);
}

/** Checks if the object is of type `AdaptRoiSelection`. */
export const isAdaptExpectedResult = (obj: any): obj is AdaptExpectedResult => {
    const result = obj as AdaptExpectedResult;
    return !!result
        && isObject(result)
        && has(result, 'isRegFileReturned') && isBoolean(result.isRegFileReturned)
        && has(result, 'isRegisteredScanReturned') && isBoolean(result.isRegisteredScanReturned)
        && has(result, 'isRTDoseReturned') && isBoolean(result.isRTDoseReturned)
        && has(result, 'isRTPlanReturned') && isBoolean(result.isRTPlanReturned)
        && has(result, 'isRTStructReturned') && isBoolean(result.isRTStructReturned)
        && has(result, 'areResultsCompressedAsZip') && isBoolean(result.areResultsCompressedAsZip)
        && has(result, 'areInputsReturned') && isBoolean(result.areInputsReturned);
}
