import { call, put } from "typed-redux-saga";
import { MVisionAppClient } from "../configurationTarget/mvision-client-list";
import { callApi } from "../sagas";
import { ModelType } from "../global-types/customization-types";
import { BackendValidationError } from "../../util/errors";
import { modelCustomizationsSet } from "./adaptSlice";

/**
 * Saga to fetch adapt customizations using the provided application client.
 *
 * This saga function performs an API call to retrieve adapt customizations
 * and handles the success or failure of the operation. On success, it updates
 * the store with the fetched customizations. On failure, it updates the store
 * with an error message.
 * 
 * @param appClient - An instance of `MVisionAppClient` or `undefined`. If `undefined`,
 *                    the saga will return `null` for adapt customizations.
 * 
 * @returns The result of the API call, which is either the fetched adapt customizations
 *          or `null` if the operation fails or the `appClient` is `undefined`.
 */
export function* fetchAdaptCustomizationsSaga(appClient: MVisionAppClient | undefined) {

    const result = yield* callApi({
        doApiCall: function* (client) {
            const adaptCustomizations = appClient === undefined ? null : yield* call(async () => client.fetchAdaptCustomizationsAsync(appClient));
            // if (adaptCustomizations !== null) {
            //     const fetchModels = yield* call(async () => client.fetchAvailableModelsAsync(appClient, ModelType.Adapt));
            //     if (fetchModels !== undefined) {
            //         // if the available models collection is in use, mark which segmentation models are unavailable
            //         for (const model of adaptCustomizations.models) {
            //             model.isAvailable = fetchModels.availableModels.includes(model.modelName);
            //         }
            //     } else {
            //         throw new Error('Could not retrieve list of available dose models');
            //     }
            // }
            console.log('adaptCustomizations', adaptCustomizations)
            return adaptCustomizations;
        },
        onSuccess: function* (result) {
            yield* put(modelCustomizationsSet({ customizations: result }));
        },
        onFailure: function* (error) {
            yield* put(modelCustomizationsSet({ customizations: null, errorMessage: error.message || 'Unspecified error.' }));
        },
        // onFinish: function* (result: any | undefined, error: Error | BackendValidationError | null) {
        //     console.log(result)
        //     console.log(error)
        // }
    });

    return result;
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForAdaptConfigSagas() {
    return [
       
    ];
}
