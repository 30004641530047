import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StoreState } from '../../../store/store';
import { CustomizationObjectType, ModelVisibility } from '../../../store/global-types/customization-types';
import { RTSTRUCT_METADATA_ATTRIBUTE_PRESETS } from '../../../store/contouring/contouring-types';
import CustomizationSection from '../CustomizationSection';
import CustomizationForm from '../CustomizationForm';
import { imageSelectors, metadataItemRemoved, metadataItemUpdated, modelCustomizationDescriptionUpdated, modelVisibilityUpdated, scrollToViewFromModelCustomizationMetadataRemoved } from '../../../store/image/imageSlice';
import { makeSelectIsAnyMetadataForOutputModified, makeSelectIsAnyTriggerForBaseModified } from '../../../store/image/imageSelectors';
import ImageSelectionRules from '../../selection/image/ImageSelectionRules';
import DicomRestrictionForm from './DicomRestrictionForm';
import OutputGeometryForm from './OutputGeometryForm';
import ContourGenerationForm from './ContourGenerationForm';
import PostProcessingForm from './PostProcessingForm';

import '../customization-page.css';

interface ImageCustomizationFormProps {
    outputId: string,
}

const ImageCustomizationForm = (props: ImageCustomizationFormProps) => {
    const { outputId } = props;
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const customizationOutput = useSelector((state: StoreState) => imageSelectors.selectOutputById(state, outputId));
    const customizationBase = useSelector((state: StoreState) => customizationOutput ? imageSelectors.selectCustomizationBaseById(state, customizationOutput.modelCustomizationBaseId) : undefined);
    const model = useSelector((state: StoreState) => customizationBase ? imageSelectors.selectModelById(state, customizationBase.modelId) : undefined);
    const { dicomRestriction, outputGeometry, contourGeneration, postProcessing } = useSelector((state: StoreState) => imageSelectors.selectMainConfigurationObjectsForOutput(state, outputId));
    const validationEntities = useSelector(imageSelectors.selectCustomizationValidationErrorEntities);
    const outputMetadata = useSelector(imageSelectors.selectOutputMetadataEntities);
    const originalMetadata = useSelector(imageSelectors.selectOriginalOutputMetadataEntities);
    const [isDicomRestrictionsSectionExpanded, setDicomRestrictionsSectionExpanded] = useState(true);
    const [isOutputGeometrySectionExpanded, setOutputGeometrySectionExpanded] = useState(true);
    const [isContourGenerationSectionExpanded, setContourGenerationSectionExpanded] = useState(true);
    // const [isPostProcessingSectionExpanded, setPostProcessingSectionExpanded] = useState(true);

    const selectIsAnyMetadataForOutputModified = useMemo(makeSelectIsAnyMetadataForOutputModified, []);
    const isAnyMetadataModified = useSelector((state: StoreState) => selectIsAnyMetadataForOutputModified(state, outputId));

    const selectIsAnyTriggerRuleForBaseModified = useMemo(makeSelectIsAnyTriggerForBaseModified, []);
    const isAnyTriggerRuleModified = useSelector((state: StoreState) => customizationOutput ? selectIsAnyTriggerRuleForBaseModified(state, customizationOutput.modelCustomizationBaseId) : false);


    const handleToggleDicomRestrictionSectionExpanded = useCallback(() => {
        setDicomRestrictionsSectionExpanded(!isDicomRestrictionsSectionExpanded);
    }, [isDicomRestrictionsSectionExpanded]);

    const handleToggleOutputGeometrySectionExpanded = useCallback(() => {
        setOutputGeometrySectionExpanded(!isOutputGeometrySectionExpanded);
    }, [isOutputGeometrySectionExpanded]);

    const handleToggleContourGenerationSectionExpanded = useCallback(() => {
        setContourGenerationSectionExpanded(!isContourGenerationSectionExpanded);
    }, [isContourGenerationSectionExpanded]);

    // const handleTogglePostProcessingSectionExpanded = useCallback(() => {
    //     setPostProcessingSectionExpanded(!isPostProcessingSectionExpanded);
    // }, [isPostProcessingSectionExpanded]);

    const onSetModelVisibility = useCallback((modelId: string, visibility: ModelVisibility) => {
        dispatch(modelVisibilityUpdated({ modelId, visibility }));
    }, [dispatch]);

    const onDescriptionChanged = useCallback((text: string) => {
        if (customizationBase) {
            dispatch(modelCustomizationDescriptionUpdated({ customizationBaseId: customizationBase.id, description: text }));
        }
    }, [dispatch, customizationBase]);

    const onMetadataItemUpdated = useCallback((metadataId: string, attribute: string | undefined, value: string | undefined, isUndoOperation?: boolean) => {
        dispatch(metadataItemUpdated({ metadataId, attribute, value, isUndoOperation }));
    }, [dispatch]);

    const onMetadaItemRemoved = useCallback((metadataId: string) => {
        dispatch(metadataItemRemoved(metadataId));
    }, [dispatch]);

    const removeScrollToViewFromMetadataItem = useCallback((metadataId: string) => {
        dispatch(scrollToViewFromModelCustomizationMetadataRemoved(metadataId));
    }, [dispatch]);


    // sanity check that we have retrieved valid objects from redux store
    // (this check has to be done after all hooks calls)
    if (customizationOutput === undefined || customizationBase === undefined || model === undefined
        || dicomRestriction === undefined || outputGeometry === undefined || contourGeneration === undefined || postProcessing === undefined) {
        return null;
    }

    const outputHasValidErrors = validationEntities[outputId] !== undefined && validationEntities[outputId].type === CustomizationObjectType.CustomizationOutput;


    return (<CustomizationForm
        model={model}
        customizationBase={customizationBase}
        customizationOutput={customizationOutput}
        validationEntities={validationEntities}
        outputMetadata={outputMetadata}
        originalMetadata={originalMetadata}
        metadataPresets={RTSTRUCT_METADATA_ATTRIBUTE_PRESETS}
        isAnyMetadataModified={isAnyMetadataModified}
        isAnyTriggerRuleModified={isAnyTriggerRuleModified}
        onSetModelVisibility={onSetModelVisibility}
        onDescriptionChanged={onDescriptionChanged}
        onMetadataItemUpdated={onMetadataItemUpdated}
        onMetadaItemRemoved={onMetadaItemRemoved}
        removeScrollToViewFromMetadataItem={removeScrollToViewFromMetadataItem}
        isMetadataSectionDisabled={false}
        isDicomSelectionDisabled={false}
        hideTargetFile={true}
        selectionRules={(
            <ImageSelectionRules
                customizationBaseId={customizationBase.id}
                isUndoDisabled={true}
                setUndoState={() => { }}
                heading={`model-selection-for-${customizationBase.id}`}
                hideCustomizationName
                showMultipleOutputsWarning={customizationBase.outputs.length > 1}
            />
        )}
    >

        <CustomizationSection
            label={t('customizationPage.image.dicomRestrictionSection.title')}
            isModified={dicomRestriction.isModified}
            isValid={!outputHasValidErrors}
            collapsedMessage={t('customizationPage.image.dicomRestrictionSection.message')}
            isExpanded={isDicomRestrictionsSectionExpanded}
            onClick={handleToggleDicomRestrictionSectionExpanded}
        >
            <DicomRestrictionForm outputId={outputId} />
        </CustomizationSection>

        <CustomizationSection
            label={t('customizationPage.image.outputGeometrySection.title')}
            isModified={outputGeometry.isModified}
            isValid={!outputHasValidErrors}
            collapsedMessage={t('customizationPage.image.outputGeometrySection.message')}
            isExpanded={isOutputGeometrySectionExpanded}
            onClick={handleToggleOutputGeometrySectionExpanded}
        >
            <OutputGeometryForm outputId={outputId} />
        </CustomizationSection>

        <CustomizationSection
            label={t('customizationPage.image.contourGenerationSection.title')}
            isModified={contourGeneration.isModified}
            isValid={!outputHasValidErrors}
            collapsedMessage={t('customizationPage.image.contourGenerationSection.message')}
            isExpanded={isContourGenerationSectionExpanded}
            onClick={handleToggleContourGenerationSectionExpanded}
        >
            <ContourGenerationForm outputId={outputId} />
        </CustomizationSection>

        {/* currently post-processing is not exposed to users */}
        {/* <CustomizationSection
            label={t('customizationPage.image.postProcessingSection.title')}
            isModified={postProcessing.isModified}
            isValid={!outputHasValidErrors}
            collapsedMessage={t('customizationPage.image.postProcessingSection.message')}
            isExpanded={isPostProcessingSectionExpanded}
            onClick={handleTogglePostProcessingSectionExpanded}
        >
            <PostProcessingForm outputId={outputId} />
        </CustomizationSection> */}

    </CustomizationForm>
    );
}

export default ImageCustomizationForm;
