import { FormGroup, MenuToggle, MenuToggleElement, Select, SelectOption } from '@patternfly/react-core';
import React, { memo, useCallback, useMemo } from 'react';

import './interpreted-type-dropdown.css';

interface InterpretedTypeDropdownProps {
    onSelect: (interpretedType: string) => void,
    value: string,
    allowEmptyValue?: boolean
}

const supportedInterpretedTypes = [
    'ORGAN',
    'EXTERNAL',
    'PTV',
    'CTV',
    'GTV',
    'CONTROL',
    'BOLUS',
    'AVOIDANCE',
    'MARKER',
    'CAVITY',
    'SUPPORT',
    'FIXATION',
    'REGISTRATION',
    'ISOCENTER',
    'CONTRAST_AGENT',
    'TREATED_VOLUME',
    'IRRAD_VOLUME',
    'BRACHY_CHANNEL',
    'BRACHY_ACCESSORY',
    'BRACHY_SRC_APP',
    'BRACHY_CHNL_SHLD',
    'DOSE_REGION',
    'DOSE_MEASUREMENT',
];

export const EMPTY_VALUE = '';

const InterpretedTypeDropdown = (props: InterpretedTypeDropdownProps) => {
    const { value, onSelect, allowEmptyValue } = props;

    const [isOpen, setIsOpen] = React.useState(false);

    const handleToggleInterpretedType = useCallback(() => {
        setIsOpen(!isOpen);
    }, []);

    // const onFocus = useCallback(() => {
    //     const element = document.getElementById('toggle-basic');
    //     element.focus();
    // }, []);

    const handleSelect = useCallback((value: any) => {
        setIsOpen(false);
        // onFocus();
        onSelect(value.target.innerText)
    }, []);

    const dropdownItems = useMemo(() => (allowEmptyValue ? [EMPTY_VALUE].concat(supportedInterpretedTypes) : supportedInterpretedTypes)
        .map(i => (<SelectOption key={i} className="interpreted-type-option">{i}</SelectOption>)),
        [allowEmptyValue]);

    return (
        <FormGroup className="interpreted-type">
            <Select
                toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
                    <MenuToggle ref={toggleRef} onClick={handleToggleInterpretedType} isFullWidth>
                        <span>{value}&nbsp;</span>
                    </MenuToggle>
                )}

                isOpen={isOpen}
                onOpenChange={() => setIsOpen(!isOpen)}
                popperProps={{ position: 'right' }}
                onSelect={handleSelect}
                shouldFocusFirstItemOnOpen={false}
            >{dropdownItems}</Select>
        </FormGroup>
    );
}

export default memo(InterpretedTypeDropdown);
