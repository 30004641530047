import React, { useCallback, useMemo, useState } from 'react';
import { Nav, Page, PageSidebar, NavItem, Modal, Button, Backdrop, Bullseye, NavGroup, PageSidebarBody } from '@patternfly/react-core';
import { ExclamationCircleIcon } from '@patternfly/react-icons'

import routes, { Route } from './routes';
import { NavLink } from 'react-router-dom';

import './Layout.css';
import { useSelector } from 'react-redux';
import SplashPage from './pages/splash/SplashPage';
import NewVersionAlert from './components/new-version-alert';
import UserSettings from './pages/user-settings/UserSettings';
import { useTranslation } from 'react-i18next';
import HelpScreen from './pages/help/HelpScreen';
import { StoreState } from './store/store';
import { appConfigSelectors } from './store/appConfig/appConfigSlice';
import { configurationTargetSelectors } from './store/configurationTarget/configurationTargetSlice';
import InteractionRequiredModal from './pages/auth/InteractionRequiredModal';
import CustomMasthead from './Masthead';
import { SupportedModelType } from './store/appConfig/appDeploymentInfoTypes';
import ExternalLink from './components/external-link';

const Layout = (props: React.PropsWithChildren<{}>) => {

    const { t } = useTranslation();

    const [activeItem, setActiveItem] = useState(-1);
    const [isUserSettingsVisible, setUserSettingsVisible] = useState(false);
    const [isHelpDialogVisible, setHelpDialogVisible] = useState(false);
    const isLayoutInsideFrame = useSelector(appConfigSelectors.selectIsLayoutInsideFrame);
    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);
    const appConfig = useSelector(appConfigSelectors.selectAppConfig);

    const onNavSelect = useCallback((result: any) => {
        setActiveItem(result.itemId);
    }, []);

    const handleOpenUserSettings = useCallback(() => {
        setUserSettingsVisible(true);
    }, []);

    const handleCloseUserSettings = useCallback(() => {
        setUserSettingsVisible(false);
    }, []);

    const handleOpenHelpDialog = useCallback(() => {
        setHelpDialogVisible(true);
    }, []);

    const handleCloseHelpDialog = useCallback(() => {
        setHelpDialogVisible(false);
    }, []);

    const renderNavItem = useCallback((route: Route, activeItem: Number) => (
        <NavItem key={route.index} isActive={activeItem === route.index}>
            <NavLink exact to={route.slug} activeClassName="pf-m-current">
                {t(route.langKey)}
            </NavLink>
        </NavItem>
    ), [t]);

    const isAppInitialized = useSelector((state: StoreState) => state.appStatus.isAppInitialized);
    const isTargetListFetched = useSelector((state: StoreState) => state.configurationTarget.isConfigurationTargetListFetched);
    const isFetchingData = useSelector(configurationTargetSelectors.selectIsFetchingConfigurationTargetData);
    const userInitError = useSelector((state: StoreState) => !!state.configurationTarget.configurationTargetListError || !!state.user.userSettingsError ?
        `${state.configurationTarget.configurationTargetListError || ''} ${state.user.userSettingsError || ''}` : null);
    const errorComponent = !!userInitError ? (<span className="splash-error"><span className="icon"><ExclamationCircleIcon /></span> {userInitError}</span>) : undefined;

    const isAdaptConfigSupported = useMemo(() => deploymentInfo?.supportedModelTypes.includes(SupportedModelType.Adapt) || false, [deploymentInfo]);
    const isContourConfigSupported = useMemo(() => deploymentInfo?.supportedModelTypes.includes(SupportedModelType.Contour) || false, [deploymentInfo]);
    const isDoseConfigSupported = useMemo(() => deploymentInfo?.supportedModelTypes.includes(SupportedModelType.Dose) || false, [deploymentInfo]);
    const isImageConfigSupported = useMemo(() => deploymentInfo?.supportedModelTypes.includes(SupportedModelType.Image) || false, [deploymentInfo]);

    if (isLayoutInsideFrame === undefined || !isAppInitialized || !isTargetListFetched || deploymentInfo === undefined || appConfig === undefined || !!userInitError) {
        return <SplashPage loadingText={errorComponent} />;
    }

    // TODO: hide or minimize masthead when the app is inside a (cockpit) frame
    // isLayoutInsideFrame

    const pageNav = (
        <Nav onSelect={onNavSelect} aria-label="Nav" className='unselectable'>
            {isContourConfigSupported && <NavGroup title={t('sideNav.contour')} className='nav-group-title'>
                {renderNavItem(routes.contourCustomizationPage, activeItem)}
                {renderNavItem(routes.contourAllStructuresPage, activeItem)}
                {renderNavItem(routes.contourModelSelectionPage, activeItem)}
            </NavGroup>}

            {isDoseConfigSupported && <NavGroup title={t('sideNav.dose')} className='nav-group-title'>
                {renderNavItem(routes.doseCustomizationPage, activeItem)}
                {/* {renderNavItem(routes.doseModelSelectionPage, activeItem)} */}
            </NavGroup>}

            {isAdaptConfigSupported && <NavGroup title={t('sideNav.adapt')} className='nav-group-title'>
                {renderNavItem(routes.adaptCustomizationPage, activeItem)}
            </NavGroup>}

            {isImageConfigSupported && <NavGroup title={t('sideNav.image')} className='nav-group-title'>
                {renderNavItem(routes.imageCustomizationPage, activeItem)}
            </NavGroup>}

            <NavGroup title={t('sideNav.general')} className='nav-group-title'>
                {appConfig.allowTextEditor && renderNavItem(routes.textEditorPage, activeItem)}
                {deploymentInfo && !deploymentInfo.disableDaemonConfigPage && renderNavItem(routes.daemonConfigPage, activeItem)}
                {renderNavItem(routes.licensePage, activeItem)}
                {renderNavItem(routes.aboutPage, activeItem)}
                {appConfig.allowDashboard && deploymentInfo && deploymentInfo.dashboardUrl !== undefined && (
                    <NavItem key="dashboard-link" isActive={false}>
                        <ExternalLink url={deploymentInfo.dashboardUrl}>{t('sideNav.dashboard')}</ExternalLink>
                    </NavItem>
                )}
            </NavGroup>
        </Nav>
    );

    const sidebar = (<PageSidebar><PageSidebarBody>{pageNav}</PageSidebarBody></PageSidebar>);
    const masthead = (<CustomMasthead handleOpenUserSettings={handleOpenUserSettings} isUserSettingsOpen={isUserSettingsVisible} handleOpenHelpDialog={handleOpenHelpDialog} />);

    return (
        <Page header={masthead} sidebar={sidebar} className="main-page" isManagedSidebar>
            <NewVersionAlert />
            {isFetchingData && (
                <Backdrop>
                    <Bullseye>
                        <SplashPage loadingText={errorComponent ? errorComponent : t('splash.loadingConfigurationTarget')} />
                    </Bullseye>
                </Backdrop>
            )}

            {props.children}

            <Modal
                isOpen={isUserSettingsVisible}
                title={t('dialog.settings')}
                className='unselectable'
                variant={'large'}
                position={'top'}
                positionOffset="10%"
                onClose={handleCloseUserSettings}
                actions={[<Button key="close-user-settings" onClick={handleCloseUserSettings} variant='tertiary'>{t('common.close')}</Button>]}
            >
                <UserSettings />
            </Modal>

            <Modal
                isOpen={isHelpDialogVisible}
                title={t('dialog.help')}
                variant={'medium'}
                onClose={handleCloseHelpDialog}
                actions={[<Button key="close-help-dialog" onClick={handleCloseHelpDialog} variant='tertiary'>{t('common.close')}</Button>]}
            >
                <HelpScreen />
            </Modal>


            <InteractionRequiredModal />

        </Page>
    );
}

export default Layout;
