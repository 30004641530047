import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, FormGroup, InputGroup, InputGroupItem, Level, LevelItem, List, ListItem, Modal, ModalVariant, Stack, StackItem, Text } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DebouncedTextInput from './debounced-text-input';
import { appConfigSelectors } from '../store/appConfig/appConfigSlice';
import { getHasAcknowledgedRegexServiceDisclaimer, setHasAcknowledgedRegexServiceDisclaimer } from '../util/local-storage';
import { literalTextToRegex, regexToLiteralText } from '../util/string-convert';

import './regex-field.css';
import RegexFieldModal from './regex-field-modal';
import RegexHelp from './regex-help';
import AppendedButton from './appended-button';

const REGEX_DEBOUNCE_TIME_MS = 75;

interface RegexFieldProps {
    id: string,
    label: string | undefined,
    onChange: (regexValue: string) => void,
    regexValue: string | undefined,
    /** If true the 'Enable regular expressions' checkbox is hidden, regex mode is always on,
     * and regex usage acknowledgment won't be prompted or used. */
    alwaysUseRegex?: boolean,
    /** If true the help popover and button will be hidden. */
    hideHelp?: boolean,
    title?: string,
}

const RegexField = (props: RegexFieldProps) => {
    const { id, label, onChange, regexValue, alwaysUseRegex, hideHelp, title } = props;

    const { t } = useTranslation();
    const deploymentConfigInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);

    /** If regex mode is OFF display current value as "plain text" converted from regex */
    const [isRegexModeOn, setRegexModeOn] = useState(false);

    const [isRegexModalOpen, setRegexModalOpen] = useState(false);

    /** Is user allowed to use plain text mode? */
    const [canTurnRegexModeOff, setCanTurnRegexModeOff] = useState(true);
    const [openRegexConfirmModal, setOpenRegexConfirmModal] = useState<boolean>(false);

    const [internalValue, setInternalValue] = useState<string>('');

    // apply changes from the base regexValue coming from outside this
    // component (including when this component is initialized)
    useEffect(() => {
        const initialValue = regexValue || '';
        const literalText = regexToLiteralText(initialValue);

        // force component into regex mode if initial value cannot be converted
        // into plain text or the component is set to always be in regex mode
        // (but only perform any changes if the value got actually changed)
        if (alwaysUseRegex || literalText === null) {
            // don't allow literal text mode -- force regex mode on
            if (initialValue !== internalValue) {
                setInternalValue(initialValue);
                setRegexModeOn(true);
                setCanTurnRegexModeOff(false);
            }
        } else {
            // literal text mode is allowed -- use whatever mode user is already using
            // (or whatever is the component default when initializing)
            if (literalText !== internalValue) {
                setCanTurnRegexModeOff(true);
                setInternalValue(isRegexModeOn ? initialValue : literalText);
            }
        }
    }, [regexValue]);

    // on immediateChange we should update the internal value and assess if
    // regex mode can be enabled or disabled from now on
    const handleImmediateChange = useCallback((value: string) => {
        if (isRegexModeOn) {
            const literalText = regexToLiteralText(value);
            setCanTurnRegexModeOff(literalText !== null);
        }

        setInternalValue(value);
    }, [isRegexModeOn]);

    // on debouncedChange we should propagate changes back up to parent component
    const handleDebouncedChange = useCallback((value: string) => {
        const regexValue = isRegexModeOn ? value : literalTextToRegex(value);
        onChange(regexValue);

    }, [onChange, isRegexModeOn, internalValue]);

    const handleTurnRegexModeOn = useCallback(() => {
        if (!isRegexModeOn) {
            setRegexModeOn(true);
            const regexValue = literalTextToRegex(internalValue);
            setInternalValue(regexValue);
        }
    }, [isRegexModeOn, internalValue]);

    const handleTurnRegexModeOff = useCallback(() => {
        if (isRegexModeOn) {
            setRegexModeOn(false);
            const literalText = regexToLiteralText(internalValue);
            setInternalValue(literalText || '');
        }
    }, [isRegexModeOn, internalValue]);

    const handleAcknowledgeRegexUsage = useCallback(() => {
        setHasAcknowledgedRegexServiceDisclaimer(true);
        setOpenRegexConfirmModal(false);
    }, []);

    const handleDoNotAcknowledgeRegexUsage = useCallback(() => {
        handleTurnRegexModeOff();
        setOpenRegexConfirmModal(false);
    }, [handleTurnRegexModeOff]);

    const handleToggleRegexEnabled = useCallback(() => {

        if (!isRegexModeOn) {
            // ask user to acknowledge enabling regex mode when they first try to turn it on
            const acknowledgedRegexServiceDisclaimer = getHasAcknowledgedRegexServiceDisclaimer();
            if (!acknowledgedRegexServiceDisclaimer) {
                setOpenRegexConfirmModal(true);
            }
        }

        if (isRegexModeOn) {
            handleTurnRegexModeOff();
        } else {
            handleTurnRegexModeOn();
        }

    }, [isRegexModeOn, handleTurnRegexModeOff, handleTurnRegexModeOn]);

    const handleOpenRegexModal = useCallback(() => {
        setRegexModalOpen(true);
    }, []);

    const handleCloseRegexModal = useCallback(() => {
        setRegexModalOpen(false);
    }, []);

    const handleConfirmModalChanges = useCallback((value: string) => {
        handleImmediateChange(value);
        handleDebouncedChange(value);
    }, [handleImmediateChange, handleDebouncedChange]);

    const regexRef = React.createRef<HTMLDivElement>();

    return (
        <>
            <Stack className={isRegexModeOn ? 'regex-input' : ''}>
                <StackItem>
                    <FormGroup
                        label={label}
                        fieldId={`regex-field-for-${id}`}
                        title={title}
                    // validated={modelValidation.GetNameValidationState(structureCustomization.name)}
                    // helperTextInvalid={modelValidation.GetNameValidationText(structureCustomization.name)}
                    // helperTextInvalidIcon={<ExclamationCircleIcon />}
                    >
                        <InputGroup ref={regexRef}>
                            <InputGroupItem isFill>
                                <DebouncedTextInput
                                    fieldId={`regex-field-for-${id}`}
                                    onDebouncedChange={handleDebouncedChange}
                                    onBlur={handleDebouncedChange}
                                    onImmediateChange={handleImmediateChange}
                                    className={`input-field ${isRegexModeOn ? 'regex-input' : ''}`}
                                    defaultValue={internalValue}
                                    debounceTimeMs={REGEX_DEBOUNCE_TIME_MS}
                                />
                            </InputGroupItem>
                            {isRegexModeOn && (<AppendedButton onClick={handleOpenRegexModal} />)}
                            {alwaysUseRegex && !hideHelp && (
                                <InputGroupItem>
                                    <RegexHelp deploymentConfigInfo={deploymentConfigInfo} appendTo={regexRef.current} />
                                </InputGroupItem>
                            )}
                        </InputGroup>
                    </FormGroup>
                </StackItem>
                <StackItem>
                    <Level>
                        {!alwaysUseRegex &&
                            <LevelItem>
                                <Checkbox
                                    label={t('regex.enableRegEx')}
                                    isChecked={isRegexModeOn}
                                    onChange={handleToggleRegexEnabled}
                                    id={`dicom-attribute-rule-regex-toggle-${id}`}
                                    isDisabled={!canTurnRegexModeOff}
                                />
                            </LevelItem>}
                        {!alwaysUseRegex && !hideHelp &&
                            <LevelItem className="regex-help">
                                <RegexHelp deploymentConfigInfo={deploymentConfigInfo} />
                            </LevelItem>}
                    </Level>
                </StackItem>
            </Stack>

            <Modal
                variant={ModalVariant.small}
                title={t('regex.disclaimer')}
                isOpen={!alwaysUseRegex && openRegexConfirmModal}
                showClose
                onClose={handleDoNotAcknowledgeRegexUsage}
                actions={[
                    <Button key="confirmDisclaimer" variant="primary" onClick={handleAcknowledgeRegexUsage}>{t('regex.disclaimer.acknowledge')}</Button>,
                    <Button key="cancelDisclaimer" variant="tertiary" onClick={handleDoNotAcknowledgeRegexUsage}>{t('common.cancel')}</Button>
                ]}
            >
                <Text className='regex-disclaimer-modal-upper-text'>{t('regex.disclaimer.beforeUsingRegex')}:</Text>
                <List>
                    <ListItem className='regex-disclaimer-modal-list-item'>{t('regex.disclaimer.incorrectSyntax')}</ListItem>
                    <ListItem className='regex-disclaimer-modal-list-item'>{t('regex.disclaimer.contactSupport')}</ListItem>
                    <ListItem className='regex-disclaimer-modal-list-item'>{t('regex.disclaimer.validationText')}</ListItem>
                </List>
            </Modal>

            <RegexFieldModal
                isOpen={isRegexModeOn && isRegexModalOpen}
                regex={internalValue}
                onConfirm={handleConfirmModalChanges}
                onClose={handleCloseRegexModal}
            />
        </>
    );
}

export default memo(RegexField);
