import { assertArray, assertExpectedProps, assertObjType, PropType } from "../../util/expected-prop";
import { createNewModelCustomizationBase, generateNewId, UI_ID_ATTRIBUTE } from "../global-types/customization-helpers";
import { CustomizationBase, DEFAULT_INTERPRETED_TYPE, isAeTitleRule, isDicomRule, METADATA_FILENAME_ATTRIBUTE, ModelType, OutputMetadataItem } from "../global-types/customization-types";
import { createLength, getLengthInMm, LengthUnit, LengthValue } from "../global-types/units";
import { _convertOutputMetadataViewModelToJson, convertCustomizationJsonObjectToViewModels, convertCustomizationViewModelsToJson, CustomizationBaseExtractResult, extractOutputMetadata, ModelTriggersByCustomizationNames } from "../global-types/view-model-conversion-helpers";
import { AdaptCustomizationEntities, AdaptCustomizationEntitiesForExport, AdaptCustomizationOutput, AdaptExpectedResult, AdaptRoiRule, AdaptRoiSelection, SelectionInclusion } from "./adapt-types";


export const createNewAdaptCustomizationOutput = (
    metadataIds: string[],
    roiSelection: AdaptRoiSelection,
    expectedResult: AdaptExpectedResult,
    pixelSpacing: LengthValue[],
    smoothingSigma: number | null,
    includeValidMask: boolean,
    customizationBaseId: string,
    filename: string | undefined,
    id?: string,
    isModified?: boolean): AdaptCustomizationOutput => {
    return {
        id: id || generateNewId(),
        metadata: metadataIds,

        roiSelection,
        expectedResult,
        pixelSpacing,
        smoothingSigma,
        isValidMaskIncluded: includeValidMask,

        modelCustomizationBaseId: customizationBaseId,
        filename: filename || '',
        isModified: isModified || false,
    }
}

export const createNewAdaptRoiSelection = (isEnabled: boolean, inclusion: SelectionInclusion, roiRules: string[]): AdaptRoiSelection => {
    return {
        isEnabled,
        inclusion,
        roiRules,
    };
}


export const createNewAdaptRoiRule = (nameRegExp: string, interpretedType: string, isEnabled: boolean,
    outputId?: string, id?: string, isModified?: boolean): AdaptRoiRule => {
    return {
        id: id || generateNewId(),

        nameRegExp,
        interpretedType,
        isEnabled,

        outputId: outputId,
        isModified: isModified || false,
    };
}

export const createNewAdaptExpectedResult = (
    isRegFileReturned: boolean,
    isRegisteredScanReturned: boolean,
    isRTDoseReturned: boolean,
    isRTPlanReturned: boolean,
    isRTStructReturned: boolean,
    areResultsCompressedAsZip: boolean,
    areInputsReturned: boolean,
): AdaptExpectedResult => {
    return {
        isRegFileReturned,
        isRegisteredScanReturned,
        isRTDoseReturned,
        isRTPlanReturned,
        isRTStructReturned,
        areResultsCompressedAsZip,
        areInputsReturned,
    };
}


export const defaultCreateNewAdaptRoiSelection = (isIncluded?: boolean, roiRules?: string[]): AdaptRoiSelection => {
    // both isIncluded and roiRules must be defined for roi selection to be enabled
    const isEnabledVal = isIncluded !== undefined && roiRules !== undefined;

    // use defaults if values have not been set
    const inclusionVal = isIncluded !== undefined ? getSelectionInclusionFromIncludedBoolean(isIncluded) : SelectionInclusion.Include;
    const roiRulesVal = roiRules !== undefined ? roiRules : [];

    return createNewAdaptRoiSelection(isEnabledVal, inclusionVal, roiRulesVal);
}

export const defaultCreateNewAdaptRoiRule = (outputId: string, nameRegExp?: string, interpretedType?: string, isEnabled?: boolean): AdaptRoiRule => {
    return createNewAdaptRoiRule(
        nameRegExp || '',
        interpretedType || DEFAULT_INTERPRETED_TYPE,
        isEnabled !== undefined ? isEnabled : true,
        outputId,
        undefined,
        true
    );
}

export const getSelectionInclusionFromIncludedBoolean = (isIncluded: boolean): SelectionInclusion => {
    return isIncluded ? SelectionInclusion.Include : SelectionInclusion.Exclude;
}



/** Convert adapt customization JSON from config API into flattened internal view model representation.
 * 
 * Regarding sorting: if any sorting is done, it's generally done to try to ensure order by original
 * name using naturalSort. Dynamic sorting during user operation is generally not wanted as it could
 * erratically shift the position of the element on the page. Set the 'noSorting' prop to 'true' if sorting
 * is not wanted (e.g. for roundtrip unit tests).
 */
export const convertAdaptJsonObjectToViewModels = (jsonObject: any, noSorting: boolean = false): AdaptCustomizationEntities => {

    const results = convertCustomizationJsonObjectToViewModels(jsonObject, ModelType.Adapt, noSorting);

    // return converted results with following notes:
    // - any undefined items are treated as empty arrays (in an unlikely case there's e.g. no selection rules at all)
    // - ignore any fields related to other algorithms (although if there were anything like that present then 
    //   the earlier conversion function should have already thrown)
    return {
        models: results.models || [],
        customizationBases: results.customizationBases || [],

        adaptOutputs: results.adaptOutputs || [],
        outputMetadata: results.outputMetadata || [],

        adaptRoiRules: results.adaptRoiRules || [],

        aeTitleRules: results.aeTitleRules || [],
        dicomRules: results.dicomRules || [],
        dicomAttributeRules: results.dicomAttributeRules || [],
    }
}


/**
 * Helper function for converting a customization base and all its outputs and other child objects into adapt+ customization entities.
 * You shouldn't need to call this function manually.
 */
export const _convertAdaptCustomizationJsonToViewModel = (
    modelCustomizationBaseResult: CustomizationBaseExtractResult,
    modelId: string,
    adaptOutputCollection: AdaptCustomizationOutput[],
    outputMetadataCollection: OutputMetadataItem[],
    adaptRoiRuleCollection: AdaptRoiRule[],
    modelTriggersByCustomizationNames: ModelTriggersByCustomizationNames,
    assertMessage: string,
    noSorting: boolean,
): CustomizationBase => {
    const { customizationId, customizationName, customizationDescription, dtoOutputs } = modelCustomizationBaseResult;

    // each 'file' will become its own customization output object
    const outputs: AdaptCustomizationOutput[] = dtoOutputs.map((customizationOutputEntry: any) => {

        // assert that output entries look correct
        assertExpectedProps(customizationOutputEntry, [
            { propName: 'metadata', propType: PropType.Array },
            { propName: 'roi_selection', propType: PropType.Object, allowNull: true },
            { propName: 'expected_result', propType: PropType.Object },
            { propName: 'pixel_spacing', propType: PropType.Array },
            { propName: 'smoothing_sigma', propType: PropType.Number, allowNull: true },
            { propName: 'include_valid_mask', propType: PropType.Boolean },
        ], assertMessage);

        // extract metadata
        const metadata = extractOutputMetadata(customizationOutputEntry, assertMessage);

        // extract adapt ROI selection
        const roiSelectionEntry = customizationOutputEntry['roi_selection'];
        let roiSelection: AdaptRoiSelection;
        if (roiSelectionEntry === null) {
            roiSelection = defaultCreateNewAdaptRoiSelection();
        } else {
            assertExpectedProps(roiSelectionEntry, [
                { propName: 'included', propType: PropType.Boolean },
                { propName: 'roi_rules', propType: PropType.Array },
            ]);

            // extract adapt ROI rules
            const roiRules: AdaptRoiRule[] = roiSelectionEntry['roi_rules'].map((roiRuleEntry: any) => {
                // assert that roi rule entries look correct
                assertExpectedProps(roiRuleEntry, [
                    { propName: 'enabled', propType: PropType.Boolean },
                    { propName: 'name', propType: PropType.String },
                    { propName: 'interpreted_type', propType: PropType.String },
                ], assertMessage);

                return createNewAdaptRoiRule(
                    roiRuleEntry['name'],
                    roiRuleEntry['interpreted_type'],
                    roiRuleEntry['enabled'],
                );
            });

            adaptRoiRuleCollection.push(...roiRules);
            const roiRuleIds = roiRules.map(r => r.id);

            roiSelection = defaultCreateNewAdaptRoiSelection(roiSelectionEntry['included'], roiRuleIds);
        }

        // extract expected results
        const expectedResultEntry = customizationOutputEntry['expected_result'];
        assertExpectedProps(expectedResultEntry, [
            { propName: 'reg', propType: PropType.Boolean },
            { propName: 'registered_scan', propType: PropType.Boolean },
            { propName: 'rtdose', propType: PropType.Boolean },
            { propName: 'rtplan', propType: PropType.Boolean },
            { propName: 'rtstruct', propType: PropType.Boolean },
            { propName: 'zip', propType: PropType.Boolean },
            { propName: 'inputs', propType: PropType.Boolean },
        ], assertMessage);
        const expectedResult: AdaptExpectedResult = createNewAdaptExpectedResult(
            expectedResultEntry['reg'],
            expectedResultEntry['registered_scan'],
            expectedResultEntry['rtdose'],
            expectedResultEntry['rtplan'],
            expectedResultEntry['rtstruct'],
            expectedResultEntry['zip'],
            expectedResultEntry['inputs'],
        );

        // extract pixel spacing
        assertArray(customizationOutputEntry['pixel_spacing'], PropType.Number);
        const pixelSpacing: LengthValue[] = customizationOutputEntry['pixel_spacing'].map(pixelSpacingEntry => createLength(pixelSpacingEntry, LengthUnit.LengthInMm));

        // extract smoothing sigma
        const smoothingSigmaEntry = customizationOutputEntry['smoothing_sigma'];
        let smoothingSigma: number | null;
        if (smoothingSigmaEntry === null) {
            smoothingSigma = null;
        } else {
            assertObjType(smoothingSigmaEntry, PropType.Number);
            smoothingSigma = smoothingSigmaEntry;
        }

        // collect ids
        const metadataIds = metadata.map(m => m.id);
        const filename = metadata.find(m => m.attribute === METADATA_FILENAME_ATTRIBUTE)?.value;

        // construct output

        const output = createNewAdaptCustomizationOutput(
            metadataIds,
            roiSelection,
            expectedResult,
            pixelSpacing,
            smoothingSigma,
            customizationOutputEntry['include_valid_mask'],
            customizationId,
            filename,
        );

        // update parent ids to child objects & 
        // add newly created objects into collections that will eventually be put into redux store
        metadata.forEach(m => m.modelCustomizationOutputId = output.id);
        outputMetadataCollection.push(...metadata);

        if (roiSelection !== null) {
            roiSelection.roiRules.forEach(rId => adaptRoiRuleCollection.find(r => r.id === rId)!.outputId = output.id);
        }

        return output;
    });

    const outputIds = outputs.map(o => o.id);
    const matchingTriggers = modelTriggersByCustomizationNames[customizationName] || [];
    const aeTitleRules = matchingTriggers.filter(isAeTitleRule);
    const dicomRules = matchingTriggers.filter(isDicomRule);
    const aeTitleRuleIds = aeTitleRules.map(t => t.id);
    const dicomRuleIds = dicomRules.map(t => t.id);
    const customization = createNewModelCustomizationBase(customizationName, customizationDescription, outputIds, aeTitleRuleIds, dicomRuleIds, modelId, customizationId);

    // update matching customization IDs now that we have a customization object
    matchingTriggers.forEach(t => t.modelCustomizationBaseId = customization.id);

    // add newly created objects into collections that will eventually be put into redux store
    adaptOutputCollection.push(...outputs);

    return customization;
}

/** Convert adapt customization view models to JSON presentation.
 * @param adaptEntities The adapt customization view model entities to convert to JSON.
 * @param noUiId Internal IDs for the view models needed for UI functionality will not be included in the export if true, and will be included
 * if set to false. Defaults to false. Including internal IDs is needed when sending data to backend to be able to cross-reference received
 * errors to their matching UI view models, but these IDs should be omitted when this JSON will be user-facing (e.g. when exported directly
 * as a downloaded file) or when running certain unit tests (e.g. round-trip tests will break if extra UI ID fields are present).
 * @returns An array of model JSON objects, ready to be sent to backend.
 */
export const convertAdaptViewModelsToJson = (adaptEntities: Partial<AdaptCustomizationEntitiesForExport>, noUiId: boolean = false): any => {

    const jsonModels = convertCustomizationViewModelsToJson(adaptEntities, ModelType.Adapt, noUiId);

    return jsonModels;
}

/**
 * Convert internal view model representation of adapt output objects and related children
 * into a model customization JSON hierarchy. You shouldn't need to call this manually.
 * @param jsonCustomization Result JSON will be embedded into this JSON.
 * @param output Adapt output view model to convert to JSON.
 * @param outputMetadata Collection of output metadata related to this customization set.
 * @param errorMessage Error message to throw on failure.
 * @param noUiId Marks if internal UI IDs should be included in converted JSON.
 */
export const _convertAdaptCustomizationOutputToJson = (
    jsonCustomization: any,
    output: AdaptCustomizationOutput,
    outputMetadata: OutputMetadataItem[],
    adaptRoiRules: AdaptRoiRule[],
    errorMessage: string,
    noUiId: boolean = false) => {

    if (output === undefined) { throw new Error(errorMessage); }

    const jsonOutput: any = {
        'metadata': [],
        'roi_selection': output.roiSelection.isEnabled ? {
            'included': output.roiSelection.inclusion === SelectionInclusion.Include,
            'roi_rules': []
        } : null,
        'expected_result': {
            'reg': output.expectedResult.isRegFileReturned,
            'registered_scan': output.expectedResult.isRegisteredScanReturned,
            'rtdose': output.expectedResult.isRTDoseReturned,
            'rtplan': output.expectedResult.isRTPlanReturned,
            'rtstruct': output.expectedResult.isRTStructReturned,
            'zip': output.expectedResult.areResultsCompressedAsZip,
            'inputs': output.expectedResult.areInputsReturned,
        },
        'pixel_spacing': [],
        'smoothing_sigma': output.smoothingSigma,
        'include_valid_mask': output.isValidMaskIncluded,
    };
    if (!noUiId) { jsonOutput[UI_ID_ATTRIBUTE] = output.id; }

    for (const metadataId of output.metadata) {
        const metadata = outputMetadata.find(m => m.id === metadataId);
        if (metadata === undefined) { throw new Error(errorMessage); }
        _convertOutputMetadataViewModelToJson(jsonOutput, metadata, noUiId);
    }

    // convert pixel spacing
    for (const pixelSpacingValue of output.pixelSpacing) {
        jsonOutput['pixel_spacing'].push(getLengthInMm(pixelSpacingValue));
    }

    // convert roi selection rules
    if (output.roiSelection) {
        for (const roiRuleId of output.roiSelection.roiRules) {
            const roiRule = adaptRoiRules.find(r => r.id === roiRuleId);
            if (roiRule === undefined) { throw new Error(errorMessage); }
            const jsonRoiRule: any = {
                'enabled': roiRule.isEnabled,
                'name': roiRule.nameRegExp,
                'interpreted_type': roiRule.interpretedType
            };
            if (!noUiId) { jsonRoiRule[UI_ID_ATTRIBUTE] = roiRule.id; }
            jsonOutput['roi_selection']['roi_rules'].push(jsonRoiRule);
        }
    }

    jsonCustomization['files'].push(jsonOutput);
}
