import React, { PropsWithChildren } from 'react';
import { TextContent, Text, TextVariants, } from '@patternfly/react-core';

import SmallAccordionButton from './SmallAccordionButton';

import './customization-section.css';
import SectionLabel from './SectionLabel';

interface CustomizationSectionProps {
    label: string,
    collapsedMessage: string,
    isExpanded: boolean,
    isModified: boolean,
    isValid: boolean,
    itemCount?: number,
    onClick: () => void,
}

const CustomizationSection = (props: PropsWithChildren<CustomizationSectionProps>) => {

    const { children, label, collapsedMessage, isExpanded, isModified, isValid, itemCount, onClick } = props;

    return (
        <div className="customization-section">
            <TextContent>
                <Text component="h3">
                    <SmallAccordionButton
                        isActive={isExpanded}
                        label={(<SectionLabel
                            label={label}
                            isModified={isModified}
                            isValid={isValid}
                            itemCount={itemCount} />)}
                        onClick={onClick}
                        className="customization-section-accordion" />
                </Text>
            </TextContent>
            <div className={isExpanded ? '' : 'is-collapsed'}>
                {children}
            </div>
            {!isExpanded && (<div className="section-hint"><Text component={TextVariants.small}>{collapsedMessage}</Text></div>)}

        </div>
    )
}

export default CustomizationSection;
