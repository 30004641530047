import { ModelType } from "../../store/global-types/customization-types";

const configApiRoot = '/config';
const contouringApiRoot = '/contouring';
const doseApiRoot = '/dose';
const labelingApiPath = '/labeling';

const apiUrls = {
    // RESTful urls
    contouringConfig: configApiRoot + '/contouring',
    doseConfig: configApiRoot + '/dose',
    adaptConfig: configApiRoot + '/reg',
    imageConfig: configApiRoot + '/translation',
    daemonsConfig: configApiRoot + '/daemons',
    daemonConfig: configApiRoot + '/daemon',
    regenerateCredentials: configApiRoot + '/daemon/credentials',
    tokenAssociate: '/token',
    resetDaemonConfig: configApiRoot + '/reset-daemon',

    // other urls
    getClientListUrl: configApiRoot + '/get-user-list',
    getListActiveModelsUrl: configApiRoot + '/list-active-models',

    contouringLabeling: contouringApiRoot + labelingApiPath,
    doseLabeling: doseApiRoot + labelingApiPath,
    backendVersionUrl: '/build-info',

    getLicenseUrl: configApiRoot + '/get-licenses',
    // NOTE: saveLicenseUrl is only used in local mode
    saveLicenseUrl: configApiRoot + '/save-license',
    getAccessRightsUrl: '/access-rights',

    // see user-settings.tsx
    //  getAllUserSettingsUrl:  '/settings', 
    //  generateSaveSettingUrl:  (setting: string): > `${getAllUserSettingsUrl}/${setting}`, 

    // functions for creating dynamic URLs
    makeGetDefaultConfigForContourModelUrl: (modelName: string) => `${apiUrls.contouringConfig}/${modelName}`,
    makeGetDefaultConfigForDoseModelUrl: (modelName: string) => `${apiUrls.doseConfig}/${modelName}`,
}

export default apiUrls;

/** API userId field for 'default' configuration */
export const DEFAULT_USER_ID = 'default';

export const getLabelingUrl = (modelType: ModelType) => {
    switch (modelType) {
        case ModelType.Contouring:
            return apiUrls.contouringLabeling;
        case ModelType.Dose:
            return apiUrls.doseLabeling;

        default:
            throw new Error(`Unsupported model type ${modelType}`);
    }
}
