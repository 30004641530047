import { createEntityAdapter, createSelector, createSlice, current, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { get, has, isObject } from "lodash-es";

import { Model, CustomizationBase, OutputMetadataItem, AeTitleRule, DicomRule, DicomAttributeRule, ModelVisibility, SelectedDoseUnit, CustomizationObjectType, METADATA_FILENAME_ATTRIBUTE } from "../global-types/customization-types";
import { DoseCustomizationEntities, DoseCustomizationOutput, DoseCustomizationOutputEntities, DoseRoi, DoseScalingMethod, DoseTarget, TargetMethod } from "./dose-types";
import { BackendValidationErrorViewModel, FormValidationError } from "../global-types/store-errors";
import { naturalSort } from "../../util/sort";
import { DoseSliceWrapper } from "../global-types/modelTypeReducerHelper/doseSliceWrapper";
import { defaultCreateNewDoseTarget, getDefaultRegexForTargetMethod } from "./dose-helpers";
import { MVisionAppClient } from "../configurationTarget/mvision-client-list";
import { BackendValidationError } from "../../util/errors";
import { convertBackendValidationErrorToViewModels, DuplicatedIdMap, duplicateFormValidationErrors, getFormValidationErrorIdsForItem, getFormValidationErrorIdsForItems, setObjectAndAncestorsAsModified } from "../global-types/reducer-helpers";
import { LengthValue } from "../global-types/units";




// entity adapters (see https://redux-toolkit.js.org/api/createEntityAdapter) for dose customization types
export const modelAdapter = createEntityAdapter<Model>();
export const customizationBaseAdapter = createEntityAdapter<CustomizationBase>();
export const doseOutputAdapter = createEntityAdapter<DoseCustomizationOutput>();
export const outputMetadataAdapter = createEntityAdapter<OutputMetadataItem>();
export const doseRoiAdapter = createEntityAdapter<DoseRoi>();
export const doseTargetAdapter = createEntityAdapter<DoseTarget>();
export const aeTitleRuleAdapter = createEntityAdapter<AeTitleRule>();
export const dicomRuleAdapter = createEntityAdapter<DicomRule>();
export const dicomAttributeRuleAdapter = createEntityAdapter<DicomAttributeRule>();

// entity adapter selectors -- these should not be exported/used directly but rather through the slice selectors
export const modelAdapterSelectors = modelAdapter.getSelectors((state: DoseSliceState) => state.models);
export const customizationBaseAdapterSelectors = customizationBaseAdapter.getSelectors((state: DoseSliceState) => state.customizationBases);
export const doseOutputAdapterSelectors = doseOutputAdapter.getSelectors((state: DoseSliceState) => state.doseOutputs);
export const outputMetadataAdapterSelectors = outputMetadataAdapter.getSelectors((state: DoseSliceState) => state.outputMetadata);
export const doseRoiAdapterSelectors = doseRoiAdapter.getSelectors((state: DoseSliceState) => state.doseRois);
export const doseTargetAdapterSelectors = doseTargetAdapter.getSelectors((state: DoseSliceState) => state.doseTargets);
export const aeTitleRuleAdapterSelectors = aeTitleRuleAdapter.getSelectors((state: DoseSliceState) => state.aeTitleRules);
export const dicomRuleAdapterSelectors = dicomRuleAdapter.getSelectors((state: DoseSliceState) => state.dicomRules);
export const dicomAttributeRuleAdapterSelectors = dicomAttributeRuleAdapter.getSelectors((state: DoseSliceState) => state.dicomAttributeRules);

/** An entity adapter for CRUD operations for backend-based customization validation errors */
export const backendValidationErrorAdapter = createEntityAdapter<BackendValidationErrorViewModel>();
/** An entity adapter for CRUD operations for UI-supplied customization validation errors */
export const formValidationErrorAdapter = createEntityAdapter<FormValidationError>();

// selectors for 'original' data
export const originalOutputMetadataAdapterSelectors = outputMetadataAdapter.getSelectors((state: DoseSliceState) => state.originalOutputMetadata);
export const originalDoseRoiAdapterSelectors = doseRoiAdapter.getSelectors((state: DoseSliceState) => state.originalDoseRois);
export const originalDoseTargetAdapterSelectors = doseTargetAdapter.getSelectors((state: DoseSliceState) => state.originalDoseTargets);

// validation error selectors
export const customizationValidationErrorAdapterSelectors = backendValidationErrorAdapter.getSelectors((state: DoseSliceState) => state.backendValidationErrors);
export const formValidationErrorAdapterSelectors = formValidationErrorAdapter.getSelectors((state: DoseSliceState) => state.formValidationErrors);

export const isDoseSliceState = (obj: any): obj is DoseSliceState => {
    return has(obj, 'models') &&
        has(obj, 'doseOutputs') &&
        has(obj, 'doseRois') &&
        has(obj, 'doseTargets') &&
        has(obj, 'doseRois.entities') &&
        isObject(obj.doseRois.entities);
}

export type DoseSliceState = {
    /** In-memory CRUD-based normalized data structure for customization objects modelling dose models. */
    models: EntityState<Model, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization base objects. */
    customizationBases: EntityState<CustomizationBase, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization outputs. */
    doseOutputs: EntityState<DoseCustomizationOutput, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization metadata. */
    outputMetadata: EntityState<OutputMetadataItem, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling dose ROI customizations. */
    doseRois: EntityState<DoseRoi, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling dose targets. */
    doseTargets: EntityState<DoseTarget, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling AE Title-based model selection rules. */
    aeTitleRules: EntityState<AeTitleRule, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling DICOM-based model selection rules. */
    dicomRules: EntityState<DicomRule, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling DICOM attribute rules within DICOM-based model selection rules. */
    dicomAttributeRules: EntityState<DicomAttributeRule, string>,

    // these are for storing data directly from the backend, so any local modifications can be easily reverted back to them

    /** Original, unmodified directly-from-backend versions of dose model customization data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalModels: EntityState<Model, string>;
    /** Original, unmodified directly-from-backend versions of dose customization base data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalCustomizationBases: EntityState<CustomizationBase, string>;
    /** Original, unmodified directly-from-backend versions of dose customization output data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalDoseOutputs: EntityState<DoseCustomizationOutput, string>;
    /** Original, unmodified directly-from-backend versions of dose customization metadata objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalOutputMetadata: EntityState<OutputMetadataItem, string>;
    /** Original, unmodified directly-from-backend versions of dose ROI customization objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalDoseRois: EntityState<DoseRoi, string>;
    /** Original, unmodified directly-from-backend versions of dose target customization objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalDoseTargets: EntityState<DoseTarget, string>;
    /** Original, unmodified directly-from-backend versions of dose AE Title rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalAeTitleRules: EntityState<AeTitleRule, string>;
    /** Original, unmodified directly-from-backend versions of dose DICOM rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalDicomRules: EntityState<DicomRule, string>;
    /** Original, unmodified directly-from-backend versions of dose DICOM attribute rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalDicomAttributeRules: EntityState<DicomAttributeRule, string>;


    /** Dose customization validation errors coming from backend. */
    backendValidationErrors: EntityState<BackendValidationErrorViewModel, string>,
    /** Dose customization validation errors generated in UI (in "forms"). */
    formValidationErrors: EntityState<FormValidationError, string>,

    /** An error that occurred when fetching dose customizations, or null. */
    customizationFetchError: string | null,

    /** Saving of model customizations to backend is in progress if true, false otherwise. */
    isModelCustomizationSavingInProgress: boolean,

    /** True if resetting all dose customizations to factory defaults is currently in progress, false otherwise. */
    isAllModelCustomizationsResetInProgress: boolean,

    /** True if resetting a single dose customization output to factory defaults is currently in progress, false otherwise. */
    isOutputResetInProgress: boolean,

    /** Model customization save error, if any. */
    modelCustomizationSaveError: string | null,

    /** An error regarding model customization data that's not directly related to saving */
    modelCustomizationDataError: string | null,

    /** An error message regarding exporting or importing entire customization config to/from JSON */
    customizationImportExportError: string | null,
};





export const initialState: DoseSliceState = {
    models: modelAdapter.getInitialState(),
    customizationBases: customizationBaseAdapter.getInitialState(),
    doseOutputs: doseOutputAdapter.getInitialState(),
    outputMetadata: outputMetadataAdapter.getInitialState(),
    doseRois: doseRoiAdapter.getInitialState(),
    doseTargets: doseTargetAdapter.getInitialState(),
    aeTitleRules: aeTitleRuleAdapter.getInitialState(),
    dicomRules: dicomRuleAdapter.getInitialState(),
    dicomAttributeRules: dicomAttributeRuleAdapter.getInitialState(),

    originalModels: modelAdapter.getInitialState(),
    originalCustomizationBases: customizationBaseAdapter.getInitialState(),
    originalDoseOutputs: doseOutputAdapter.getInitialState(),
    originalOutputMetadata: outputMetadataAdapter.getInitialState(),
    originalDoseRois: doseRoiAdapter.getInitialState(),
    originalDoseTargets: doseTargetAdapter.getInitialState(),
    originalAeTitleRules: aeTitleRuleAdapter.getInitialState(),
    originalDicomRules: dicomRuleAdapter.getInitialState(),
    originalDicomAttributeRules: dicomAttributeRuleAdapter.getInitialState(),

    backendValidationErrors: backendValidationErrorAdapter.getInitialState(),
    formValidationErrors: formValidationErrorAdapter.getInitialState(),

    customizationFetchError: null,
    isModelCustomizationSavingInProgress: false,
    isAllModelCustomizationsResetInProgress: false,
    isOutputResetInProgress: false,
    modelCustomizationSaveError: null,
    modelCustomizationDataError: null,
    customizationImportExportError: null,

};



const sliceWrapper = new DoseSliceWrapper();


/** Redux store slice for interacting with dose+ configuration and customization. */
const doseSlice = createSlice({
    name: 'dose',
    initialState,
    reducers: {
        /**
         * Sets current dose model customization entities to provided values. This is used when initializing, swapping, or
         * resetting entire model customizations loaded into memory.
         * @param action.customizations All dose-related model customization entities to load into memory. If set to undefined then
         * in-model entities are reset to default empty values.
         * @param action.errorMessage Optional error message.
         */
        modelCustomizationsSet(state, action: PayloadAction<{ customizations: DoseCustomizationEntities | null, errorMessage?: string }>) {
            const { customizations, errorMessage } = action.payload;

            if (customizations !== null) {
                // set everything according to input
                modelAdapter.setAll(state.models, customizations.models);
                customizationBaseAdapter.setAll(state.customizationBases, customizations.customizationBases);
                doseOutputAdapter.setAll(state.doseOutputs, customizations.doseOutputs);
                outputMetadataAdapter.setAll(state.outputMetadata, customizations.outputMetadata);
                doseRoiAdapter.setAll(state.doseRois, customizations.doseRois);
                doseTargetAdapter.setAll(state.doseTargets, customizations.doseTargets);
                aeTitleRuleAdapter.setAll(state.aeTitleRules, customizations.aeTitleRules);
                dicomRuleAdapter.setAll(state.dicomRules, customizations.dicomRules);
                dicomAttributeRuleAdapter.setAll(state.dicomAttributeRules, customizations.dicomAttributeRules);

                // set original collections, also, so we can revert changes to the actual collection with ease
                modelAdapter.setAll(state.originalModels, customizations.models);
                customizationBaseAdapter.setAll(state.originalCustomizationBases, customizations.customizationBases);
                doseOutputAdapter.setAll(state.originalDoseOutputs, customizations.doseOutputs);
                outputMetadataAdapter.setAll(state.originalOutputMetadata, customizations.outputMetadata);
                doseRoiAdapter.setAll(state.originalDoseRois, customizations.doseRois);
                doseTargetAdapter.setAll(state.originalDoseTargets, customizations.doseTargets);
                aeTitleRuleAdapter.setAll(state.originalAeTitleRules, customizations.aeTitleRules);
                dicomRuleAdapter.setAll(state.originalDicomRules, customizations.dicomRules);
                dicomAttributeRuleAdapter.setAll(state.originalDicomAttributeRules, customizations.dicomAttributeRules);
            } else {
                // set everything to default values
                modelAdapter.setAll(state.models, modelAdapter.getInitialState().entities as Record<string, Model>);
                customizationBaseAdapter.setAll(state.customizationBases, customizationBaseAdapter.getInitialState().entities as Record<string, CustomizationBase>);
                doseOutputAdapter.setAll(state.doseOutputs, doseOutputAdapter.getInitialState().entities as Record<string, DoseCustomizationOutput>);
                outputMetadataAdapter.setAll(state.outputMetadata, outputMetadataAdapter.getInitialState().entities as Record<string, OutputMetadataItem>);
                doseRoiAdapter.setAll(state.doseRois, doseRoiAdapter.getInitialState().entities as Record<string, DoseRoi>);
                doseTargetAdapter.setAll(state.doseTargets, doseTargetAdapter.getInitialState().entities as Record<string, DoseTarget>);
                aeTitleRuleAdapter.setAll(state.aeTitleRules, aeTitleRuleAdapter.getInitialState().entities as Record<string, AeTitleRule>);
                dicomRuleAdapter.setAll(state.dicomRules, dicomRuleAdapter.getInitialState().entities as Record<string, DicomRule>);
                dicomAttributeRuleAdapter.setAll(state.dicomAttributeRules, dicomAttributeRuleAdapter.getInitialState().entities as Record<string, DicomAttributeRule>);

                // set original collections, also, so we can revert changes to the actual collection with ease
                modelAdapter.setAll(state.originalModels, modelAdapter.getInitialState().entities as Record<string, Model>);
                customizationBaseAdapter.setAll(state.originalCustomizationBases, customizationBaseAdapter.getInitialState().entities as Record<string, CustomizationBase>);
                doseOutputAdapter.setAll(state.originalDoseOutputs, doseOutputAdapter.getInitialState().entities as Record<string, DoseCustomizationOutput>);
                outputMetadataAdapter.setAll(state.originalOutputMetadata, outputMetadataAdapter.getInitialState().entities as Record<string, OutputMetadataItem>);
                doseRoiAdapter.setAll(state.originalDoseRois, doseRoiAdapter.getInitialState().entities as Record<string, DoseRoi>);
                doseTargetAdapter.setAll(state.originalDoseTargets, doseTargetAdapter.getInitialState().entities as Record<string, DoseTarget>);
                aeTitleRuleAdapter.setAll(state.originalAeTitleRules, aeTitleRuleAdapter.getInitialState().entities as Record<string, AeTitleRule>);
                dicomRuleAdapter.setAll(state.originalDicomRules, dicomRuleAdapter.getInitialState().entities as Record<string, DicomRule>);
                dicomAttributeRuleAdapter.setAll(state.originalDicomAttributeRules, dicomAttributeRuleAdapter.getInitialState().entities as Record<string, DicomAttributeRule>);
            }

            state.customizationFetchError = errorMessage !== undefined ? errorMessage : null;
            if (errorMessage === undefined) {
                // clear any errors
                backendValidationErrorAdapter.removeAll(state.backendValidationErrors);
                formValidationErrorAdapter.removeAll(state.formValidationErrors);

                // calculate initial form validation errors
                for (const outputId of state.doseOutputs.ids) {
                    sliceWrapper.performFormValidationForOutput(state, outputId);
                }
            }
        },

        /**
         * Updates a dose model's visibility.
         * @param action.modelId Internal (entity adapter) ID of the dose model to update.
         * @param action.visibility The new visibility setting for the dose model.
         */
        modelVisibilityUpdated(state, action: PayloadAction<{ modelId: string, visibility: ModelVisibility }>) {
            const { modelId, visibility } = action.payload;
            sliceWrapper.updateModelVisibility(state, modelId, visibility);
        },

        /**
        * Updates the description field on a customization base object.
        * @param action.customizationBaseId Internal (entity adapter) ID of the customization base to update.
        * @param action.description The new description to set.
        */
        modelCustomizationDescriptionUpdated(state, action: PayloadAction<{ customizationBaseId: string, description: string }>) {
            const { customizationBaseId, description } = action.payload;
            sliceWrapper.updateModelCustomizationDescription(state, customizationBaseId, description);
        },

        /**
         * Updates an output metadata item tuple. If either attribute or value is left as undefined then that particular prop is not updated
         * and the existing prop is left unchanged.
         * @param action.metadataId Internal (entity adapter) ID of the output metadata item that will be updated.
         * @param action.attribute The new attribute name of the metadata item that will be used, or undefined if attribute should not be changed.
         * @param action.value The new value of the metadata item, or undefined if value should not be changed.
         * @param action.isUndoOperation Marks this update as part of an undo operation. Note that the correct reverted attribute and value props
         * must be supplied to this function manually, this function does not perform an undo by itself. Optional, defaults to false.
         */
        metadataItemUpdated(state, action: PayloadAction<{ metadataId: string, attribute: string | undefined, value: string | undefined, isUndoOperation?: boolean }>) {
            const { metadataId, attribute, value, isUndoOperation } = action.payload;
            sliceWrapper.updateMetadataItem(state, metadataId, attribute, value, isUndoOperation);
        },

        /**
         * Removes an output metadata item.
         * @param action.metadataId Internal (entity adapter) ID of the output metadata item that will be removed.
         */
        metadataItemRemoved(state, action: PayloadAction<string>) {
            const metadataId = action.payload;
            sliceWrapper.removeMetadataItem(state, metadataId);
        },

        /**
         * Removes a tag from a (newly created) customization metadata item that would mark it as being the target of being
         * automatically scrolled to in UI.
         * 
         * This tag is generally given to metadata items after their creation in the UI and should be removed once
         * the UI has scrolled to the correct position in the UI once.
         * 
         * @param action Internal (entity adapter) ID of the customization metadata item where this tag is to be removed.
         */
        scrollToViewFromModelCustomizationMetadataRemoved(state, action: PayloadAction<string>) {
            const metadataId = action.payload;
            sliceWrapper.removeScrollToViewFromMetadata(state, metadataId);
        },

        /**
        * Adds supplied AE Title rule into dose customization configuration.
        * @param action The AE Title rule to add.
        */
        aeTitleRuleAdded(state, action: PayloadAction<AeTitleRule>) {
            const aeTitleRule = action.payload;
            sliceWrapper.addAeTitleRule(state, aeTitleRule);
        },

        /**
         * Updates the 'action' field in an AE Title rule.
         * @param action.id Internal (entity adapter) ID of the AE Title rule to update.
         * @param action.action The new action value for the rule.
         */
        aeTitleRuleActionUpdated(state, action: PayloadAction<{ id: string, action: string }>) {
            const { id, action: newAction } = action.payload;
            sliceWrapper.updateAeTitleRuleAction(state, id, newAction);
        },

        /**
         * Removes an AE Title rule from dose customization configuration.
         * Throws if the AE Title rule to be removed is not found from configuration.
         * @param action Internal (entity adapter) ID of the AE Title rule to remove.
         */
        aeTitleRuleRemoved(state, action: PayloadAction<string>) {
            const id = action.payload;
            sliceWrapper.removeAeTitleRule(state, id);
        },

        /**
         * Adds supplied DICOM rule into dose customization configuration.
         * Note that DICOM attribute rules under a DICOM rule must be added separately.
         * @param action The DICOM rule to add.
         */
        dicomRuleAdded(state, action: PayloadAction<DicomRule>) {
            const dicomRule = action.payload;
            sliceWrapper.addDicomRule(state, dicomRule);
        },

        /**
         * Removes a DICOM rule from dose customization configuration. Also removes any DICOM attribute
         * rules under the DICOM rule.
         * Throws if the DICOM rule to be removed is not found from configuration.
         * @param action Internal (entity adapter) ID of the DICOM rule to remove.
         */
        dicomRuleRemoved(state, action: PayloadAction<string>) {
            const id = action.payload;
            sliceWrapper.removeDicomRule(state, id);
        },

        /**
        * Adds supplied DICOM attribute rule into dose customization configuration.
        * Note that the parent DICOM rule must already exist in configuration or this function will throw.
        * @param action The DICOM attribute rule to add.
        */
        dicomAttributeRuleAdded(state, action: PayloadAction<DicomAttributeRule>) {
            const dicomAttributeRule = action.payload;
            sliceWrapper.addDicomAttributeRule(state, dicomAttributeRule);
        },

        /**
         * Removes a DICOM attribute rule from dose customization configuration.
         * Throws if the DICOM attribute rule to be removed or its parent DICOM rule are not found from configuration.
         * @param action Internal (entity adapter) ID of the DICOM attribute rule to remove.
         */
        dicomAttributeRuleRemoved(state, action: PayloadAction<string>) {
            const id = action.payload;
            sliceWrapper.removeDicomAttributeRule(state, id);
        },

        /**
         * Updates a DICOM attribute rule.
         * @param action.id Internal (entity adapter) ID of the DICOM attribute rule to update.
         * @param action.dicomAttribute The attribute field of the rule is updated to this value.
         * @param action.dicomValue The DICOM value field of the rule is updated to this value.
         */
        dicomAttributeRuleUpdated(state, action: PayloadAction<{ id: string, dicomAttribute: string, dicomValue: string }>) {
            const { id, dicomAttribute, dicomValue } = action.payload;
            sliceWrapper.updateDicomAttributeRule(state, id, dicomAttribute, dicomValue);
        },

        /**
         * Updates a dose output's dose scaling method.
         * @param action.id ID of the dose output.
         * @param action.method The value to set the dose scaling's method field to.
         */
        doseScalingMethodSet(state, action: PayloadAction<{ id: string, method: DoseScalingMethod }>) {
            const { id, method } = action.payload;

            const output = state.doseOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${id}`); }

            let doseScaling = output.doseScaling;
            doseScaling.method = method;
            doseOutputAdapter.updateOne(state.doseOutputs, { id: id, changes: { doseScaling } });
            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Updates a dose output's dose scaling's volume field.
         * @param action.id ID of the dose output.
         * @param action.volume The value to set the volume field to.
         */
        doseScalingVolumeSet(state, action: PayloadAction<{ id: string, volume: number }>) {
            const { id, volume } = action.payload;

            const output = state.doseOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${id}`); }

            let doseScaling = output.doseScaling;
            doseScaling.volume = volume;
            doseOutputAdapter.updateOne(state.doseOutputs, { id: id, changes: { doseScaling } });
            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Updates a dose output's dose scaling's dose field.
         * @param action.id ID of the dose output.
         * @param action.dose The value to set the dose field to.
         */
        doseScalingDoseSet(state, action: PayloadAction<{ id: string, dose: number }>) {
            const { id, dose } = action.payload;

            const output = state.doseOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${id}`); }

            let doseScaling = output.doseScaling;
            doseScaling.dose = dose;
            doseOutputAdapter.updateOne(state.doseOutputs, { id: id, changes: { doseScaling } });
            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Updates a dose output's pixel spacing.
         * @param action.id ID of the dose output.
         * @param pixelSpacing The value to set pixel spacing to.
         */
        pixelSpacingSet(state, action: PayloadAction<{ id: string, pixelSpacing: LengthValue }>) {
            const { id, pixelSpacing } = action.payload;

            const output = state.doseOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${id}`); }

            doseOutputAdapter.updateOne(state.doseOutputs, { id: id, changes: { pixelSpacing } });
            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Sets a dose output's dose cropping to on or off.
         * @param action.id ID of the dose output.
         * @param action.isEnabled The value to set the isEnabled field to.
         */
        doseCroppingIsEnabledSet(state, action: PayloadAction<{ id: string, isEnabled: boolean }>) {
            const { id, isEnabled } = action.payload;

            const output = state.doseOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${id}`); }

            let doseCropping = output.doseCropping;
            doseCropping.isEnabled = isEnabled;
            doseOutputAdapter.updateOne(state.doseOutputs, { id: id, changes: { doseCropping: doseCropping } });
            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Sets a dose output's Z-margin cropping value.
         * @param action.id ID of the dose output.
         * @param action.value The value to set the z-margin cropping to.
         */
        doseCroppingValueSet(state, action: PayloadAction<{ id: string, zMargin: LengthValue }>) {
            const { id, zMargin } = action.payload;

            const output = state.doseOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${id}`); }

            let doseCropping = output.doseCropping;
            doseCropping.zMargin = zMargin;
            doseOutputAdapter.updateOne(state.doseOutputs, { id: id, changes: { doseCropping: doseCropping } });
            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Sets whether or not RT Plan is included with returned dose.
         * @param action.id ID of the dose output.
         * @param action.isIncluded The value to set the isRTPlanIncluded field to.
         */
        outputIsRTPlanIncludedSet(state, action: PayloadAction<{ id: string, isIncluded: boolean }>) {
            const { id, isIncluded } = action.payload;

            const output = state.doseOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${id}`); }

            doseOutputAdapter.updateOne(state.doseOutputs, { id: id, changes: { isRTPlanIncluded: isIncluded } });
            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Sets whether or not RT Plan beam sequence is included with returned dose.
         * @param action.id ID of the dose output.
         * @param action.isIncluded The value to set the isBeamIncluded field to.
         */
        outputIsBeamIncludedSet(state, action: PayloadAction<{ id: string, isIncluded: boolean }>) {
            const { id, isIncluded } = action.payload;

            const output = state.doseOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${id}`); }

            doseOutputAdapter.updateOne(state.doseOutputs, { id: id, changes: { isBeamIncluded: isIncluded } });
            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Sets dose output's machine type.
         * @param action.id ID of the dose output.
         * @param action.machineType The value to set the machineType field to.
         */
        outputMachineTypeSet(state, action: PayloadAction<{ id: string, machineType: string }>) {
            const { id, machineType } = action.payload;

            const output = state.doseOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${id}`); }

            doseOutputAdapter.updateOne(state.doseOutputs, { id: id, changes: { machineType } });
            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Sets dose output's machine name.
         * @param action.id ID of the dose output.
         * @param action.machineType The value to set the machineName field to.
         */
        outputMachineNameSet(state, action: PayloadAction<{ id: string, machineName: string }>) {
            const { id, machineName } = action.payload;

            const output = state.doseOutputs.entities[id];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${id}`); }

            doseOutputAdapter.updateOne(state.doseOutputs, { id: id, changes: { machineName } });
            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, id);
        },

        /**
         * Sets a dose ROI's name.
         * @param action.id ID of the dose ROI.
         * @param action.name The value to set the name to.
         */
        doseRoiNameSet(state, action: PayloadAction<{ id: string, name: string }>) {
            const { id, name } = action.payload;

            const roi = state.doseRois.entities[id];
            if (roi === undefined) { throw new Error(`Could not find dose ROI with id ${id}`); }

            doseRoiAdapter.updateOne(state.doseRois, { id: id, changes: { name } });
            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.Roi);
            if (roi.outputId) {
                sliceWrapper.performFormValidationForOutput(state, roi.outputId);
            }
        },

        /**
         * Sets a dose ROI's regular expression (match pattern).
         * @param action.id ID of the dose ROI.
         * @param action.regExp The value to set the regExp field to.
         */
        doseRoiRegExpSet(state, action: PayloadAction<{ id: string, regExp: string }>) {
            const { id, regExp } = action.payload;

            const roi = state.doseRois.entities[id];
            if (roi === undefined) { throw new Error(`Could not find dose ROI with id ${id}`); }

            doseRoiAdapter.updateOne(state.doseRois, { id: id, changes: { regExp } });
            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.Roi);
            if (roi.outputId) {
                sliceWrapper.performFormValidationForOutput(state, roi.outputId);
            }
        },

        /**
         * Reverts a dose ROI to its original values (if available).
         * @param action ID of the dose ROI.
         */
        doseRoiChangesReverted(state, action: PayloadAction<string>) {
            const roiId = action.payload;

            const originalRoi = state.originalDoseRois.entities[roiId];
            if (originalRoi) {
                doseRoiAdapter.upsertOne(state.doseRois, { ...originalRoi, });

                // remove any validation errors
                backendValidationErrorAdapter.removeOne(state.backendValidationErrors, roiId);
                formValidationErrorAdapter.removeMany(state.formValidationErrors, getFormValidationErrorIdsForItem(state, roiId));

                // re-validate form changes after partial data in an output just got undoed
                const roi = state.doseRois.entities[roiId];
                const output = roi?.outputId ? state.doseOutputs.entities[roi.outputId] : undefined;
                if (output) {
                    sliceWrapper.performFormValidationForOutput(state, output.id);
                }
            }
        },

        /**
         * Sets target method for dose output.
         * @param action.outputId ID of the dose output.
         * @param action.targetMethod The value to set the method field to.
         */
        doseTargetMethodSet(state, action: PayloadAction<{ outputId: string, targetMethod: TargetMethod }>) {
            const { outputId, targetMethod } = action.payload;

            const output = state.doseOutputs.entities[outputId];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${outputId}`); }

            // Automatic swapping between target regexes no longer makes sense now that the default regex for
            // fixed method is an empty string. Code left here for posterity in case this changes in near future.
            //
            // const isMethodChanged = output.targetMethod !== targetMethod &&
            //     output.targetMethod !== TargetMethod.NotSet &&
            //     targetMethod !== TargetMethod.NotSet;

            // if (isMethodChanged) {
            //     // change all default regexes to the other default regex
            //     const previousDefaultRegex = getDefaultRegexForTargetMethod(output.targetMethod);
            //     const newDefaultRegex = getDefaultRegexForTargetMethod(targetMethod);

            //     for (const targetId of output.targets) {
            //         const target = state.doseTargets.entities[targetId];
            //         if (target === undefined) { throw new Error(`Could not find dose target with id ${targetId}`); }
            //         if (target.regExp === previousDefaultRegex) {
            //             doseTargetAdapter.updateOne(state.doseTargets, { id: targetId, changes: { regExp: newDefaultRegex, isModified: true } });
            //         }
            //     }
            // }

            doseOutputAdapter.updateOne(state.doseOutputs, { id: outputId, changes: { targetMethod: targetMethod } });

            setObjectAndAncestorsAsModified(state, [outputId], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, outputId);
        },

        /**
         * Sets dose unit for dose output's targets.
         * @param action.id ID of the dose output.
         * @param action.doseUnit The value to set the unit field to.
         */
        doseTargetUnitSet(state, action: PayloadAction<{ outputId: string, doseUnit: SelectedDoseUnit }>) {
            const { outputId, doseUnit } = action.payload;

            const output = state.doseOutputs.entities[outputId];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${outputId}`); }

            doseOutputAdapter.updateOne(state.doseOutputs, { id: outputId, changes: { targetUnit: doseUnit } })

            setObjectAndAncestorsAsModified(state, [outputId], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, outputId);
        },

        /**
         * Sets a dose target's ROI name.
         * @param action.id ID of the dose target.
         * @param action.regExp The value to set the roiName field to.
         */
        doseTargetRoiNameSet(state, action: PayloadAction<{ id: string, roiName: string }>) {
            const { id, roiName } = action.payload;

            const target = state.doseTargets.entities[id];
            if (target === undefined) { throw new Error(`Could not find dose target with id ${id}`); }

            doseTargetAdapter.updateOne(state.doseTargets, { id: id, changes: { roiName } });
            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.Target);
            if (target.outputId) {
                sliceWrapper.performFormValidationForOutput(state, target.outputId);
            }
        },

        /**
         * Sets a dose target's regular expression (match pattern).
         * @param action.id ID of the dose target.
         * @param action.regExp The value to set the regExp field to.
         */
        doseTargetRegExpSet(state, action: PayloadAction<{ id: string, regExp: string }>) {
            const { id, regExp } = action.payload;

            const target = state.doseTargets.entities[id];
            if (target === undefined) { throw new Error(`Could not find dose target with id ${id}`); }

            doseTargetAdapter.updateOne(state.doseTargets, { id: id, changes: { regExp } });
            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.Target);
            if (target.outputId) {
                sliceWrapper.performFormValidationForOutput(state, target.outputId);
            }
        },

        /**
         * Sets a dose target's prescription.
         * @param action.id ID of the dose target.
         * @param action.doseValue The dose value to set the prescription field to. The unit defined in the parent output
         * object will be used.
         */
        doseTargetPrescriptionSet(state, action: PayloadAction<{ id: string, doseValue: number | null }>) {
            const { id, doseValue } = action.payload;

            const target = state.doseTargets.entities[id];
            if (target === undefined) { throw new Error(`Could not find dose target with id ${id}`); }

            if (target.outputId === undefined) { throw new Error('Target does not have parent output defined.'); }

            const output = state.doseOutputs.entities[target.outputId];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${target.outputId}`); }

            doseTargetAdapter.updateOne(state.doseTargets, { id: id, changes: { prescription: doseValue } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.Target);
            if (target.outputId) {
                sliceWrapper.performFormValidationForOutput(state, target.outputId);
            }
        },

        /**
        * Adds a new dose target with default settings to the given dose output.
        * @param action ID of the dose output.
        */
        newDoseTargetAdded(state, action: PayloadAction<string>) {
            const outputId = action.payload;

            const output = state.doseOutputs.entities[outputId];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${outputId}`); }

            const target = defaultCreateNewDoseTarget(outputId, output.targetMethod);
            doseTargetAdapter.addOne(state.doseTargets, target);
            doseOutputAdapter.updateOne(state.doseOutputs, { id: outputId, changes: { targets: output.targets.concat(target.id) } });
            setObjectAndAncestorsAsModified(state, [outputId], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, outputId);
        },

        /**
         * Removes dose target with given ID
         * @param action ID of the dose target.
         */
        doseTargetRemoved(state, action: PayloadAction<string>) {
            const targetId = action.payload;

            const target = state.doseTargets.entities[targetId];
            if (target === undefined) { throw new Error(`Could not find dose target with id ${targetId}`); }

            // try to get outputId -- the field is technically optional, but the UI should NOT ever be in
            // a state where the user is allowed to delete a dose target that's not correctly linked to
            // a parent output
            const outputId = target.outputId;
            if (!outputId) { throw new Error('Dose target is not linked to a valid output'); }
            const output = state.doseOutputs.entities[outputId];
            if (output === undefined) { throw new Error(`Could not find dose output with id ${outputId}`); }

            doseOutputAdapter.updateOne(state.doseOutputs, { id: outputId, changes: { targets: output.targets.filter(tId => tId !== targetId) } });
            doseTargetAdapter.removeOne(state.doseTargets, targetId);

            setObjectAndAncestorsAsModified(state, [outputId], CustomizationObjectType.CustomizationOutput);
            sliceWrapper.performFormValidationForOutput(state, outputId);
        },

        /**
         * Reverts a dose target to its original values (if available).
         * @param action ID of the dose target.
         */
        doseTargetChangesReverted(state, action: PayloadAction<string>) {
            const targetId = action.payload;

            const originalTarget = state.originalDoseTargets.entities[targetId];
            if (originalTarget) {
                doseTargetAdapter.upsertOne(state.doseTargets, { ...originalTarget, });

                // remove any validation errors
                backendValidationErrorAdapter.removeOne(state.backendValidationErrors, targetId);
                formValidationErrorAdapter.removeMany(state.formValidationErrors, getFormValidationErrorIdsForItem(state, targetId));

                // re-validate form changes after partial data in an output just got undoed
                const target = state.doseTargets.entities[targetId];
                const output = target?.outputId ? state.doseTargets.entities[target.outputId] : undefined;
                if (output) {
                    sliceWrapper.performFormValidationForOutput(state, output.id);
                }
            }
        },

        /**
         * Performs an undo operation for all dose customization data, reverting all dose customization entities
         * to their original values from backend. Any new unsaved entries are lost.
         */
        allCustomizationChangesReverted(state) {
            modelAdapter.setAll(state.models, state.originalModels.entities as Record<string, Model>);
            customizationBaseAdapter.setAll(state.customizationBases, state.originalCustomizationBases.entities as Record<string, CustomizationBase>);
            doseOutputAdapter.setAll(state.doseOutputs, state.originalDoseOutputs.entities as Record<string, DoseCustomizationOutput>);
            outputMetadataAdapter.setAll(state.outputMetadata, state.originalOutputMetadata.entities as Record<string, OutputMetadataItem>);
            doseRoiAdapter.setAll(state.doseRois, state.originalDoseRois.entities as Record<string, DoseRoi>);
            doseTargetAdapter.setAll(state.doseTargets, state.originalDoseTargets.entities as Record<string, DoseTarget>);
            aeTitleRuleAdapter.setAll(state.aeTitleRules, state.originalAeTitleRules.entities as Record<string, AeTitleRule>);
            dicomRuleAdapter.setAll(state.dicomRules, state.originalDicomRules.entities as Record<string, DicomRule>);
            dicomAttributeRuleAdapter.setAll(state.dicomAttributeRules, state.originalDicomAttributeRules.entities as Record<string, DicomAttributeRule>);

            // remove any validation errors
            backendValidationErrorAdapter.removeAll(state.backendValidationErrors);
            formValidationErrorAdapter.removeAll(state.formValidationErrors);
            state.modelCustomizationSaveError = null;

            // calculate initial form validation errors
            for (const outputId of state.doseOutputs.ids) {
                sliceWrapper.performFormValidationForOutput(state, outputId);
            }
        },

        /**
         * Marks any supplied dose customization entities as having been modified.
         * @param action A collection of customization entities to mark as modified as per their
         * entity adapter IDs.
         */
        modelCustomizationObjectsSetAsModified(state, action: PayloadAction<Partial<DoseCustomizationEntities>>) {
            const entitiesToUpdate = action.payload;

            sliceWrapper.setStateObjectsAsModified(state, entitiesToUpdate);
        },

        /**
         * Signals that entire dose model customization should be saved to backend.
         * @param action Current configuration target (AppClient).
         */
        modelCustomizationSaved(state, action: PayloadAction<MVisionAppClient>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /**
         * Marks dose customization save as being in progress.
         */
        saveModelCustomizationStarted(state) {
            state.isModelCustomizationSavingInProgress = true;
        },

        /**
         * Marks dose customization save as having been finished, whether in success or failure.
         * @param action.saveWasSuccessful True if save succeeded, false otherwise.
         * @param action.errorMessage An optional error message if save was unsuccessful,
         * @param action.error An optional backend validation error object if save was unsuccessful.
         */
        saveModelCustomizationFinished(state, action: PayloadAction<{ saveWasSuccessful: boolean, errorMessage?: string, error?: BackendValidationError }>) {
            const { saveWasSuccessful, errorMessage, error } = action.payload;
            state.isModelCustomizationSavingInProgress = false;

            // remove existing backend validation errors -- we may add new ones more in just a moment
            // (DON'T remove FORM validation errors though -- we may have warnings there that still apply)
            backendValidationErrorAdapter.removeAll(state.backendValidationErrors);

            if (saveWasSuccessful) {
                // set everything back to unmodified & store as the new original entities
                Object.values(state.models.entities).forEach(m => { if (m !== undefined) { m.isModified = false } });
                Object.values(state.customizationBases.entities).forEach(c => { if (c !== undefined) { c.isModified = false } });
                Object.values(state.doseOutputs.entities).forEach(c => { if (c !== undefined) { c.isModified = false } });
                Object.values(state.outputMetadata.entities).forEach(md => { if (md !== undefined) { md.isModified = false } });
                Object.values(state.doseRois.entities).forEach(r => { if (r !== undefined) { r.isModified = false } });
                Object.values(state.doseTargets.entities).forEach(r => { if (r !== undefined) { r.isModified = false } });
                Object.values(state.aeTitleRules.entities).forEach(r => { if (r !== undefined) { r.isModified = false; r.isNew = false; } });
                Object.values(state.dicomRules.entities).forEach(r => { if (r !== undefined) { r.isModified = false; r.isNew = false; } });
                Object.values(state.dicomAttributeRules.entities).forEach(r => { if (r !== undefined) { r.isModified = false; r.isNew = false; } });

                modelAdapter.setAll(state.originalModels, state.models.entities as Record<string, Model>);
                customizationBaseAdapter.setAll(state.originalCustomizationBases, state.customizationBases.entities as Record<string, CustomizationBase>);
                doseOutputAdapter.setAll(state.originalDoseOutputs, state.doseOutputs.entities as Record<string, DoseCustomizationOutput>);
                outputMetadataAdapter.setAll(state.originalOutputMetadata, state.outputMetadata.entities as Record<string, OutputMetadataItem>);
                doseRoiAdapter.setAll(state.originalDoseRois, state.doseRois.entities as Record<string, DoseRoi>);
                doseTargetAdapter.setAll(state.originalDoseTargets, state.doseTargets.entities as Record<string, DoseTarget>);
                aeTitleRuleAdapter.setAll(state.originalAeTitleRules, state.aeTitleRules.entities as Record<string, AeTitleRule>);
                dicomRuleAdapter.setAll(state.originalDicomRules, state.dicomRules.entities as Record<string, DicomRule>);
                dicomAttributeRuleAdapter.setAll(state.originalDicomAttributeRules, state.dicomAttributeRules.entities as Record<string, DicomAttributeRule>);

            }

            state.modelCustomizationSaveError = errorMessage !== undefined ? errorMessage : null;

            // collect save errors
            try {
                if (!saveWasSuccessful && error !== undefined) {
                    const backendValidationErrors = convertBackendValidationErrorToViewModels(state, error);
                    if (backendValidationErrors.length > 0) {
                        // apply new validation errors and override whatever error message we received from sagas
                        backendValidationErrorAdapter.addMany(state.backendValidationErrors, backendValidationErrors);
                        state.modelCustomizationSaveError = 'Could not save customizations because they have invalid entries. Please fix any validation errors and try again.';
                    }
                }
            }
            catch (ex) {
                const message: string | undefined = get(ex, 'message', undefined);
                state.modelCustomizationSaveError = `An error occurred when trying to parse validation error message${message ? `: ${message}` : '.'}`
            }
        },

        /**
         * Duplicates given customization base and any entities under it.
         * @param action.customizationBaseId The ID of the customization base to duplicate.
         * @param action.newCustomizationName The name of the new duplicate customization base.
         */
        customizationBaseDuplicated(state, action: PayloadAction<{ customizationBaseId: string, newCustomizationName: string }>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /**
        * Adds duplicated customization base items into redux store. Duplication itself is done in a saga function.
        */
        customizationDuplicationItemsAdded(state, action: PayloadAction<{
            parentModelId: string,
            customization: CustomizationBase,
            outputs: DoseCustomizationOutput[],
            metadata: OutputMetadataItem[],
            rois: DoseRoi[],
            targets: DoseTarget[],
            duplicatedIds: DuplicatedIdMap[]
        }>) {
            const { parentModelId, customization, outputs, metadata, rois, targets, duplicatedIds } = action.payload;

            const parentModel = state.models.entities[parentModelId];
            if (!parentModel) { throw new Error('No parent model found for duplication target'); }

            customizationBaseAdapter.addOne(state.customizationBases, customization);
            doseOutputAdapter.addMany(state.doseOutputs, outputs);
            outputMetadataAdapter.addMany(state.outputMetadata, metadata);
            doseRoiAdapter.addMany(state.doseRois, rois);
            doseTargetAdapter.addMany(state.doseTargets, targets);

            modelAdapter.updateOne(state.models, {
                id: parentModelId,
                changes: { customizations: parentModel.customizations.concat(customization.id) }
            });

            setObjectAndAncestorsAsModified(state, [parentModelId], CustomizationObjectType.Model);

            // collect and duplicate any form errors we may have -- they still apply (backend errors are not worth duplicating, they'll re-appear during save)
            const duplicatedErrors = duplicateFormValidationErrors(state, duplicatedIds);
            if (duplicatedErrors.length > 0) {
                formValidationErrorAdapter.addMany(state.formValidationErrors, duplicatedErrors);
            }
        },

        /**
         * Removes the specified customization base entity and all entities under it in the hierarchy.
         * @param action The ID of the customization base to remove.
         */
        customizationBaseRemoved(state, action: PayloadAction<string>) {
            const customizationBaseId = action.payload;

            sliceWrapper.removeCustomizationBase(state, customizationBaseId);
        },

        /**
         * Signals that all current dose customizations should be reset back to factory default values.
         * @param action Current configuration target
         */
        allModelCustomizationsReset(state, action: PayloadAction<MVisionAppClient>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /** Marks dose customization reset as having started. */
        resetAllModelCustomizationsStarted(state) {
            state.isAllModelCustomizationsResetInProgress = true;
        },

        /** Marks dose customization reset as finished. */
        resetAllModelCustomizationsFinished(state) {
            state.isAllModelCustomizationsResetInProgress = false;
        },

        /**
         * Signals that a single customization output should be reset back to factory default values.
         * @param action Internal ID of the output to reset.
         */
        singleCustomizationReset(state, action: PayloadAction<string>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /** Marks a single dose output reset as having started. */
        resetSingleOutputStarted(state) {
            state.isOutputResetInProgress = true;
        },

        /** Marks a single dose output reset as finished.
         * @param action.resetWasSuccessful True if reset was succesful, false otherwise.
         * @param action.errorMessage Optional error message if output reset failed.
         */
        resetSingleOutputFinished(state, action: PayloadAction<{ resetWasSuccessful: boolean, errorMessage?: string }>) {
            state.isOutputResetInProgress = false;
            state.modelCustomizationDataError = action.payload.errorMessage || null;
        },

        /**
         * Replaces a specified dose customization output with supplied values, including ROIs, targets and metadata. ID of the original 
         * dose output is retained.
         * @param action.customizationOutputId The ID of the dose output to be replaced.
         * @param action.newOutputEntities The values that will be used to replace the dose output.
         */
        customizationOutputReplaced(state, action: PayloadAction<{ customizationOutputId: string, newOutputEntities: DoseCustomizationOutputEntities }>) {
            const { customizationOutputId, newOutputEntities } = action.payload;
            const originalOutput = state.doseOutputs.entities[customizationOutputId];

            if (!originalOutput) { throw new Error(`No original dose output (${customizationOutputId}) to replace!`); }
            if (newOutputEntities.outputs.length < 1) { throw new Error('No replacement dose output given'); }

            // currently only support replacing first output from given input even if we'd get several
            const newOutput = newOutputEntities.outputs[0];
            const newMetadata = newOutputEntities.metadata.filter(m => newOutput.metadata.includes(m.id));
            const newRois = newOutputEntities.rois.filter(r => newOutput.rois.includes(r.id));
            const newTargets = newOutputEntities.targets.filter(t => newOutput.targets.includes(t.id));

            // modify key parts of the replacement output items to match the original. also mark everything as modified
            newOutput.id = originalOutput.id;
            newOutput.modelCustomizationBaseId = originalOutput.modelCustomizationBaseId;
            newOutput.filename = originalOutput.filename;
            newOutput.isModified = true;

            newMetadata.forEach(m => {
                if (m.attribute === METADATA_FILENAME_ATTRIBUTE) { m.value = originalOutput.filename; }
                m.modelCustomizationOutputId = originalOutput.id;
                m.isModified = true;
            });

            newRois.forEach(r => {
                r.outputId = originalOutput.id;
                r.isModified = true;
            });

            newTargets.forEach(t => {
                t.outputId = originalOutput.id;
                t.isModified = true;
            });

            // remove old metadata, roi items, and related form validation errors
            outputMetadataAdapter.removeMany(state.outputMetadata, originalOutput.metadata);
            doseRoiAdapter.removeMany(state.doseRois, originalOutput.rois);
            doseTargetAdapter.removeMany(state.doseTargets, originalOutput.targets);
            const removedIds: string[] = [
                customizationOutputId,
                ...originalOutput.rois,
                ...originalOutput.metadata,
            ];

            formValidationErrorAdapter.removeMany(state.formValidationErrors, getFormValidationErrorIdsForItems(state, removedIds));

            // add new items
            outputMetadataAdapter.addMany(state.outputMetadata, newMetadata);
            doseRoiAdapter.addMany(state.doseRois, newRois);
            doseTargetAdapter.addMany(state.doseTargets, newTargets);

            // swap in the new customization output
            doseOutputAdapter.setOne(state.doseOutputs, newOutput);

            // mark ancestors as modified (start with customization base as the output was already marked earlier)
            setObjectAndAncestorsAsModified(state, [newOutput.modelCustomizationBaseId], CustomizationObjectType.CustomizationBase);
        },

        /**
         * Renames given customization base.
         * @param action.customizationBaseId ID of the customization base to rename.
         * @param action.name The new name of the customization base.
         */
        customizationRenamed(state, action: PayloadAction<{ customizationBaseId: string, name: string }>) {
            const { customizationBaseId, name } = action.payload;
            customizationBaseAdapter.updateOne(state.customizationBases, { id: customizationBaseId, changes: { isModified: true, customizationName: name } });
            setObjectAndAncestorsAsModified(state, [customizationBaseId], CustomizationObjectType.CustomizationBase);
        },

        /**
         * Export entire current dose customization into a JSON file.
         * @param action Current configuration target.
         */
        modelCustomizationExported(state, action: PayloadAction<MVisionAppClient | undefined>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /**
         * Imports parsed JSON file contents into current dose configuration target.
         * @param action Dose customization to import.
         */
        modelCustomizationImported(state, action: PayloadAction<DoseCustomizationEntities | null>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /**
         * Marks importing of a JSON file into dose customization as failed.
         * @param action Error message, or null if error message should be reset.
         */
        modelCustomizationImportFailed(state, action: PayloadAction<string | null>) {
            const errorMessage = action.payload;
            state.customizationImportExportError = errorMessage;
        },

        /**
         * Marks exporting of a JSON file from dose customization as failed.
         * @param action Error message, or null if error message should be reset.
         */
        modelCustomizationExportFailed(state, action: PayloadAction<string | null>) {
            const errorMessage = action.payload;
            state.customizationImportExportError = errorMessage;
        },



        /** Unit test helper reducer for setting an object and all its ancestors as modified.
         *
         * Do not use this reducer outside of unit tests!
         */
        _unitTest_customizationObjectPathSetAsModified(state, action: PayloadAction<{ objectIds: string[], objectType: CustomizationObjectType }>) {
            const { objectIds, objectType } = action.payload;
            setObjectAndAncestorsAsModified(state, objectIds, objectType);
        },

        /** Unit test helper reducer for adding a form validation error.
         *
         * Do not use this reducer outside of unit tests!
         */
        _unitTest_formValidationErrorAdded(state, action: PayloadAction<FormValidationError>) {
            const formError = action.payload;
            formValidationErrorAdapter.addOne(state.formValidationErrors, formError);
        },

        /** Unit test helper reducer for removing a form validation error.
         * 
         * Do not use this reducer outside of unit tests!
         */
        _unitTest_formValidationErrorRemoved(state, action: PayloadAction<string>) {
            const formErrorId = action.payload;
            formValidationErrorAdapter.removeOne(state.formValidationErrors, formErrorId);
        },

        /** Unit test helper reducer for adding a backend validation error.
         *
         * Do not use this reducer outside of unit tests!
         */
        _unitTest_backendValidationErrorAdded(state, action: PayloadAction<BackendValidationErrorViewModel>) {
            const backendValidationError = action.payload;
            backendValidationErrorAdapter.addOne(state.backendValidationErrors, backendValidationError);
        },
    },
    selectors: {

        /** Returns all dose models that have their visibility set as Always Hidden. */
        selectUserHiddenModels: createSelector(
            (state: DoseSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => m.visibility === ModelVisibility.AlwaysHidden)),

        /** Returns all dose models that are not in current user's license. */
        selectNonLicensedModels: createSelector(
            (state: DoseSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => !m.isAvailable)),

        /** Returns all dose models that are deprecated. */
        selectDeprecatedModels: createSelector(
            (state: DoseSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => m.isDeprecated)),

        /** Returns the top-level model name for a dose output */
        selectModelNameForOutput: (state, outputId: string) => {
            const output = localSelectors.selectOutputById(state, outputId);
            if (output) {
                const customization = localSelectors.selectCustomizationBaseById(state, output.modelCustomizationBaseId);
                if (customization) {
                    const model = localSelectors.selectModelById(state, customization.modelId);
                    if (model) {
                        return model.modelName;
                    }
                }
            }
        },

        /** Returns true if any model customization object has been modified. */
        selectIsAnyCustomizationModelModified: createSelector(
            (state: DoseSliceState): Model[] => localSelectors.selectModels(state),
            (models) => models.some(m => m.isModified)),

        /** Returns true if all model selection rules are valid, false if any of them is invalid. */
        selectIsEverySelectionRuleValid: createSelector(
            [
                (state: DoseSliceState): AeTitleRule[] => localSelectors.selectAeTitleRules(state),
                (state: DoseSliceState): DicomRule[] => localSelectors.selectDicomRules(state),
                (state: DoseSliceState): DicomAttributeRule[] => localSelectors.selectDicomAttributeRules(state),

            ],
            (aeTitleRules, dicomRules, dicomAttributeRules) => aeTitleRules.every(r => r.isValid)
                && dicomRules.every(r => r.isValid)
                && dicomAttributeRules.every(r => r.isValid)),

        /** Returns an array of all customization names and their internal parent model IDs. */
        selectAllCustomizationNamesAndParentModelIds: createSelector(
            (state: DoseSliceState): CustomizationBase[] => localSelectors.selectCustomizationBases(state),
            (customizations) => customizations.map(c => ({ customizationName: c.customizationName, modelId: c.modelId }))),

        /** Returns true if there are ANY unsaved DOSE+ changes in the app. */
        selectAnyUnsavedDoseChangesInApp: (state) => localSelectors.selectIsAnyCustomizationModelModified(state),

        // ENTITY ADAPTER SELECTORS:
        // for all supported createEntityAdapter selectors see https://redux-toolkit.js.org/api/createEntityAdapter#selector-functions

        selectModels: (state): Model[] => modelAdapterSelectors.selectAll(state),
        selectModelEntities: (state) => modelAdapterSelectors.selectEntities(state),
        selectModelById: (state, id: string): Model | undefined => modelAdapterSelectors.selectById(state, id),
        selectModelsInAlphabeticalOrder: createSelector(
            [(state: DoseSliceState): Model[] => localSelectors.selectModels(state)],
            models => naturalSort(models, 'modelName')),

        selectCustomizationBases: (state) => customizationBaseAdapterSelectors.selectAll(state),
        selectCustomizationBaseIds: (state) => customizationBaseAdapterSelectors.selectIds(state),
        selectCustomizationBaseById: (state, id: string): CustomizationBase | undefined => customizationBaseAdapterSelectors.selectById(state, id),
        selectCustomizationBaseEntities: (state) => customizationBaseAdapterSelectors.selectEntities(state),
        selectCustomizationBasesInAlphabeticalOrder: createSelector(
            [(state: DoseSliceState): CustomizationBase[] => localSelectors.selectCustomizationBases(state)],
            bases => naturalSort(bases, 'customizationName')),

        selectOutputs: (state) => doseOutputAdapterSelectors.selectAll(state),
        selectOutputIds: (state) => doseOutputAdapterSelectors.selectIds(state),
        selectOutputEntities: (state) => doseOutputAdapterSelectors.selectEntities(state),
        selectOutputById: (state, id: string): DoseCustomizationOutput | undefined => doseOutputAdapterSelectors.selectById(state, id),

        selectRois: (state) => doseRoiAdapterSelectors.selectAll(state),
        selectRoiById: (state, id: string): DoseRoi | undefined => doseRoiAdapterSelectors.selectById(state, id),
        selectRoiEntities: (state) => doseRoiAdapterSelectors.selectEntities(state),
        selectOriginalRoiById: (state, id: string): DoseRoi | undefined => originalDoseRoiAdapterSelectors.selectById(state, id),

        selectTargets: (state) => doseTargetAdapterSelectors.selectAll(state),
        selectTargetById: (state, id: string): DoseTarget | undefined => doseTargetAdapterSelectors.selectById(state, id),
        selectTargetEntities: (state) => doseTargetAdapterSelectors.selectEntities(state),
        selectOriginalTargetById: (state, id: string): DoseTarget | undefined => originalDoseTargetAdapterSelectors.selectById(state, id),
        selectTargetIds: (state) => doseTargetAdapterSelectors.selectIds(state),

        selectOutputMetadata: (state) => outputMetadataAdapterSelectors.selectAll(state),
        selectOutputMetadataEntities: (state) => outputMetadataAdapterSelectors.selectEntities(state),
        selectOriginalOutputMetadataEntities: (state) => originalOutputMetadataAdapterSelectors.selectEntities(state),
        selectOutputMetadataById: (state, id: string): OutputMetadataItem | undefined => outputMetadataAdapterSelectors.selectById(state, id),

        selectAeTitleRules: (state) => aeTitleRuleAdapterSelectors.selectAll(state),
        selectAeTitleRuleEntities: (state) => aeTitleRuleAdapterSelectors.selectEntities(state),
        selectAeTitleRuleById: (state, id: string): AeTitleRule | undefined => aeTitleRuleAdapterSelectors.selectById(state, id),

        selectDicomRules: (state) => dicomRuleAdapterSelectors.selectAll(state),
        selectDicomRuleEntities: (state) => dicomRuleAdapterSelectors.selectEntities(state),
        selectDicomRuleById: (state, id: string): DicomRule | undefined => dicomRuleAdapterSelectors.selectById(state, id),

        selectDicomAttributeRules: (state) => dicomAttributeRuleAdapterSelectors.selectAll(state),
        selectDicomAttributeRuleById: (state, id: string): DicomAttributeRule | undefined => dicomAttributeRuleAdapterSelectors.selectById(state, id),

        selectCustomizationValidationError: (state, id: string): BackendValidationErrorViewModel | undefined => customizationValidationErrorAdapterSelectors.selectById(state, id),
        selectCustomizationValidationErrorEntities: (state) => customizationValidationErrorAdapterSelectors.selectEntities(state),

        selectFormValidationErrors: (state) => formValidationErrorAdapterSelectors.selectAll(state),
    }
});




export const {
    modelCustomizationsSet,
    modelVisibilityUpdated,
    modelCustomizationDescriptionUpdated,
    metadataItemUpdated,
    metadataItemRemoved,
    scrollToViewFromModelCustomizationMetadataRemoved,
    aeTitleRuleAdded,
    aeTitleRuleActionUpdated,
    aeTitleRuleRemoved,
    dicomRuleAdded,
    dicomRuleRemoved,
    dicomAttributeRuleAdded,
    dicomAttributeRuleRemoved,
    dicomAttributeRuleUpdated,
    doseScalingMethodSet,
    doseScalingVolumeSet,
    doseScalingDoseSet,
    pixelSpacingSet,
    doseCroppingIsEnabledSet,
    doseCroppingValueSet,
    outputIsRTPlanIncludedSet,
    outputIsBeamIncludedSet,
    outputMachineTypeSet,
    outputMachineNameSet,
    doseRoiNameSet,
    doseRoiRegExpSet,
    doseRoiChangesReverted,
    doseTargetMethodSet,
    doseTargetUnitSet,
    doseTargetRoiNameSet,
    doseTargetRegExpSet,
    doseTargetPrescriptionSet,
    newDoseTargetAdded,
    doseTargetRemoved,
    doseTargetChangesReverted,
    allCustomizationChangesReverted,
    modelCustomizationObjectsSetAsModified,
    modelCustomizationSaved,
    saveModelCustomizationStarted,
    saveModelCustomizationFinished,
    customizationBaseDuplicated,
    customizationDuplicationItemsAdded,
    customizationBaseRemoved,
    allModelCustomizationsReset,
    resetAllModelCustomizationsStarted,
    resetAllModelCustomizationsFinished,
    singleCustomizationReset,
    resetSingleOutputStarted,
    resetSingleOutputFinished,
    customizationOutputReplaced,
    customizationRenamed,
    modelCustomizationExported,
    modelCustomizationExportFailed,
    modelCustomizationImported,
    modelCustomizationImportFailed,

    _unitTest_customizationObjectPathSetAsModified,
    _unitTest_formValidationErrorAdded,
    _unitTest_formValidationErrorRemoved,
    _unitTest_backendValidationErrorAdded,

} = doseSlice.actions;

const localSelectors = doseSlice.getSelectors();

export const { getInitialState, selectors: doseSelectors } = doseSlice;

export default doseSlice.reducer;
