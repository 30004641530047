import { has, isArray, isBoolean, isString, isUndefined } from "lodash-es";
import { AeTitleRule, CustomizationBase, CustomizationOutput, DicomAttributeRule, DicomRule, isCustomizationOutput, Model, OutputMetadataItem } from "../global-types/customization-types";
import { isLengthValue, isVolumeValue, LengthValue, VolumeValue } from "../global-types/units";

export enum ContourAttachSeries { NotSet = 'not_set', Input = 'input', Output = 'output' };
export enum ImageSmoothingMethod { NotSet = 'not_set', Gaussian = 'gaussian', Mean = 'mean', Median = 'median' };

export const EXPORT_IMAGE_JSON_FILE_PREFIX = 'mvision-image-';

/** Prefix to use for image+ model AE Titles */
export const AETITLE_PREFIX_IMAGE = 'MV_TODO_IMAGE';

/** Model type for image+ models. */
export const IMAGE_MODEL_TYPE = 'translation';

/** A single customization output (ie. a result file) for an image+ model. A customization base contains one or more of these. */
export type ImageCustomizationOutput = CustomizationOutput & {

    /** DICOM restrictions customization */
    dicomRestriction: string;   // DicomRestriction id
    /** Output geometry customization */
    outputGeometry: string;     // OutputGeometry id
    /** Contour generation customization */
    contourGeneration: string;  // ContourGeneration id
    /** Output postprocessing customization */
    postProcessing: string;     // PostProcessing id
};

/** DICOM restrictions customization */
export type ImageDicomRestriction = {
    /** Redux ID of this image DicomRestriction. */
    id: string;

    /** Enable or disable DICOM restrictions */
    isEnabled: boolean;
    /** Rules for the metadata of the result files (DICOM). Each list item specifies the rule for one DICOM tag or one file attribute (e.g. filename). */
    tags: string[]; // DicomTag ids

    /** True if this item has unsaved changes, false otherwise. */
    isModified: boolean;
    // /** If true, scroll the viewport to show this item when it's mounted into DOM. This is used to auto-scroll to newly added items. */
    // scrollToView: boolean;
    /** Parent model customization output this item belongs to. */
    outputId?: string;  // this is optional because it's usually set post-constructor
};

/** Rules for the metadata of the result files (DICOM). Each list item specifies the rule for one DICOM tag or one file attribute (e.g. filename). */
export type ImageDicomTag = {
    /** Redux ID of this image DicomTag. */
    id: string;
    /** DICOM tag (e.g. StructureSetLabel) or file attributes, e.g. filename. */
    attribute: string;
    /** python f-string to be evaluated to get the value of the attribute. For f-string formatting, see https://docs.python.org/3/tutorial/inputoutput.html#formatted-string-literals */
    value: string;

    /** True if this item has unsaved changes, false otherwise. */
    isModified: boolean;
    /** Parent dicom restriction object this item belongs to. */
    dicomRestrictionId?: string;  // this is optional because it's usually set post-constructor
};

/** Output geometry customization */
export type ImageOutputGeometry = {
    /** Redux ID of this image OutputGeometry. */
    id: string;

    /** Match input geometry. In which case all other fields are ignored. */
    matchInputGeometry: boolean;
    /** Field of view (within-slice) in cm */
    fovSize: LengthValue;
    /** Slice thickness details */
    sliceThickness: ImageSliceThickness;
    /** Shape in x and y direction, typically 512. */
    matrixSize: number;

    /** True if this item has unsaved changes, false otherwise. */
    isModified: boolean;
    // /** If true, scroll the viewport to show this item when it's mounted into DOM. This is used to auto-scroll to newly added items. */
    // scrollToView: boolean;

    /** Parent model customization output this item belongs to. */
    outputId?: string;  // this is optional because it's usually set post-constructor
};

/** Slice thickness details */
export type ImageSliceThickness = {
    /** Match input slice thickness. */
    matchInput: boolean;
    /** Slice thickness. Only used when match input is False */
    sliceThickness: LengthValue;
};

/** Contour generation customization */
export type ImageContourGeneration = {
    /** Redux ID of this image ContourGeneration. */
    id: string;

    /** Enable or disable contour generation */
    isEnabled: boolean;
    /** Model name to call for contour generation. Includes the customization name as well, i.e. model_name.customization_name */
    action: string | null;
    /** Attach generated contours to which series. Valid Options: 'input', 'output' */
    attachTo: ContourAttachSeries;

    /** True if this item has unsaved changes, false otherwise. */
    isModified: boolean;
    // /** If true, scroll the viewport to show this item when it's mounted into DOM. This is used to auto-scroll to newly added items. */
    // scrollToView: boolean;

    /** Parent model customization output this item belongs to. */
    outputId?: string;  // this is optional because it's usually set post-constructor
};

/** Output postprocessing customization */
export type ImagePostProcessing = {
    /** Redux ID of this image PostProcessing. */
    id: string;

    /** Enable or disable restore non-human anatomy */
    restoreNonHumanAnatomy: boolean;

    /** Keep Largest Component details */
    keepLargestComponent: string;        // KeepLargestComponent id
    /** Fill Holes details */
    fillHoles: string;                   // FillHoles id

    /** True if this item has unsaved changes, false otherwise. */
    isModified: boolean;
    // /** If true, scroll the viewport to show this item when it's mounted into DOM. This is used to auto-scroll to newly added items. */
    // scrollToView: boolean;

    /** Parent model customization output this item belongs to. */
    outputId?: string;  // this is optional because it's usually set post-constructor
};

/** Keep Largest Component details */
export type KeepLargestComponent = {
    /** Redux ID of this image post processing KeepLargestComponent. */
    id: string;

    /** Enable or disable keep largest component */
    isEnabled: boolean;
    /** HU threshold */
    huThreshold: number;
    /** Delete small connected components with volume less than this value in mm^3 */
    deleteSmallComponentsVol: VolumeValue;
    /** Smoothing details */
    smoothing: ImageSmoothing | null;

    /** True if this item has unsaved changes, false otherwise. */
    isModified: boolean;
    /** Parent post processing object this item belongs to. */
    postProcessingId?: string;  // this is optional because it's usually set post-constructor
};

/** Smoothing details */
export type ImageSmoothing = {
    /** Smoothing method. Valid Options: 'gaussian', 'mean', 'median' */
    method: ImageSmoothingMethod;
    /** Sigma value for gaussian smoothing */
    sigma: number;
};

/** Fill Holes details */
export type FillHoles = {
    /** Redux ID of this image post processing FillHoles. */
    id: string;

    /** Enable or disable fill holes */
    isEnabled: boolean;
    bodyMask: string;        // BodyMask id
    holeMask: string;        // HoleMask id

    /** True if this item has unsaved changes, false otherwise. */
    isModified: boolean;
    /** Parent post processing object this item belongs to. */
    postProcessingId?: string;  // this is optional because it's usually set post-constructor
};

/** Body mask details */
export type BodyMask = {
    /** Redux ID of this image post processing FillHoles BodyMask. */
    id: string;
    /** Enable or disable body mask */
    isEnabled: boolean;

    /** HU threshold */
    huThreshold: number;
    /** Erode details */
    erode: number[];
    /** Smoothing details */
    smoothing: ImageSmoothing | null;
    /** Delete top slices */
    deleteTopSlices: number;
    /** Delete bottom slices */
    deleteBottomSlices: number;

    /** True if this item has unsaved changes, false otherwise. */
    isModified: boolean;
    /** Parent fill holes object this item belongs to. */
    fillHolesId?: string;  // this is optional because it's usually set post-constructor
};

/** Hole mask details */
export type HoleMask = {
    /** Redux ID of this image post processing FillHoles HoleMask. */
    id: string;
    /** Enable or disable hole mask */
    isEnabled: boolean;

    /** Lower HU threshold */
    huThresholdLower: number;
    /** Upper HU threshold */
    huThresholdUpper: number;
    /** Delete small connected components with volume less than this value in mm^3 */
    deleteSmallComponentsVol: VolumeValue;
    /** Dilate details */
    dilate: number[];
    /** Smoothing details */
    smoothing: ImageSmoothing | null;

    /** True if this item has unsaved changes, false otherwise. */
    isModified: boolean;
    /** Parent fill holes object this item belongs to. */
    fillHolesId?: string;  // this is optional because it's usually set post-constructor
};



/** A full collection of image customization-related entities and any
 * related internal helper objects. */
export type ImageCustomizationEntities = {
    models: Model[];
    customizationBases: CustomizationBase[];
    imageOutputs: ImageCustomizationOutput[];
    outputMetadata: OutputMetadataItem[];

    imageDicomRestrictions: ImageDicomRestriction[];
    imageDicomTags: ImageDicomTag[];
    imageOutputGeometry: ImageOutputGeometry[];
    imageContourGeneration: ImageContourGeneration[];
    imagePostProcessing: ImagePostProcessing[];
    keepLargestComponent: KeepLargestComponent[];
    fillHoles: FillHoles[];
    bodyMasks: BodyMask[];
    holeMasks: HoleMask[];

    aeTitleRules: AeTitleRule[];
    dicomRules: DicomRule[];
    dicomAttributeRules: DicomAttributeRule[];
};

/** A full collection of image customization-related entities without
 * any internal helper entities that are not needed for export.
 * NOTE: as of now this is identical with ImageCustomizationEntities but
 * it's possible these will diverge in future. */
export type ImageCustomizationEntitiesForExport = {
    models: Model[];
    customizationBases: CustomizationBase[];
    imageOutputs: ImageCustomizationOutput[];
    outputMetadata: OutputMetadataItem[];

    imageDicomRestrictions: ImageDicomRestriction[];
    imageDicomTags: ImageDicomTag[];
    imageOutputGeometry: ImageOutputGeometry[];
    imageContourGeneration: ImageContourGeneration[];
    imagePostProcessing: ImagePostProcessing[];
    keepLargestComponent: KeepLargestComponent[];
    fillHoles: FillHoles[];
    bodyMasks: BodyMask[];
    holeMasks: HoleMask[];

    aeTitleRules: AeTitleRule[];
    dicomRules: DicomRule[];
    dicomAttributeRules: DicomAttributeRule[];
};


// *** type guards ***

/** Checks if the object is of type `ImageCustomizationOutput`. */
export const isImageCustomizationOutput = (obj: any): obj is ImageCustomizationOutput => {
    const output = obj as ImageCustomizationOutput;
    return !!output
        && isCustomizationOutput(obj)
        && has(output, 'dicomRestriction') && isString(output.dicomRestriction)
        && has(output, 'outputGeometry') && isString(output.outputGeometry)
        && has(output, 'contourGeneration') && isString(output.contourGeneration)
        && has(output, 'postProcessing') && isString(output.postProcessing);
};

/** Checks if the object is of type `ImageDicomRestriction`. */
export const isImageDicomRestriction = (obj: any): obj is ImageDicomRestriction => {
    const dicomRestriction = obj as ImageDicomRestriction;
    return !!dicomRestriction
        && typeof dicomRestriction.id === 'string'
        && typeof dicomRestriction.isEnabled === 'boolean'
        && isArray(dicomRestriction.tags)
        && typeof dicomRestriction.isModified === 'boolean';
};

/** Checks if the object is of type `ImageDicomTag`. */
export const isImageDicomTag = (obj: any): obj is ImageDicomTag => {
    const dicomTag = obj as ImageDicomTag;
    return !!dicomTag
        && typeof dicomTag.id === 'string'
        && typeof dicomTag.attribute === 'string'
        && typeof dicomTag.value === 'string'
        && has(dicomTag, 'isModified') && isBoolean(dicomTag.isModified)
        && (has(dicomTag, 'dicomRestrictionId') && (isString(dicomTag['dicomRestrictionId'])) || isUndefined(dicomTag['dicomRestrictionId']))
        // must not look like a dicom attribute rule
        && !has(dicomTag, 'parentDicomRuleId')
        // or a metadata item
        && !has(dicomTag, 'modelCustomizationOutputId');
};

/** Checks if the object is of type `ImageOutputGeometry`. */
export const isImageOutputGeometry = (obj: any): obj is ImageOutputGeometry => {
    const outputGeometry = obj as ImageOutputGeometry;
    return !!outputGeometry
        && typeof outputGeometry.id === 'string'
        && typeof outputGeometry.matchInputGeometry === 'boolean'
        && isLengthValue(outputGeometry.fovSize)
        && isImageSliceThickness(outputGeometry.sliceThickness)
        && typeof outputGeometry.matrixSize === 'number'
        && typeof outputGeometry.isModified === 'boolean';
};

/** Checks if the object is of type `ImageSliceThickness`. */
export const isImageSliceThickness = (obj: any): obj is ImageSliceThickness => {
    const sliceThickness = obj as ImageSliceThickness;
    return !!sliceThickness
        && typeof sliceThickness.matchInput === 'boolean'
        && isLengthValue(sliceThickness.sliceThickness);
};

/** Checks if the object is of type `ImageContourGeneration`. */
export const isImageContourGeneration = (obj: any): obj is ImageContourGeneration => {
    const contourGeneration = obj as ImageContourGeneration;
    return !!contourGeneration
        && typeof contourGeneration.id === 'string'
        && typeof contourGeneration.isEnabled === 'boolean'
        && (contourGeneration.action === null || typeof contourGeneration.action === 'string')
        && (contourGeneration.attachTo === ContourAttachSeries.Input || contourGeneration.attachTo === ContourAttachSeries.Output)
        && typeof contourGeneration.isModified === 'boolean';
};

/** Checks if the object is of type `ImagePostProcessing`. */
export const isImagePostProcessing = (obj: any): obj is ImagePostProcessing => {
    const postProcessing = obj as ImagePostProcessing;
    return !!postProcessing
        && typeof postProcessing.id === 'string'
        && isBoolean(postProcessing.restoreNonHumanAnatomy)
        && typeof postProcessing.keepLargestComponent === 'string'
        && typeof postProcessing.fillHoles === 'string'
        && typeof postProcessing.isModified === 'boolean';
};

/** Checks if the object is of type `KeepLargestComponent`. */
export const isKeepLargestComponent = (obj: any): obj is KeepLargestComponent => {
    const keepLargestComponent = obj as KeepLargestComponent;
    return !!keepLargestComponent
        && typeof keepLargestComponent.id === 'string'
        && typeof keepLargestComponent.isEnabled === 'boolean'
        && typeof keepLargestComponent.huThreshold === 'number'
        && isVolumeValue(keepLargestComponent.deleteSmallComponentsVol)
        && (keepLargestComponent.smoothing === null || isImageSmoothing(keepLargestComponent.smoothing));
};

/** Checks if the object is of type `ImageSmoothing`. */
export const isImageSmoothing = (obj: any): obj is ImageSmoothing => {
    const smoothing = obj as ImageSmoothing;
    return !!smoothing
        && (smoothing.method === ImageSmoothingMethod.Gaussian || smoothing.method === ImageSmoothingMethod.Mean || smoothing.method === ImageSmoothingMethod.Median)
        && typeof smoothing.sigma === 'number';
};

/** Checks if the object is of type `FillHoles`. */
export const isFillHoles = (obj: any): obj is FillHoles => {
    const fillHoles = obj as FillHoles;
    return !!fillHoles
        && typeof fillHoles.id === 'string'
        && typeof fillHoles.isEnabled === 'boolean'
        && typeof fillHoles.bodyMask === 'string'
        && typeof fillHoles.holeMask === 'string';
};

/** Checks if the object is of type `BodyMask`. */
export const isBodyMask = (obj: any): obj is BodyMask => {
    const bodyMask = obj as BodyMask;
    return !!bodyMask
        && typeof bodyMask.id === 'string'
        && typeof bodyMask.huThreshold === 'number'
        && Array.isArray(bodyMask.erode)
        && (bodyMask.smoothing === null || isImageSmoothing(bodyMask.smoothing))
        && typeof bodyMask.deleteTopSlices === 'number'
        && typeof bodyMask.deleteBottomSlices === 'number';
};

/** Checks if the object is of type `HoleMask`. */
export const isHoleMask = (obj: any): obj is HoleMask => {
    const holeMask = obj as HoleMask;
    return !!holeMask
        && typeof holeMask.id === 'string'
        && typeof holeMask.huThresholdLower === 'number'
        && typeof holeMask.huThresholdUpper === 'number'
        && isVolumeValue(holeMask.deleteSmallComponentsVol)
        && Array.isArray(holeMask.dilate)
        && (holeMask.smoothing === null || isImageSmoothing(holeMask.smoothing));
};
