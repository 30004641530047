import { isNumber, isString } from "lodash-es";
import { ContouringCustomizationOutput, ContouringRoi, isContouringRoi } from "../contouring/contouring-types";
import { ContouringSliceState, aeTitleRuleAdapter as contourAeTitleRuleAdapter, customizationBaseAdapter as contourCustomizationBaseAdapter, customizationOutputAdapter as contourCustomizationOutputAdapter, dicomAttributeRuleAdapter as contourDicomAttributeRuleAdapter, dicomRuleAdapter as contourDicomRuleAdapter, globalRoiCustomizationAdapter, modelAdapter as contourModelAdapter, outputMetadataAdapter as contourOutputMetadataAdapter, roiCustomizationAdapter as contourRoiCustomizationAdapter, isContouringSliceState } from "../contouring/contouringSlice";
import { DoseCustomizationOutput, DoseRoi, DoseTarget, isDoseRoi, isDoseTarget } from "../dose/dose-types";
import { DoseSliceState, aeTitleRuleAdapter as doseAeTitleRuleAdapter, customizationBaseAdapter as doseCustomizationBaseAdapter, doseOutputAdapter, dicomAttributeRuleAdapter as doseDicomAttributeRuleAdapter, dicomRuleAdapter as doseDicomRuleAdapter, modelAdapter as doseModelAdapter, outputMetadataAdapter as doseOutputMetadataAdapter, doseRoiAdapter, doseTargetAdapter, isDoseSliceState } from "../dose/doseSlice";
import { AeTitleRule, CustomizationBase, CustomizationObjectType, CustomizationOutput, DicomAttributeRule, DicomRule, isAeTitleRule, isCustomizationBase, isCustomizationOutput, isDicomAttributeRule, isDicomRule, isModel, isOutputMetadata, Model, OutputMetadataItem } from "./customization-types";
import { BackendValidationErrorViewModel, duplicateFormValidationError, FormValidationError } from "./store-errors";
import { BackendValidationError, LOC_VALUE_BODY } from "../../util/errors";
import { UI_ID_ATTRIBUTE } from "./customization-helpers";
import { ImageSliceState, isImageSliceState, aeTitleRuleAdapter as imageAeTitleRuleAdapter, customizationBaseAdapter as imageCustomizationBaseAdapter, imageOutputAdapter, dicomAttributeRuleAdapter as imageDicomAttributeRuleAdapter, dicomRuleAdapter as imageDicomRuleAdapter, modelAdapter as imageModelAdapter, outputMetadataAdapter as imageOutputMetadataAdapter, imageDicomRestrictionAdapter, imageOutputGeometryAdapter, imageContourGenerationAdapter, imagePostProcessingAdapter, imageDicomTagAdapter, keepLargestComponentAdapter, fillHolesAdapter, bodyMaskAdapter, holeMaskAdapter, } from "../image/imageSlice";
import { AdaptSliceState, isAdaptSliceState, aeTitleRuleAdapter as adaptAeTitleRuleAdapter, customizationBaseAdapter as adaptCustomizationBaseAdapter, adaptOutputAdapter, dicomAttributeRuleAdapter as adaptDicomAttributeRuleAdapter, dicomRuleAdapter as adaptDicomRuleAdapter, modelAdapter as adaptModelAdapter, outputMetadataAdapter as adaptOutputMetadataAdapter, adaptRoiRuleAdapter, } from "../adapt/adaptSlice";
import { BodyMask, FillHoles, HoleMask, ImageContourGeneration, ImageCustomizationOutput, ImageDicomRestriction, ImageDicomTag, ImageOutputGeometry, ImagePostProcessing, isBodyMask, isFillHoles, isHoleMask, isImageContourGeneration, isImageDicomRestriction, isImageDicomTag, isImageOutputGeometry, isImagePostProcessing, isKeepLargestComponent, KeepLargestComponent } from "../image/image-types";
import { AdaptCustomizationOutput, AdaptRoiRule, isAdaptRoiRule } from "../adapt/adapt-types";


/***
 * 
 * This file contains generic-ish helper functions for different model type slices. These functions are not meant to be used outside of the slice file
 * (apart from unit tests).
 * 
 */


/** A combination type of contouring-related customization types.
 * NOTE: GlobalRoiCustomization is intentionally excluded as it's not really a part of this hierarchy.
 */
export type AnyContourCustomizationType = Model | CustomizationBase | ContouringCustomizationOutput | OutputMetadataItem | ContouringRoi |
    AeTitleRule | DicomRule | DicomAttributeRule;

/** A combination type of dose-related customization types. */
export type AnyDoseCustomizationType = Model | CustomizationBase | DoseCustomizationOutput | OutputMetadataItem | DoseRoi | DoseTarget |
    AeTitleRule | DicomRule | DicomAttributeRule;

/** A combination type of image-related customization types. */
export type AnyImageCustomizationType = Model | CustomizationBase | ImageCustomizationOutput | OutputMetadataItem |
    ImageDicomRestriction | ImageOutputGeometry | ImageContourGeneration | ImagePostProcessing |
    ImageDicomTag | KeepLargestComponent | FillHoles | BodyMask | HoleMask |
    AeTitleRule | DicomRule | DicomAttributeRule;

/** A combination type of adapt-related customization types.  */
export type AnyAdaptCustomizationType = Model | CustomizationBase | AdaptCustomizationOutput | OutputMetadataItem | AdaptRoiRule |
    AeTitleRule | DicomRule | DicomAttributeRule;

/** */
export type BaseTypes = CustomizationOutput;

/** All supported customization types. */
export type AnyCustomizationType = BaseTypes | AnyContourCustomizationType | AnyDoseCustomizationType | AnyImageCustomizationType | AnyAdaptCustomizationType;

/** All supported slice states. */
export type AnySliceState = ContouringSliceState | DoseSliceState | ImageSliceState | AdaptSliceState;



export type DuplicatedIdMap = { sourceId: string, targetId: string };



/**
 * A helper function for asserting that given object is not null or undefined and for printing
 * a nice error message if not so.
 * @param obj The object that is being asserted for being not null and not undefined.
 * @param typeName Name of the type of the object being asserted. This is only used in the assertion error message.
 * @param id Optional ID of the object being asserted. This is only used in the assertion error message.
 */
export function doAssert<T>(obj: T, typeName: string, id?: string): asserts obj is NonNullable<T> {
    if (obj === undefined || obj === null) { throw new Error(`Could not find a valid ${typeName} object${id ? ` with given ID '${id}'` : ''}`); }
};

/**
 * Marks the given customization view model object and all its parent objects as 'modified'.
 * 
 * This should only be called from within contouringConfig/doseConfig reducers (or from unit tests).
 * 
 * @param objectIds List of object IDs for which upwards ancestor travelsal will be performed. These objects and all their ancestors will
 * be marked as having been modified. All entities specified in this argument must be of the same type.
 * @param objectType The type of the objects specified in the objectIds field as this is not easily deduced otherwise. All entities
 * specified in objectIds must be of the same type.
 */
export const setObjectAndAncestorsAsModified = (state: AnySliceState, objectIds: string[], objectType: CustomizationObjectType) => {

    // these IDs will all be marked as modified in one go
    const targets: { [parentType in CustomizationObjectType]: string[] } = {
        [CustomizationObjectType.None]: [],
        [CustomizationObjectType.Model]: [],
        [CustomizationObjectType.CustomizationBase]: [],
        [CustomizationObjectType.CustomizationOutput]: [],
        [CustomizationObjectType.Metadata]: [],
        [CustomizationObjectType.Roi]: [],
        [CustomizationObjectType.Target]: [],
        [CustomizationObjectType.DicomRestriction]: [],
        [CustomizationObjectType.OutputGeometry]: [],
        [CustomizationObjectType.ContourGeneration]: [],
        [CustomizationObjectType.PostProcessing]: [],
        [CustomizationObjectType.GlobalRoi]: [],
        [CustomizationObjectType.AeTitleRule]: [],
        [CustomizationObjectType.DicomRule]: [],
        [CustomizationObjectType.DicomAttributeRule]: [],
        [CustomizationObjectType.TriggerRule]: [],
        [CustomizationObjectType.CodingScheme]: [],
        [CustomizationObjectType.PhysicalProperties]: [],
        [CustomizationObjectType.DicomTag]: [],
        [CustomizationObjectType.KeepLargestComponent]: [],
        [CustomizationObjectType.FillHoles]: [],
        [CustomizationObjectType.BodyMask]: [],
        [CustomizationObjectType.HoleMask]: [],
        [CustomizationObjectType.AdaptRoiRule]: [],
    };

    // walk through the entire hierarchy in reverse order & set everything touched as modified

    // set initial child objects
    targets[objectType] = objectIds;

    for (const id of targets[CustomizationObjectType.DicomAttributeRule]) {
        const dicomAttributeRule = state.dicomAttributeRules.entities[id];
        doAssert(dicomAttributeRule, 'DICOM attribute rule', id);
        targets[CustomizationObjectType.DicomRule].push(getParent(state, dicomAttributeRule).id);
    }

    for (const id of targets[CustomizationObjectType.DicomRule]) {
        const dicomRule = state.dicomRules.entities[id];
        doAssert(dicomRule, 'DICOM rule', id);
        targets[CustomizationObjectType.CustomizationBase].push(getParent(state, dicomRule).id);
    }

    for (const id of targets[CustomizationObjectType.AeTitleRule]) {
        const aeTitleRule = state.aeTitleRules.entities[id];
        doAssert(aeTitleRule, 'AE title rule rule', id);
        targets[CustomizationObjectType.CustomizationBase].push(getParent(state, aeTitleRule).id);
    }

    for (const id of targets[CustomizationObjectType.GlobalRoi]) {
        if (!isContouringSliceState(state)) { throw new Error('Unexpected state type'); }
        const globalRoi = state.globalRoiCustomizations.entities[id];
        doAssert(globalRoi, 'global ROI customization', id);
        // global roi special case: add all covered regular roi items
        targets[CustomizationObjectType.Roi].push(...globalRoi.coveredRois);
    }

    for (const id of targets[CustomizationObjectType.BodyMask]) {
        if (!isImageSliceState(state)) { throw new Error('Unexpected state type'); }
        const bodyMask = state.bodyMasks.entities[id];
        doAssert(bodyMask, 'fill holes body mask', id);
        targets[CustomizationObjectType.FillHoles].push(getParent(state, bodyMask).id);
    }

    for (const id of targets[CustomizationObjectType.HoleMask]) {
        if (!isImageSliceState(state)) { throw new Error('Unexpected state type'); }
        const holeMask = state.holeMasks.entities[id];
        doAssert(holeMask, 'fill holes hole mask', id);
        targets[CustomizationObjectType.FillHoles].push(getParent(state, holeMask).id);
    }

    for (const id of targets[CustomizationObjectType.FillHoles]) {
        if (!isImageSliceState(state)) { throw new Error('Unexpected state type'); }
        const fillHoles = state.fillHoles.entities[id];
        doAssert(fillHoles, 'fill holes post processing', id);
        targets[CustomizationObjectType.PostProcessing].push(getParent(state, fillHoles).id);
    }

    for (const id of targets[CustomizationObjectType.KeepLargestComponent]) {
        if (!isImageSliceState(state)) { throw new Error('Unexpected state type'); }
        const keepLargestComponent = state.keepLargestComponent.entities[id];
        doAssert(keepLargestComponent, 'keep largest component post processing', id);
        targets[CustomizationObjectType.PostProcessing].push(getParent(state, keepLargestComponent).id);
    }

    for (const id of targets[CustomizationObjectType.PostProcessing]) {
        if (!isImageSliceState(state)) { throw new Error('Unexpected state type'); }
        const postProcessing = state.imagePostProcessing.entities[id];
        doAssert(postProcessing, 'image post processing', id);
        targets[CustomizationObjectType.CustomizationOutput].push(getParent(state, postProcessing).id);
    }

    for (const id of targets[CustomizationObjectType.ContourGeneration]) {
        if (!isImageSliceState(state)) { throw new Error('Unexpected state type'); }
        const contourGeneration = state.imageContourGeneration.entities[id];
        doAssert(contourGeneration, 'contour generation', id);
        targets[CustomizationObjectType.CustomizationOutput].push(getParent(state, contourGeneration).id);
    }

    for (const id of targets[CustomizationObjectType.OutputGeometry]) {
        if (!isImageSliceState(state)) { throw new Error('Unexpected state type'); }
        const outputGeometry = state.imageOutputGeometry.entities[id];
        doAssert(outputGeometry, 'output geometry', id);
        targets[CustomizationObjectType.CustomizationOutput].push(getParent(state, outputGeometry).id);
    }

    for (const id of targets[CustomizationObjectType.DicomTag]) {
        if (!isImageSliceState(state)) { throw new Error('Unexpected state type'); }
        const dicomTag = state.imageDicomTags.entities[id];
        doAssert(dicomTag, 'dicom restriction tag', id);
        targets[CustomizationObjectType.DicomRestriction].push(getParent(state, dicomTag).id);
    }

    for (const id of targets[CustomizationObjectType.DicomRestriction]) {
        if (!isImageSliceState(state)) { throw new Error('Unexpected state type'); }
        const dicomRestriction = state.imageDicomRestrictions.entities[id];
        doAssert(dicomRestriction, 'dicom restriction', id);
        targets[CustomizationObjectType.CustomizationOutput].push(getParent(state, dicomRestriction).id);
    }

    for (const id of targets[CustomizationObjectType.Target]) {
        if (!isDoseSliceState(state)) { throw new Error('Unexpected state type'); }
        const target = state.doseTargets.entities[id];
        doAssert(target, 'target customization', id);
        targets[CustomizationObjectType.CustomizationOutput].push(getParent(state, target).id);
    }

    for (const id of targets[CustomizationObjectType.Roi]) {
        const roi = isContouringSliceState(state) ? state.roiCustomizations.entities[id] : isDoseSliceState(state) ? state.doseRois.entities[id] : null;
        if (roi === null) { throw new Error('Unexpected state type'); }
        doAssert(roi, 'ROI customization', id);
        targets[CustomizationObjectType.CustomizationOutput].push(getParent(state, roi).id);
    }

    for (const id of targets[CustomizationObjectType.AdaptRoiRule]) {
        const roiRule = isAdaptSliceState(state) ? state.adaptRoiRules.entities[id] : null;
        if (roiRule === null) { throw new Error('Unexpected state type'); }
        doAssert(roiRule, 'adapt ROI rule', id);
        targets[CustomizationObjectType.CustomizationOutput].push(getParent(state, roiRule).id);
    }

    for (const id of targets[CustomizationObjectType.Metadata]) {
        const metadata = state.outputMetadata.entities[id];
        doAssert(metadata, 'customization metadata', id);
        targets[CustomizationObjectType.CustomizationOutput].push(getParent(state, metadata).id);
    }

    for (const id of targets[CustomizationObjectType.CustomizationOutput]) {
        const output = isContouringSliceState(state) ? state.contourOutputs.entities[id] :
            isDoseSliceState(state) ? state.doseOutputs.entities[id] :
                isImageSliceState(state) ? state.imageOutputs.entities[id] :
                    isAdaptSliceState(state) ? state.adaptOutputs.entities[id] :
                        null;
        if (output === null) { throw new Error('Unexpected state type'); }
        doAssert(output, 'customization output', id);
        targets[CustomizationObjectType.CustomizationBase].push(getParent(state, output).id);
    }

    for (const id of targets[CustomizationObjectType.CustomizationBase]) {
        const customization = state.customizationBases.entities[id];
        doAssert(customization, 'customization base', id);
        targets[CustomizationObjectType.Model].push(getParent(state, customization).id);
    }


    // apply updates
    if (isContouringSliceState(state)) {
        applyContourUpdates(targets, state);
    } else if (isDoseSliceState(state)) {
        applyDoseUpdates(targets, state);
    } else if (isImageSliceState(state)) {
        applyImageUpdates(targets, state);
    } else if (isAdaptSliceState(state)) {
        applyAdaptUpdates(targets, state);
    } else { throw new Error('Unexpected state type'); }
}

const applyContourUpdates = (targets: { [parentType in CustomizationObjectType]: string[] }, state: ContouringSliceState) => {
    if (targets[CustomizationObjectType.Model].length > 0) {
        contourModelAdapter.updateMany(state.models, targets[CustomizationObjectType.Model].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.CustomizationBase].length > 0) {
        contourCustomizationBaseAdapter.updateMany(state.customizationBases, targets[CustomizationObjectType.CustomizationBase].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.CustomizationOutput].length > 0) {
        contourCustomizationOutputAdapter.updateMany(state.contourOutputs, targets[CustomizationObjectType.CustomizationOutput].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.Metadata].length > 0) {
        contourOutputMetadataAdapter.updateMany(state.outputMetadata, targets[CustomizationObjectType.Metadata].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.Roi].length > 0) {
        contourRoiCustomizationAdapter.updateMany(state.roiCustomizations, targets[CustomizationObjectType.Roi].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.GlobalRoi].length > 0) {
        globalRoiCustomizationAdapter.updateMany(state.globalRoiCustomizations, targets[CustomizationObjectType.GlobalRoi].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.AeTitleRule].length > 0) {
        contourAeTitleRuleAdapter.updateMany(state.aeTitleRules, targets[CustomizationObjectType.AeTitleRule].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.DicomRule].length > 0) {
        contourDicomRuleAdapter.updateMany(state.dicomRules, targets[CustomizationObjectType.DicomRule].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.DicomAttributeRule].length > 0) {
        contourDicomAttributeRuleAdapter.updateMany(state.dicomAttributeRules, targets[CustomizationObjectType.DicomAttributeRule].map(id => ({ id, changes: { isModified: true } })));
    }
}

const applyDoseUpdates = (targets: { [parentType in CustomizationObjectType]: string[] }, state: DoseSliceState) => {
    if (targets[CustomizationObjectType.Model].length > 0) {
        doseModelAdapter.updateMany(state.models, targets[CustomizationObjectType.Model].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.CustomizationBase].length > 0) {
        doseCustomizationBaseAdapter.updateMany(state.customizationBases, targets[CustomizationObjectType.CustomizationBase].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.CustomizationOutput].length > 0) {
        doseOutputAdapter.updateMany(state.doseOutputs, targets[CustomizationObjectType.CustomizationOutput].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.Metadata].length > 0) {
        doseOutputMetadataAdapter.updateMany(state.outputMetadata, targets[CustomizationObjectType.Metadata].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.Roi].length > 0) {
        doseRoiAdapter.updateMany(state.doseRois, targets[CustomizationObjectType.Roi].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.Target].length > 0) {
        doseTargetAdapter.updateMany(state.doseTargets, targets[CustomizationObjectType.Target].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.AeTitleRule].length > 0) {
        doseAeTitleRuleAdapter.updateMany(state.aeTitleRules, targets[CustomizationObjectType.AeTitleRule].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.DicomRule].length > 0) {
        doseDicomRuleAdapter.updateMany(state.dicomRules, targets[CustomizationObjectType.DicomRule].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.DicomAttributeRule].length > 0) {
        doseDicomAttributeRuleAdapter.updateMany(state.dicomAttributeRules, targets[CustomizationObjectType.DicomAttributeRule].map(id => ({ id, changes: { isModified: true } })));
    }
}

const applyImageUpdates = (targets: { [parentType in CustomizationObjectType]: string[] }, state: ImageSliceState) => {
    if (targets[CustomizationObjectType.Model].length > 0) {
        imageModelAdapter.updateMany(state.models, targets[CustomizationObjectType.Model].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.CustomizationBase].length > 0) {
        imageCustomizationBaseAdapter.updateMany(state.customizationBases, targets[CustomizationObjectType.CustomizationBase].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.CustomizationOutput].length > 0) {
        imageOutputAdapter.updateMany(state.imageOutputs, targets[CustomizationObjectType.CustomizationOutput].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.Metadata].length > 0) {
        imageOutputMetadataAdapter.updateMany(state.outputMetadata, targets[CustomizationObjectType.Metadata].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.DicomRestriction].length > 0) {
        imageDicomRestrictionAdapter.updateMany(state.imageDicomRestrictions, targets[CustomizationObjectType.DicomRestriction].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.DicomTag].length > 0) {
        imageDicomTagAdapter.updateMany(state.imageDicomTags, targets[CustomizationObjectType.DicomTag].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.OutputGeometry].length > 0) {
        imageOutputGeometryAdapter.updateMany(state.imageOutputGeometry, targets[CustomizationObjectType.OutputGeometry].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.ContourGeneration].length > 0) {
        imageContourGenerationAdapter.updateMany(state.imageContourGeneration, targets[CustomizationObjectType.ContourGeneration].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.PostProcessing].length > 0) {
        imagePostProcessingAdapter.updateMany(state.imagePostProcessing, targets[CustomizationObjectType.PostProcessing].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.KeepLargestComponent].length > 0) {
        keepLargestComponentAdapter.updateMany(state.keepLargestComponent, targets[CustomizationObjectType.KeepLargestComponent].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.FillHoles].length > 0) {
        fillHolesAdapter.updateMany(state.fillHoles, targets[CustomizationObjectType.FillHoles].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.BodyMask].length > 0) {
        bodyMaskAdapter.updateMany(state.bodyMasks, targets[CustomizationObjectType.BodyMask].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.HoleMask].length > 0) {
        holeMaskAdapter.updateMany(state.holeMasks, targets[CustomizationObjectType.HoleMask].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.AeTitleRule].length > 0) {
        imageAeTitleRuleAdapter.updateMany(state.aeTitleRules, targets[CustomizationObjectType.AeTitleRule].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.DicomRule].length > 0) {
        imageDicomRuleAdapter.updateMany(state.dicomRules, targets[CustomizationObjectType.DicomRule].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.DicomAttributeRule].length > 0) {
        imageDicomAttributeRuleAdapter.updateMany(state.dicomAttributeRules, targets[CustomizationObjectType.DicomAttributeRule].map(id => ({ id, changes: { isModified: true } })));
    }
}

const applyAdaptUpdates = (targets: { [parentType in CustomizationObjectType]: string[] }, state: AdaptSliceState) => {
    if (targets[CustomizationObjectType.Model].length > 0) {
        adaptModelAdapter.updateMany(state.models, targets[CustomizationObjectType.Model].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.CustomizationBase].length > 0) {
        adaptCustomizationBaseAdapter.updateMany(state.customizationBases, targets[CustomizationObjectType.CustomizationBase].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.CustomizationOutput].length > 0) {
        adaptOutputAdapter.updateMany(state.adaptOutputs, targets[CustomizationObjectType.CustomizationOutput].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.AdaptRoiRule].length > 0) {
        adaptRoiRuleAdapter.updateMany(state.adaptRoiRules, targets[CustomizationObjectType.AdaptRoiRule].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.Metadata].length > 0) {
        adaptOutputMetadataAdapter.updateMany(state.outputMetadata, targets[CustomizationObjectType.Metadata].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.AeTitleRule].length > 0) {
        adaptAeTitleRuleAdapter.updateMany(state.aeTitleRules, targets[CustomizationObjectType.AeTitleRule].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.DicomRule].length > 0) {
        adaptDicomRuleAdapter.updateMany(state.dicomRules, targets[CustomizationObjectType.DicomRule].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.DicomAttributeRule].length > 0) {
        adaptDicomAttributeRuleAdapter.updateMany(state.dicomAttributeRules, targets[CustomizationObjectType.DicomAttributeRule].map(id => ({ id, changes: { isModified: true } })));
    }
}


/** 
 * Find and returns the immediate parent object for a segmentation model customization object if one is found from the redux store.
 * Throws otherwise. 
 * 
 * This should only be called from within contouringConfig reducers (or from unit tests).
 * 
 * @param obj The object for which a parent is attempted to be found.
 * */
export const getParent = (state: AnySliceState, obj: AnyCustomizationType): AnyCustomizationType => {
    if (isModel(obj)) {
        throw new Error('Segmentation models thenselves have no parent objects!');
    }

    if (isCustomizationBase(obj)) {
        const model = Object.values(state.models.entities).find(m => m?.customizations.includes(obj.id));
        doAssert(model, 'model');
        return model;
    }

    if (isCustomizationOutput(obj)) {
        const base = Object.values(state.customizationBases.entities).find(c => c?.outputs.includes(obj.id));
        doAssert(base, 'customization base');
        return base;
    }

    if (isContouringRoi(obj) && isContouringSliceState(state)) {
        const output = obj.customizationOutputId ?
            state.contourOutputs.entities[obj.customizationOutputId] :
            Object.values(state.contourOutputs.entities).find(o => o?.rois.includes(obj.id));
        doAssert(output, 'contour output');
        return output;
    }

    if (isDoseRoi(obj) && isDoseSliceState(state)) {
        const output = obj.outputId ?
            state.doseOutputs.entities[obj.outputId] :
            Object.values(state.doseOutputs.entities).find(o => o?.rois.includes(obj.id));
        doAssert(output, 'dose output');
        return output;
    }

    if (isDoseTarget(obj) && isDoseSliceState(state)) {
        const output = obj.outputId ?
            state.doseOutputs.entities[obj.outputId] :
            Object.values(state.doseOutputs.entities).find(o => o?.targets.includes(obj.id));
        doAssert(output, 'dose output');
        return output;
    }

    if (isAdaptRoiRule(obj) && isAdaptSliceState(state)) {
        const output = obj.outputId ?
            state.adaptOutputs.entities[obj.outputId] :
            Object.values(state.adaptOutputs.entities).find(o => o?.roiSelection?.roiRules.includes(obj.id));
        doAssert(output, 'adapt output');
        return output;
    }

    if (isImageDicomRestriction(obj) && isImageSliceState(state)) {
        const output = obj.outputId ?
            state.imageOutputs.entities[obj.outputId] :
            Object.values(state.imageOutputs.entities).find(o => o?.dicomRestriction === obj.id);
        doAssert(output, 'image output');
        return output;
    }

    if (isImageDicomTag(obj) && isImageSliceState(state)) {
        const dicomRestriction = obj.dicomRestrictionId ?
            state.imageDicomRestrictions.entities[obj.dicomRestrictionId] :
            Object.values(state.imageDicomRestrictions.entities).find(o => o?.tags.includes(obj.id));
        doAssert(dicomRestriction, 'dicom restriction');
        return dicomRestriction;
    }

    if (isImageOutputGeometry(obj) && isImageSliceState(state)) {
        const output = obj.outputId ?
            state.imageOutputs.entities[obj.outputId] :
            Object.values(state.imageOutputs.entities).find(o => o?.outputGeometry === obj.id);
        doAssert(output, 'image output');
        return output;
    }

    if (isImageContourGeneration(obj) && isImageSliceState(state)) {
        const output = obj.outputId ?
            state.imageOutputs.entities[obj.outputId] :
            Object.values(state.imageOutputs.entities).find(o => o?.contourGeneration === obj.id);
        doAssert(output, 'image output');
        return output;
    }

    if (isImagePostProcessing(obj) && isImageSliceState(state)) {
        const output = obj.outputId ?
            state.imageOutputs.entities[obj.outputId] :
            Object.values(state.imageOutputs.entities).find(o => o?.postProcessing === obj.id);
        doAssert(output, 'image output');
        return output;
    }

    if (isKeepLargestComponent(obj) && isImageSliceState(state)) {
        const postProcessing = obj.postProcessingId ?
            state.imagePostProcessing.entities[obj.postProcessingId] :
            Object.values(state.imagePostProcessing.entities).find(o => o?.keepLargestComponent === obj.id);
        doAssert(postProcessing, 'post processing');
        return postProcessing;
    }

    if (isFillHoles(obj) && isImageSliceState(state)) {
        const postProcessing = obj.postProcessingId ?
            state.imagePostProcessing.entities[obj.postProcessingId] :
            Object.values(state.imagePostProcessing.entities).find(o => o?.fillHoles === obj.id);
        doAssert(postProcessing, 'post processing');
        return postProcessing;
    }

    if (isBodyMask(obj) && isImageSliceState(state)) {
        const fillHoles = obj.fillHolesId ?
            state.fillHoles.entities[obj.fillHolesId] :
            Object.values(state.fillHoles.entities).find(o => o?.bodyMask === obj.id);
        doAssert(fillHoles, 'fill holes post processing');
        return fillHoles;
    }

    if (isHoleMask(obj) && isImageSliceState(state)) {
        const fillHoles = obj.fillHolesId ?
            state.fillHoles.entities[obj.fillHolesId] :
            Object.values(state.fillHoles.entities).find(o => o?.holeMask === obj.id);
        doAssert(fillHoles, 'fill holes post processing');
        return fillHoles;
    }

    if (isAeTitleRule(obj)) {
        const base = obj.modelCustomizationBaseId ?
            state.customizationBases.entities[obj.modelCustomizationBaseId] :
            Object.values(state.customizationBases.entities).find(c => c?.aeTitleRules.includes(obj.id));
        doAssert(base, 'customization base');
        return base;
    }

    if (isDicomRule(obj)) {
        const base = obj.modelCustomizationBaseId ?
            state.customizationBases.entities[obj.modelCustomizationBaseId] :
            Object.values(state.customizationBases.entities).find(c => c?.dicomRules.includes(obj.id));
        doAssert(base, 'customization base');
        return base;
    }

    if (isDicomAttributeRule(obj)) {
        const dicomRule = state.dicomRules.entities[obj.parentDicomRuleId];
        doAssert(dicomRule, 'DICOM rule');
        return dicomRule;
    }

    if (isOutputMetadata(obj)) {
        let output: CustomizationOutput | undefined = undefined;
        if (isContouringSliceState(state)) {
            output = obj.modelCustomizationOutputId ?
                state.contourOutputs.entities[obj.modelCustomizationOutputId] :
                Object.values(state.contourOutputs.entities).find(o => o?.metadata.includes(obj.id));
        } else if (isDoseSliceState(state)) {
            output = obj.modelCustomizationOutputId ?
                state.doseOutputs.entities[obj.modelCustomizationOutputId] :
                Object.values(state.doseOutputs.entities).find(o => o?.metadata.includes(obj.id));
        } else if (isImageSliceState(state)) {
            output = obj.modelCustomizationOutputId ?
                state.imageOutputs.entities[obj.modelCustomizationOutputId] :
                Object.values(state.imageOutputs.entities).find(o => o?.metadata.includes(obj.id));
        } else if (isAdaptSliceState(state)) {
            output = obj.modelCustomizationOutputId ?
                state.adaptOutputs.entities[obj.modelCustomizationOutputId] :
                Object.values(state.adaptOutputs.entities).find(o => o?.metadata.includes(obj.id));
        }

        doAssert(output, 'customization output');
        return output;
    }

    throw new Error('Unsupported type');
};

/** Returns all store IDs for FormValidationError objects matching given itemId (e.g. roiId) */
export const getFormValidationErrorIdsForItem = (state: AnySliceState, itemId: string): string[] => {
    const validationErrors = Object.values(state.formValidationErrors.entities).filter(e => e?.itemId === itemId);
    return validationErrors.map(e => e.id);
}

/** Returns all store IDs for FormValidationError objects matching given itemIds (e.g. roiId) */
export const getFormValidationErrorIdsForItems = (state: AnySliceState, itemIds: string[]): string[] => {
    const validationErrors = Object.values(state.formValidationErrors.entities).filter(e => itemIds.includes(e.itemId));
    return validationErrors.map(e => e.id);
}

/** Duplicate FormValidationErrors for given itemIds */
export const duplicateFormValidationErrors = (state: AnySliceState, duplicatedIds: DuplicatedIdMap[]): FormValidationError[] => {

    const sourceIds = duplicatedIds.map(d => d.sourceId);
    const errorsToDuplicate = Object.values(state.formValidationErrors.entities).filter(e => sourceIds.includes(e.itemId));

    const duplicatedErrors: FormValidationError[] = [];
    duplicatedErrors.push(...errorsToDuplicate.map(e => duplicateFormValidationError(e, duplicatedIds.find(d => d.sourceId === e.itemId)!.targetId)));

    return duplicatedErrors;
}


/** Converts raw(-ish) backend validation error objects into matching view model objects that can be used in UI
 * to pinpoint the exact location of the validation error.
 */
export const convertBackendValidationErrorToViewModels = (state: ContouringSliceState | DoseSliceState, error: BackendValidationError): BackendValidationErrorViewModel[] => {
    const { json } = error;

    if (json === undefined) {
        throw new Error('Could not retrieve proper error messages as given JSON is undefined');
    }

    const jsonData = JSON.parse(json);
    const storeErrors: BackendValidationErrorViewModel[] = [];

    error.validationErrors.forEach(e => {
        let cursor = jsonData;
        let cursorPath = '';
        let targetType = CustomizationObjectType.None;
        let target: { id: string, type: CustomizationObjectType } | undefined = undefined;
        for (const locValue of e.loc) {
            if (locValue === LOC_VALUE_BODY) {
                cursor = jsonData;
                cursorPath = 'body';
            } else if (targetType === CustomizationObjectType.CodingScheme) {
                // special case handling for fma id entries
                break;
            }
            else {
                cursor = cursor[locValue];
                cursorPath += `/${locValue}`;
                if (cursor === undefined) {
                    if (target && target.type === CustomizationObjectType.AeTitleRule && locValue === 'action') {
                        // special case for ae titles where we might have two 'action' nodes
                        // sequentially -- in this case just let the code fall through
                    } else {
                        console.error(target)
                        throw new Error(`Could not find JSON node matching error message loc: ${cursorPath}`);
                    }
                }
            }

            // if locValue is a string, we need to figure out what the next type of object will be
            if (isString(locValue)) {
                targetType = getTargetType(locValue);
            }

            // if locValue is a number, we need to figure out which exact object of target type we're dealing with
            else if (isNumber(locValue)) {
                target = getTargetObject(state, targetType, cursor, target, cursorPath);
            }
        }

        if (target) {

            // replace dicom attributes with their parent dicom rules for now
            if (target.type === CustomizationObjectType.DicomAttributeRule) {
                const dicomAttribute = state.dicomAttributeRules.entities[target.id];
                if (dicomAttribute) {
                    target.id = dicomAttribute.parentDicomRuleId;
                    target.type = CustomizationObjectType.DicomRule;
                }
            }

            const field = e.loc && e.loc.length > 0 ? e.loc[e.loc.length - 1] as string : undefined;

            const validationError = {
                id: target.id,
                type: target.type,
                message: e.msg,
                detail: e.type,
                field: field,
                ctx: JSON.stringify(e.ctx),
            }

            storeErrors.push(validationError);

            if (isContouringSliceState(state) && target.type === CustomizationObjectType.Roi) {
                // create a copy for global roi if there's a matching one (and one hasn't been made already)
                const globalRoi = Object.values(state.globalRoiCustomizations.entities).find(gr => gr?.coveredRois.includes(target!.id));
                if (globalRoi && !storeErrors.find(e => e.id === globalRoi.id)) {
                    storeErrors.push({ ...validationError, id: globalRoi.id });
                }
            }
        }
    });

    return storeErrors;
}

/** Helper function for convertBackendValidationErrorToViewModels that returns customization object type matching the row currently being parsed. */
const getTargetType = (loc: string | number): CustomizationObjectType => {
    if (!isString(loc)) {
        return CustomizationObjectType.None;
    }

    switch (loc) {
        case 'rois':
            return CustomizationObjectType.Roi;
        case 'targets':
            return CustomizationObjectType.Target;
        case 'metadata':
            return CustomizationObjectType.Metadata;
        case 'files':
            return CustomizationObjectType.CustomizationOutput;
        case 'customizations':
            return CustomizationObjectType.CustomizationBase;
        case 'triggers':
            return CustomizationObjectType.TriggerRule;
        case 'body':
            return CustomizationObjectType.Model;
        case 'fma_id':
            return CustomizationObjectType.CodingScheme;
        case 'physical_properties':
            return CustomizationObjectType.PhysicalProperties;
        default:
            return CustomizationObjectType.None;
    }
}

/** Helper function for convertBackendValidationErrorToViewModels for parsing a python-based backend error message iteratively. */
const getTargetObject = (state: ContouringSliceState | DoseSliceState, type: CustomizationObjectType, jsonCursor: any, target: { id: string, type: CustomizationObjectType } | undefined, cursorPath: string) => {
    const currentId = jsonCursor[UI_ID_ATTRIBUTE];
    switch (type) {
        case CustomizationObjectType.Model:
            {
                const model = state.models.entities[currentId];
                if (model === undefined) { throw new Error(`Could not find matching segmentation model for validation error json node ${cursorPath}`); }
                return { id: model.id, type };
            }

        case CustomizationObjectType.TriggerRule:
            {
                if (jsonCursor['action'] !== undefined) {
                    const aeTitleRule = state.aeTitleRules.entities[currentId];
                    if (aeTitleRule) {
                        return { id: aeTitleRule.id, type: CustomizationObjectType.AeTitleRule };
                    }
                } else if (jsonCursor['dicom_attributes'] !== undefined) {
                    const dicomRule = state.dicomRules.entities[currentId];
                    if (dicomRule) {
                        return { id: dicomRule.id, type: CustomizationObjectType.DicomRule };
                    }
                }

                throw new Error(`Could not retrieve either an AE title rule or DICOM rule for ${cursorPath}`);
            }

        case CustomizationObjectType.CustomizationBase:
            {
                const customizationBase = state.customizationBases.entities[currentId];
                if (customizationBase === undefined) { throw new Error(`Could not find matching customization base for validation error json node ${cursorPath}`); }
                return { id: customizationBase.id, type };
            }

        case CustomizationObjectType.CustomizationOutput:
            {
                const customizationOutput = isContouringSliceState(state) ? state.contourOutputs.entities[currentId] :
                    isDoseSliceState(state) ? state.doseOutputs.entities[currentId] : null;
                if (!customizationOutput) { throw new Error(`Unsupported state for ${type}`); }
                if (customizationOutput === undefined) { throw new Error(`Could not find matching customization output for validation error json node ${cursorPath}`); }
                return { id: customizationOutput.id, type };
            }

        case CustomizationObjectType.Metadata:
            {
                const metadata = state.outputMetadata.entities[currentId];
                if (metadata === undefined) { throw new Error(`Could not find matching metadata entry for validation error json node ${cursorPath}`); }
                return { id: metadata.id, type };
            }

        case CustomizationObjectType.Roi:
            {
                const roi = isContouringSliceState(state) ? state.roiCustomizations.entities[currentId] :
                    isDoseSliceState(state) ? state.doseRois.entities[currentId] : null;
                if (!roi) { throw new Error(`Unsupported state for ${type}`); }
                if (roi === undefined) { throw new Error(`Could not find matching roi customization for validation error json node ${cursorPath}`); }
                return { id: roi.id, type };
            }

        case CustomizationObjectType.Target:
            {
                const doseTarget = isDoseSliceState(state) ? state.doseTargets.entities[currentId] : null;
                if (!doseTarget) { throw new Error(`Unsupported state for ${type}`); }
                if (doseTarget === undefined) { throw new Error(`Could not find matching target customization for validation error json node ${cursorPath}`); }
                return { id: doseTarget.id, type };
            }

        case CustomizationObjectType.PhysicalProperties:
            {
                // return the previous roi but with the new PhysicalProperties type
                if (isContouringSliceState(state)) {
                    const roi = target && target.type === CustomizationObjectType.Roi ? state.roiCustomizations.entities[target.id] : undefined;
                    if (roi === undefined) { throw new Error(`Could not find matching roi customization for validation error json node ${cursorPath}`); }
                    return { id: roi.id, type };
                } else {
                    throw new Error(`Unsupported state for ${type}`);
                }
            }

        default:
            throw new Error(`No valid type given for getTargetObject (${type})`);
    }
}
