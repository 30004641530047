import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { BodyMask, ContourAttachSeries, FillHoles, HoleMask, ImageContourGeneration, ImageCustomizationEntities, ImageCustomizationOutput, ImageDicomRestriction, ImageDicomTag, ImageOutputGeometry, ImagePostProcessing, KeepLargestComponent } from "./image-types";
import { AeTitleRule, CustomizationBase, CustomizationObjectType, DicomAttributeRule, DicomRule, Model, ModelVisibility, OutputMetadataItem } from "../global-types/customization-types";
import { BackendValidationErrorViewModel, FormValidationError } from "../global-types/store-errors";
import { has, isObject } from "lodash-es";
import { naturalSort } from "../../util/sort";
import { ImageSliceWrapper } from "../global-types/modelTypeReducerHelper/imageSliceWrapper";
import { setObjectAndAncestorsAsModified } from "../global-types/reducer-helpers";
import { createNewImageDicomTag } from "./image-helpers";
import { LengthValue } from "../global-types/units";

// entity adapters (see https://redux-toolkit.js.org/api/createEntityAdapter) for image customization types
export const modelAdapter = createEntityAdapter<Model>();
export const customizationBaseAdapter = createEntityAdapter<CustomizationBase>();
export const imageOutputAdapter = createEntityAdapter<ImageCustomizationOutput>();
export const outputMetadataAdapter = createEntityAdapter<OutputMetadataItem>();

export const imageDicomRestrictionAdapter = createEntityAdapter<ImageDicomRestriction>();
export const imageOutputGeometryAdapter = createEntityAdapter<ImageOutputGeometry>();
export const imageContourGenerationAdapter = createEntityAdapter<ImageContourGeneration>();
export const imagePostProcessingAdapter = createEntityAdapter<ImagePostProcessing>();
export const imageDicomTagAdapter = createEntityAdapter<ImageDicomTag>();
export const keepLargestComponentAdapter = createEntityAdapter<KeepLargestComponent>();
export const fillHolesAdapter = createEntityAdapter<FillHoles>();
export const bodyMaskAdapter = createEntityAdapter<BodyMask>();
export const holeMaskAdapter = createEntityAdapter<HoleMask>();

export const aeTitleRuleAdapter = createEntityAdapter<AeTitleRule>();
export const dicomRuleAdapter = createEntityAdapter<DicomRule>();
export const dicomAttributeRuleAdapter = createEntityAdapter<DicomAttributeRule>();

// entity adapter selectors -- these should not be exported/used directly but rather through the slice selectors
export const modelAdapterSelectors = modelAdapter.getSelectors((state: ImageSliceState) => state.models);
export const customizationBaseAdapterSelectors = customizationBaseAdapter.getSelectors((state: ImageSliceState) => state.customizationBases);
export const imageOutputAdapterSelectors = imageOutputAdapter.getSelectors((state: ImageSliceState) => state.imageOutputs);
export const outputMetadataAdapterSelectors = outputMetadataAdapter.getSelectors((state: ImageSliceState) => state.outputMetadata);

export const imageDicomRestrictionAdapterSelectors = imageDicomRestrictionAdapter.getSelectors((state: ImageSliceState) => state.imageDicomRestrictions);
export const imageOutputGeometryAdapterSelectors = imageOutputGeometryAdapter.getSelectors((state: ImageSliceState) => state.imageOutputGeometry);
export const imageContourGenerationAdapterSelectors = imageContourGenerationAdapter.getSelectors((state: ImageSliceState) => state.imageContourGeneration);
export const imagePostProcessingAdapterSelectors = imagePostProcessingAdapter.getSelectors((state: ImageSliceState) => state.imagePostProcessing);
export const imageDicomTagAdapterSelectors = imageDicomTagAdapter.getSelectors((state: ImageSliceState) => state.imageDicomTags);
export const keepLargestComponentAdapterSelectors = keepLargestComponentAdapter.getSelectors((state: ImageSliceState) => state.keepLargestComponent);
export const fillHolesAdapterSelectors = fillHolesAdapter.getSelectors((state: ImageSliceState) => state.fillHoles);
export const bodyMaskAdapterSelectors = bodyMaskAdapter.getSelectors((state: ImageSliceState) => state.bodyMasks);
export const holeMaskAdapterSelectors = holeMaskAdapter.getSelectors((state: ImageSliceState) => state.holeMasks);


export const aeTitleRuleAdapterSelectors = aeTitleRuleAdapter.getSelectors((state: ImageSliceState) => state.aeTitleRules);
export const dicomRuleAdapterSelectors = dicomRuleAdapter.getSelectors((state: ImageSliceState) => state.dicomRules);
export const dicomAttributeRuleAdapterSelectors = dicomAttributeRuleAdapter.getSelectors((state: ImageSliceState) => state.dicomAttributeRules);


/** An entity adapter for CRUD operations for backend-based customization validation errors */
export const backendValidationErrorAdapter = createEntityAdapter<BackendValidationErrorViewModel>();
/** An entity adapter for CRUD operations for UI-supplied customization validation errors */
export const formValidationErrorAdapter = createEntityAdapter<FormValidationError>();

// selectors for 'original' data
export const originalOutputMetadataAdapterSelectors = outputMetadataAdapter.getSelectors((state: ImageSliceState) => state.originalOutputMetadata);
export const originalImageDicomTagAdapterSelectors = imageDicomTagAdapter.getSelectors((state: ImageSliceState) => state.originalImageDicomTags);

// validation error selectors
export const customizationValidationErrorAdapterSelectors = backendValidationErrorAdapter.getSelectors((state: ImageSliceState) => state.backendValidationErrors);
export const formValidationErrorAdapterSelectors = formValidationErrorAdapter.getSelectors((state: ImageSliceState) => state.formValidationErrors);


/** Type guard for ImageSliceState type. */
export const isImageSliceState = (obj: any): obj is ImageSliceState => {
    return has(obj, 'models') &&
        has(obj, 'imageOutputs') &&
        has(obj, 'imageDicomRestrictions') &&
        has(obj, 'imageOutputGeometry') &&
        has(obj, 'imageContourGeneration') &&
        has(obj, 'imagePostProcessing') &&
        has(obj, 'imageDicomRestrictions.entities') &&
        isObject(obj.imageDicomRestrictions.entities);
}


export type ImageSliceState = {
    /** In-memory CRUD-based normalized data structure for customization objects modelling image models. */
    models: EntityState<Model, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization base objects. */
    customizationBases: EntityState<CustomizationBase, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization outputs. */
    imageOutputs: EntityState<ImageCustomizationOutput, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization metadata. */
    outputMetadata: EntityState<OutputMetadataItem, string>,

    /** In-memory CRUD-based normalized data structure for customization objects modelling image+ dicom restriction objects. */
    imageDicomRestrictions: EntityState<ImageDicomRestriction, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling image+ output geometry objects. */
    imageOutputGeometry: EntityState<ImageOutputGeometry, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling image+ contour generation objects. */
    imageContourGeneration: EntityState<ImageContourGeneration, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling image+ post-processing objects. */
    imagePostProcessing: EntityState<ImagePostProcessing, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling image+ dicom restriction tag objects. */
    imageDicomTags: EntityState<ImageDicomTag, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling image+ keep largest component post-processing objects. */
    keepLargestComponent: EntityState<KeepLargestComponent, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling image+ fill holes post-processing objects. */
    fillHoles: EntityState<FillHoles, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling image+ body mask post-processing objects. */
    bodyMasks: EntityState<BodyMask, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling image+ hole mask post-processing objects. */
    holeMasks: EntityState<HoleMask, string>,

    /** In-memory CRUD-based normalized data structure for customization objects modelling AE Title-based model selection rules. */
    aeTitleRules: EntityState<AeTitleRule, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling DICOM-based model selection rules. */
    dicomRules: EntityState<DicomRule, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling DICOM attribute rules within DICOM-based model selection rules. */
    dicomAttributeRules: EntityState<DicomAttributeRule, string>,



    // these are for storing data directly from the backend, so any local modifications can be easily reverted back to them

    /** Original, unmodified directly-from-backend versions of image+ model customization data objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalModels: EntityState<Model, string>;
    /** Original, unmodified directly-from-backend versions of image+ customization base data objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalCustomizationBases: EntityState<CustomizationBase, string>;
    /** Original, unmodified directly-from-backend versions of image+ customization output data objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalImageOutputs: EntityState<ImageCustomizationOutput, string>;
    /** Original, unmodified directly-from-backend versions of image+ customization metadata objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalOutputMetadata: EntityState<OutputMetadataItem, string>;

    /** Original, unmodified directly-from-backend versions of image+ dicom restriction objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalImageDicomRestrictions: EntityState<ImageDicomRestriction, string>,
    /** Original, unmodified directly-from-backend versions of image+ output geometry objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalImageOutputGeometry: EntityState<ImageOutputGeometry, string>,
    /** Original, unmodified directly-from-backend versions of image+ contour generation objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalImageContourGeneration: EntityState<ImageContourGeneration, string>,
    /** Original, unmodified directly-from-backend versions of image+ post-processing objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalImagePostProcessing: EntityState<ImagePostProcessing, string>,
    /** Original, unmodified directly-from-backend versions of image+ dicom restriction tag objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalImageDicomTags: EntityState<ImageDicomTag, string>,
    /** Original, unmodified directly-from-backend versions of image+ keep largest component post-processing objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalKeepLargestComponent: EntityState<KeepLargestComponent, string>,
    /** Original, unmodified directly-from-backend versions of image+ fill holes post-processing objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalFillHoles: EntityState<FillHoles, string>,
    /** Original, unmodified directly-from-backend versions of image+ body mask post-processing objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalBodyMasks: EntityState<BodyMask, string>,
    /** Original, unmodified directly-from-backend versions of image+ hole mask post-processing objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalHoleMasks: EntityState<HoleMask, string>,

    /** Original, unmodified directly-from-backend versions of image+ AE Title rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalAeTitleRules: EntityState<AeTitleRule, string>;
    /** Original, unmodified directly-from-backend versions of image+ DICOM rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalDicomRules: EntityState<DicomRule, string>;
    /** Original, unmodified directly-from-backend versions of image+ DICOM attribute rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy. */
    originalDicomAttributeRules: EntityState<DicomAttributeRule, string>;


    /** Image+ customization validation errors coming from backend. */
    backendValidationErrors: EntityState<BackendValidationErrorViewModel, string>,
    /** Image+ customization validation errors generated in UI (in "forms"). */
    formValidationErrors: EntityState<FormValidationError, string>,

    /** An error that occurred when fetching image+ customizations, or null. */
    customizationFetchError: string | null,

    /** Saving of model customizations to backend is in progress if true, false otherwise. */
    isModelCustomizationSavingInProgress: boolean,

    /** True if resetting all image+ customizations to factory defaults is currently in progress, false otherwise. */
    isAllModelCustomizationsResetInProgress: boolean,

    /** True if resetting a single image+ customization output to factory defaults is currently in progress, false otherwise. */
    isOutputResetInProgress: boolean,

    /** Model customization save error, if any. */
    modelCustomizationSaveError: string | null,

    /** An error regarding model customization data that's not directly related to saving */
    modelCustomizationDataError: string | null,

    /** An error message regarding exporting or importing entire customization config to/from JSON */
    customizationImportExportError: string | null,
};



export const initialState: ImageSliceState = {
    models: modelAdapter.getInitialState(),
    customizationBases: customizationBaseAdapter.getInitialState(),
    imageOutputs: imageOutputAdapter.getInitialState(),
    outputMetadata: outputMetadataAdapter.getInitialState(),

    imageDicomRestrictions: imageDicomRestrictionAdapter.getInitialState(),
    imageOutputGeometry: imageOutputGeometryAdapter.getInitialState(),
    imageContourGeneration: imageContourGenerationAdapter.getInitialState(),
    imagePostProcessing: imagePostProcessingAdapter.getInitialState(),
    imageDicomTags: imageDicomTagAdapter.getInitialState(),
    keepLargestComponent: keepLargestComponentAdapter.getInitialState(),
    fillHoles: fillHolesAdapter.getInitialState(),
    bodyMasks: bodyMaskAdapter.getInitialState(),
    holeMasks: holeMaskAdapter.getInitialState(),

    aeTitleRules: aeTitleRuleAdapter.getInitialState(),
    dicomRules: dicomRuleAdapter.getInitialState(),
    dicomAttributeRules: dicomAttributeRuleAdapter.getInitialState(),

    originalModels: modelAdapter.getInitialState(),
    originalCustomizationBases: customizationBaseAdapter.getInitialState(),
    originalImageOutputs: imageOutputAdapter.getInitialState(),
    originalOutputMetadata: outputMetadataAdapter.getInitialState(),
    originalImageDicomRestrictions: imageDicomRestrictionAdapter.getInitialState(),
    originalImageOutputGeometry: imageOutputGeometryAdapter.getInitialState(),
    originalImageContourGeneration: imageContourGenerationAdapter.getInitialState(),
    originalImagePostProcessing: imagePostProcessingAdapter.getInitialState(),
    originalImageDicomTags: imageDicomTagAdapter.getInitialState(),
    originalKeepLargestComponent: keepLargestComponentAdapter.getInitialState(),
    originalFillHoles: fillHolesAdapter.getInitialState(),
    originalBodyMasks: bodyMaskAdapter.getInitialState(),
    originalHoleMasks: holeMaskAdapter.getInitialState(),
    originalAeTitleRules: aeTitleRuleAdapter.getInitialState(),
    originalDicomRules: dicomRuleAdapter.getInitialState(),
    originalDicomAttributeRules: dicomAttributeRuleAdapter.getInitialState(),

    backendValidationErrors: backendValidationErrorAdapter.getInitialState(),
    formValidationErrors: formValidationErrorAdapter.getInitialState(),

    customizationFetchError: null,
    isModelCustomizationSavingInProgress: false,
    isAllModelCustomizationsResetInProgress: false,
    isOutputResetInProgress: false,
    modelCustomizationSaveError: null,
    modelCustomizationDataError: null,
    customizationImportExportError: null,
};



const sliceWrapper = new ImageSliceWrapper();



/** Redux store slice for interacting with image+ configuration and customization. */
const imageSlice = createSlice({
    name: 'image',
    initialState,
    reducers: {
        /**
         * Sets current image model customization entities to provided values. This is used when initializing, swapping, or
         * resetting entire model customizations loaded into memory.
         * @param action.customizations All image-related model customization entities to load into memory. If set to undefined then
         * in-model entities are reset to default empty values.
         * @param action.errorMessage Optional error message.
         */
        modelCustomizationsSet(state, action: PayloadAction<{ customizations: ImageCustomizationEntities | null, errorMessage?: string }>) {
            const { customizations, errorMessage } = action.payload;

            if (customizations !== null) {
                // set everything according to input
                modelAdapter.setAll(state.models, customizations.models);
                customizationBaseAdapter.setAll(state.customizationBases, customizations.customizationBases);
                imageOutputAdapter.setAll(state.imageOutputs, customizations.imageOutputs);
                outputMetadataAdapter.setAll(state.outputMetadata, customizations.outputMetadata);

                imageDicomRestrictionAdapter.setAll(state.imageDicomRestrictions, customizations.imageDicomRestrictions);
                imageOutputGeometryAdapter.setAll(state.imageOutputGeometry, customizations.imageOutputGeometry);
                imageContourGenerationAdapter.setAll(state.imageContourGeneration, customizations.imageContourGeneration);
                imagePostProcessingAdapter.setAll(state.imagePostProcessing, customizations.imagePostProcessing);
                imageDicomTagAdapter.setAll(state.imageDicomTags, customizations.imageDicomTags);
                keepLargestComponentAdapter.setAll(state.keepLargestComponent, customizations.keepLargestComponent);
                fillHolesAdapter.setAll(state.fillHoles, customizations.fillHoles);
                bodyMaskAdapter.setAll(state.bodyMasks, customizations.bodyMasks);
                holeMaskAdapter.setAll(state.holeMasks, customizations.holeMasks);

                aeTitleRuleAdapter.setAll(state.aeTitleRules, customizations.aeTitleRules);
                dicomRuleAdapter.setAll(state.dicomRules, customizations.dicomRules);
                dicomAttributeRuleAdapter.setAll(state.dicomAttributeRules, customizations.dicomAttributeRules);

                // set original collections, also, so we can revert changes to the actual collection with ease
                modelAdapter.setAll(state.originalModels, customizations.models);
                customizationBaseAdapter.setAll(state.originalCustomizationBases, customizations.customizationBases);
                imageOutputAdapter.setAll(state.originalImageOutputs, customizations.imageOutputs);
                outputMetadataAdapter.setAll(state.originalOutputMetadata, customizations.outputMetadata);

                imageDicomRestrictionAdapter.setAll(state.originalImageDicomRestrictions, customizations.imageDicomRestrictions);
                imageOutputGeometryAdapter.setAll(state.originalImageOutputGeometry, customizations.imageOutputGeometry);
                imageContourGenerationAdapter.setAll(state.originalImageContourGeneration, customizations.imageContourGeneration);
                imagePostProcessingAdapter.setAll(state.originalImagePostProcessing, customizations.imagePostProcessing);
                imageDicomTagAdapter.setAll(state.originalImageDicomTags, customizations.imageDicomTags);
                keepLargestComponentAdapter.setAll(state.originalKeepLargestComponent, customizations.keepLargestComponent);
                fillHolesAdapter.setAll(state.originalFillHoles, customizations.fillHoles);
                bodyMaskAdapter.setAll(state.originalBodyMasks, customizations.bodyMasks);
                holeMaskAdapter.setAll(state.originalHoleMasks, customizations.holeMasks);

                aeTitleRuleAdapter.setAll(state.originalAeTitleRules, customizations.aeTitleRules);
                dicomRuleAdapter.setAll(state.originalDicomRules, customizations.dicomRules);
                dicomAttributeRuleAdapter.setAll(state.originalDicomAttributeRules, customizations.dicomAttributeRules);
            } else {
                // set everything to default values
                modelAdapter.setAll(state.models, modelAdapter.getInitialState().entities as Record<string, Model>);
                customizationBaseAdapter.setAll(state.customizationBases, customizationBaseAdapter.getInitialState().entities as Record<string, CustomizationBase>);
                imageOutputAdapter.setAll(state.imageOutputs, imageOutputAdapter.getInitialState().entities as Record<string, ImageCustomizationOutput>);
                outputMetadataAdapter.setAll(state.outputMetadata, outputMetadataAdapter.getInitialState().entities as Record<string, OutputMetadataItem>);

                imageDicomRestrictionAdapter.setAll(state.imageDicomRestrictions, imageDicomRestrictionAdapter.getInitialState().entities as Record<string, ImageDicomRestriction>);
                imageOutputGeometryAdapter.setAll(state.imageOutputGeometry, imageOutputGeometryAdapter.getInitialState().entities as Record<string, ImageOutputGeometry>);
                imageContourGenerationAdapter.setAll(state.imageContourGeneration, imageContourGenerationAdapter.getInitialState().entities as Record<string, ImageContourGeneration>);
                imagePostProcessingAdapter.setAll(state.imagePostProcessing, imagePostProcessingAdapter.getInitialState().entities as Record<string, ImagePostProcessing>);
                imageDicomTagAdapter.setAll(state.imageDicomTags, imageDicomTagAdapter.getInitialState().entities as Record<string, ImageDicomTag>);
                keepLargestComponentAdapter.setAll(state.keepLargestComponent, keepLargestComponentAdapter.getInitialState().entities as Record<string, KeepLargestComponent>);
                fillHolesAdapter.setAll(state.fillHoles, fillHolesAdapter.getInitialState().entities as Record<string, FillHoles>);
                bodyMaskAdapter.setAll(state.bodyMasks, bodyMaskAdapter.getInitialState().entities as Record<string, BodyMask>);
                holeMaskAdapter.setAll(state.holeMasks, holeMaskAdapter.getInitialState().entities as Record<string, HoleMask>);

                aeTitleRuleAdapter.setAll(state.aeTitleRules, aeTitleRuleAdapter.getInitialState().entities as Record<string, AeTitleRule>);
                dicomRuleAdapter.setAll(state.dicomRules, dicomRuleAdapter.getInitialState().entities as Record<string, DicomRule>);
                dicomAttributeRuleAdapter.setAll(state.dicomAttributeRules, dicomAttributeRuleAdapter.getInitialState().entities as Record<string, DicomAttributeRule>);

                // set original collections, also, so we can revert changes to the actual collection with ease
                modelAdapter.setAll(state.originalModels, modelAdapter.getInitialState().entities as Record<string, Model>);
                customizationBaseAdapter.setAll(state.originalCustomizationBases, customizationBaseAdapter.getInitialState().entities as Record<string, CustomizationBase>);
                imageOutputAdapter.setAll(state.originalImageOutputs, imageOutputAdapter.getInitialState().entities as Record<string, ImageCustomizationOutput>);
                outputMetadataAdapter.setAll(state.originalOutputMetadata, outputMetadataAdapter.getInitialState().entities as Record<string, OutputMetadataItem>);

                imageDicomRestrictionAdapter.setAll(state.originalImageDicomRestrictions, imageDicomRestrictionAdapter.getInitialState().entities as Record<string, ImageDicomRestriction>);
                imageOutputGeometryAdapter.setAll(state.originalImageOutputGeometry, imageOutputGeometryAdapter.getInitialState().entities as Record<string, ImageOutputGeometry>);
                imageContourGenerationAdapter.setAll(state.originalImageContourGeneration, imageContourGenerationAdapter.getInitialState().entities as Record<string, ImageContourGeneration>);
                imagePostProcessingAdapter.setAll(state.originalImagePostProcessing, imagePostProcessingAdapter.getInitialState().entities as Record<string, ImagePostProcessing>);
                imageDicomTagAdapter.setAll(state.originalImageDicomTags, imageDicomTagAdapter.getInitialState().entities as Record<string, ImageDicomTag>);
                keepLargestComponentAdapter.setAll(state.originalKeepLargestComponent, keepLargestComponentAdapter.getInitialState().entities as Record<string, KeepLargestComponent>);
                fillHolesAdapter.setAll(state.originalFillHoles, fillHolesAdapter.getInitialState().entities as Record<string, FillHoles>);
                bodyMaskAdapter.setAll(state.originalBodyMasks, bodyMaskAdapter.getInitialState().entities as Record<string, BodyMask>);
                holeMaskAdapter.setAll(state.originalHoleMasks, holeMaskAdapter.getInitialState().entities as Record<string, HoleMask>);

                aeTitleRuleAdapter.setAll(state.originalAeTitleRules, aeTitleRuleAdapter.getInitialState().entities as Record<string, AeTitleRule>);
                dicomRuleAdapter.setAll(state.originalDicomRules, dicomRuleAdapter.getInitialState().entities as Record<string, DicomRule>);
                dicomAttributeRuleAdapter.setAll(state.originalDicomAttributeRules, dicomAttributeRuleAdapter.getInitialState().entities as Record<string, DicomAttributeRule>);
            }

            state.customizationFetchError = errorMessage !== undefined ? errorMessage : null;
            if (errorMessage === undefined) {
                // clear any errors
                backendValidationErrorAdapter.removeAll(state.backendValidationErrors);
                formValidationErrorAdapter.removeAll(state.formValidationErrors);

                console.log('TODO 1: implement initial validation')
                // // calculate initial form validation errors
                // for (const outputId of state.imageOutputs.ids) {
                //     sliceWrapper.performFormValidationForOutput(state, outputId);
                // }
            }
        },

        /**
         * Updates a image model's visibility.
         * @param action.modelId Internal (entity adapter) ID of the image model to update.
         * @param action.visibility The new visibility setting for the image model.
         */
        modelVisibilityUpdated(state, action: PayloadAction<{ modelId: string, visibility: ModelVisibility }>) {
            const { modelId, visibility } = action.payload;
            sliceWrapper.updateModelVisibility(state, modelId, visibility);
        },

        /**
        * Updates the description field on a customization base object.
        * @param action.customizationBaseId Internal (entity adapter) ID of the customization base to update.
        * @param action.description The new description to set.
        */
        modelCustomizationDescriptionUpdated(state, action: PayloadAction<{ customizationBaseId: string, description: string }>) {
            const { customizationBaseId, description } = action.payload;
            sliceWrapper.updateModelCustomizationDescription(state, customizationBaseId, description);
        },

        /**
         * Updates an output metadata item tuple. If either attribute or value is left as undefined then that particular prop is not updated
         * and the existing prop is left unchanged.
         * @param action.metadataId Internal (entity adapter) ID of the output metadata item that will be updated.
         * @param action.attribute The new attribute name of the metadata item that will be used, or undefined if attribute should not be changed.
         * @param action.value The new value of the metadata item, or undefined if value should not be changed.
         * @param action.isUndoOperation Marks this update as part of an undo operation. Note that the correct reverted attribute and value props
         * must be supplied to this function manually, this function does not perform an undo by itself. Optional, defaults to false.
         */
        metadataItemUpdated(state, action: PayloadAction<{ metadataId: string, attribute: string | undefined, value: string | undefined, isUndoOperation?: boolean }>) {
            const { metadataId, attribute, value, isUndoOperation } = action.payload;
            sliceWrapper.updateMetadataItem(state, metadataId, attribute, value, isUndoOperation);
        },

        /**
         * Removes an output metadata item.
         * @param action.metadataId Internal (entity adapter) ID of the output metadata item that will be removed.
         */
        metadataItemRemoved(state, action: PayloadAction<string>) {
            const metadataId = action.payload;
            sliceWrapper.removeMetadataItem(state, metadataId);
        },

        /**
         * Removes a tag from a (newly created) customization metadata item that would mark it as being the target of being
         * automatically scrolled to in UI.
         * 
         * This tag is generally given to metadata items after their creation in the UI and should be removed once
         * the UI has scrolled to the correct position in the UI once.
         * 
         * @param action Internal (entity adapter) ID of the customization metadata item where this tag is to be removed.
         */
        scrollToViewFromModelCustomizationMetadataRemoved(state, action: PayloadAction<string>) {
            const metadataId = action.payload;
            sliceWrapper.removeScrollToViewFromMetadata(state, metadataId);
        },

        /**
        * Adds supplied AE Title rule into image customization configuration.
        * @param action The AE Title rule to add.
        */
        aeTitleRuleAdded(state, action: PayloadAction<AeTitleRule>) {
            const aeTitleRule = action.payload;
            sliceWrapper.addAeTitleRule(state, aeTitleRule);
        },

        /**
         * Updates the 'action' field in an AE Title rule.
         * @param action.id Internal (entity adapter) ID of the AE Title rule to update.
         * @param action.action The new action value for the rule.
         */
        aeTitleRuleActionUpdated(state, action: PayloadAction<{ id: string, action: string }>) {
            const { id, action: newAction } = action.payload;
            sliceWrapper.updateAeTitleRuleAction(state, id, newAction);
        },

        /**
         * Removes an AE Title rule from image customization configuration.
         * Throws if the AE Title rule to be removed is not found from configuration.
         * @param action Internal (entity adapter) ID of the AE Title rule to remove.
         */
        aeTitleRuleRemoved(state, action: PayloadAction<string>) {
            const id = action.payload;
            sliceWrapper.removeAeTitleRule(state, id);
        },

        /**
         * Adds supplied DICOM rule into image customization configuration.
         * Note that DICOM attribute rules under a DICOM rule must be added separately.
         * @param action The DICOM rule to add.
         */
        dicomRuleAdded(state, action: PayloadAction<DicomRule>) {
            const dicomRule = action.payload;
            sliceWrapper.addDicomRule(state, dicomRule);
        },

        /**
         * Removes a DICOM rule from image customization configuration. Also removes any DICOM attribute
         * rules under the DICOM rule.
         * Throws if the DICOM rule to be removed is not found from configuration.
         * @param action Internal (entity adapter) ID of the DICOM rule to remove.
         */
        dicomRuleRemoved(state, action: PayloadAction<string>) {
            const id = action.payload;
            sliceWrapper.removeDicomRule(state, id);
        },

        /**
        * Adds supplied DICOM attribute rule into image customization configuration.
        * Note that the parent DICOM rule must already exist in configuration or this function will throw.
        * @param action The DICOM attribute rule to add.
        */
        dicomAttributeRuleAdded(state, action: PayloadAction<DicomAttributeRule>) {
            const dicomAttributeRule = action.payload;
            sliceWrapper.addDicomAttributeRule(state, dicomAttributeRule);
        },

        /**
         * Removes a DICOM attribute rule from image customization configuration.
         * Throws if the DICOM attribute rule to be removed or its parent DICOM rule are not found from configuration.
         * @param action Internal (entity adapter) ID of the DICOM attribute rule to remove.
         */
        dicomAttributeRuleRemoved(state, action: PayloadAction<string>) {
            const id = action.payload;
            sliceWrapper.removeDicomAttributeRule(state, id);
        },

        /**
         * Updates a DICOM attribute rule.
         * @param action.id Internal (entity adapter) ID of the DICOM attribute rule to update.
         * @param action.dicomAttribute The attribute field of the rule is updated to this value.
         * @param action.dicomValue The DICOM value field of the rule is updated to this value.
         */
        dicomAttributeRuleUpdated(state, action: PayloadAction<{ id: string, dicomAttribute: string, dicomValue: string }>) {
            const { id, dicomAttribute, dicomValue } = action.payload;
            sliceWrapper.updateDicomAttributeRule(state, id, dicomAttribute, dicomValue);
        },

        /**
         * Updates an image dicom restriction's "enabled" status.
         * @param action.id ID of the dicom restriction.
         * @param action.isEnabled Whether dicom restriction will be enabled (true) or disabled (false).
         */
        imageDicomRestrictionEnabledSet(state, action: PayloadAction<{ id: string, isEnabled: boolean }>) {
            const { id, isEnabled } = action.payload;

            const dicomRestriction = state.imageDicomRestrictions.entities[id];
            if (dicomRestriction === undefined) { throw new Error(`Could not find dicom restriction with id ${id}`); }
            if (dicomRestriction.outputId === undefined) { throw new Error(`Could not find parent output for dicom restriction with id ${id}`); }

            imageDicomRestrictionAdapter.updateOne(state.imageDicomRestrictions, { id: id, changes: { isEnabled: isEnabled } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.DicomRestriction);
            sliceWrapper.performFormValidationForOutput(state, dicomRestriction.outputId);
        },

        /**
        * Adds a new DICOM tag with default settings to the given image dicom restriction.
        * @param action ID of the dicom restriction.
        */
        newDicomTagAdded(state, action: PayloadAction<string>) {
            const dicomRestrictionId = action.payload;

            const dicomRestriction = state.imageDicomRestrictions.entities[dicomRestrictionId];
            if (dicomRestriction === undefined) { throw new Error(`Could not find dicom restriction with id ${dicomRestrictionId}`); }
            if (dicomRestriction.outputId === undefined) { throw new Error(`Could not find parent output for dicom restriction with id ${dicomRestrictionId}`); }

            const dicomTag = createNewImageDicomTag('', '', dicomRestrictionId, undefined, true);
            imageDicomTagAdapter.addOne(state.imageDicomTags, dicomTag);
            imageDicomRestrictionAdapter.updateOne(state.imageDicomRestrictions, {
                id: dicomRestrictionId,
                changes: { tags: dicomRestriction.tags ? dicomRestriction.tags.concat(dicomTag.id) : [dicomTag.id] }
            });
            setObjectAndAncestorsAsModified(state, [dicomTag.id], CustomizationObjectType.DicomTag);
            sliceWrapper.performFormValidationForOutput(state, dicomRestriction.outputId);
        },

        /**
         * Sets a DICOM tag's attribute.
         * @param action.id ID of the dicom tag.
         * @param action.attribute The value to set the attribute field to.
         */
        dicomTagAttributeSet(state, action: PayloadAction<{ id: string, attribute: string }>) {
            const { id, attribute } = action.payload;

            const dicomTag = state.imageDicomTags.entities[id];
            if (dicomTag === undefined) { throw new Error(`Could not find DICOM tag with id ${id}`); }

            imageDicomTagAdapter.updateOne(state.imageDicomTags, { id: id, changes: { attribute } });
            setObjectAndAncestorsAsModified(state, [dicomTag.id], CustomizationObjectType.DicomTag);
            console.log('TODO 3: perform validation here?')
            // if (target.outputId) {
            //     sliceWrapper.performFormValidationForOutput(state, target.outputId);
            // }
        },

        /**
         * Sets a DICOM tag's value.
         * @param action.id ID of the dicom tag.
         * @param action.value The value to set the value field to.
         */
        dicomTagValueSet(state, action: PayloadAction<{ id: string, value: string }>) {
            const { id, value } = action.payload;

            const dicomTag = state.imageDicomTags.entities[id];
            if (dicomTag === undefined) { throw new Error(`Could not find DICOM tag with id ${id}`); }

            imageDicomTagAdapter.updateOne(state.imageDicomTags, { id: id, changes: { value } });
            setObjectAndAncestorsAsModified(state, [dicomTag.id], CustomizationObjectType.DicomTag);
            console.log('TODO 3: perform validation here?')
            // if (target.outputId) {
            //     sliceWrapper.performFormValidationForOutput(state, target.outputId);
            // }
        },

        /**
         * Removes dicom tag with given ID
         * @param action ID of the dicom tag.
         */
        dicomTagRemoved(state, action: PayloadAction<string>) {
            const dicomTagId = action.payload;

            const dicomTag = state.imageDicomTags.entities[dicomTagId];
            if (dicomTag === undefined) { throw new Error(`Could not find dicom tag with id ${dicomTagId}`); }

            const dicomRestrictionId = dicomTag.dicomRestrictionId;
            if (!dicomRestrictionId) { throw new Error(`Dicom tag ${dicomTagId} is not linked to a valid dicom restriction object`); }
            const dicomRestriction = state.imageDicomRestrictions.entities[dicomRestrictionId];
            if (dicomRestriction === undefined) { throw new Error(`Could not find dicom restriction with id ${dicomRestrictionId}`); }
            if (dicomRestriction.outputId === undefined) { throw new Error(`Could not find parent output for dicom restriction with id ${dicomRestrictionId}`); }

            imageDicomRestrictionAdapter.updateOne(state.imageDicomRestrictions, { id: dicomRestrictionId, changes: { tags: dicomRestriction.tags ? dicomRestriction.tags.filter(tId => tId !== dicomTagId) : [] } });
            imageDicomTagAdapter.removeOne(state.imageDicomTags, dicomTagId);

            setObjectAndAncestorsAsModified(state, [dicomRestrictionId], CustomizationObjectType.DicomRestriction);
            sliceWrapper.performFormValidationForOutput(state, dicomRestriction.outputId);
        },

        /**
         * Updates an image output geometry's "match input geometry" status.
         * @param action.id ID of the output geometry.
         * @param action.matchInput Whether output geometry will match input (true) or not (false).
         */
        imageOutputGeometryMatchInputSet(state, action: PayloadAction<{ id: string, matchInput: boolean }>) {
            const { id, matchInput } = action.payload;

            const outputGeometry = state.imageOutputGeometry.entities[id];
            if (outputGeometry === undefined) { throw new Error(`Could not find output geometry with id ${id}`); }

            imageOutputGeometryAdapter.updateOne(state.imageOutputGeometry, { id: id, changes: { matchInputGeometry: matchInput } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.OutputGeometry);
            console.log('TODO 4: do validation for output geometry')
            // sliceWrapper.performFormValidationForOutput(state, outputGeometry.outputId);
        },

        /**
         * Sets an image output geometry's FOV size field.
         * @param action.id ID of the output geometry.
         * @param action.fovSize The size to set FOV to.
         */
        imageOutputGeometryFovSizeSet(state, action: PayloadAction<{ id: string, fovSize: LengthValue }>) {
            const { id, fovSize } = action.payload;

            const outputGeometry = state.imageOutputGeometry.entities[id];
            if (outputGeometry === undefined) { throw new Error(`Could not find output geometry with id ${id}`); }

            imageOutputGeometryAdapter.updateOne(state.imageOutputGeometry, { id: id, changes: { fovSize } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.OutputGeometry);
            console.log('TODO 4: do validation for output geometry')
            // sliceWrapper.performFormValidationForOutput(state, outputGeometry.outputId);
        },

        /**
         * Sets an image output geometry's matrix size field.
         * @param action.id ID of the output geometry.
         * @param action.matrixSize The value to set matrix size to.
         */
        imageOutputGeometryMatrixSizeSet(state, action: PayloadAction<{ id: string, matrixSize: number }>) {
            const { id, matrixSize } = action.payload;

            const outputGeometry = state.imageOutputGeometry.entities[id];
            if (outputGeometry === undefined) { throw new Error(`Could not find output geometry with id ${id}`); }

            imageOutputGeometryAdapter.updateOne(state.imageOutputGeometry, { id: id, changes: { matrixSize } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.OutputGeometry);
            console.log('TODO 4: do validation for output geometry')
            // sliceWrapper.performFormValidationForOutput(state, outputGeometry.outputId);
        },

        /**
         * Updates an image output geometry's "match input slice thickness" status.
         * @param action.id ID of the output geometry.
         * @param action.matchInputSliceThickness Whether output geometry will match input slice thickness (true) or not (false).
         */
        imageOutputGeometryMatchInputSliceThicknessSet(state, action: PayloadAction<{ id: string, matchInputSliceThickness: boolean }>) {
            const { id, matchInputSliceThickness } = action.payload;

            const outputGeometry = state.imageOutputGeometry.entities[id];
            if (outputGeometry === undefined) { throw new Error(`Could not find output geometry with id ${id}`); }

            imageOutputGeometryAdapter.updateOne(state.imageOutputGeometry, {
                id: id, changes: {
                    sliceThickness: {
                        ...outputGeometry.sliceThickness,
                        matchInput: matchInputSliceThickness
                    }
                }
            });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.OutputGeometry);
            console.log('TODO 4: do validation for output geometry')
            // sliceWrapper.performFormValidationForOutput(state, outputGeometry.outputId);
        },

        /**
         * Sets an image output geometry's output slice thickness.
         * @param action.id ID of the output geometry.
         * @param action.sliceThickness The size to set output slice thickness to.
         */
        imageOutputGeometryOutputSliceThicknessSet(state, action: PayloadAction<{ id: string, sliceThickness: LengthValue }>) {
            const { id, sliceThickness } = action.payload;

            const outputGeometry = state.imageOutputGeometry.entities[id];
            if (outputGeometry === undefined) { throw new Error(`Could not find output geometry with id ${id}`); }

            imageOutputGeometryAdapter.updateOne(state.imageOutputGeometry,
                {
                    id: id, changes: {
                        sliceThickness: {
                            ...outputGeometry.sliceThickness,
                            sliceThickness: sliceThickness
                        }
                    }
                });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.OutputGeometry);
            console.log('TODO 4: do validation for output geometry')
            // sliceWrapper.performFormValidationForOutput(state, outputGeometry.outputId);
        },

        /**
         * Updates an image contour generation's "enabled" status.
         * @param action.id ID of the contour generation.
         * @param action.isEnabled Whether contour generation will be enabled (true) or disabled (false).
         */
        imageContourGenerationEnabledSet(state, action: PayloadAction<{ id: string, isEnabled: boolean }>) {
            const { id, isEnabled } = action.payload;

            const contourGeneration = state.imageContourGeneration.entities[id];
            if (contourGeneration === undefined) { throw new Error(`Could not find contour generation with id ${id}`); }
            if (contourGeneration.outputId === undefined) { throw new Error(`Could not find parent output for contour generation with id ${id}`); }

            imageContourGenerationAdapter.updateOne(state.imageContourGeneration, { id: id, changes: { isEnabled: isEnabled } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.ContourGeneration);
            sliceWrapper.performFormValidationForOutput(state, contourGeneration.outputId);
        },

        /**
         * Updates an image contour generation's "action" status.
         * @param action.id ID of the contour generation.
         * @param action.actionName The action name to be set.
         */
        imageContourGenerationActionSet(state, action: PayloadAction<{ id: string, actionName: string }>) {
            const { id, actionName } = action.payload;

            const contourGeneration = state.imageContourGeneration.entities[id];
            if (contourGeneration === undefined) { throw new Error(`Could not find contour generation with id ${id}`); }
            if (contourGeneration.outputId === undefined) { throw new Error(`Could not find parent output for contour generation with id ${id}`); }

            imageContourGenerationAdapter.updateOne(state.imageContourGeneration, { id: id, changes: { action: actionName } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.ContourGeneration);
            sliceWrapper.performFormValidationForOutput(state, contourGeneration.outputId);
        },

        /**
         * Updates an image contour generation's "attach to" status.
         * @param action.id ID of the contour generation.
         * @param action.attachTo Whether contour generation will be attached to output or input.
         */
        imageContourGenerationAttachToSet(state, action: PayloadAction<{ id: string, attachTo: ContourAttachSeries }>) {
            const { id, attachTo } = action.payload;

            const contourGeneration = state.imageContourGeneration.entities[id];
            if (contourGeneration === undefined) { throw new Error(`Could not find contour generation with id ${id}`); }
            if (contourGeneration.outputId === undefined) { throw new Error(`Could not find parent output for contour generation with id ${id}`); }

            imageContourGenerationAdapter.updateOne(state.imageContourGeneration, { id: id, changes: { attachTo } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.ContourGeneration);
            sliceWrapper.performFormValidationForOutput(state, contourGeneration.outputId);
        },

        /**
         * Updates an image keep largest component post-processing entry's "enabled" status.
         * @param action.id ID of the keep largest component object.
         * @param action.isEnabled Whether keep largest component post-processing will be enabled (true) or disabled (false).
         */
        imageKeepLargestComponentEnabledSet(state, action: PayloadAction<{ id: string, isEnabled: boolean }>) {
            const { id, isEnabled } = action.payload;

            const keepLargestComponent = state.keepLargestComponent.entities[id];
            if (keepLargestComponent === undefined) { throw new Error(`Could not find keep largest component with id ${id}`); }
            if (keepLargestComponent.postProcessingId === undefined) { throw new Error(`Could not find parent post-processing for keep largest component with id ${id}`); }

            keepLargestComponentAdapter.updateOne(state.keepLargestComponent, { id: id, changes: { isEnabled: isEnabled } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.KeepLargestComponent);
            console.log('TODO 5: perform validation')
            // sliceWrapper.performFormValidationForOutput(state, keepLargestComponent.postProcessingId);
        },

        /**
         * Updates an image fill holes post-processing entry's "enabled" status.
         * @param action.id ID of the fill holes object.
         * @param action.isEnabled Whether fill holes post-processing will be enabled (true) or disabled (false).
         */
        imageFillHolesEnabledSet(state, action: PayloadAction<{ id: string, isEnabled: boolean }>) {
            const { id, isEnabled } = action.payload;

            const fillHoles = state.fillHoles.entities[id];
            if (fillHoles === undefined) { throw new Error(`Could not find fill holes object with id ${id}`); }
            if (fillHoles.postProcessingId === undefined) { throw new Error(`Could not find parent post-processing for fill holes object with id ${id}`); }

            fillHolesAdapter.updateOne(state.fillHoles, { id: id, changes: { isEnabled: isEnabled } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.FillHoles);
            console.log('TODO 5: perform validation')
            // sliceWrapper.performFormValidationForOutput(state, fillHoles.postProcessingId);
        },

        /**
         * Updates an image restore non-human anatomy post-processing entry's "enabled" status.
         * @param action.id ID of the post processing object.
         * @param action.isEnabled Whether restore non-human anatomy post-processing will be enabled (true) or disabled (false).
         */
        imageRestoreNonHumanAnatomyEnabledSet(state, action: PayloadAction<{ id: string, isEnabled: boolean }>) {
            const { id, isEnabled } = action.payload;

            const postProcessing = state.imagePostProcessing.entities[id];
            if (postProcessing === undefined) { throw new Error(`Could not find post-processing object with id ${id}`); }
            if (postProcessing.outputId === undefined) { throw new Error(`Could not find parent output for post-processing object with id ${id}`); }

            imagePostProcessingAdapter.updateOne(state.imagePostProcessing, { id: id, changes: { restoreNonHumanAnatomy: isEnabled } });

            setObjectAndAncestorsAsModified(state, [id], CustomizationObjectType.PostProcessing);
            console.log('TODO 5: perform validation')
            // sliceWrapper.performFormValidationForOutput(state, fillHoles.postProcessingId);
        },


        /**
         * Removes the specified customization base entity and all entities under it in the hierarchy.
         * @param action The ID of the customization base to remove.
         */
        customizationBaseRemoved(state, action: PayloadAction<string>) {
            const customizationBaseId = action.payload;

            sliceWrapper.removeCustomizationBase(state, customizationBaseId);
        },


        /** Unit test helper reducer for setting an object and all its ancestors as modified.
         *
         * Do not use this reducer outside of unit tests!
         */
        _unitTest_customizationObjectPathSetAsModified(state, action: PayloadAction<{ objectIds: string[], objectType: CustomizationObjectType }>) {
            const { objectIds, objectType } = action.payload;
            setObjectAndAncestorsAsModified(state, objectIds, objectType);
        },
    },
    selectors: {

        /** Returns all image+ models that have their visibility set as Always Hidden. */
        selectUserHiddenModels: createSelector(
            (state: ImageSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => m.visibility === ModelVisibility.AlwaysHidden)),

        /** Returns all image+ models that are not in current user's license. */
        selectNonLicensedModels: createSelector(
            (state: ImageSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => !m.isAvailable)),

        /** Returns all image+ models that are deprecated. */
        selectDeprecatedModels: createSelector(
            (state: ImageSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => m.isDeprecated)),

        /** Returns the top-level model name for a image+ output */
        selectModelNameForOutput: (state, outputId: string) => {
            const output = localSelectors.selectOutputById(state, outputId);
            if (output) {
                const customization = localSelectors.selectCustomizationBaseById(state, output.modelCustomizationBaseId);
                if (customization) {
                    const model = localSelectors.selectModelById(state, customization.modelId);
                    if (model) {
                        return model.modelName;
                    }
                }
            }
        },

        /** Returns true if any model customization object has been modified. */
        selectIsAnyCustomizationModelModified: createSelector(
            (state: ImageSliceState): Model[] => localSelectors.selectModels(state),
            (models) => models.some(m => m.isModified)),

        /** Returns true if all model selection rules are valid, false if any of them is invalid. */
        selectIsEverySelectionRuleValid: createSelector(
            [
                (state: ImageSliceState): AeTitleRule[] => localSelectors.selectAeTitleRules(state),
                (state: ImageSliceState): DicomRule[] => localSelectors.selectDicomRules(state),
                (state: ImageSliceState): DicomAttributeRule[] => localSelectors.selectDicomAttributeRules(state),

            ],
            (aeTitleRules, dicomRules, dicomAttributeRules) => aeTitleRules.every(r => r.isValid)
                && dicomRules.every(r => r.isValid)
                && dicomAttributeRules.every(r => r.isValid)),

        /** Returns an array of all customization names and their internal parent model IDs. */
        selectAllCustomizationNamesAndParentModelIds: createSelector(
            (state: ImageSliceState): CustomizationBase[] => localSelectors.selectCustomizationBases(state),
            (customizations) => customizations.map(c => ({ customizationName: c.customizationName, modelId: c.modelId }))),

        /** Returns true if there are ANY unsaved IMAGE+ changes in the app. */
        selectAnyUnsavedImageChangesInApp: (state) => localSelectors.selectIsAnyCustomizationModelModified(state),

        /** Returns dicom restriction object for given image+ customization output id, or undefined if output or 
         * dicom restriction is not found. */
        selectDicomRestrictionForOutput: createSelector(
            [
                (state: ImageSliceState, outputId: string): ImageCustomizationOutput | undefined => localSelectors.selectOutputById(state, outputId),
                (state: ImageSliceState): Record<string, ImageDicomRestriction> => localSelectors.selectDicomRestrictionEntities(state),

            ],
            (output, dicomRestrictionEntities) => output ? dicomRestrictionEntities[output.dicomRestriction] : undefined),

        /** Returns output geometry object for given image+ customization output id, or undefined if output or 
         * output geometry is not found. */
        selectOutputGeometryForOutput: createSelector(
            [
                (state: ImageSliceState, outputId: string): ImageCustomizationOutput | undefined => localSelectors.selectOutputById(state, outputId),
                (state: ImageSliceState): Record<string, ImageOutputGeometry> => localSelectors.selectImageOutputGeometryEntities(state),

            ],
            (output, geometryEntities) => output ? geometryEntities[output.outputGeometry] : undefined),

        /** Returns contour generation object for given image+ customization output id, or undefined if output or
         * contour generation is not found. */
        selectContourGenerationForOutput: createSelector(
            [
                (state: ImageSliceState, outputId: string): ImageCustomizationOutput | undefined => localSelectors.selectOutputById(state, outputId),
                (state: ImageSliceState): Record<string, ImageContourGeneration> => localSelectors.selectImageContourGenerationEntities(state),

            ],
            (output, contourEntities) => output ? contourEntities[output.contourGeneration] : undefined),

        /** Returns post-processing object for given image+ customization output id, or undefined if output or
         * post-processing is not found. */
        selectPostProcessingForOutput: createSelector(
            [
                (state: ImageSliceState, outputId: string): ImageCustomizationOutput | undefined => localSelectors.selectOutputById(state, outputId),
                (state: ImageSliceState): Record<string, ImagePostProcessing> => localSelectors.selectImagePostProcessingEntities(state),

            ],
            (output, postProcessingEntities) => output ? postProcessingEntities[output.postProcessing] : undefined),

        /** Returns dicom restriction, output geometry, contour generation and post-processing configuration objects for given image+ customization
         * output id, or undefined if not found. */
        selectMainConfigurationObjectsForOutput: createSelector(
            [
                (state: ImageSliceState, outputId: string): ImageDicomRestriction | undefined => localSelectors.selectDicomRestrictionForOutput(state, outputId),
                (state: ImageSliceState, outputId: string): ImageOutputGeometry | undefined => localSelectors.selectOutputGeometryForOutput(state, outputId),
                (state: ImageSliceState, outputId: string): ImageContourGeneration | undefined => localSelectors.selectContourGenerationForOutput(state, outputId),
                (state: ImageSliceState, outputId: string): ImagePostProcessing | undefined => localSelectors.selectPostProcessingForOutput(state, outputId),
            ],
            (dicomRestriction, outputGeometry, contourGeneration, postProcessing) => {
                return {
                    dicomRestriction,
                    outputGeometry,
                    contourGeneration,
                    postProcessing
                }
            }
        ),

        selectPostProcessingObjectsForOutput: createSelector(
            [
                (state: ImageSliceState, outputId: string): ImagePostProcessing | undefined => localSelectors.selectPostProcessingForOutput(state, outputId),
                (state: ImageSliceState): Record<string, KeepLargestComponent> => localSelectors.selectKeepLargestComponentEntities(state),
                (state: ImageSliceState): Record<string, FillHoles> => localSelectors.selectFillHolesEntities(state),
                (state: ImageSliceState): Record<string, BodyMask> => localSelectors.selectBodyMaskEntities(state),
                (state: ImageSliceState): Record<string, HoleMask> => localSelectors.selectHoleMaskEntities(state),
            ],
            (
                postProcessing,
                keepLargestComponentEntities,
                fillHolesEntities,
                bodyMaskEntities,
                holeMaskEntities) => {
                    const fillHoles = postProcessing ? fillHolesEntities[postProcessing.fillHoles] : undefined;
                return {
                    postProcessing,
                    keepLargestComponent: postProcessing ? keepLargestComponentEntities[postProcessing.keepLargestComponent] : undefined,
                    fillHoles: postProcessing ? fillHolesEntities[postProcessing.fillHoles] : undefined,
                    bodyMask: fillHoles ? bodyMaskEntities[fillHoles.bodyMask] : undefined,
                    holeMask: fillHoles ? holeMaskEntities[fillHoles.holeMask] : undefined,
                }
            }
        ),

        // ENTITY ADAPTER SELECTORS:
        // for all supported createEntityAdapter selectors see https://redux-toolkit.js.org/api/createEntityAdapter#selector-functions

        selectModels: (state): Model[] => modelAdapterSelectors.selectAll(state),
        selectModelEntities: (state) => modelAdapterSelectors.selectEntities(state),
        selectModelById: (state, id: string): Model | undefined => modelAdapterSelectors.selectById(state, id),
        selectModelsInAlphabeticalOrder: createSelector(
            [(state: ImageSliceState): Model[] => localSelectors.selectModels(state)],
            models => naturalSort(models, 'modelName')),

        selectCustomizationBases: (state) => customizationBaseAdapterSelectors.selectAll(state),
        selectCustomizationBaseIds: (state) => customizationBaseAdapterSelectors.selectIds(state),
        selectCustomizationBaseById: (state, id: string): CustomizationBase | undefined => customizationBaseAdapterSelectors.selectById(state, id),
        selectCustomizationBaseEntities: (state) => customizationBaseAdapterSelectors.selectEntities(state),
        selectCustomizationBasesInAlphabeticalOrder: createSelector(
            [(state: ImageSliceState): CustomizationBase[] => localSelectors.selectCustomizationBases(state)],
            bases => naturalSort(bases, 'customizationName')),

        selectOutputs: (state) => imageOutputAdapterSelectors.selectAll(state),
        selectOutputIds: (state) => imageOutputAdapterSelectors.selectIds(state),
        selectOutputEntities: (state) => imageOutputAdapterSelectors.selectEntities(state),
        selectOutputById: (state, id: string): ImageCustomizationOutput | undefined => imageOutputAdapterSelectors.selectById(state, id),


        selectOutputMetadata: (state) => outputMetadataAdapterSelectors.selectAll(state),
        selectOutputMetadataEntities: (state) => outputMetadataAdapterSelectors.selectEntities(state),
        selectOriginalOutputMetadataEntities: (state) => originalOutputMetadataAdapterSelectors.selectEntities(state),
        selectOutputMetadataById: (state, id: string): OutputMetadataItem | undefined => outputMetadataAdapterSelectors.selectById(state, id),

        selectAeTitleRules: (state) => aeTitleRuleAdapterSelectors.selectAll(state),
        selectAeTitleRuleEntities: (state) => aeTitleRuleAdapterSelectors.selectEntities(state),
        selectAeTitleRuleById: (state, id: string): AeTitleRule | undefined => aeTitleRuleAdapterSelectors.selectById(state, id),

        selectDicomRules: (state) => dicomRuleAdapterSelectors.selectAll(state),
        selectDicomRuleEntities: (state) => dicomRuleAdapterSelectors.selectEntities(state),
        selectDicomRuleById: (state, id: string): DicomRule | undefined => dicomRuleAdapterSelectors.selectById(state, id),

        selectDicomAttributeRules: (state) => dicomAttributeRuleAdapterSelectors.selectAll(state),
        selectDicomAttributeRuleById: (state, id: string): DicomAttributeRule | undefined => dicomAttributeRuleAdapterSelectors.selectById(state, id),

        selectCustomizationValidationError: (state, id: string): BackendValidationErrorViewModel | undefined => customizationValidationErrorAdapterSelectors.selectById(state, id),
        selectCustomizationValidationErrorEntities: (state) => customizationValidationErrorAdapterSelectors.selectEntities(state),

        selectFormValidationErrors: (state) => formValidationErrorAdapterSelectors.selectAll(state),

        selectDicomRestrictions: (state) => imageDicomRestrictionAdapterSelectors.selectAll(state),
        selectDicomRestrictionEntities: (state) => imageDicomRestrictionAdapterSelectors.selectEntities(state),
        selectDicomRestrictionById: (state, id: string): ImageDicomRestriction | undefined => imageDicomRestrictionAdapterSelectors.selectById(state, id),

        selectImageOutputGeometry: (state) => imageOutputGeometryAdapterSelectors.selectAll(state),
        selectImageOutputGeometryEntities: (state) => imageOutputGeometryAdapterSelectors.selectEntities(state),
        selectImageOutputGeometryById: (state, id: string): ImageOutputGeometry | undefined => imageOutputGeometryAdapterSelectors.selectById(state, id),

        selectImageContourGeneration: (state) => imageContourGenerationAdapterSelectors.selectAll(state),
        selectImageContourGenerationEntities: (state) => imageContourGenerationAdapterSelectors.selectEntities(state),
        selectImageContourGenerationById: (state, id: string): ImageContourGeneration | undefined => imageContourGenerationAdapterSelectors.selectById(state, id),

        selectImagePostProcessing: (state) => imagePostProcessingAdapterSelectors.selectAll(state),
        selectImagePostProcessingEntities: (state) => imagePostProcessingAdapterSelectors.selectEntities(state),
        selectImagePostProcessingById: (state, id: string): ImagePostProcessing | undefined => imagePostProcessingAdapterSelectors.selectById(state, id),

        selectDicomTagById: (state, id: string): ImageDicomTag | undefined => imageDicomTagAdapterSelectors.selectById(state, id),
        selectOriginalDicomTagById: (state, id: string): ImageDicomTag | undefined => originalImageDicomTagAdapterSelectors.selectById(state, id),

        selectKeepLargestComponentEntities: (state) => keepLargestComponentAdapterSelectors.selectEntities(state),
        selectFillHolesEntities: (state) => fillHolesAdapterSelectors.selectEntities(state),
        selectBodyMaskEntities: (state) => bodyMaskAdapterSelectors.selectEntities(state),
        selectHoleMaskEntities: (state) => holeMaskAdapterSelectors.selectEntities(state),
    }
});

export const {
    modelCustomizationsSet,
    modelVisibilityUpdated,
    modelCustomizationDescriptionUpdated,
    metadataItemUpdated,
    metadataItemRemoved,
    scrollToViewFromModelCustomizationMetadataRemoved,
    aeTitleRuleAdded,
    aeTitleRuleActionUpdated,
    aeTitleRuleRemoved,
    dicomRuleAdded,
    dicomRuleRemoved,
    dicomAttributeRuleAdded,
    dicomAttributeRuleRemoved,
    dicomAttributeRuleUpdated,

    imageDicomRestrictionEnabledSet,
    newDicomTagAdded,
    dicomTagAttributeSet,
    dicomTagValueSet,
    dicomTagRemoved,
    imageOutputGeometryMatchInputSet,
    imageOutputGeometryFovSizeSet,
    imageOutputGeometryMatrixSizeSet,
    imageOutputGeometryMatchInputSliceThicknessSet,
    imageOutputGeometryOutputSliceThicknessSet,
    imageContourGenerationEnabledSet,
    imageContourGenerationActionSet,
    imageContourGenerationAttachToSet,
    imageKeepLargestComponentEnabledSet,
    imageFillHolesEnabledSet,
    imageRestoreNonHumanAnatomyEnabledSet,

    customizationBaseRemoved,
    // allModelCustomizationsReset,
    // resetAllModelCustomizationsStarted,
    // resetAllModelCustomizationsFinished,
    // singleCustomizationReset,
    // resetSingleOutputStarted,
    // resetSingleOutputFinished,
    // customizationOutputReplaced,
    // customizationRenamed,
    // modelCustomizationExported,
    // modelCustomizationExportFailed,
    // modelCustomizationImported,
    // modelCustomizationImportFailed,

    _unitTest_customizationObjectPathSetAsModified,
    // _unitTest_formValidationErrorAdded,
    // _unitTest_formValidationErrorRemoved,
    // _unitTest_backendValidationErrorAdded,

} = imageSlice.actions;

const localSelectors = imageSlice.getSelectors();

export const { getInitialState, selectors: imageSelectors } = imageSlice;

export default imageSlice.reducer;
