import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { literalTextToRegex } from '../../../util/string-convert';
import { StoreState } from '../../../store/store';
import { createNewAeTitleRule, generateNewId, createNewDicomAttributeRule, createNewDicomRule } from '../../../store/global-types/customization-helpers';
import { ModelType } from '../../../store/global-types/customization-types';
import { ModelSelectionUndoState } from '../ModelSelectionPage';
import SelectionRulesForm from '../SelectionRulesForm';
import { aeTitleRuleAdded, dicomAttributeRuleAdded, dicomRuleAdded, imageSelectors } from '../../../store/image/imageSlice';
import ImageDicomAttributeRule from './ImageDicomAttributeRule';
import ImageAeTitleRule from './ImageAeTitleRule';
import ImageDicomRule from './ImageDicomRule';

interface ImageSelectionRulesProps {
    customizationBaseId: string,
    setUndoState: (undoState: ModelSelectionUndoState) => void,
    heading: string,
    hideCustomizationName?: boolean,
    showMultipleOutputsWarning: boolean,
    /** If true, don't show undo UI options. */
    isUndoDisabled?: boolean,
}

const ImageSelectionRules = (props: ImageSelectionRulesProps) => {
    const { customizationBaseId, setUndoState, heading, hideCustomizationName, showMultipleOutputsWarning, isUndoDisabled } = props;

    const dispatch = useDispatch();

    const customizationBase = useSelector((state: StoreState) => imageSelectors.selectCustomizationBaseById(state, customizationBaseId));
    const model = useSelector((state: StoreState) => customizationBase && imageSelectors.selectModelById(state, customizationBase.modelId));

    const handleAddAeTitleRuleClick = useCallback(() => {
        const newAeTitleRule = createNewAeTitleRule(undefined, null, undefined, undefined,
            undefined, customizationBaseId, true);
        dispatch(aeTitleRuleAdded(newAeTitleRule));
    }, [dispatch, customizationBaseId]);

    const handleAddDicomRuleClick = useCallback(() => {
        const dicomRuleId = generateNewId();
        const newDicomAttribute = createNewDicomAttributeRule('', literalTextToRegex(''), dicomRuleId, true);
        const newDicomRule = createNewDicomRule([newDicomAttribute.id], dicomRuleId,
            customizationBaseId, null, undefined, true);
        dispatch(dicomRuleAdded(newDicomRule));
        dispatch(dicomAttributeRuleAdded(newDicomAttribute));
    }, [dispatch, customizationBaseId]);


    const getDicomAttributeRule = useCallback((id: string, runningNumber: number, onRemoveDicomRuleButtonClicked: () => void) => {
        return (<ImageDicomAttributeRule key={id}
            dicomAttributeRuleId={id} runningNumber={runningNumber}
            setUndoState={setUndoState} isUndoDisabled={isUndoDisabled}
            onRemoveDicomRule={onRemoveDicomRuleButtonClicked}
        />)
    }, [setUndoState, isUndoDisabled]);


    const getAeTitleRule = useCallback((id: string, runningNumber: number) => {
        return (<ImageAeTitleRule key={id}
            aeTitleRuleId={id} runningNumber={runningNumber}
            setUndoState={setUndoState} isUndoDisabled={isUndoDisabled}
        />);
    }, [setUndoState, isUndoDisabled]);

    
    const getDicomRule = useCallback((id: string, runningNumber: number) => {
        return (<ImageDicomRule key={id}
            dicomRuleId={id} runningNumber={runningNumber}
            setUndoState={setUndoState} isUndoDisabled={isUndoDisabled}
            getDicomAttributeRule={getDicomAttributeRule}
        />);
    }, [setUndoState, isUndoDisabled]);


    if (customizationBase === undefined || model === undefined) {
        return null;
    }

    return (
        <SelectionRulesForm
            modelType={ModelType.Image}
            aeTitleRuleIds={customizationBase.aeTitleRules}
            dicomRuleIds={customizationBase.dicomRules}
            modelName={model.modelName}
            modelLabel={model.label}
            customizationName={customizationBase.customizationName}
            isCustomizationBaseModified={customizationBase.isModified}
            onAeTitleRuleAdded={handleAddAeTitleRuleClick}
            onDicomRuleAdded={handleAddDicomRuleClick}
            setUndoState={setUndoState}
            heading={heading}
            hideCustomizationName={hideCustomizationName}
            showMultipleOutputsWarning={showMultipleOutputsWarning}
            isUndoDisabled={isUndoDisabled}
            getAeTitleRule={getAeTitleRule}
            getDicomRule={getDicomRule}
            isDicomSelectionDisabled={false}
        />

    );
}

export default ImageSelectionRules;
