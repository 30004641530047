import { createSelector } from "@reduxjs/toolkit";
import { imageSelectors } from "./imageSlice";
import { StoreState } from "../store";
import { CustomizationObjectType, CustomizationOutput } from "../global-types/customization-types";
import { BackendValidationErrorViewModel, FormValidationError } from "../global-types/store-errors";
import { generateCustomizationTreeViewItems, SelectorWithInputArg } from "../global-types/customizationSelectors";
import { isImageCustomizationOutput } from "./image-types";

/** Generate a tree view hierarchy of models and some of their child objects. This is used by the customization page side navigation.
 * This data isn't ready-to-go for Patternfly's TreeView component but the separate convertTreeViewDataIntoItemHierarchy helper function
 * in customization-helpers.tsx is used to generate the appropriate data from what this function produces.
 */
export const selectImageTreeViewItems = createSelector([
    (state: StoreState) => imageSelectors.selectModelsInAlphabeticalOrder(state),
    (state: StoreState) => imageSelectors.selectCustomizationBasesInAlphabeticalOrder(state),
    (state: StoreState) => imageSelectors.selectOutputEntities(state),
    (state: StoreState) => imageSelectors.selectOutputMetadataEntities(state),
    (state: StoreState) => imageSelectors.selectCustomizationValidationErrorEntities(state),
],
    (models, customizationBases, outputEntities, metadataEntities, validationEntities) => {

        // create an inline validation function that will be supplied to tree view generator function
        const areOutputChildrenValid = (output: CustomizationOutput, validationEntities: Record<string, BackendValidationErrorViewModel>): boolean => {
            if (!isImageCustomizationOutput(output)) {
                throw new Error('Invalid output type');
            }
            console.log('TODO 2: Implement the logic for checking if the output children are valid');
            // return output.rois.every(rId => !validationEntities[rId] || validationEntities[rId]!.type !== CustomizationObjectType.Roi);
            return true;
        }

        const results = generateCustomizationTreeViewItems(models, customizationBases, outputEntities, metadataEntities, validationEntities, areOutputChildrenValid);
        return results;
    });

/**
* Factory function for making a memoized selector that returns true if any metadata for given output id is modified.
*/
export const makeSelectIsAnyMetadataForOutputModified = (): SelectorWithInputArg<boolean, string> => createSelector(
    [
        (state: StoreState, customizationOutputId: string) => imageSelectors.selectOutputById(state, customizationOutputId),
        (state: StoreState) => imageSelectors.selectOutputMetadataEntities(state)
    ],
    (customizationOutput, metadataEntities) => customizationOutput?.metadata.some(mId => metadataEntities[mId]?.isModified) || false
);

/**
 * Factory function for making a memoized selector that returns true if any selection rule (trigger) for a customization base id is modified.
 */
export const makeSelectIsAnyTriggerForBaseModified = (): SelectorWithInputArg<boolean, string> => createSelector(
    [
        (state: StoreState, customizationBaseId) => imageSelectors.selectCustomizationBaseById(state, customizationBaseId),
        (state: StoreState) => imageSelectors.selectAeTitleRuleEntities(state),
        (state: StoreState) => imageSelectors.selectDicomRuleEntities(state)
    ],
    (customizationBase, aeTitleRuleEntities, dicomRuleEntities) => {
        const isAnyAeTitleRuleModified = customizationBase?.aeTitleRules.some(aId => aeTitleRuleEntities[aId]?.isModified) || false;
        if (isAnyAeTitleRuleModified) { return true; }
        return customizationBase?.dicomRules.some(dId => dicomRuleEntities[dId]?.isModified === true) || false;
    }
);

/** Factory function for making a memoized selector that returns all form validation errors objects that are related to a specific dicom rule. */
export const makeSelectFormValidationErrorsForDicomRule = (): SelectorWithInputArg<FormValidationError[], string> => createSelector(
    [(_, dicomRuleId: string) => dicomRuleId, imageSelectors.selectFormValidationErrors],
    (dicomRuleId, formValidationErrors) => formValidationErrors.filter(e => e.itemId === dicomRuleId && e.itemType === CustomizationObjectType.DicomRule)
);

/** Factory function for making a memoized selector that returns all form validation errors objects that are related to a specific dicom attribute rule. */
export const makeSelectFormValidationErrorsForDicomAttributeRule = (): SelectorWithInputArg<FormValidationError[], string> => createSelector(
    [(_, dicomAttributeRuleId: string) => dicomAttributeRuleId, imageSelectors.selectFormValidationErrors],
    (dicomAttributeRuleId, formValidationErrors) => formValidationErrors.filter(e => e.itemId === dicomAttributeRuleId && e.itemType === CustomizationObjectType.DicomAttributeRule)
);
