import { StackItem, TextContent, Text } from '@patternfly/react-core';

import './section.css';

interface SectionHelpTextProps {
    helpText: string,
}

const SectionHelpText = (props: SectionHelpTextProps) => {
    const { helpText } = props;

    return (
        <StackItem className="section-item">
            <div className="section-help-text">
                <TextContent>
                    <Text>
                        {helpText}
                    </Text>
                </TextContent>
            </div>
        </StackItem>
    );

}

export default SectionHelpText;
