import { DataList, FormGroup, InputGroup, Switch } from '@patternfly/react-core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../store/store';
import Section from '../Section';
import SectionItem from '../SectionItem';
import NumberInput from '../../../components/number-input';

import '../customization-form.css';
import { adaptSelectors, expectedResultAreInputsReturnedSet, expectedResultAreResultsCompressedAsZipSet, expectedResultIsRegFileReturnedSet, expectedResultIsRegisteredScanReturnedSet, expectedResultIsRTDoseReturnedSet, expectedResultIsRTPlanReturnedSet, expectedResultIsRTStructReturnedSet, isValidMaskIncludedSet, smoothingSigmaSet } from '../../../store/adapt/adaptSlice';
import SectionHelpText from '../SectionHelpText';
import { DEFAULT_SMOOTHING_SIGMA } from '../../../store/adapt/adapt-types';

interface AdaptOutputForm {
    outputId: string,
}

const AdaptOutputForm = (props: AdaptOutputForm) => {

    const { outputId } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const output = useSelector((state: StoreState) => adaptSelectors.selectOutputById(state, outputId));

    const handleEnableSmoothingSigmaToggled = useCallback(() => {
        if (output) { dispatch(smoothingSigmaSet({ id: outputId, smoothingSigma: output.smoothingSigma ? null : DEFAULT_SMOOTHING_SIGMA })); }
    }, [dispatch, output]);

    const handleSmoothingSigmaChanged = useCallback((value: number) => {
        if (output) { dispatch(smoothingSigmaSet({ id: outputId, smoothingSigma: value })); }
    }, [dispatch, output]);

    const handleIsValidMaskIncludedToggled = useCallback(() => {
        if (output) { dispatch(isValidMaskIncludedSet({ id: outputId, isIncluded: !output.isValidMaskIncluded })); }
    }, [dispatch, output]);

    const handleAreInputsReturnedToggled = useCallback(() => {
        if (output) { dispatch(expectedResultAreInputsReturnedSet({ id: outputId, areInputsReturned: !output.expectedResult.areInputsReturned })); }
    }, [dispatch, output]);

    const handleAreResultsCompressedAsZipToggled = useCallback(() => {
        if (output) { dispatch(expectedResultAreResultsCompressedAsZipSet({ id: outputId, areResultsCompressedAsZip: !output.expectedResult.areResultsCompressedAsZip })); }
    }, [dispatch, output]);

    const handleIsRegFileReturnedToggled = useCallback(() => {
        if (output) { dispatch(expectedResultIsRegFileReturnedSet({ id: outputId, isRegFileReturned: !output.expectedResult.isRegFileReturned })); }
    }, [dispatch, output]);

    const handleIsRegisteredScanReturnedToggled = useCallback(() => {
        if (output) { dispatch(expectedResultIsRegisteredScanReturnedSet({ id: outputId, isRegisteredScanReturned: !output.expectedResult.isRegisteredScanReturned })); }
    }, [dispatch, output]);

    const handleIsRTDoseReturnedToggled = useCallback(() => {
        if (output) { dispatch(expectedResultIsRTDoseReturnedSet({ id: outputId, isRTDoseReturned: !output.expectedResult.isRTDoseReturned })); }
    }, [dispatch, output]);

    const handleIsRTPlanReturnedToggled = useCallback(() => {
        if (output) { dispatch(expectedResultIsRTPlanReturnedSet({ id: outputId, isRTPlanReturned: !output.expectedResult.isRTPlanReturned })); }
    }, [dispatch, output]);

    const handleIsRTStructReturnedToggled = useCallback(() => {
        if (output) { dispatch(expectedResultIsRTStructReturnedSet({ id: outputId, isRTStructReturned: !output.expectedResult.isRTStructReturned })); }
    }, [dispatch, output]);

    // TODO: add section-specific output validity checking here later on if we have
    // any relevant form validation for those parts -- currently we don't
    const outputIsValid = true;

    if (!output) {
        return null;
    }

    return (
        <div>
            <DataList aria-label={`Adapt+ registration customization`} isCompact className="customization-form">

                {/* TODO: pixel spacing once it's been implemented in the models themselves */}

                <Section
                    title={t('customizationPage.adapt.outputSection.smoothingSigma.label')}
                    isModified={false}
                    hasValidationError={!outputIsValid}
                    validationError={undefined}
                >

                    <SectionHelpText
                        helpText={t('customizationPage.adapt.outputSection.smoothingSigma.helpText')}
                    />

                    <SectionItem label={t('customizationPage.adapt.outputSection.smoothingSigma.enabled.label')}>
                        <FormGroup>
                            <InputGroup>
                                <Switch
                                    label={t('customizationPage.adapt.outputSection.smoothingSigma.enabled.on')}
                                    labelOff={t('customizationPage.adapt.outputSection.smoothingSigma.enabled.off')}
                                    isChecked={output.smoothingSigma !== null}
                                    onChange={handleEnableSmoothingSigmaToggled}
                                />
                            </InputGroup>
                        </FormGroup>
                    </SectionItem>


                    {output.smoothingSigma !== null && (
                        <>
                            <SectionItem label={t('customizationPage.adapt.outputSection.smoothingSigma.label')}>
                                <FormGroup>
                                    <NumberInput
                                        fieldId={`adapt-smoothing-sigma-for-${outputId}`}
                                        onChange={handleSmoothingSigmaChanged}
                                        defaultValue={output.smoothingSigma}
                                        lowerBound={0}
                                        upperBound={1}
                                    />
                                </FormGroup>
                            </SectionItem>
                        </>
                    )}
                </Section>

                <Section
                    title={t('customizationPage.adapt.outputSection.includeValidMask.label')}
                    isModified={false}
                    hasValidationError={!outputIsValid}
                    validationError={undefined}
                >
                    <SectionHelpText
                        helpText={t('customizationPage.adapt.outputSection.includeValidMask.helpText')}
                    />

                    <SectionItem label={t('customizationPage.adapt.outputSection.includeValidMask.enabled.label')}>
                        <FormGroup>
                            <InputGroup>
                                <Switch
                                    label={t('customizationPage.adapt.outputSection.includeValidMask.enabled.on')}
                                    labelOff={t('customizationPage.adapt.outputSection.includeValidMask.enabled.off')}
                                    isChecked={output.isValidMaskIncluded}
                                    onChange={handleIsValidMaskIncludedToggled}
                                />
                            </InputGroup>
                        </FormGroup>
                    </SectionItem>

                </Section>

                <Section
                    title={t('customizationPage.adapt.outputSection.expectedResult.title')}
                    isModified={false}
                    hasValidationError={!outputIsValid}
                    validationError={undefined}
                >

                    <SectionHelpText
                        helpText={t('customizationPage.adapt.outputSection.expectedResult.helpText')}
                    />

                    <SectionItem label={t('customizationPage.adapt.outputSection.expectedResult.regFile.label')}>
                        <FormGroup>
                            <InputGroup>
                                <Switch
                                    label={t('customizationPage.adapt.outputSection.expectedResult.regFile.on')}
                                    labelOff={t('customizationPage.adapt.outputSection.expectedResult.regFile.off')}
                                    isChecked={output.expectedResult.isRegFileReturned}
                                    onChange={handleIsRegFileReturnedToggled}
                                />
                            </InputGroup>
                        </FormGroup>
                    </SectionItem>
                    <SectionItem label={t('customizationPage.adapt.outputSection.expectedResult.regScan.label')}>
                        <FormGroup>
                            <InputGroup>
                                <Switch
                                    label={t('customizationPage.adapt.outputSection.expectedResult.regScan.on')}
                                    labelOff={t('customizationPage.adapt.outputSection.expectedResult.regScan.off')}
                                    isChecked={output.expectedResult.isRegisteredScanReturned}
                                    onChange={handleIsRegisteredScanReturnedToggled}
                                />
                            </InputGroup>
                        </FormGroup>
                    </SectionItem>
                    <SectionItem label={t('customizationPage.adapt.outputSection.expectedResult.rtDose.label')}>
                        <FormGroup>
                            <InputGroup>
                                <Switch
                                    label={t('customizationPage.adapt.outputSection.expectedResult.rtDose.on')}
                                    labelOff={t('customizationPage.adapt.outputSection.expectedResult.rtDose.off')}
                                    isChecked={output.expectedResult.isRTDoseReturned}
                                    onChange={handleIsRTDoseReturnedToggled}
                                />
                            </InputGroup>
                        </FormGroup>
                    </SectionItem>
                    <SectionItem label={t('customizationPage.adapt.outputSection.expectedResult.rtPlan.label')}>
                        <FormGroup>
                            <InputGroup>
                                <Switch
                                    label={t('customizationPage.adapt.outputSection.expectedResult.rtPlan.on')}
                                    labelOff={t('customizationPage.adapt.outputSection.expectedResult.rtPlan.off')}
                                    isChecked={output.expectedResult.isRTPlanReturned}
                                    onChange={handleIsRTPlanReturnedToggled}
                                />
                            </InputGroup>
                        </FormGroup>
                    </SectionItem>
                    <SectionItem label={t('customizationPage.adapt.outputSection.expectedResult.rtStruct.label')}>
                        <FormGroup>
                            <InputGroup>
                                <Switch
                                    label={t('customizationPage.adapt.outputSection.expectedResult.rtStruct.on')}
                                    labelOff={t('customizationPage.adapt.outputSection.expectedResult.rtStruct.off')}
                                    isChecked={output.expectedResult.isRTStructReturned}
                                    onChange={handleIsRTStructReturnedToggled}
                                />
                            </InputGroup>
                        </FormGroup>
                    </SectionItem>
                    <SectionItem label={t('customizationPage.adapt.outputSection.expectedResult.zip.label')}>
                        <FormGroup>
                            <InputGroup>
                                <Switch
                                    label={t('customizationPage.adapt.outputSection.expectedResult.zip.on')}
                                    labelOff={t('customizationPage.adapt.outputSection.expectedResult.zip.off')}
                                    isChecked={output.expectedResult.areResultsCompressedAsZip}
                                    onChange={handleAreResultsCompressedAsZipToggled}
                                />
                            </InputGroup>
                        </FormGroup>
                    </SectionItem>
                    <SectionItem label={t('customizationPage.adapt.outputSection.expectedResult.inputs.label')}>
                        <FormGroup>
                            <InputGroup>
                                <Switch
                                    label={t('customizationPage.adapt.outputSection.expectedResult.inputs.on')}
                                    labelOff={t('customizationPage.adapt.outputSection.expectedResult.inputs.off')}
                                    isChecked={output.expectedResult.areInputsReturned}
                                    onChange={handleAreInputsReturnedToggled}
                                />
                            </InputGroup>
                        </FormGroup>
                    </SectionItem>


                </Section>

            </DataList >
        </div>
    );
}

export default AdaptOutputForm;
