import React, { PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TextContent, Text, DataList, Divider, Tooltip, TextInputGroup, Button, Alert, Icon, DropdownItem, Dropdown, MenuToggle, MenuToggleElement, Toolbar, ToolbarContent, ToolbarItem, } from '@patternfly/react-core';
import { LockIcon, InfoCircleIcon, EditIcon, PlusIcon } from '@patternfly/react-icons'
import { usePreviousImmediate } from 'rooks';
import { useTranslation } from 'react-i18next';

import MetadataItemForm from './MetadataItemForm';
import SectionLabel from './SectionLabel';
import { isSomeEnum } from '../../util/enum';
import { notEmpty } from '../../util/filter';
import { CustomizationBase, CustomizationObjectType, CustomizationOutput, Model, ModelVisibility, OutputMetadataItem } from '../../store/global-types/customization-types';
import { BackendValidationErrorViewModel } from '../../store/global-types/store-errors';
import CustomizationSection from './CustomizationSection';
import DebouncedTextInput from '../../components/debounced-text-input';

import './customization-page.css';

interface CustomizationFormProps {
    model: Model,
    customizationBase: CustomizationBase,
    customizationOutput: CustomizationOutput,
    outputMetadata: Record<string, OutputMetadataItem>,
    originalMetadata: Record<string, OutputMetadataItem>,
    validationEntities: Record<string, BackendValidationErrorViewModel>,
    metadataPresets: string[],
    contentSummary?: ReactNode,
    isAnyMetadataModified: boolean,
    isAnyTriggerRuleModified: boolean,
    selectionRules: JSX.Element,
    onAddMetadataEntry?: (customizationOutputId: string) => void,
    onSetModelVisibility: (modelId: string, visibility: ModelVisibility) => void,
    onDescriptionChanged: (description: string) => void,
    onMetadataItemUpdated: (metadataId: string, attribute: string | undefined, value: string | undefined, isUndoOperation?: boolean) => void,
    onMetadaItemRemoved: (metadataId: string) => void,
    removeScrollToViewFromMetadataItem: (metadataId: string) => void,
    isMetadataSectionDisabled?: boolean,
    isDicomSelectionDisabled?: boolean,
    hideTargetFile?: boolean,
}

const CustomizationForm = (props: PropsWithChildren<CustomizationFormProps>) => {
    const {
        model,
        customizationBase,
        customizationOutput,
        outputMetadata,
        originalMetadata,
        validationEntities,
        metadataPresets,
        contentSummary,
        isAnyMetadataModified,
        isAnyTriggerRuleModified,
        selectionRules,
        onAddMetadataEntry,
        onSetModelVisibility,
        onDescriptionChanged,
        onMetadataItemUpdated,
        onMetadaItemRemoved,
        removeScrollToViewFromMetadataItem,
        isMetadataSectionDisabled,
        isDicomSelectionDisabled,
        hideTargetFile,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const { t } = useTranslation();

    const [isMetadataSectionExpanded, setMetadataSectionExpanded] = useState(false);
    const [isTriggersSectionExpanded, setTriggersSectionExpanded] = useState(false);
    const [isModelVisibilitySelectOpen, setIsModelVisibilitySelectOpen] = useState(false);

    const handleToggleMetadataExpanded = useCallback(() => {
        setMetadataSectionExpanded(!isMetadataSectionExpanded);
    }, [isMetadataSectionExpanded]);

    const handleToggleTriggersExpanded = useCallback(() => {
        setTriggersSectionExpanded(!isTriggersSectionExpanded);
    }, [isTriggersSectionExpanded]);

    const handleToggleModelVisibilitySelectOpen = useCallback(() => {
        setIsModelVisibilitySelectOpen(prev => !prev);
    }, []);

    const handleSetModelVisibility = useCallback((_: unknown, value: string | number | undefined) => {
        if (model && isSomeEnum(ModelVisibility)(value)) {
            onSetModelVisibility(model.id, value);
        }
        setIsModelVisibilitySelectOpen(false);
    }, [model, onSetModelVisibility]);

    const handleDescriptionChanged = useCallback((text: string) => {
        onDescriptionChanged(text);
    }, [onDescriptionChanged]);

    const handleAddMetadataEntry = useCallback(() => {
        if (onAddMetadataEntry && customizationOutput.id) {
            onAddMetadataEntry(customizationOutput.id);
        }
    }, [onAddMetadataEntry, customizationOutput.id]);

    const previousCustomization = usePreviousImmediate(customizationOutput);

    useEffect(() => {
        if (previousCustomization && !isMetadataSectionExpanded && customizationOutput.id === previousCustomization.id && customizationOutput.metadata.length !== previousCustomization.metadata.length) {
            // make sure metadata section is expanded if metadata is added or removed
            setMetadataSectionExpanded(true);
        }
    }, [customizationOutput, previousCustomization, isMetadataSectionExpanded]);

    const customizationName = customizationBase.customizationName;

    const onInputRef = () => {
        if (inputRef.current !== null) {
            inputRef.current.focus();
        }
    }

    const triggersHaveValidationErrors = customizationBase.aeTitleRules.some(tId => validationEntities[tId] !== undefined && validationEntities[tId]!.type === CustomizationObjectType.AeTitleRule)
        || customizationBase.dicomRules.some(tId => validationEntities[tId] !== undefined && validationEntities[tId]!.type === CustomizationObjectType.DicomRule);
    const metadataHasValidationErrors = customizationOutput.metadata.some(mId => validationEntities[mId] !== undefined && validationEntities[mId]!.type === CustomizationObjectType.Metadata);
    const customizationValidationErrors = Object.values(validationEntities).filter(notEmpty)
        .filter(v => (v.type === CustomizationObjectType.CustomizationBase && v.id === customizationBase.id) || (v.type === CustomizationObjectType.CustomizationOutput && v.id === customizationOutput.id));

    const dropdownItems = useMemo(() => [
        <DropdownItem key={0} value={ModelVisibility.Default}>{t('modelVisibility.default')}</DropdownItem>,
        <DropdownItem key={1} value={ModelVisibility.AlwaysShown}>
            {t('modelVisibility.alwaysShow')}
            <Tooltip position={'right'} flipBehavior={['left']} content={t('modelVisibility.alwaysShow.description')}><InfoCircleIcon className="visibility-selector-icon" /></Tooltip>
        </DropdownItem>,
        <DropdownItem key={2} value={ModelVisibility.AlwaysHidden}>
            {t('modelVisibility.alwaysHide')}
            <Tooltip position={'right'} flipBehavior={['left']} content={t('modelVisibility.alwaysHide.description')}><InfoCircleIcon className="visibility-selector-icon" /></Tooltip>
        </DropdownItem>
    ], [t]);

    const getModelVisibilityLabel = (visibility: ModelVisibility) => {
        switch (visibility) {
            case ModelVisibility.Default:
                return t('modelVisibility.default');
            case ModelVisibility.AlwaysShown:
                return t('modelVisibility.alwaysShow');
            case ModelVisibility.AlwaysHidden:
                return t('modelVisibility.alwaysHide');
        }
    }

    return (
        <div className="model-customization-form">

            <div className="model-inclusion-bar">
                <TextContent>
                    <Text component="h2">
                        <span className="customization-name">
                            {!model.isAvailable && (<span className="model-customization-no-license icon" title={t('customizationPage.noLicense.tooltip')}><LockIcon /></span>)}
                            <span className={`human-readable-label ${!model.isAvailable ? 'model-customization-no-license' : ''}`}>{model.label}</span>
                            <span className="label-parenthesis">(</span>
                            <span className="model-label">{model.modelName}</span>
                            <span className="model-customization-separator">.</span>
                            <span className="customization-label">{customizationName}</span>
                            <span className="label-parenthesis">)</span>
                        </span>
                        <span className="model-id-summary">
                            {!hideTargetFile && (<span>{t('customizationPage.targetFile')}: <span className="model-id-name">{customizationOutput.filename}</span></span>)}
                            <span>{t('customizationPage.modelVisibility')}:
                            </span>
                            <span className="model-visibility">
                                <Dropdown
                                    onSelect={handleSetModelVisibility}
                                    toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
                                        <MenuToggle ref={toggleRef} onClick={handleToggleModelVisibilitySelectOpen} isExpanded={isModelVisibilitySelectOpen}>
                                            {getModelVisibilityLabel(model.visibility)}
                                        </MenuToggle>)
                                    }
                                    isOpen={isModelVisibilitySelectOpen}
                                    shouldFocusFirstItemOnOpen={false}
                                    onOpenChange={handleToggleModelVisibilitySelectOpen}
                                >{dropdownItems}
                                </Dropdown>
                            </span>
                        </span>
                        <div className="model-description">
                            {model.description && (
                                <div className='span-flex base-model-description'>
                                    <span>{model.description}</span>
                                </div>
                            )}
                            {customizationBase.id && (
                                <div className='span-flex'>
                                    <span style={{ fontWeight: 700 }}>
                                        <TextInputGroup>
                                            <DebouncedTextInput
                                                fieldId="customization-description"
                                                onDebouncedChange={handleDescriptionChanged}
                                                placeholder={t('customizationPage.modelDescription.noDescription')}
                                                inputRef={inputRef}
                                                defaultValue={customizationBase.description}
                                            />
                                            <Tooltip content={t('customizationPage.modelDescription.edit')} position="top">
                                                <Button variant="plain" aria-label={t('customizationPage.modelDescription.edit')} onClick={onInputRef}>
                                                    <Icon><EditIcon /></Icon>
                                                </Button>
                                            </Tooltip>
                                        </TextInputGroup>
                                    </span>
                                </div>
                            )}
                        </div>
                    </Text>

                    {!model.isAvailable && (
                        <Text component="p" className=""><b>{t('customizationPage.noLicense.message')}</b></Text>
                    )}

                    {contentSummary !== undefined && contentSummary}

                </TextContent>

                {customizationValidationErrors.length > 0 && (
                    <div className="toolbar-error selectable">
                        <Alert variant="danger" isInline title={t('customizationPage.validationErrors')}>
                            {customizationValidationErrors.map((v, i) => (<p key={i}>{v.message}</p>))}
                        </Alert>
                    </div>
                )}

                <Divider />
            </div>

            <CustomizationSection
                label={t('customizationPage.triggersSection.title')}
                isModified={isAnyTriggerRuleModified}
                isValid={!triggersHaveValidationErrors}
                itemCount={customizationBase.aeTitleRules.length + customizationBase.dicomRules.length}
                collapsedMessage={!isDicomSelectionDisabled ? t('customizationPage.triggersSection.message') : t('customizationPage.triggersSection.message.noDicomSelection')}
                isExpanded={isTriggersSectionExpanded}
                onClick={handleToggleTriggersExpanded}
            >
                {selectionRules}
            </CustomizationSection>

            {!isMetadataSectionDisabled && <CustomizationSection
                label={t('customizationPage.metadataSection.title')}
                isModified={isAnyMetadataModified}
                isValid={!metadataHasValidationErrors}
                itemCount={customizationOutput.metadata.length}
                collapsedMessage={t('customizationPage.metadataSection.message')}
                isExpanded={isMetadataSectionExpanded}
                onClick={handleToggleMetadataExpanded}
            >
                <DataList isCompact aria-label={`Metadata config form for customization ${customizationName}`}>
                    {customizationOutput.metadata.map(metadataId => (
                        <MetadataItemForm
                            key={metadataId}
                            metadataItem={outputMetadata[metadataId]}
                            originalMetadataItem={originalMetadata[metadataId]}
                            validationEntities={validationEntities}
                            metadataPresets={metadataPresets}
                            onMetadataItemUpdated={onMetadataItemUpdated}
                            onMetadaItemRemoved={onMetadaItemRemoved}
                            removeScrollToViewFromMetadataItem={removeScrollToViewFromMetadataItem}
                        />
                    ))}
                </DataList>
                {onAddMetadataEntry && (
                    <div className="metadata-section">
                        <Toolbar>
                            <ToolbarContent>
                                <ToolbarItem>
                                    <Button
                                        variant="secondary"
                                        icon={<PlusIcon />}
                                        onClick={handleAddMetadataEntry}>{t('customizationToolbar.addMetadataEntry')}</Button>
                                </ToolbarItem>
                            </ToolbarContent>
                        </Toolbar>
                        <Divider />
                    </div>
                )}
            </CustomizationSection>}

            {props.children}
        </div>
    );
}

export default CustomizationForm;
